import { n as normalizeComponent } from "./index.js";
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getAugmentedNamespace(n) {
  if (n.__esModule)
    return n;
  var a = Object.defineProperty({}, "__esModule", { value: true });
  Object.keys(n).forEach(function(k) {
    var d = Object.getOwnPropertyDescriptor(n, k);
    Object.defineProperty(a, k, d.get ? d : {
      enumerable: true,
      get: function() {
        return n[k];
      }
    });
  });
  return a;
}
var esm5 = {};
var BrowserBarcodeReader$1 = {};
var BrowserCodeReader$1 = {};
var ArgumentException$1 = {};
var Exception$1 = {};
function fixProto(target, prototype) {
  var setPrototypeOf = Object.setPrototypeOf;
  setPrototypeOf ? setPrototypeOf(target, prototype) : target.__proto__ = prototype;
}
function fixStack(target, fn) {
  if (fn === void 0) {
    fn = target.constructor;
  }
  var captureStackTrace = Error.captureStackTrace;
  captureStackTrace && captureStackTrace(target, fn);
}
var __extends$A = function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2) {
        if (b2.hasOwnProperty(p)) {
          d2[p] = b2[p];
        }
      }
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var CustomError = function(_super) {
  __extends$A(CustomError2, _super);
  function CustomError2(message) {
    var _newTarget = this.constructor;
    var _this = _super.call(this, message) || this;
    Object.defineProperty(_this, "name", {
      value: _newTarget.name,
      enumerable: false,
      configurable: true
    });
    fixProto(_this, _newTarget.prototype);
    fixStack(_this);
    return _this;
  }
  return CustomError2;
}(Error);
var __spreadArrays = function() {
  var arguments$1 = arguments;
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments$1[i].length;
  }
  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }
  return r;
};
function customErrorFactory(fn, parent) {
  if (parent === void 0) {
    parent = Error;
  }
  function CustomError2() {
    var arguments$1 = arguments;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments$1[_i];
    }
    if (!(this instanceof CustomError2)) {
      return new (CustomError2.bind.apply(CustomError2, __spreadArrays([void 0], args)))();
    }
    parent.apply(this, args);
    Object.defineProperty(this, "name", {
      value: fn.name || parent.name,
      enumerable: false,
      configurable: true
    });
    fn.apply(this, args);
    fixStack(this, CustomError2);
  }
  return Object.defineProperties(CustomError2, {
    prototype: {
      value: Object.create(parent.prototype, {
        constructor: {
          value: CustomError2,
          writable: true,
          configurable: true
        }
      })
    }
  });
}
var customError = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  CustomError,
  customErrorFactory
}, Symbol.toStringTag, { value: "Module" }));
var require$$0 = /* @__PURE__ */ getAugmentedNamespace(customError);
var __extends$z = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(Exception$1, "__esModule", { value: true });
var ts_custom_error_1 = require$$0;
var Exception = function(_super) {
  __extends$z(Exception2, _super);
  function Exception2(message) {
    if (message === void 0) {
      message = void 0;
    }
    var _this = _super.call(this, message) || this;
    _this.message = message;
    return _this;
  }
  return Exception2;
}(ts_custom_error_1.CustomError);
Exception$1.default = Exception;
var __extends$y = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ArgumentException$1, "__esModule", { value: true });
var Exception_1$a = Exception$1;
var ArgumentException = function(_super) {
  __extends$y(ArgumentException2, _super);
  function ArgumentException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ArgumentException2;
}(Exception_1$a.default);
ArgumentException$1.default = ArgumentException;
var BinaryBitmap$1 = {};
var IllegalArgumentException$1 = {};
var __extends$x = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(IllegalArgumentException$1, "__esModule", { value: true });
var Exception_1$9 = Exception$1;
var IllegalArgumentException = function(_super) {
  __extends$x(IllegalArgumentException2, _super);
  function IllegalArgumentException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return IllegalArgumentException2;
}(Exception_1$9.default);
IllegalArgumentException$1.default = IllegalArgumentException;
Object.defineProperty(BinaryBitmap$1, "__esModule", { value: true });
var IllegalArgumentException_1$i = IllegalArgumentException$1;
var BinaryBitmap = function() {
  function BinaryBitmap2(binarizer) {
    this.binarizer = binarizer;
    if (binarizer === null) {
      throw new IllegalArgumentException_1$i.default("Binarizer must be non-null.");
    }
  }
  BinaryBitmap2.prototype.getWidth = function() {
    return this.binarizer.getWidth();
  };
  BinaryBitmap2.prototype.getHeight = function() {
    return this.binarizer.getHeight();
  };
  BinaryBitmap2.prototype.getBlackRow = function(y, row) {
    return this.binarizer.getBlackRow(y, row);
  };
  BinaryBitmap2.prototype.getBlackMatrix = function() {
    if (this.matrix === null || this.matrix === void 0) {
      this.matrix = this.binarizer.getBlackMatrix();
    }
    return this.matrix;
  };
  BinaryBitmap2.prototype.isCropSupported = function() {
    return this.binarizer.getLuminanceSource().isCropSupported();
  };
  BinaryBitmap2.prototype.crop = function(left, top, width, height) {
    var newSource = this.binarizer.getLuminanceSource().crop(left, top, width, height);
    return new BinaryBitmap2(this.binarizer.createBinarizer(newSource));
  };
  BinaryBitmap2.prototype.isRotateSupported = function() {
    return this.binarizer.getLuminanceSource().isRotateSupported();
  };
  BinaryBitmap2.prototype.rotateCounterClockwise = function() {
    var newSource = this.binarizer.getLuminanceSource().rotateCounterClockwise();
    return new BinaryBitmap2(this.binarizer.createBinarizer(newSource));
  };
  BinaryBitmap2.prototype.rotateCounterClockwise45 = function() {
    var newSource = this.binarizer.getLuminanceSource().rotateCounterClockwise45();
    return new BinaryBitmap2(this.binarizer.createBinarizer(newSource));
  };
  BinaryBitmap2.prototype.toString = function() {
    try {
      return this.getBlackMatrix().toString();
    } catch (e) {
      return "";
    }
  };
  return BinaryBitmap2;
}();
BinaryBitmap$1.default = BinaryBitmap;
var ChecksumException$1 = {};
var __extends$w = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ChecksumException$1, "__esModule", { value: true });
var Exception_1$8 = Exception$1;
var ChecksumException = function(_super) {
  __extends$w(ChecksumException2, _super);
  function ChecksumException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ChecksumException2;
}(Exception_1$8.default);
ChecksumException$1.default = ChecksumException;
var HybridBinarizer$1 = {};
var GlobalHistogramBinarizer$1 = {};
var Binarizer$1 = {};
Object.defineProperty(Binarizer$1, "__esModule", { value: true });
var Binarizer = function() {
  function Binarizer2(source) {
    this.source = source;
  }
  Binarizer2.prototype.getLuminanceSource = function() {
    return this.source;
  };
  Binarizer2.prototype.getWidth = function() {
    return this.source.getWidth();
  };
  Binarizer2.prototype.getHeight = function() {
    return this.source.getHeight();
  };
  return Binarizer2;
}();
Binarizer$1.default = Binarizer;
var BitArray$1 = {};
var System$1 = {};
Object.defineProperty(System$1, "__esModule", { value: true });
var System = function() {
  function System2() {
  }
  System2.arraycopy = function(src, srcPos, dest, destPos, length) {
    while (length--) {
      dest[destPos++] = src[srcPos++];
    }
  };
  System2.currentTimeMillis = function() {
    return Date.now();
  };
  return System2;
}();
System$1.default = System;
var Integer$1 = {};
Object.defineProperty(Integer$1, "__esModule", { value: true });
var Integer = function() {
  function Integer2() {
  }
  Integer2.numberOfTrailingZeros = function(i) {
    var y;
    if (i === 0)
      return 32;
    var n = 31;
    y = i << 16;
    if (y !== 0) {
      n -= 16;
      i = y;
    }
    y = i << 8;
    if (y !== 0) {
      n -= 8;
      i = y;
    }
    y = i << 4;
    if (y !== 0) {
      n -= 4;
      i = y;
    }
    y = i << 2;
    if (y !== 0) {
      n -= 2;
      i = y;
    }
    return n - (i << 1 >>> 31);
  };
  Integer2.numberOfLeadingZeros = function(i) {
    if (i === 0) {
      return 32;
    }
    var n = 1;
    if (i >>> 16 === 0) {
      n += 16;
      i <<= 16;
    }
    if (i >>> 24 === 0) {
      n += 8;
      i <<= 8;
    }
    if (i >>> 28 === 0) {
      n += 4;
      i <<= 4;
    }
    if (i >>> 30 === 0) {
      n += 2;
      i <<= 2;
    }
    n -= i >>> 31;
    return n;
  };
  Integer2.toHexString = function(i) {
    return i.toString(16);
  };
  Integer2.bitCount = function(i) {
    i = i - (i >>> 1 & 1431655765);
    i = (i & 858993459) + (i >>> 2 & 858993459);
    i = i + (i >>> 4) & 252645135;
    i = i + (i >>> 8);
    i = i + (i >>> 16);
    return i & 63;
  };
  Integer2.MIN_VALUE_32_BITS = -2147483648;
  return Integer2;
}();
Integer$1.default = Integer;
var Arrays$1 = {};
Object.defineProperty(Arrays$1, "__esModule", { value: true });
var System_1$a = System$1;
var Arrays = function() {
  function Arrays2() {
  }
  Arrays2.equals = function(first, second) {
    if (!first) {
      return false;
    }
    if (!second) {
      return false;
    }
    if (!first.length) {
      return false;
    }
    if (!second.length) {
      return false;
    }
    if (first.length !== second.length) {
      return false;
    }
    for (var i = 0, length_1 = first.length; i < length_1; i++) {
      if (first[i] !== second[i]) {
        return false;
      }
    }
    return true;
  };
  Arrays2.hashCode = function(a) {
    if (a === null) {
      return 0;
    }
    var result = 1;
    for (var _i = 0, a_1 = a; _i < a_1.length; _i++) {
      var element = a_1[_i];
      result = 31 * result + element;
    }
    return result;
  };
  Arrays2.fillUint8Array = function(a, value) {
    for (var i = 0; i !== a.length; i++) {
      a[i] = value;
    }
  };
  Arrays2.copyOf = function(original, newLength) {
    var copy = new Int32Array(newLength);
    System_1$a.default.arraycopy(original, 0, copy, 0, Math.min(original.length, newLength));
    return copy;
  };
  Arrays2.binarySearch = function(ar, el, comparator) {
    if (void 0 === comparator) {
      comparator = Arrays2.numberComparator;
    }
    var m = 0;
    var n = ar.length - 1;
    while (m <= n) {
      var k = n + m >> 1;
      var cmp = comparator(el, ar[k]);
      if (cmp > 0) {
        m = k + 1;
      } else if (cmp < 0) {
        n = k - 1;
      } else {
        return k;
      }
    }
    return -m - 1;
  };
  Arrays2.numberComparator = function(a, b) {
    return a - b;
  };
  return Arrays2;
}();
Arrays$1.default = Arrays;
Object.defineProperty(BitArray$1, "__esModule", { value: true });
var System_1$9 = System$1;
var Integer_1$3 = Integer$1;
var Arrays_1$2 = Arrays$1;
var IllegalArgumentException_1$h = IllegalArgumentException$1;
var BitArray = function() {
  function BitArray2(size, bits) {
    if (void 0 === size) {
      this.size = 0;
      this.bits = new Int32Array(1);
    } else {
      this.size = size;
      if (void 0 === bits || null === bits) {
        this.bits = BitArray2.makeArray(size);
      } else {
        this.bits = bits;
      }
    }
  }
  BitArray2.prototype.getSize = function() {
    return this.size;
  };
  BitArray2.prototype.getSizeInBytes = function() {
    return Math.floor((this.size + 7) / 8);
  };
  BitArray2.prototype.ensureCapacity = function(size) {
    if (size > this.bits.length * 32) {
      var newBits = BitArray2.makeArray(size);
      System_1$9.default.arraycopy(this.bits, 0, newBits, 0, this.bits.length);
      this.bits = newBits;
    }
  };
  BitArray2.prototype.get = function(i) {
    return (this.bits[Math.floor(i / 32)] & 1 << (i & 31)) !== 0;
  };
  BitArray2.prototype.set = function(i) {
    this.bits[Math.floor(i / 32)] |= 1 << (i & 31);
  };
  BitArray2.prototype.flip = function(i) {
    this.bits[Math.floor(i / 32)] ^= 1 << (i & 31);
  };
  BitArray2.prototype.getNextSet = function(from) {
    var size = this.size;
    if (from >= size) {
      return size;
    }
    var bits = this.bits;
    var bitsOffset = Math.floor(from / 32);
    var currentBits = bits[bitsOffset];
    currentBits &= ~((1 << (from & 31)) - 1);
    var length = bits.length;
    while (currentBits === 0) {
      if (++bitsOffset === length) {
        return size;
      }
      currentBits = bits[bitsOffset];
    }
    var result = bitsOffset * 32 + Integer_1$3.default.numberOfTrailingZeros(currentBits);
    return result > size ? size : result;
  };
  BitArray2.prototype.getNextUnset = function(from) {
    var size = this.size;
    if (from >= size) {
      return size;
    }
    var bits = this.bits;
    var bitsOffset = Math.floor(from / 32);
    var currentBits = ~bits[bitsOffset];
    currentBits &= ~((1 << (from & 31)) - 1);
    var length = bits.length;
    while (currentBits === 0) {
      if (++bitsOffset === length) {
        return size;
      }
      currentBits = ~bits[bitsOffset];
    }
    var result = bitsOffset * 32 + Integer_1$3.default.numberOfTrailingZeros(currentBits);
    return result > size ? size : result;
  };
  BitArray2.prototype.setBulk = function(i, newBits) {
    this.bits[Math.floor(i / 32)] = newBits;
  };
  BitArray2.prototype.setRange = function(start, end) {
    if (end < start || start < 0 || end > this.size) {
      throw new IllegalArgumentException_1$h.default();
    }
    if (end === start) {
      return;
    }
    end--;
    var firstInt = Math.floor(start / 32);
    var lastInt = Math.floor(end / 32);
    var bits = this.bits;
    for (var i = firstInt; i <= lastInt; i++) {
      var firstBit = i > firstInt ? 0 : start & 31;
      var lastBit = i < lastInt ? 31 : end & 31;
      var mask = (2 << lastBit) - (1 << firstBit);
      bits[i] |= mask;
    }
  };
  BitArray2.prototype.clear = function() {
    var max = this.bits.length;
    var bits = this.bits;
    for (var i = 0; i < max; i++) {
      bits[i] = 0;
    }
  };
  BitArray2.prototype.isRange = function(start, end, value) {
    if (end < start || start < 0 || end > this.size) {
      throw new IllegalArgumentException_1$h.default();
    }
    if (end === start) {
      return true;
    }
    end--;
    var firstInt = Math.floor(start / 32);
    var lastInt = Math.floor(end / 32);
    var bits = this.bits;
    for (var i = firstInt; i <= lastInt; i++) {
      var firstBit = i > firstInt ? 0 : start & 31;
      var lastBit = i < lastInt ? 31 : end & 31;
      var mask = (2 << lastBit) - (1 << firstBit) & 4294967295;
      if ((bits[i] & mask) !== (value ? mask : 0)) {
        return false;
      }
    }
    return true;
  };
  BitArray2.prototype.appendBit = function(bit) {
    this.ensureCapacity(this.size + 1);
    if (bit) {
      this.bits[Math.floor(this.size / 32)] |= 1 << (this.size & 31);
    }
    this.size++;
  };
  BitArray2.prototype.appendBits = function(value, numBits) {
    if (numBits < 0 || numBits > 32) {
      throw new IllegalArgumentException_1$h.default("Num bits must be between 0 and 32");
    }
    this.ensureCapacity(this.size + numBits);
    this.appendBit;
    for (var numBitsLeft = numBits; numBitsLeft > 0; numBitsLeft--) {
      this.appendBit((value >> numBitsLeft - 1 & 1) === 1);
    }
  };
  BitArray2.prototype.appendBitArray = function(other) {
    var otherSize = other.size;
    this.ensureCapacity(this.size + otherSize);
    this.appendBit;
    for (var i = 0; i < otherSize; i++) {
      this.appendBit(other.get(i));
    }
  };
  BitArray2.prototype.xor = function(other) {
    if (this.size !== other.size) {
      throw new IllegalArgumentException_1$h.default("Sizes don't match");
    }
    var bits = this.bits;
    for (var i = 0, length_1 = bits.length; i < length_1; i++) {
      bits[i] ^= other.bits[i];
    }
  };
  BitArray2.prototype.toBytes = function(bitOffset, array, offset, numBytes) {
    for (var i = 0; i < numBytes; i++) {
      var theByte = 0;
      for (var j = 0; j < 8; j++) {
        if (this.get(bitOffset)) {
          theByte |= 1 << 7 - j;
        }
        bitOffset++;
      }
      array[offset + i] = theByte;
    }
  };
  BitArray2.prototype.getBitArray = function() {
    return this.bits;
  };
  BitArray2.prototype.reverse = function() {
    var newBits = new Int32Array(this.bits.length);
    var len = Math.floor((this.size - 1) / 32);
    var oldBitsLen = len + 1;
    var bits = this.bits;
    for (var i = 0; i < oldBitsLen; i++) {
      var x = bits[i];
      x = x >> 1 & 1431655765 | (x & 1431655765) << 1;
      x = x >> 2 & 858993459 | (x & 858993459) << 2;
      x = x >> 4 & 252645135 | (x & 252645135) << 4;
      x = x >> 8 & 16711935 | (x & 16711935) << 8;
      x = x >> 16 & 65535 | (x & 65535) << 16;
      newBits[len - i] = x;
    }
    if (this.size !== oldBitsLen * 32) {
      var leftOffset = oldBitsLen * 32 - this.size;
      var currentInt = newBits[0] >>> leftOffset;
      for (var i = 1; i < oldBitsLen; i++) {
        var nextInt = newBits[i];
        currentInt |= nextInt << 32 - leftOffset;
        newBits[i - 1] = currentInt;
        currentInt = nextInt >>> leftOffset;
      }
      newBits[oldBitsLen - 1] = currentInt;
    }
    this.bits = newBits;
  };
  BitArray2.makeArray = function(size) {
    return new Int32Array(Math.floor((size + 31) / 32));
  };
  BitArray2.prototype.equals = function(o) {
    if (!(o instanceof BitArray2)) {
      return false;
    }
    var other = o;
    return this.size === other.size && Arrays_1$2.default.equals(this.bits, other.bits);
  };
  BitArray2.prototype.hashCode = function() {
    return 31 * this.size + Arrays_1$2.default.hashCode(this.bits);
  };
  BitArray2.prototype.toString = function() {
    var result = "";
    for (var i = 0, size = this.size; i < size; i++) {
      if ((i & 7) === 0) {
        result += " ";
      }
      result += this.get(i) ? "X" : ".";
    }
    return result;
  };
  BitArray2.prototype.clone = function() {
    return new BitArray2(this.size, this.bits.slice());
  };
  return BitArray2;
}();
BitArray$1.default = BitArray;
var BitMatrix$1 = {};
var StringBuilder$1 = {};
Object.defineProperty(StringBuilder$1, "__esModule", { value: true });
var StringBuilder = function() {
  function StringBuilder2(value) {
    if (value === void 0) {
      value = "";
    }
    this.value = value;
  }
  StringBuilder2.prototype.append = function(s) {
    if (typeof s === "string") {
      this.value += s.toString();
    } else {
      this.value += String.fromCharCode(s);
    }
    return this;
  };
  StringBuilder2.prototype.length = function() {
    return this.value.length;
  };
  StringBuilder2.prototype.charAt = function(n) {
    return this.value.charAt(n);
  };
  StringBuilder2.prototype.deleteCharAt = function(n) {
    this.value = this.value.substr(0, n) + this.value.substring(n + 1);
  };
  StringBuilder2.prototype.setCharAt = function(n, c) {
    this.value = this.value.substr(0, n) + c + this.value.substr(n + 1);
  };
  StringBuilder2.prototype.toString = function() {
    return this.value;
  };
  StringBuilder2.prototype.insert = function(n, c) {
    this.value = this.value.substr(0, n) + c + this.value.substr(n + c.length);
  };
  return StringBuilder2;
}();
StringBuilder$1.default = StringBuilder;
Object.defineProperty(BitMatrix$1, "__esModule", { value: true });
var BitArray_1$4 = BitArray$1;
var System_1$8 = System$1;
var Arrays_1$1 = Arrays$1;
var StringBuilder_1$7 = StringBuilder$1;
var IllegalArgumentException_1$g = IllegalArgumentException$1;
var BitMatrix = function() {
  function BitMatrix2(width, height, rowSize, bits) {
    this.width = width;
    this.height = height;
    this.rowSize = rowSize;
    this.bits = bits;
    if (void 0 === height || null === height) {
      height = width;
    }
    this.height = height;
    if (width < 1 || height < 1) {
      throw new IllegalArgumentException_1$g.default("Both dimensions must be greater than 0");
    }
    if (void 0 === rowSize || null === rowSize) {
      rowSize = Math.floor((width + 31) / 32);
    }
    this.rowSize = rowSize;
    if (void 0 === bits || null === bits) {
      this.bits = new Int32Array(this.rowSize * this.height);
    }
  }
  BitMatrix2.parseFromBooleanArray = function(image) {
    var height = image.length;
    var width = image[0].length;
    var bits = new BitMatrix2(width, height);
    for (var i = 0; i < height; i++) {
      var imageI = image[i];
      for (var j = 0; j < width; j++) {
        if (imageI[j]) {
          bits.set(j, i);
        }
      }
    }
    return bits;
  };
  BitMatrix2.parseFromString = function(stringRepresentation, setString, unsetString) {
    if (stringRepresentation === null) {
      throw new IllegalArgumentException_1$g.default("stringRepresentation cannot be null");
    }
    var bits = new Array(stringRepresentation.length);
    var bitsPos = 0;
    var rowStartPos = 0;
    var rowLength = -1;
    var nRows = 0;
    var pos = 0;
    while (pos < stringRepresentation.length) {
      if (stringRepresentation.charAt(pos) === "\n" || stringRepresentation.charAt(pos) === "\r") {
        if (bitsPos > rowStartPos) {
          if (rowLength === -1) {
            rowLength = bitsPos - rowStartPos;
          } else if (bitsPos - rowStartPos !== rowLength) {
            throw new IllegalArgumentException_1$g.default("row lengths do not match");
          }
          rowStartPos = bitsPos;
          nRows++;
        }
        pos++;
      } else if (stringRepresentation.substring(pos, pos + setString.length) === setString) {
        pos += setString.length;
        bits[bitsPos] = true;
        bitsPos++;
      } else if (stringRepresentation.substring(pos, pos + unsetString.length) === unsetString) {
        pos += unsetString.length;
        bits[bitsPos] = false;
        bitsPos++;
      } else {
        throw new IllegalArgumentException_1$g.default("illegal character encountered: " + stringRepresentation.substring(pos));
      }
    }
    if (bitsPos > rowStartPos) {
      if (rowLength === -1) {
        rowLength = bitsPos - rowStartPos;
      } else if (bitsPos - rowStartPos !== rowLength) {
        throw new IllegalArgumentException_1$g.default("row lengths do not match");
      }
      nRows++;
    }
    var matrix = new BitMatrix2(rowLength, nRows);
    for (var i = 0; i < bitsPos; i++) {
      if (bits[i]) {
        matrix.set(Math.floor(i % rowLength), Math.floor(i / rowLength));
      }
    }
    return matrix;
  };
  BitMatrix2.prototype.get = function(x, y) {
    var offset = y * this.rowSize + Math.floor(x / 32);
    return (this.bits[offset] >>> (x & 31) & 1) !== 0;
  };
  BitMatrix2.prototype.set = function(x, y) {
    var offset = y * this.rowSize + Math.floor(x / 32);
    this.bits[offset] |= 1 << (x & 31) & 4294967295;
  };
  BitMatrix2.prototype.unset = function(x, y) {
    var offset = y * this.rowSize + Math.floor(x / 32);
    this.bits[offset] &= ~(1 << (x & 31) & 4294967295);
  };
  BitMatrix2.prototype.flip = function(x, y) {
    var offset = y * this.rowSize + Math.floor(x / 32);
    this.bits[offset] ^= 1 << (x & 31) & 4294967295;
  };
  BitMatrix2.prototype.xor = function(mask) {
    if (this.width !== mask.getWidth() || this.height !== mask.getHeight() || this.rowSize !== mask.getRowSize()) {
      throw new IllegalArgumentException_1$g.default("input matrix dimensions do not match");
    }
    var rowArray = new BitArray_1$4.default(Math.floor(this.width / 32) + 1);
    var rowSize = this.rowSize;
    var bits = this.bits;
    for (var y = 0, height = this.height; y < height; y++) {
      var offset = y * rowSize;
      var row = mask.getRow(y, rowArray).getBitArray();
      for (var x = 0; x < rowSize; x++) {
        bits[offset + x] ^= row[x];
      }
    }
  };
  BitMatrix2.prototype.clear = function() {
    var bits = this.bits;
    var max = bits.length;
    for (var i = 0; i < max; i++) {
      bits[i] = 0;
    }
  };
  BitMatrix2.prototype.setRegion = function(left, top, width, height) {
    if (top < 0 || left < 0) {
      throw new IllegalArgumentException_1$g.default("Left and top must be nonnegative");
    }
    if (height < 1 || width < 1) {
      throw new IllegalArgumentException_1$g.default("Height and width must be at least 1");
    }
    var right = left + width;
    var bottom = top + height;
    if (bottom > this.height || right > this.width) {
      throw new IllegalArgumentException_1$g.default("The region must fit inside the matrix");
    }
    var rowSize = this.rowSize;
    var bits = this.bits;
    for (var y = top; y < bottom; y++) {
      var offset = y * rowSize;
      for (var x = left; x < right; x++) {
        bits[offset + Math.floor(x / 32)] |= 1 << (x & 31) & 4294967295;
      }
    }
  };
  BitMatrix2.prototype.getRow = function(y, row) {
    if (row === null || row === void 0 || row.getSize() < this.width) {
      row = new BitArray_1$4.default(this.width);
    } else {
      row.clear();
    }
    var rowSize = this.rowSize;
    var bits = this.bits;
    var offset = y * rowSize;
    for (var x = 0; x < rowSize; x++) {
      row.setBulk(x * 32, bits[offset + x]);
    }
    return row;
  };
  BitMatrix2.prototype.setRow = function(y, row) {
    System_1$8.default.arraycopy(row.getBitArray(), 0, this.bits, y * this.rowSize, this.rowSize);
  };
  BitMatrix2.prototype.rotate180 = function() {
    var width = this.getWidth();
    var height = this.getHeight();
    var topRow = new BitArray_1$4.default(width);
    var bottomRow = new BitArray_1$4.default(width);
    for (var i = 0, length_1 = Math.floor((height + 1) / 2); i < length_1; i++) {
      topRow = this.getRow(i, topRow);
      bottomRow = this.getRow(height - 1 - i, bottomRow);
      topRow.reverse();
      bottomRow.reverse();
      this.setRow(i, bottomRow);
      this.setRow(height - 1 - i, topRow);
    }
  };
  BitMatrix2.prototype.getEnclosingRectangle = function() {
    var width = this.width;
    var height = this.height;
    var rowSize = this.rowSize;
    var bits = this.bits;
    var left = width;
    var top = height;
    var right = -1;
    var bottom = -1;
    for (var y = 0; y < height; y++) {
      for (var x32 = 0; x32 < rowSize; x32++) {
        var theBits = bits[y * rowSize + x32];
        if (theBits !== 0) {
          if (y < top) {
            top = y;
          }
          if (y > bottom) {
            bottom = y;
          }
          if (x32 * 32 < left) {
            var bit = 0;
            while ((theBits << 31 - bit & 4294967295) === 0) {
              bit++;
            }
            if (x32 * 32 + bit < left) {
              left = x32 * 32 + bit;
            }
          }
          if (x32 * 32 + 31 > right) {
            var bit = 31;
            while (theBits >>> bit === 0) {
              bit--;
            }
            if (x32 * 32 + bit > right) {
              right = x32 * 32 + bit;
            }
          }
        }
      }
    }
    if (right < left || bottom < top) {
      return null;
    }
    return Int32Array.from([left, top, right - left + 1, bottom - top + 1]);
  };
  BitMatrix2.prototype.getTopLeftOnBit = function() {
    var rowSize = this.rowSize;
    var bits = this.bits;
    var bitsOffset = 0;
    while (bitsOffset < bits.length && bits[bitsOffset] === 0) {
      bitsOffset++;
    }
    if (bitsOffset === bits.length) {
      return null;
    }
    var y = bitsOffset / rowSize;
    var x = bitsOffset % rowSize * 32;
    var theBits = bits[bitsOffset];
    var bit = 0;
    while ((theBits << 31 - bit & 4294967295) === 0) {
      bit++;
    }
    x += bit;
    return Int32Array.from([x, y]);
  };
  BitMatrix2.prototype.getBottomRightOnBit = function() {
    var rowSize = this.rowSize;
    var bits = this.bits;
    var bitsOffset = bits.length - 1;
    while (bitsOffset >= 0 && bits[bitsOffset] === 0) {
      bitsOffset--;
    }
    if (bitsOffset < 0) {
      return null;
    }
    var y = Math.floor(bitsOffset / rowSize);
    var x = Math.floor(bitsOffset % rowSize) * 32;
    var theBits = bits[bitsOffset];
    var bit = 31;
    while (theBits >>> bit === 0) {
      bit--;
    }
    x += bit;
    return Int32Array.from([x, y]);
  };
  BitMatrix2.prototype.getWidth = function() {
    return this.width;
  };
  BitMatrix2.prototype.getHeight = function() {
    return this.height;
  };
  BitMatrix2.prototype.getRowSize = function() {
    return this.rowSize;
  };
  BitMatrix2.prototype.equals = function(o) {
    if (!(o instanceof BitMatrix2)) {
      return false;
    }
    var other = o;
    return this.width === other.width && this.height === other.height && this.rowSize === other.rowSize && Arrays_1$1.default.equals(this.bits, other.bits);
  };
  BitMatrix2.prototype.hashCode = function() {
    var hash = this.width;
    hash = 31 * hash + this.width;
    hash = 31 * hash + this.height;
    hash = 31 * hash + this.rowSize;
    hash = 31 * hash + Arrays_1$1.default.hashCode(this.bits);
    return hash;
  };
  BitMatrix2.prototype.toString = function(setString, unsetString, lineSeparator) {
    if (setString === void 0) {
      setString = "x";
    }
    if (unsetString === void 0) {
      unsetString = " ";
    }
    if (lineSeparator === void 0) {
      lineSeparator = "\n";
    }
    return this.buildToString(setString, unsetString, lineSeparator);
  };
  BitMatrix2.prototype.buildToString = function(setString, unsetString, lineSeparator) {
    var result = new StringBuilder_1$7.default();
    result.append(lineSeparator);
    for (var y = 0, height = this.height; y < height; y++) {
      for (var x = 0, width = this.width; x < width; x++) {
        result.append(this.get(x, y) ? setString : unsetString);
      }
      result.append(lineSeparator);
    }
    return result.toString();
  };
  BitMatrix2.prototype.clone = function() {
    return new BitMatrix2(this.width, this.height, this.rowSize, this.bits.slice());
  };
  return BitMatrix2;
}();
BitMatrix$1.default = BitMatrix;
var NotFoundException$1 = {};
var __extends$v = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(NotFoundException$1, "__esModule", { value: true });
var Exception_1$7 = Exception$1;
var NotFoundException = function(_super) {
  __extends$v(NotFoundException2, _super);
  function NotFoundException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return NotFoundException2;
}(Exception_1$7.default);
NotFoundException$1.default = NotFoundException;
var __extends$u = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(GlobalHistogramBinarizer$1, "__esModule", { value: true });
var Binarizer_1 = Binarizer$1;
var BitArray_1$3 = BitArray$1;
var BitMatrix_1$8 = BitMatrix$1;
var NotFoundException_1$n = NotFoundException$1;
var GlobalHistogramBinarizer = function(_super) {
  __extends$u(GlobalHistogramBinarizer2, _super);
  function GlobalHistogramBinarizer2(source) {
    var _this = _super.call(this, source) || this;
    _this.luminances = GlobalHistogramBinarizer2.EMPTY;
    _this.buckets = new Int32Array(GlobalHistogramBinarizer2.LUMINANCE_BUCKETS);
    return _this;
  }
  GlobalHistogramBinarizer2.prototype.getBlackRow = function(y, row) {
    var source = this.getLuminanceSource();
    var width = source.getWidth();
    if (row === void 0 || row === null || row.getSize() < width) {
      row = new BitArray_1$3.default(width);
    } else {
      row.clear();
    }
    this.initArrays(width);
    var localLuminances = source.getRow(y, this.luminances);
    var localBuckets = this.buckets;
    for (var x = 0; x < width; x++) {
      localBuckets[(localLuminances[x] & 255) >> GlobalHistogramBinarizer2.LUMINANCE_SHIFT]++;
    }
    var blackPoint = GlobalHistogramBinarizer2.estimateBlackPoint(localBuckets);
    if (width < 3) {
      for (var x = 0; x < width; x++) {
        if ((localLuminances[x] & 255) < blackPoint) {
          row.set(x);
        }
      }
    } else {
      var left = localLuminances[0] & 255;
      var center = localLuminances[1] & 255;
      for (var x = 1; x < width - 1; x++) {
        var right = localLuminances[x + 1] & 255;
        if ((center * 4 - left - right) / 2 < blackPoint) {
          row.set(x);
        }
        left = center;
        center = right;
      }
    }
    return row;
  };
  GlobalHistogramBinarizer2.prototype.getBlackMatrix = function() {
    var source = this.getLuminanceSource();
    var width = source.getWidth();
    var height = source.getHeight();
    var matrix = new BitMatrix_1$8.default(width, height);
    this.initArrays(width);
    var localBuckets = this.buckets;
    for (var y = 1; y < 5; y++) {
      var row = height * y / 5;
      var localLuminances_1 = source.getRow(row, this.luminances);
      var right = Math.floor(width * 4 / 5);
      for (var x = Math.floor(width / 5); x < right; x++) {
        var pixel = localLuminances_1[x] & 255;
        localBuckets[pixel >> GlobalHistogramBinarizer2.LUMINANCE_SHIFT]++;
      }
    }
    var blackPoint = GlobalHistogramBinarizer2.estimateBlackPoint(localBuckets);
    var localLuminances = source.getMatrix();
    for (var y = 0; y < height; y++) {
      var offset = y * width;
      for (var x = 0; x < width; x++) {
        var pixel = localLuminances[offset + x] & 255;
        if (pixel < blackPoint) {
          matrix.set(x, y);
        }
      }
    }
    return matrix;
  };
  GlobalHistogramBinarizer2.prototype.createBinarizer = function(source) {
    return new GlobalHistogramBinarizer2(source);
  };
  GlobalHistogramBinarizer2.prototype.initArrays = function(luminanceSize) {
    if (this.luminances.length < luminanceSize) {
      this.luminances = new Uint8ClampedArray(luminanceSize);
    }
    var buckets = this.buckets;
    for (var x = 0; x < GlobalHistogramBinarizer2.LUMINANCE_BUCKETS; x++) {
      buckets[x] = 0;
    }
  };
  GlobalHistogramBinarizer2.estimateBlackPoint = function(buckets) {
    var numBuckets = buckets.length;
    var maxBucketCount = 0;
    var firstPeak = 0;
    var firstPeakSize = 0;
    for (var x = 0; x < numBuckets; x++) {
      if (buckets[x] > firstPeakSize) {
        firstPeak = x;
        firstPeakSize = buckets[x];
      }
      if (buckets[x] > maxBucketCount) {
        maxBucketCount = buckets[x];
      }
    }
    var secondPeak = 0;
    var secondPeakScore = 0;
    for (var x = 0; x < numBuckets; x++) {
      var distanceToBiggest = x - firstPeak;
      var score = buckets[x] * distanceToBiggest * distanceToBiggest;
      if (score > secondPeakScore) {
        secondPeak = x;
        secondPeakScore = score;
      }
    }
    if (firstPeak > secondPeak) {
      var temp = firstPeak;
      firstPeak = secondPeak;
      secondPeak = temp;
    }
    if (secondPeak - firstPeak <= numBuckets / 16) {
      throw new NotFoundException_1$n.default();
    }
    var bestValley = secondPeak - 1;
    var bestValleyScore = -1;
    for (var x = secondPeak - 1; x > firstPeak; x--) {
      var fromFirst = x - firstPeak;
      var score = fromFirst * fromFirst * (secondPeak - x) * (maxBucketCount - buckets[x]);
      if (score > bestValleyScore) {
        bestValley = x;
        bestValleyScore = score;
      }
    }
    return bestValley << GlobalHistogramBinarizer2.LUMINANCE_SHIFT;
  };
  GlobalHistogramBinarizer2.LUMINANCE_BITS = 5;
  GlobalHistogramBinarizer2.LUMINANCE_SHIFT = 8 - GlobalHistogramBinarizer2.LUMINANCE_BITS;
  GlobalHistogramBinarizer2.LUMINANCE_BUCKETS = 1 << GlobalHistogramBinarizer2.LUMINANCE_BITS;
  GlobalHistogramBinarizer2.EMPTY = Uint8ClampedArray.from([0]);
  return GlobalHistogramBinarizer2;
}(Binarizer_1.default);
GlobalHistogramBinarizer$1.default = GlobalHistogramBinarizer;
var __extends$t = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(HybridBinarizer$1, "__esModule", { value: true });
var GlobalHistogramBinarizer_1 = GlobalHistogramBinarizer$1;
var BitMatrix_1$7 = BitMatrix$1;
var HybridBinarizer = function(_super) {
  __extends$t(HybridBinarizer2, _super);
  function HybridBinarizer2(source) {
    var _this = _super.call(this, source) || this;
    _this.matrix = null;
    return _this;
  }
  HybridBinarizer2.prototype.getBlackMatrix = function() {
    if (this.matrix !== null) {
      return this.matrix;
    }
    var source = this.getLuminanceSource();
    var width = source.getWidth();
    var height = source.getHeight();
    if (width >= HybridBinarizer2.MINIMUM_DIMENSION && height >= HybridBinarizer2.MINIMUM_DIMENSION) {
      var luminances = source.getMatrix();
      var subWidth = width >> HybridBinarizer2.BLOCK_SIZE_POWER;
      if ((width & HybridBinarizer2.BLOCK_SIZE_MASK) !== 0) {
        subWidth++;
      }
      var subHeight = height >> HybridBinarizer2.BLOCK_SIZE_POWER;
      if ((height & HybridBinarizer2.BLOCK_SIZE_MASK) !== 0) {
        subHeight++;
      }
      var blackPoints = HybridBinarizer2.calculateBlackPoints(luminances, subWidth, subHeight, width, height);
      var newMatrix = new BitMatrix_1$7.default(width, height);
      HybridBinarizer2.calculateThresholdForBlock(luminances, subWidth, subHeight, width, height, blackPoints, newMatrix);
      this.matrix = newMatrix;
    } else {
      this.matrix = _super.prototype.getBlackMatrix.call(this);
    }
    return this.matrix;
  };
  HybridBinarizer2.prototype.createBinarizer = function(source) {
    return new HybridBinarizer2(source);
  };
  HybridBinarizer2.calculateThresholdForBlock = function(luminances, subWidth, subHeight, width, height, blackPoints, matrix) {
    var maxYOffset = height - HybridBinarizer2.BLOCK_SIZE;
    var maxXOffset = width - HybridBinarizer2.BLOCK_SIZE;
    for (var y = 0; y < subHeight; y++) {
      var yoffset = y << HybridBinarizer2.BLOCK_SIZE_POWER;
      if (yoffset > maxYOffset) {
        yoffset = maxYOffset;
      }
      var top_1 = HybridBinarizer2.cap(y, 2, subHeight - 3);
      for (var x = 0; x < subWidth; x++) {
        var xoffset = x << HybridBinarizer2.BLOCK_SIZE_POWER;
        if (xoffset > maxXOffset) {
          xoffset = maxXOffset;
        }
        var left = HybridBinarizer2.cap(x, 2, subWidth - 3);
        var sum = 0;
        for (var z = -2; z <= 2; z++) {
          var blackRow = blackPoints[top_1 + z];
          sum += blackRow[left - 2] + blackRow[left - 1] + blackRow[left] + blackRow[left + 1] + blackRow[left + 2];
        }
        var average = sum / 25;
        HybridBinarizer2.thresholdBlock(luminances, xoffset, yoffset, average, width, matrix);
      }
    }
  };
  HybridBinarizer2.cap = function(value, min, max) {
    return value < min ? min : value > max ? max : value;
  };
  HybridBinarizer2.thresholdBlock = function(luminances, xoffset, yoffset, threshold, stride, matrix) {
    for (var y = 0, offset = yoffset * stride + xoffset; y < HybridBinarizer2.BLOCK_SIZE; y++, offset += stride) {
      for (var x = 0; x < HybridBinarizer2.BLOCK_SIZE; x++) {
        if ((luminances[offset + x] & 255) <= threshold) {
          matrix.set(xoffset + x, yoffset + y);
        }
      }
    }
  };
  HybridBinarizer2.calculateBlackPoints = function(luminances, subWidth, subHeight, width, height) {
    var maxYOffset = height - HybridBinarizer2.BLOCK_SIZE;
    var maxXOffset = width - HybridBinarizer2.BLOCK_SIZE;
    var blackPoints = new Array(subHeight);
    for (var y = 0; y < subHeight; y++) {
      blackPoints[y] = new Int32Array(subWidth);
      var yoffset = y << HybridBinarizer2.BLOCK_SIZE_POWER;
      if (yoffset > maxYOffset) {
        yoffset = maxYOffset;
      }
      for (var x = 0; x < subWidth; x++) {
        var xoffset = x << HybridBinarizer2.BLOCK_SIZE_POWER;
        if (xoffset > maxXOffset) {
          xoffset = maxXOffset;
        }
        var sum = 0;
        var min = 255;
        var max = 0;
        for (var yy = 0, offset = yoffset * width + xoffset; yy < HybridBinarizer2.BLOCK_SIZE; yy++, offset += width) {
          for (var xx = 0; xx < HybridBinarizer2.BLOCK_SIZE; xx++) {
            var pixel = luminances[offset + xx] & 255;
            sum += pixel;
            if (pixel < min) {
              min = pixel;
            }
            if (pixel > max) {
              max = pixel;
            }
          }
          if (max - min > HybridBinarizer2.MIN_DYNAMIC_RANGE) {
            for (yy++, offset += width; yy < HybridBinarizer2.BLOCK_SIZE; yy++, offset += width) {
              for (var xx = 0; xx < HybridBinarizer2.BLOCK_SIZE; xx++) {
                sum += luminances[offset + xx] & 255;
              }
            }
          }
        }
        var average = sum >> HybridBinarizer2.BLOCK_SIZE_POWER * 2;
        if (max - min <= HybridBinarizer2.MIN_DYNAMIC_RANGE) {
          average = min / 2;
          if (y > 0 && x > 0) {
            var averageNeighborBlackPoint = (blackPoints[y - 1][x] + 2 * blackPoints[y][x - 1] + blackPoints[y - 1][x - 1]) / 4;
            if (min < averageNeighborBlackPoint) {
              average = averageNeighborBlackPoint;
            }
          }
        }
        blackPoints[y][x] = average;
      }
    }
    return blackPoints;
  };
  HybridBinarizer2.BLOCK_SIZE_POWER = 3;
  HybridBinarizer2.BLOCK_SIZE = 1 << HybridBinarizer2.BLOCK_SIZE_POWER;
  HybridBinarizer2.BLOCK_SIZE_MASK = HybridBinarizer2.BLOCK_SIZE - 1;
  HybridBinarizer2.MINIMUM_DIMENSION = HybridBinarizer2.BLOCK_SIZE * 5;
  HybridBinarizer2.MIN_DYNAMIC_RANGE = 24;
  return HybridBinarizer2;
}(GlobalHistogramBinarizer_1.default);
HybridBinarizer$1.default = HybridBinarizer;
var FormatException$1 = {};
var __extends$s = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(FormatException$1, "__esModule", { value: true });
var Exception_1$6 = Exception$1;
var FormatException = function(_super) {
  __extends$s(FormatException2, _super);
  function FormatException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return FormatException2;
}(Exception_1$6.default);
FormatException$1.default = FormatException;
var HTMLCanvasElementLuminanceSource$1 = {};
var InvertedLuminanceSource$1 = {};
var LuminanceSource$1 = {};
var UnsupportedOperationException$1 = {};
var __extends$r = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(UnsupportedOperationException$1, "__esModule", { value: true });
var Exception_1$5 = Exception$1;
var UnsupportedOperationException = function(_super) {
  __extends$r(UnsupportedOperationException2, _super);
  function UnsupportedOperationException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return UnsupportedOperationException2;
}(Exception_1$5.default);
UnsupportedOperationException$1.default = UnsupportedOperationException;
Object.defineProperty(LuminanceSource$1, "__esModule", { value: true });
var StringBuilder_1$6 = StringBuilder$1;
var UnsupportedOperationException_1$1 = UnsupportedOperationException$1;
var LuminanceSource = function() {
  function LuminanceSource2(width, height) {
    this.width = width;
    this.height = height;
  }
  LuminanceSource2.prototype.getWidth = function() {
    return this.width;
  };
  LuminanceSource2.prototype.getHeight = function() {
    return this.height;
  };
  LuminanceSource2.prototype.isCropSupported = function() {
    return false;
  };
  LuminanceSource2.prototype.crop = function(left, top, width, height) {
    throw new UnsupportedOperationException_1$1.default("This luminance source does not support cropping.");
  };
  LuminanceSource2.prototype.isRotateSupported = function() {
    return false;
  };
  LuminanceSource2.prototype.rotateCounterClockwise = function() {
    throw new UnsupportedOperationException_1$1.default("This luminance source does not support rotation by 90 degrees.");
  };
  LuminanceSource2.prototype.rotateCounterClockwise45 = function() {
    throw new UnsupportedOperationException_1$1.default("This luminance source does not support rotation by 45 degrees.");
  };
  LuminanceSource2.prototype.toString = function() {
    var row = new Uint8ClampedArray(this.width);
    var result = new StringBuilder_1$6.default();
    for (var y = 0; y < this.height; y++) {
      var sourceRow = this.getRow(y, row);
      for (var x = 0; x < this.width; x++) {
        var luminance = sourceRow[x] & 255;
        var c = void 0;
        if (luminance < 64) {
          c = "#";
        } else if (luminance < 128) {
          c = "+";
        } else if (luminance < 192) {
          c = ".";
        } else {
          c = " ";
        }
        result.append(c);
      }
      result.append("\n");
    }
    return result.toString();
  };
  return LuminanceSource2;
}();
LuminanceSource$1.default = LuminanceSource;
var __extends$q = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(InvertedLuminanceSource$1, "__esModule", { value: true });
var LuminanceSource_1$3 = LuminanceSource$1;
var InvertedLuminanceSource = function(_super) {
  __extends$q(InvertedLuminanceSource2, _super);
  function InvertedLuminanceSource2(delegate) {
    var _this = _super.call(this, delegate.getWidth(), delegate.getHeight()) || this;
    _this.delegate = delegate;
    return _this;
  }
  InvertedLuminanceSource2.prototype.getRow = function(y, row) {
    var sourceRow = this.delegate.getRow(y, row);
    var width = this.getWidth();
    for (var i = 0; i < width; i++) {
      sourceRow[i] = 255 - (sourceRow[i] & 255);
    }
    return sourceRow;
  };
  InvertedLuminanceSource2.prototype.getMatrix = function() {
    var matrix = this.delegate.getMatrix();
    var length = this.getWidth() * this.getHeight();
    var invertedMatrix = new Uint8ClampedArray(length);
    for (var i = 0; i < length; i++) {
      invertedMatrix[i] = 255 - (matrix[i] & 255);
    }
    return invertedMatrix;
  };
  InvertedLuminanceSource2.prototype.isCropSupported = function() {
    return this.delegate.isCropSupported();
  };
  InvertedLuminanceSource2.prototype.crop = function(left, top, width, height) {
    return new InvertedLuminanceSource2(this.delegate.crop(left, top, width, height));
  };
  InvertedLuminanceSource2.prototype.isRotateSupported = function() {
    return this.delegate.isRotateSupported();
  };
  InvertedLuminanceSource2.prototype.invert = function() {
    return this.delegate;
  };
  InvertedLuminanceSource2.prototype.rotateCounterClockwise = function() {
    return new InvertedLuminanceSource2(this.delegate.rotateCounterClockwise());
  };
  InvertedLuminanceSource2.prototype.rotateCounterClockwise45 = function() {
    return new InvertedLuminanceSource2(this.delegate.rotateCounterClockwise45());
  };
  return InvertedLuminanceSource2;
}(LuminanceSource_1$3.default);
InvertedLuminanceSource$1.default = InvertedLuminanceSource;
var __extends$p = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(HTMLCanvasElementLuminanceSource$1, "__esModule", { value: true });
var InvertedLuminanceSource_1$2 = InvertedLuminanceSource$1;
var LuminanceSource_1$2 = LuminanceSource$1;
var IllegalArgumentException_1$f = IllegalArgumentException$1;
var HTMLCanvasElementLuminanceSource = function(_super) {
  __extends$p(HTMLCanvasElementLuminanceSource2, _super);
  function HTMLCanvasElementLuminanceSource2(canvas) {
    var _this = _super.call(this, canvas.width, canvas.height) || this;
    _this.canvas = canvas;
    _this.tempCanvasElement = null;
    _this.buffer = HTMLCanvasElementLuminanceSource2.makeBufferFromCanvasImageData(canvas);
    return _this;
  }
  HTMLCanvasElementLuminanceSource2.makeBufferFromCanvasImageData = function(canvas) {
    var imageData = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height);
    return HTMLCanvasElementLuminanceSource2.toGrayscaleBuffer(imageData.data, canvas.width, canvas.height);
  };
  HTMLCanvasElementLuminanceSource2.toGrayscaleBuffer = function(imageBuffer, width, height) {
    var grayscaleBuffer = new Uint8ClampedArray(width * height);
    for (var i = 0, j = 0, length_1 = imageBuffer.length; i < length_1; i += 4, j++) {
      var gray = void 0;
      var alpha = imageBuffer[i + 3];
      if (alpha === 0) {
        gray = 255;
      } else {
        var pixelR = imageBuffer[i];
        var pixelG = imageBuffer[i + 1];
        var pixelB = imageBuffer[i + 2];
        gray = 306 * pixelR + 601 * pixelG + 117 * pixelB + 512 >> 10;
      }
      grayscaleBuffer[j] = gray;
    }
    return grayscaleBuffer;
  };
  HTMLCanvasElementLuminanceSource2.prototype.getRow = function(y, row) {
    if (y < 0 || y >= this.getHeight()) {
      throw new IllegalArgumentException_1$f.default("Requested row is outside the image: " + y);
    }
    var width = this.getWidth();
    var start = y * width;
    if (row === null) {
      row = this.buffer.slice(start, start + width);
    } else {
      if (row.length < width) {
        row = new Uint8ClampedArray(width);
      }
      row.set(this.buffer.slice(start, start + width));
    }
    return row;
  };
  HTMLCanvasElementLuminanceSource2.prototype.getMatrix = function() {
    return this.buffer;
  };
  HTMLCanvasElementLuminanceSource2.prototype.isCropSupported = function() {
    return true;
  };
  HTMLCanvasElementLuminanceSource2.prototype.crop = function(left, top, width, height) {
    this.crop(left, top, width, height);
    return this;
  };
  HTMLCanvasElementLuminanceSource2.prototype.isRotateSupported = function() {
    return true;
  };
  HTMLCanvasElementLuminanceSource2.prototype.rotateCounterClockwise = function() {
    this.rotate(-90);
    return this;
  };
  HTMLCanvasElementLuminanceSource2.prototype.rotateCounterClockwise45 = function() {
    this.rotate(-45);
    return this;
  };
  HTMLCanvasElementLuminanceSource2.prototype.getTempCanvasElement = function() {
    if (null === this.tempCanvasElement) {
      var tempCanvasElement = this.canvas.ownerDocument.createElement("canvas");
      tempCanvasElement.width = this.canvas.width;
      tempCanvasElement.height = this.canvas.height;
      this.tempCanvasElement = tempCanvasElement;
    }
    return this.tempCanvasElement;
  };
  HTMLCanvasElementLuminanceSource2.prototype.rotate = function(angle) {
    var tempCanvasElement = this.getTempCanvasElement();
    var tempContext = tempCanvasElement.getContext("2d");
    var angleRadians = angle * HTMLCanvasElementLuminanceSource2.DEGREE_TO_RADIANS;
    var width = this.canvas.width;
    var height = this.canvas.height;
    var newWidth = Math.ceil(Math.abs(Math.cos(angleRadians)) * width + Math.abs(Math.sin(angleRadians)) * height);
    var newHeight = Math.ceil(Math.abs(Math.sin(angleRadians)) * width + Math.abs(Math.cos(angleRadians)) * height);
    tempCanvasElement.width = newWidth;
    tempCanvasElement.height = newHeight;
    tempContext.translate(newWidth / 2, newHeight / 2);
    tempContext.rotate(angleRadians);
    tempContext.drawImage(this.canvas, width / -2, height / -2);
    this.buffer = HTMLCanvasElementLuminanceSource2.makeBufferFromCanvasImageData(tempCanvasElement);
    return this;
  };
  HTMLCanvasElementLuminanceSource2.prototype.invert = function() {
    return new InvertedLuminanceSource_1$2.default(this);
  };
  HTMLCanvasElementLuminanceSource2.DEGREE_TO_RADIANS = Math.PI / 180;
  return HTMLCanvasElementLuminanceSource2;
}(LuminanceSource_1$2.default);
HTMLCanvasElementLuminanceSource$1.HTMLCanvasElementLuminanceSource = HTMLCanvasElementLuminanceSource;
var VideoInputDevice$1 = {};
Object.defineProperty(VideoInputDevice$1, "__esModule", { value: true });
var VideoInputDevice = function() {
  function VideoInputDevice2(deviceId, label, groupId) {
    this.deviceId = deviceId;
    this.label = label;
    this.kind = "videoinput";
    this.groupId = groupId || void 0;
  }
  VideoInputDevice2.prototype.toJSON = function() {
    return {
      kind: this.kind,
      groupId: this.groupId,
      deviceId: this.deviceId,
      label: this.label
    };
  };
  return VideoInputDevice2;
}();
VideoInputDevice$1.VideoInputDevice = VideoInputDevice;
var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
  return new (P || (P = Promise))(function(resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : new P(function(resolve2) {
        resolve2(result.value);
      }).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = commonjsGlobal && commonjsGlobal.__generator || function(thisArg, body) {
  var _ = { label: 0, sent: function() {
    if (t[0] & 1)
      throw t[1];
    return t[1];
  }, trys: [], ops: [] }, f, y, t, g;
  return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
    return this;
  }), g;
  function verb(n) {
    return function(v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f)
      throw new TypeError("Generator is already executing.");
    while (_)
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
          return t;
        if (y = 0, t)
          op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return { value: op[1], done: false };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2])
              _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    if (op[0] & 5)
      throw op[1];
    return { value: op[0] ? op[1] : void 0, done: true };
  }
};
Object.defineProperty(BrowserCodeReader$1, "__esModule", { value: true });
var ArgumentException_1 = ArgumentException$1;
var BinaryBitmap_1 = BinaryBitmap$1;
var ChecksumException_1$5 = ChecksumException$1;
var HybridBinarizer_1 = HybridBinarizer$1;
var FormatException_1$a = FormatException$1;
var NotFoundException_1$m = NotFoundException$1;
var HTMLCanvasElementLuminanceSource_1 = HTMLCanvasElementLuminanceSource$1;
var VideoInputDevice_1 = VideoInputDevice$1;
var BrowserCodeReader = function() {
  function BrowserCodeReader2(reader, timeBetweenScansMillis, _hints) {
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    this.reader = reader;
    this.timeBetweenScansMillis = timeBetweenScansMillis;
    this._hints = _hints;
    this._stopContinuousDecode = false;
    this._stopAsyncDecode = false;
    this._timeBetweenDecodingAttempts = 0;
  }
  Object.defineProperty(BrowserCodeReader2.prototype, "hasNavigator", {
    get: function() {
      return typeof navigator !== "undefined";
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(BrowserCodeReader2.prototype, "isMediaDevicesSuported", {
    get: function() {
      return this.hasNavigator && !!navigator.mediaDevices;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(BrowserCodeReader2.prototype, "canEnumerateDevices", {
    get: function() {
      return !!(this.isMediaDevicesSuported && navigator.mediaDevices.enumerateDevices);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(BrowserCodeReader2.prototype, "timeBetweenDecodingAttempts", {
    get: function() {
      return this._timeBetweenDecodingAttempts;
    },
    set: function(millis) {
      this._timeBetweenDecodingAttempts = millis < 0 ? 0 : millis;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(BrowserCodeReader2.prototype, "hints", {
    get: function() {
      return this._hints;
    },
    set: function(hints) {
      this._hints = hints || null;
    },
    enumerable: true,
    configurable: true
  });
  BrowserCodeReader2.prototype.listVideoInputDevices = function() {
    return __awaiter(this, void 0, void 0, function() {
      var devices, videoDevices, _i, devices_1, device, kind, deviceId, label, groupId, videoDevice;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            if (!this.hasNavigator) {
              throw new Error("Can't enumerate devices, navigator is not present.");
            }
            if (!this.canEnumerateDevices) {
              throw new Error("Can't enumerate devices, method not supported.");
            }
            return [4, navigator.mediaDevices.enumerateDevices()];
          case 1:
            devices = _a.sent();
            videoDevices = [];
            for (_i = 0, devices_1 = devices; _i < devices_1.length; _i++) {
              device = devices_1[_i];
              kind = device.kind === "video" ? "videoinput" : device.kind;
              if (kind !== "videoinput") {
                continue;
              }
              deviceId = device.deviceId || device.id;
              label = device.label || "Video device " + (videoDevices.length + 1);
              groupId = device.groupId;
              videoDevice = { deviceId, label, kind, groupId };
              videoDevices.push(videoDevice);
            }
            return [2, videoDevices];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.getVideoInputDevices = function() {
    return __awaiter(this, void 0, void 0, function() {
      var devices;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.listVideoInputDevices()];
          case 1:
            devices = _a.sent();
            return [2, devices.map(function(d) {
              return new VideoInputDevice_1.VideoInputDevice(d.deviceId, d.label);
            })];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.findDeviceById = function(deviceId) {
    return __awaiter(this, void 0, void 0, function() {
      var devices;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.listVideoInputDevices()];
          case 1:
            devices = _a.sent();
            if (!devices) {
              return [2, null];
            }
            return [2, devices.find(function(x) {
              return x.deviceId === deviceId;
            })];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeFromInputVideoDevice = function(deviceId, videoSource) {
    return __awaiter(this, void 0, void 0, function() {
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.decodeOnceFromVideoDevice(deviceId, videoSource)];
          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeOnceFromVideoDevice = function(deviceId, videoSource) {
    return __awaiter(this, void 0, void 0, function() {
      var videoConstraints, constraints;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            this.reset();
            if (!deviceId) {
              videoConstraints = { facingMode: "environment" };
            } else {
              videoConstraints = { deviceId: { exact: deviceId } };
            }
            constraints = { video: videoConstraints };
            return [4, this.decodeOnceFromConstraints(constraints, videoSource)];
          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeOnceFromConstraints = function(constraints, videoSource) {
    return __awaiter(this, void 0, void 0, function() {
      var stream;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, navigator.mediaDevices.getUserMedia(constraints)];
          case 1:
            stream = _a.sent();
            return [4, this.decodeOnceFromStream(stream, videoSource)];
          case 2:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeOnceFromStream = function(stream, videoSource) {
    return __awaiter(this, void 0, void 0, function() {
      var video, result;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            this.reset();
            return [4, this.attachStreamToVideo(stream, videoSource)];
          case 1:
            video = _a.sent();
            return [4, this.decodeOnce(video)];
          case 2:
            result = _a.sent();
            return [2, result];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeFromInputVideoDeviceContinuously = function(deviceId, videoSource, callbackFn) {
    return __awaiter(this, void 0, void 0, function() {
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.decodeFromVideoDevice(deviceId, videoSource, callbackFn)];
          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeFromVideoDevice = function(deviceId, videoSource, callbackFn) {
    return __awaiter(this, void 0, void 0, function() {
      var videoConstraints, constraints;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            if (!deviceId) {
              videoConstraints = { facingMode: "environment" };
            } else {
              videoConstraints = { deviceId: { exact: deviceId } };
            }
            constraints = { video: videoConstraints };
            return [4, this.decodeFromConstraints(constraints, videoSource, callbackFn)];
          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeFromConstraints = function(constraints, videoSource, callbackFn) {
    return __awaiter(this, void 0, void 0, function() {
      var stream;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, navigator.mediaDevices.getUserMedia(constraints)];
          case 1:
            stream = _a.sent();
            return [4, this.decodeFromStream(stream, videoSource, callbackFn)];
          case 2:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.decodeFromStream = function(stream, videoSource, callbackFn) {
    return __awaiter(this, void 0, void 0, function() {
      var video;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            this.reset();
            return [4, this.attachStreamToVideo(stream, videoSource)];
          case 1:
            video = _a.sent();
            return [4, this.decodeContinuously(video, callbackFn)];
          case 2:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.stopAsyncDecode = function() {
    this._stopAsyncDecode = true;
  };
  BrowserCodeReader2.prototype.stopContinuousDecode = function() {
    this._stopContinuousDecode = true;
  };
  BrowserCodeReader2.prototype.attachStreamToVideo = function(stream, videoSource) {
    return __awaiter(this, void 0, void 0, function() {
      var videoElement;
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            videoElement = this.prepareVideoElement(videoSource);
            this.addVideoSource(videoElement, stream);
            this.videoElement = videoElement;
            this.stream = stream;
            return [4, this.playVideoOnLoadAsync(videoElement)];
          case 1:
            _a.sent();
            return [2, videoElement];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.playVideoOnLoadAsync = function(videoElement) {
    var _this = this;
    return new Promise(function(resolve, reject) {
      return _this.playVideoOnLoad(videoElement, function() {
        return resolve();
      });
    });
  };
  BrowserCodeReader2.prototype.playVideoOnLoad = function(element, callbackFn) {
    var _this = this;
    this.videoEndedListener = function() {
      return _this.stopStreams();
    };
    this.videoCanPlayListener = function() {
      return _this.tryPlayVideo(element);
    };
    element.addEventListener("ended", this.videoEndedListener);
    element.addEventListener("canplay", this.videoCanPlayListener);
    element.addEventListener("playing", callbackFn);
    this.tryPlayVideo(element);
  };
  BrowserCodeReader2.prototype.isVideoPlaying = function(video) {
    return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
  };
  BrowserCodeReader2.prototype.tryPlayVideo = function(videoElement) {
    return __awaiter(this, void 0, void 0, function() {
      return __generator(this, function(_b) {
        switch (_b.label) {
          case 0:
            if (this.isVideoPlaying(videoElement)) {
              console.warn("Trying to play video that is already playing.");
              return [2];
            }
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3, , 4]);
            return [4, videoElement.play()];
          case 2:
            _b.sent();
            return [3, 4];
          case 3:
            _b.sent();
            console.warn("It was not possible to play the video.");
            return [3, 4];
          case 4:
            return [2];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.getMediaElement = function(mediaElementId, type) {
    var mediaElement = document.getElementById(mediaElementId);
    if (!mediaElement) {
      throw new ArgumentException_1.default("element with id '" + mediaElementId + "' not found");
    }
    if (mediaElement.nodeName.toLowerCase() !== type.toLowerCase()) {
      throw new ArgumentException_1.default("element with id '" + mediaElementId + "' must be an " + type + " element");
    }
    return mediaElement;
  };
  BrowserCodeReader2.prototype.decodeFromImage = function(source, url) {
    if (!source && !url) {
      throw new ArgumentException_1.default("either imageElement with a src set or an url must be provided");
    }
    if (url && !source) {
      return this.decodeFromImageUrl(url);
    }
    return this.decodeFromImageElement(source);
  };
  BrowserCodeReader2.prototype.decodeFromVideo = function(source, url) {
    if (!source && !url) {
      throw new ArgumentException_1.default("Either an element with a src set or an URL must be provided");
    }
    if (url && !source) {
      return this.decodeFromVideoUrl(url);
    }
    return this.decodeFromVideoElement(source);
  };
  BrowserCodeReader2.prototype.decodeFromVideoContinuously = function(source, url, callbackFn) {
    if (void 0 === source && void 0 === url) {
      throw new ArgumentException_1.default("Either an element with a src set or an URL must be provided");
    }
    if (url && !source) {
      return this.decodeFromVideoUrlContinuously(url, callbackFn);
    }
    return this.decodeFromVideoElementContinuously(source, callbackFn);
  };
  BrowserCodeReader2.prototype.decodeFromImageElement = function(source) {
    if (!source) {
      throw new ArgumentException_1.default("An image element must be provided.");
    }
    this.reset();
    var element = this.prepareImageElement(source);
    this.imageElement = element;
    var task;
    if (this.isImageLoaded(element)) {
      task = this.decodeOnce(element, false, true);
    } else {
      task = this._decodeOnLoadImage(element);
    }
    return task;
  };
  BrowserCodeReader2.prototype.decodeFromVideoElement = function(source) {
    var element = this._decodeFromVideoElementSetup(source);
    return this._decodeOnLoadVideo(element);
  };
  BrowserCodeReader2.prototype.decodeFromVideoElementContinuously = function(source, callbackFn) {
    var element = this._decodeFromVideoElementSetup(source);
    return this._decodeOnLoadVideoContinuously(element, callbackFn);
  };
  BrowserCodeReader2.prototype._decodeFromVideoElementSetup = function(source) {
    if (!source) {
      throw new ArgumentException_1.default("A video element must be provided.");
    }
    this.reset();
    var element = this.prepareVideoElement(source);
    this.videoElement = element;
    return element;
  };
  BrowserCodeReader2.prototype.decodeFromImageUrl = function(url) {
    if (!url) {
      throw new ArgumentException_1.default("An URL must be provided.");
    }
    this.reset();
    var element = this.prepareImageElement();
    this.imageElement = element;
    var decodeTask = this._decodeOnLoadImage(element);
    element.src = url;
    return decodeTask;
  };
  BrowserCodeReader2.prototype.decodeFromVideoUrl = function(url) {
    if (!url) {
      throw new ArgumentException_1.default("An URL must be provided.");
    }
    this.reset();
    var element = this.prepareVideoElement();
    var decodeTask = this.decodeFromVideoElement(element);
    element.src = url;
    return decodeTask;
  };
  BrowserCodeReader2.prototype.decodeFromVideoUrlContinuously = function(url, callbackFn) {
    if (!url) {
      throw new ArgumentException_1.default("An URL must be provided.");
    }
    this.reset();
    var element = this.prepareVideoElement();
    var decodeTask = this.decodeFromVideoElementContinuously(element, callbackFn);
    element.src = url;
    return decodeTask;
  };
  BrowserCodeReader2.prototype._decodeOnLoadImage = function(element) {
    var _this = this;
    return new Promise(function(resolve, reject) {
      _this.imageLoadedListener = function() {
        return _this.decodeOnce(element, false, true).then(resolve, reject);
      };
      element.addEventListener("load", _this.imageLoadedListener);
    });
  };
  BrowserCodeReader2.prototype._decodeOnLoadVideo = function(videoElement) {
    return __awaiter(this, void 0, void 0, function() {
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.playVideoOnLoadAsync(videoElement)];
          case 1:
            _a.sent();
            return [4, this.decodeOnce(videoElement)];
          case 2:
            return [2, _a.sent()];
        }
      });
    });
  };
  BrowserCodeReader2.prototype._decodeOnLoadVideoContinuously = function(videoElement, callbackFn) {
    return __awaiter(this, void 0, void 0, function() {
      return __generator(this, function(_a) {
        switch (_a.label) {
          case 0:
            return [4, this.playVideoOnLoadAsync(videoElement)];
          case 1:
            _a.sent();
            this.decodeContinuously(videoElement, callbackFn);
            return [2];
        }
      });
    });
  };
  BrowserCodeReader2.prototype.isImageLoaded = function(img) {
    if (!img.complete) {
      return false;
    }
    if (img.naturalWidth === 0) {
      return false;
    }
    return true;
  };
  BrowserCodeReader2.prototype.prepareImageElement = function(imageSource) {
    var imageElement;
    if (typeof imageSource === "undefined") {
      imageElement = document.createElement("img");
      imageElement.width = 200;
      imageElement.height = 200;
    }
    if (typeof imageSource === "string") {
      imageElement = this.getMediaElement(imageSource, "img");
    }
    if (imageSource instanceof HTMLImageElement) {
      imageElement = imageSource;
    }
    return imageElement;
  };
  BrowserCodeReader2.prototype.prepareVideoElement = function(videoSource) {
    var videoElement;
    if (!videoSource && typeof document !== "undefined") {
      videoElement = document.createElement("video");
      videoElement.width = 200;
      videoElement.height = 200;
    }
    if (typeof videoSource === "string") {
      videoElement = this.getMediaElement(videoSource, "video");
    }
    if (videoSource instanceof HTMLVideoElement) {
      videoElement = videoSource;
    }
    videoElement.setAttribute("autoplay", "true");
    videoElement.setAttribute("muted", "true");
    videoElement.setAttribute("playsinline", "true");
    return videoElement;
  };
  BrowserCodeReader2.prototype.decodeOnce = function(element, retryIfNotFound, retryIfChecksumOrFormatError) {
    var _this = this;
    if (retryIfNotFound === void 0) {
      retryIfNotFound = true;
    }
    if (retryIfChecksumOrFormatError === void 0) {
      retryIfChecksumOrFormatError = true;
    }
    this._stopAsyncDecode = false;
    var loop = function(resolve, reject) {
      if (_this._stopAsyncDecode) {
        reject(new NotFoundException_1$m.default("Video stream has ended before any code could be detected."));
        _this._stopAsyncDecode = void 0;
        return;
      }
      try {
        var result = _this.decode(element);
        resolve(result);
      } catch (e) {
        var ifNotFound = retryIfNotFound && e instanceof NotFoundException_1$m.default;
        var isChecksumOrFormatError = e instanceof ChecksumException_1$5.default || e instanceof FormatException_1$a.default;
        var ifChecksumOrFormat = isChecksumOrFormatError && retryIfChecksumOrFormatError;
        if (ifNotFound || ifChecksumOrFormat) {
          return setTimeout(function() {
            return loop(resolve, reject);
          }, _this._timeBetweenDecodingAttempts);
        }
        reject(e);
      }
    };
    return new Promise(function(resolve, reject) {
      return loop(resolve, reject);
    });
  };
  BrowserCodeReader2.prototype.decodeContinuously = function(element, callbackFn) {
    var _this = this;
    this._stopContinuousDecode = false;
    var loop = function() {
      if (_this._stopContinuousDecode) {
        _this._stopContinuousDecode = void 0;
        return;
      }
      try {
        var result = _this.decode(element);
        callbackFn(result, null);
        setTimeout(function() {
          return loop();
        }, _this.timeBetweenScansMillis);
      } catch (e) {
        callbackFn(null, e);
        var isChecksumOrFormatError = e instanceof ChecksumException_1$5.default || e instanceof FormatException_1$a.default;
        var isNotFound = e instanceof NotFoundException_1$m.default;
        if (isChecksumOrFormatError || isNotFound) {
          setTimeout(function() {
            return loop();
          }, _this._timeBetweenDecodingAttempts);
        }
      }
    };
    loop();
  };
  BrowserCodeReader2.prototype.decode = function(element) {
    var binaryBitmap = this.createBinaryBitmap(element);
    return this.decodeBitmap(binaryBitmap);
  };
  BrowserCodeReader2.prototype.createBinaryBitmap = function(mediaElement) {
    var ctx = this.getCaptureCanvasContext(mediaElement);
    this.drawImageOnCanvas(ctx, mediaElement);
    var canvas = this.getCaptureCanvas(mediaElement);
    var luminanceSource = new HTMLCanvasElementLuminanceSource_1.HTMLCanvasElementLuminanceSource(canvas);
    var hybridBinarizer = new HybridBinarizer_1.default(luminanceSource);
    return new BinaryBitmap_1.default(hybridBinarizer);
  };
  BrowserCodeReader2.prototype.getCaptureCanvasContext = function(mediaElement) {
    if (!this.captureCanvasContext) {
      var elem = this.getCaptureCanvas(mediaElement);
      var ctx = elem.getContext("2d");
      this.captureCanvasContext = ctx;
    }
    return this.captureCanvasContext;
  };
  BrowserCodeReader2.prototype.getCaptureCanvas = function(mediaElement) {
    if (!this.captureCanvas) {
      var elem = this.createCaptureCanvas(mediaElement);
      this.captureCanvas = elem;
    }
    return this.captureCanvas;
  };
  BrowserCodeReader2.prototype.drawImageOnCanvas = function(canvasElementContext, srcElement) {
    canvasElementContext.drawImage(srcElement, 0, 0);
  };
  BrowserCodeReader2.prototype.decodeBitmap = function(binaryBitmap) {
    return this.reader.decode(binaryBitmap, this._hints);
  };
  BrowserCodeReader2.prototype.createCaptureCanvas = function(mediaElement) {
    if (typeof document === "undefined") {
      this._destroyCaptureCanvas();
      return null;
    }
    var canvasElement = document.createElement("canvas");
    var width;
    var height;
    if (typeof mediaElement !== "undefined") {
      if (mediaElement instanceof HTMLVideoElement) {
        width = mediaElement.videoWidth;
        height = mediaElement.videoHeight;
      } else if (mediaElement instanceof HTMLImageElement) {
        width = mediaElement.naturalWidth || mediaElement.width;
        height = mediaElement.naturalHeight || mediaElement.height;
      }
    }
    canvasElement.style.width = width + "px";
    canvasElement.style.height = height + "px";
    canvasElement.width = width;
    canvasElement.height = height;
    return canvasElement;
  };
  BrowserCodeReader2.prototype.stopStreams = function() {
    if (this.stream) {
      this.stream.getVideoTracks().forEach(function(t) {
        return t.stop();
      });
      this.stream = void 0;
    }
    if (this._stopAsyncDecode === false) {
      this.stopAsyncDecode();
    }
    if (this._stopContinuousDecode === false) {
      this.stopContinuousDecode();
    }
  };
  BrowserCodeReader2.prototype.reset = function() {
    this.stopStreams();
    this._destroyVideoElement();
    this._destroyImageElement();
    this._destroyCaptureCanvas();
  };
  BrowserCodeReader2.prototype._destroyVideoElement = function() {
    if (!this.videoElement) {
      return;
    }
    if (typeof this.videoEndedListener !== "undefined") {
      this.videoElement.removeEventListener("ended", this.videoEndedListener);
    }
    if (typeof this.videoPlayingEventListener !== "undefined") {
      this.videoElement.removeEventListener("playing", this.videoPlayingEventListener);
    }
    if (typeof this.videoCanPlayListener !== "undefined") {
      this.videoElement.removeEventListener("loadedmetadata", this.videoCanPlayListener);
    }
    this.cleanVideoSource(this.videoElement);
    this.videoElement = void 0;
  };
  BrowserCodeReader2.prototype._destroyImageElement = function() {
    if (!this.imageElement) {
      return;
    }
    if (void 0 !== this.imageLoadedListener) {
      this.imageElement.removeEventListener("load", this.imageLoadedListener);
    }
    this.imageElement.src = void 0;
    this.imageElement.removeAttribute("src");
    this.imageElement = void 0;
  };
  BrowserCodeReader2.prototype._destroyCaptureCanvas = function() {
    this.captureCanvasContext = void 0;
    this.captureCanvas = void 0;
  };
  BrowserCodeReader2.prototype.addVideoSource = function(videoElement, stream) {
    try {
      videoElement.srcObject = stream;
    } catch (err) {
      videoElement.src = URL.createObjectURL(stream);
    }
  };
  BrowserCodeReader2.prototype.cleanVideoSource = function(videoElement) {
    try {
      videoElement.srcObject = null;
    } catch (err) {
      videoElement.src = "";
    }
    this.videoElement.removeAttribute("src");
  };
  return BrowserCodeReader2;
}();
BrowserCodeReader$1.BrowserCodeReader = BrowserCodeReader;
var MultiFormatOneDReader$1 = {};
var BarcodeFormat$1 = {};
Object.defineProperty(BarcodeFormat$1, "__esModule", { value: true });
var BarcodeFormat;
(function(BarcodeFormat2) {
  BarcodeFormat2[BarcodeFormat2["AZTEC"] = 0] = "AZTEC";
  BarcodeFormat2[BarcodeFormat2["CODABAR"] = 1] = "CODABAR";
  BarcodeFormat2[BarcodeFormat2["CODE_39"] = 2] = "CODE_39";
  BarcodeFormat2[BarcodeFormat2["CODE_93"] = 3] = "CODE_93";
  BarcodeFormat2[BarcodeFormat2["CODE_128"] = 4] = "CODE_128";
  BarcodeFormat2[BarcodeFormat2["DATA_MATRIX"] = 5] = "DATA_MATRIX";
  BarcodeFormat2[BarcodeFormat2["EAN_8"] = 6] = "EAN_8";
  BarcodeFormat2[BarcodeFormat2["EAN_13"] = 7] = "EAN_13";
  BarcodeFormat2[BarcodeFormat2["ITF"] = 8] = "ITF";
  BarcodeFormat2[BarcodeFormat2["MAXICODE"] = 9] = "MAXICODE";
  BarcodeFormat2[BarcodeFormat2["PDF_417"] = 10] = "PDF_417";
  BarcodeFormat2[BarcodeFormat2["QR_CODE"] = 11] = "QR_CODE";
  BarcodeFormat2[BarcodeFormat2["RSS_14"] = 12] = "RSS_14";
  BarcodeFormat2[BarcodeFormat2["RSS_EXPANDED"] = 13] = "RSS_EXPANDED";
  BarcodeFormat2[BarcodeFormat2["UPC_A"] = 14] = "UPC_A";
  BarcodeFormat2[BarcodeFormat2["UPC_E"] = 15] = "UPC_E";
  BarcodeFormat2[BarcodeFormat2["UPC_EAN_EXTENSION"] = 16] = "UPC_EAN_EXTENSION";
})(BarcodeFormat || (BarcodeFormat = {}));
BarcodeFormat$1.default = BarcodeFormat;
var Code39Reader$1 = {};
var OneDReader$1 = {};
var DecodeHintType$1 = {};
Object.defineProperty(DecodeHintType$1, "__esModule", { value: true });
var DecodeHintType;
(function(DecodeHintType2) {
  DecodeHintType2[DecodeHintType2["OTHER"] = 0] = "OTHER";
  DecodeHintType2[DecodeHintType2["PURE_BARCODE"] = 1] = "PURE_BARCODE";
  DecodeHintType2[DecodeHintType2["POSSIBLE_FORMATS"] = 2] = "POSSIBLE_FORMATS";
  DecodeHintType2[DecodeHintType2["TRY_HARDER"] = 3] = "TRY_HARDER";
  DecodeHintType2[DecodeHintType2["CHARACTER_SET"] = 4] = "CHARACTER_SET";
  DecodeHintType2[DecodeHintType2["ALLOWED_LENGTHS"] = 5] = "ALLOWED_LENGTHS";
  DecodeHintType2[DecodeHintType2["ASSUME_CODE_39_CHECK_DIGIT"] = 6] = "ASSUME_CODE_39_CHECK_DIGIT";
  DecodeHintType2[DecodeHintType2["ASSUME_GS1"] = 7] = "ASSUME_GS1";
  DecodeHintType2[DecodeHintType2["RETURN_CODABAR_START_END"] = 8] = "RETURN_CODABAR_START_END";
  DecodeHintType2[DecodeHintType2["NEED_RESULT_POINT_CALLBACK"] = 9] = "NEED_RESULT_POINT_CALLBACK";
  DecodeHintType2[DecodeHintType2["ALLOWED_EAN_EXTENSIONS"] = 10] = "ALLOWED_EAN_EXTENSIONS";
})(DecodeHintType || (DecodeHintType = {}));
DecodeHintType$1.default = DecodeHintType;
var ResultMetadataType$1 = {};
Object.defineProperty(ResultMetadataType$1, "__esModule", { value: true });
var ResultMetadataType;
(function(ResultMetadataType2) {
  ResultMetadataType2[ResultMetadataType2["OTHER"] = 0] = "OTHER";
  ResultMetadataType2[ResultMetadataType2["ORIENTATION"] = 1] = "ORIENTATION";
  ResultMetadataType2[ResultMetadataType2["BYTE_SEGMENTS"] = 2] = "BYTE_SEGMENTS";
  ResultMetadataType2[ResultMetadataType2["ERROR_CORRECTION_LEVEL"] = 3] = "ERROR_CORRECTION_LEVEL";
  ResultMetadataType2[ResultMetadataType2["ISSUE_NUMBER"] = 4] = "ISSUE_NUMBER";
  ResultMetadataType2[ResultMetadataType2["SUGGESTED_PRICE"] = 5] = "SUGGESTED_PRICE";
  ResultMetadataType2[ResultMetadataType2["POSSIBLE_COUNTRY"] = 6] = "POSSIBLE_COUNTRY";
  ResultMetadataType2[ResultMetadataType2["UPC_EAN_EXTENSION"] = 7] = "UPC_EAN_EXTENSION";
  ResultMetadataType2[ResultMetadataType2["PDF417_EXTRA_METADATA"] = 8] = "PDF417_EXTRA_METADATA";
  ResultMetadataType2[ResultMetadataType2["STRUCTURED_APPEND_SEQUENCE"] = 9] = "STRUCTURED_APPEND_SEQUENCE";
  ResultMetadataType2[ResultMetadataType2["STRUCTURED_APPEND_PARITY"] = 10] = "STRUCTURED_APPEND_PARITY";
})(ResultMetadataType || (ResultMetadataType = {}));
ResultMetadataType$1.default = ResultMetadataType;
var ResultPoint$1 = {};
var MathUtils$1 = {};
Object.defineProperty(MathUtils$1, "__esModule", { value: true });
var MathUtils = function() {
  function MathUtils2() {
  }
  MathUtils2.prototype.MathUtils = function() {
  };
  MathUtils2.round = function(d) {
    if (NaN === d)
      return 0;
    if (d <= Number.MIN_SAFE_INTEGER)
      return Number.MIN_SAFE_INTEGER;
    if (d >= Number.MAX_SAFE_INTEGER)
      return Number.MAX_SAFE_INTEGER;
    return d + (d < 0 ? -0.5 : 0.5) | 0;
  };
  MathUtils2.distance = function(aX, aY, bX, bY) {
    var xDiff = aX - bX;
    var yDiff = aY - bY;
    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  };
  MathUtils2.sum = function(array) {
    var count = 0;
    for (var i = 0, length_1 = array.length; i !== length_1; i++) {
      var a = array[i];
      count += a;
    }
    return count;
  };
  return MathUtils2;
}();
MathUtils$1.default = MathUtils;
var Float$1 = {};
Object.defineProperty(Float$1, "__esModule", { value: true });
var Float = function() {
  function Float2() {
  }
  Float2.floatToIntBits = function(f) {
    return f;
  };
  return Float2;
}();
Float$1.default = Float;
Object.defineProperty(ResultPoint$1, "__esModule", { value: true });
var MathUtils_1$5 = MathUtils$1;
var Float_1 = Float$1;
var ResultPoint = function() {
  function ResultPoint2(x, y) {
    this.x = x;
    this.y = y;
  }
  ResultPoint2.prototype.getX = function() {
    return this.x;
  };
  ResultPoint2.prototype.getY = function() {
    return this.y;
  };
  ResultPoint2.prototype.equals = function(other) {
    if (other instanceof ResultPoint2) {
      var otherPoint = other;
      return this.x === otherPoint.x && this.y === otherPoint.y;
    }
    return false;
  };
  ResultPoint2.prototype.hashCode = function() {
    return 31 * Float_1.default.floatToIntBits(this.x) + Float_1.default.floatToIntBits(this.y);
  };
  ResultPoint2.prototype.toString = function() {
    return "(" + this.x + "," + this.y + ")";
  };
  ResultPoint2.orderBestPatterns = function(patterns) {
    var zeroOneDistance = this.distance(patterns[0], patterns[1]);
    var oneTwoDistance = this.distance(patterns[1], patterns[2]);
    var zeroTwoDistance = this.distance(patterns[0], patterns[2]);
    var pointA;
    var pointB;
    var pointC;
    if (oneTwoDistance >= zeroOneDistance && oneTwoDistance >= zeroTwoDistance) {
      pointB = patterns[0];
      pointA = patterns[1];
      pointC = patterns[2];
    } else if (zeroTwoDistance >= oneTwoDistance && zeroTwoDistance >= zeroOneDistance) {
      pointB = patterns[1];
      pointA = patterns[0];
      pointC = patterns[2];
    } else {
      pointB = patterns[2];
      pointA = patterns[0];
      pointC = patterns[1];
    }
    if (this.crossProductZ(pointA, pointB, pointC) < 0) {
      var temp = pointA;
      pointA = pointC;
      pointC = temp;
    }
    patterns[0] = pointA;
    patterns[1] = pointB;
    patterns[2] = pointC;
  };
  ResultPoint2.distance = function(pattern1, pattern2) {
    return MathUtils_1$5.default.distance(pattern1.x, pattern1.y, pattern2.x, pattern2.y);
  };
  ResultPoint2.crossProductZ = function(pointA, pointB, pointC) {
    var bX = pointB.x;
    var bY = pointB.y;
    return (pointC.x - bX) * (pointA.y - bY) - (pointC.y - bY) * (pointA.x - bX);
  };
  return ResultPoint2;
}();
ResultPoint$1.default = ResultPoint;
Object.defineProperty(OneDReader$1, "__esModule", { value: true });
var BitArray_1$2 = BitArray$1;
var DecodeHintType_1$c = DecodeHintType$1;
var ResultMetadataType_1$5 = ResultMetadataType$1;
var ResultPoint_1$f = ResultPoint$1;
var NotFoundException_1$l = NotFoundException$1;
var OneDReader = function() {
  function OneDReader2() {
  }
  OneDReader2.prototype.decode = function(image, hints) {
    try {
      return this.doDecode(image, hints);
    } catch (nfe) {
      var tryHarder = hints && hints.get(DecodeHintType_1$c.default.TRY_HARDER) === true;
      if (tryHarder && image.isRotateSupported()) {
        var rotatedImage = image.rotateCounterClockwise();
        var result = this.doDecode(rotatedImage, hints);
        var metadata = result.getResultMetadata();
        var orientation_1 = 270;
        if (metadata !== null && metadata.get(ResultMetadataType_1$5.default.ORIENTATION) === true) {
          orientation_1 = orientation_1 + metadata.get(ResultMetadataType_1$5.default.ORIENTATION) % 360;
        }
        result.putMetadata(ResultMetadataType_1$5.default.ORIENTATION, orientation_1);
        var points = result.getResultPoints();
        if (points !== null) {
          var height = rotatedImage.getHeight();
          for (var i = 0; i < points.length; i++) {
            points[i] = new ResultPoint_1$f.default(height - points[i].getY() - 1, points[i].getX());
          }
        }
        return result;
      } else {
        throw new NotFoundException_1$l.default();
      }
    }
  };
  OneDReader2.prototype.reset = function() {
  };
  OneDReader2.prototype.doDecode = function(image, hints) {
    var width = image.getWidth();
    var height = image.getHeight();
    var row = new BitArray_1$2.default(width);
    var tryHarder = hints && hints.get(DecodeHintType_1$c.default.TRY_HARDER) === true;
    var rowStep = Math.max(1, height >> (tryHarder ? 8 : 5));
    var maxLines;
    if (tryHarder) {
      maxLines = height;
    } else {
      maxLines = 15;
    }
    var middle = Math.trunc(height / 2);
    for (var x = 0; x < maxLines; x++) {
      var rowStepsAboveOrBelow = Math.trunc((x + 1) / 2);
      var isAbove = (x & 1) === 0;
      var rowNumber = middle + rowStep * (isAbove ? rowStepsAboveOrBelow : -rowStepsAboveOrBelow);
      if (rowNumber < 0 || rowNumber >= height) {
        break;
      }
      try {
        row = image.getBlackRow(rowNumber, row);
      } catch (ignored) {
        continue;
      }
      var _loop_1 = function(attempt2) {
        if (attempt2 === 1) {
          row.reverse();
          if (hints && hints.get(DecodeHintType_1$c.default.NEED_RESULT_POINT_CALLBACK) === true) {
            var newHints_1 = /* @__PURE__ */ new Map();
            hints.forEach(function(hint, key) {
              return newHints_1.set(key, hint);
            });
            newHints_1.delete(DecodeHintType_1$c.default.NEED_RESULT_POINT_CALLBACK);
            hints = newHints_1;
          }
        }
        try {
          var result = this_1.decodeRow(rowNumber, row, hints);
          if (attempt2 === 1) {
            result.putMetadata(ResultMetadataType_1$5.default.ORIENTATION, 180);
            var points = result.getResultPoints();
            if (points !== null) {
              points[0] = new ResultPoint_1$f.default(width - points[0].getX() - 1, points[0].getY());
              points[1] = new ResultPoint_1$f.default(width - points[1].getX() - 1, points[1].getY());
            }
          }
          return { value: result };
        } catch (re) {
        }
      };
      var this_1 = this;
      for (var attempt = 0; attempt < 2; attempt++) {
        var state_1 = _loop_1(attempt);
        if (typeof state_1 === "object")
          return state_1.value;
      }
    }
    throw new NotFoundException_1$l.default();
  };
  OneDReader2.recordPattern = function(row, start, counters) {
    var numCounters = counters.length;
    for (var index = 0; index < numCounters; index++)
      counters[index] = 0;
    var end = row.getSize();
    if (start >= end) {
      throw new NotFoundException_1$l.default();
    }
    var isWhite = !row.get(start);
    var counterPosition = 0;
    var i = start;
    while (i < end) {
      if (row.get(i) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (++counterPosition === numCounters) {
          break;
        } else {
          counters[counterPosition] = 1;
          isWhite = !isWhite;
        }
      }
      i++;
    }
    if (!(counterPosition === numCounters || counterPosition === numCounters - 1 && i === end)) {
      throw new NotFoundException_1$l.default();
    }
  };
  OneDReader2.recordPatternInReverse = function(row, start, counters) {
    var numTransitionsLeft = counters.length;
    var last = row.get(start);
    while (start > 0 && numTransitionsLeft >= 0) {
      if (row.get(--start) !== last) {
        numTransitionsLeft--;
        last = !last;
      }
    }
    if (numTransitionsLeft >= 0) {
      throw new NotFoundException_1$l.default();
    }
    OneDReader2.recordPattern(row, start + 1, counters);
  };
  OneDReader2.patternMatchVariance = function(counters, pattern, maxIndividualVariance) {
    var numCounters = counters.length;
    var total = 0;
    var patternLength = 0;
    for (var i = 0; i < numCounters; i++) {
      total += counters[i];
      patternLength += pattern[i];
    }
    if (total < patternLength) {
      return Number.POSITIVE_INFINITY;
    }
    var unitBarWidth = total / patternLength;
    maxIndividualVariance *= unitBarWidth;
    var totalVariance = 0;
    for (var x = 0; x < numCounters; x++) {
      var counter = counters[x];
      var scaledPattern = pattern[x] * unitBarWidth;
      var variance = counter > scaledPattern ? counter - scaledPattern : scaledPattern - counter;
      if (variance > maxIndividualVariance) {
        return Number.POSITIVE_INFINITY;
      }
      totalVariance += variance;
    }
    return totalVariance / total;
  };
  return OneDReader2;
}();
OneDReader$1.default = OneDReader;
var Result$1 = {};
Object.defineProperty(Result$1, "__esModule", { value: true });
var ResultPoint_1$e = ResultPoint$1;
var System_1$7 = System$1;
var Result = function() {
  function Result2(text, rawBytes, numBits, resultPoints, format, timestamp) {
    this.text = text;
    this.rawBytes = rawBytes;
    this.numBits = numBits;
    this.resultPoints = resultPoints;
    this.format = format;
    this.timestamp = timestamp;
    this.text = text;
    this.rawBytes = rawBytes;
    if (void 0 === numBits || null === numBits) {
      this.numBits = rawBytes === null || rawBytes === void 0 ? 0 : 8 * rawBytes.length;
    } else {
      this.numBits = numBits;
    }
    this.resultPoints = resultPoints;
    this.format = format;
    this.resultMetadata = null;
    if (void 0 === timestamp || null === timestamp) {
      this.timestamp = System_1$7.default.currentTimeMillis();
    } else {
      this.timestamp = timestamp;
    }
  }
  Result2.prototype.getText = function() {
    return this.text;
  };
  Result2.prototype.getRawBytes = function() {
    return this.rawBytes;
  };
  Result2.prototype.getNumBits = function() {
    return this.numBits;
  };
  Result2.prototype.getResultPoints = function() {
    return this.resultPoints;
  };
  Result2.prototype.getBarcodeFormat = function() {
    return this.format;
  };
  Result2.prototype.getResultMetadata = function() {
    return this.resultMetadata;
  };
  Result2.prototype.putMetadata = function(type, value) {
    if (this.resultMetadata === null) {
      this.resultMetadata = /* @__PURE__ */ new Map();
    }
    this.resultMetadata.set(type, value);
  };
  Result2.prototype.putAllMetadata = function(metadata) {
    if (metadata !== null) {
      if (this.resultMetadata === null) {
        this.resultMetadata = metadata;
      } else {
        this.resultMetadata = new Map(metadata);
      }
    }
  };
  Result2.prototype.addResultPoints = function(newPoints) {
    var oldPoints = this.resultPoints;
    if (oldPoints === null) {
      this.resultPoints = newPoints;
    } else if (newPoints !== null && newPoints.length > 0) {
      var allPoints = new ResultPoint_1$e.default[oldPoints.length + newPoints.length]();
      System_1$7.default.arraycopy(oldPoints, 0, allPoints, 0, oldPoints.length);
      System_1$7.default.arraycopy(newPoints, 0, allPoints, oldPoints.length, newPoints.length);
      this.resultPoints = allPoints;
    }
  };
  Result2.prototype.getTimestamp = function() {
    return this.timestamp;
  };
  Result2.prototype.toString = function() {
    return this.text;
  };
  return Result2;
}();
Result$1.default = Result;
var __extends$o = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(Code39Reader$1, "__esModule", { value: true });
var BarcodeFormat_1$f = BarcodeFormat$1;
var ChecksumException_1$4 = ChecksumException$1;
var FormatException_1$9 = FormatException$1;
var NotFoundException_1$k = NotFoundException$1;
var OneDReader_1$7 = OneDReader$1;
var Result_1$8 = Result$1;
var ResultPoint_1$d = ResultPoint$1;
var Code39Reader = function(_super) {
  __extends$o(Code39Reader2, _super);
  function Code39Reader2(usingCheckDigit, extendedMode) {
    if (usingCheckDigit === void 0) {
      usingCheckDigit = false;
    }
    if (extendedMode === void 0) {
      extendedMode = false;
    }
    var _this = _super.call(this) || this;
    _this.usingCheckDigit = usingCheckDigit;
    _this.extendedMode = extendedMode;
    _this.decodeRowResult = "";
    _this.counters = new Array(9);
    return _this;
  }
  Code39Reader2.prototype.decodeRow = function(rowNumber, row, hints) {
    var theCounters = this.counters;
    theCounters.fill(0);
    this.decodeRowResult = "";
    var start = Code39Reader2.findAsteriskPattern(row, theCounters);
    var nextStart = row.getNextSet(start[1]);
    var end = row.getSize();
    var decodedChar;
    var lastStart;
    do {
      Code39Reader2.recordPattern(row, nextStart, theCounters);
      var pattern = Code39Reader2.toNarrowWidePattern(theCounters);
      if (pattern < 0) {
        throw new NotFoundException_1$k.default();
      }
      decodedChar = Code39Reader2.patternToChar(pattern);
      this.decodeRowResult += decodedChar;
      lastStart = nextStart;
      for (var _i = 0, theCounters_1 = theCounters; _i < theCounters_1.length; _i++) {
        var counter = theCounters_1[_i];
        nextStart += counter;
      }
      nextStart = row.getNextSet(nextStart);
    } while (decodedChar !== "*");
    this.decodeRowResult = this.decodeRowResult.substring(0, this.decodeRowResult.length - 1);
    var lastPatternSize = 0;
    for (var _a = 0, theCounters_2 = theCounters; _a < theCounters_2.length; _a++) {
      var counter = theCounters_2[_a];
      lastPatternSize += counter;
    }
    var whiteSpaceAfterEnd = nextStart - lastStart - lastPatternSize;
    if (nextStart !== end && whiteSpaceAfterEnd * 2 < lastPatternSize) {
      throw new NotFoundException_1$k.default();
    }
    if (this.usingCheckDigit) {
      var max = this.decodeRowResult.length - 1;
      var total = 0;
      for (var i = 0; i < max; i++) {
        total += Code39Reader2.ALPHABET_STRING.indexOf(this.decodeRowResult.charAt(i));
      }
      if (this.decodeRowResult.charAt(max) !== Code39Reader2.ALPHABET_STRING.charAt(total % 43)) {
        throw new ChecksumException_1$4.default();
      }
      this.decodeRowResult = this.decodeRowResult.substring(0, max);
    }
    if (this.decodeRowResult.length === 0) {
      throw new NotFoundException_1$k.default();
    }
    var resultString;
    if (this.extendedMode) {
      resultString = Code39Reader2.decodeExtended(this.decodeRowResult);
    } else {
      resultString = this.decodeRowResult;
    }
    var left = (start[1] + start[0]) / 2;
    var right = lastStart + lastPatternSize / 2;
    return new Result_1$8.default(resultString, null, 0, [new ResultPoint_1$d.default(left, rowNumber), new ResultPoint_1$d.default(right, rowNumber)], BarcodeFormat_1$f.default.CODE_39, new Date().getTime());
  };
  Code39Reader2.findAsteriskPattern = function(row, counters) {
    var width = row.getSize();
    var rowOffset = row.getNextSet(0);
    var counterPosition = 0;
    var patternStart = rowOffset;
    var isWhite = false;
    var patternLength = counters.length;
    for (var i = rowOffset; i < width; i++) {
      if (row.get(i) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (counterPosition === patternLength - 1) {
          if (this.toNarrowWidePattern(counters) === Code39Reader2.ASTERISK_ENCODING && row.isRange(Math.max(0, patternStart - Math.floor((i - patternStart) / 2)), patternStart, false)) {
            return [patternStart, i];
          }
          patternStart += counters[0] + counters[1];
          counters.copyWithin(0, 2, 2 + counterPosition - 1);
          counters[counterPosition - 1] = 0;
          counters[counterPosition] = 0;
          counterPosition--;
        } else {
          counterPosition++;
        }
        counters[counterPosition] = 1;
        isWhite = !isWhite;
      }
    }
    throw new NotFoundException_1$k.default();
  };
  Code39Reader2.toNarrowWidePattern = function(counters) {
    var numCounters = counters.length;
    var maxNarrowCounter = 0;
    var wideCounters;
    do {
      var minCounter = 2147483647;
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        if (counter < minCounter && counter > maxNarrowCounter) {
          minCounter = counter;
        }
      }
      maxNarrowCounter = minCounter;
      wideCounters = 0;
      var totalWideCountersWidth = 0;
      var pattern = 0;
      for (var i = 0; i < numCounters; i++) {
        var counter = counters[i];
        if (counter > maxNarrowCounter) {
          pattern |= 1 << numCounters - 1 - i;
          wideCounters++;
          totalWideCountersWidth += counter;
        }
      }
      if (wideCounters === 3) {
        for (var i = 0; i < numCounters && wideCounters > 0; i++) {
          var counter = counters[i];
          if (counter > maxNarrowCounter) {
            wideCounters--;
            if (counter * 2 >= totalWideCountersWidth) {
              return -1;
            }
          }
        }
        return pattern;
      }
    } while (wideCounters > 3);
    return -1;
  };
  Code39Reader2.patternToChar = function(pattern) {
    for (var i = 0; i < Code39Reader2.CHARACTER_ENCODINGS.length; i++) {
      if (Code39Reader2.CHARACTER_ENCODINGS[i] === pattern) {
        return Code39Reader2.ALPHABET_STRING.charAt(i);
      }
    }
    if (pattern === Code39Reader2.ASTERISK_ENCODING) {
      return "*";
    }
    throw new NotFoundException_1$k.default();
  };
  Code39Reader2.decodeExtended = function(encoded) {
    var length = encoded.length;
    var decoded = "";
    for (var i = 0; i < length; i++) {
      var c = encoded.charAt(i);
      if (c === "+" || c === "$" || c === "%" || c === "/") {
        var next = encoded.charAt(i + 1);
        var decodedChar = "\0";
        switch (c) {
          case "+":
            if (next >= "A" && next <= "Z") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) + 32);
            } else {
              throw new FormatException_1$9.default();
            }
            break;
          case "$":
            if (next >= "A" && next <= "Z") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) - 64);
            } else {
              throw new FormatException_1$9.default();
            }
            break;
          case "%":
            if (next >= "A" && next <= "E") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) - 38);
            } else if (next >= "F" && next <= "J") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) - 11);
            } else if (next >= "K" && next <= "O") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) + 16);
            } else if (next >= "P" && next <= "T") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) + 43);
            } else if (next === "U") {
              decodedChar = "\0";
            } else if (next === "V") {
              decodedChar = "@";
            } else if (next === "W") {
              decodedChar = "`";
            } else if (next === "X" || next === "Y" || next === "Z") {
              decodedChar = "\x7F";
            } else {
              throw new FormatException_1$9.default();
            }
            break;
          case "/":
            if (next >= "A" && next <= "O") {
              decodedChar = String.fromCharCode(next.charCodeAt(0) - 32);
            } else if (next === "Z") {
              decodedChar = ":";
            } else {
              throw new FormatException_1$9.default();
            }
            break;
        }
        decoded += decodedChar;
        i++;
      } else {
        decoded += c;
      }
    }
    return decoded;
  };
  Code39Reader2.ALPHABET_STRING = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";
  Code39Reader2.CHARACTER_ENCODINGS = [
    52,
    289,
    97,
    352,
    49,
    304,
    112,
    37,
    292,
    100,
    265,
    73,
    328,
    25,
    280,
    88,
    13,
    268,
    76,
    28,
    259,
    67,
    322,
    19,
    274,
    82,
    7,
    262,
    70,
    22,
    385,
    193,
    448,
    145,
    400,
    208,
    133,
    388,
    196,
    168,
    162,
    138,
    42
  ];
  Code39Reader2.ASTERISK_ENCODING = 148;
  return Code39Reader2;
}(OneDReader_1$7.default);
Code39Reader$1.default = Code39Reader;
var Code128Reader$1 = {};
var __extends$n = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(Code128Reader$1, "__esModule", { value: true });
var BarcodeFormat_1$e = BarcodeFormat$1;
var DecodeHintType_1$b = DecodeHintType$1;
var Result_1$7 = Result$1;
var ResultPoint_1$c = ResultPoint$1;
var OneDReader_1$6 = OneDReader$1;
var NotFoundException_1$j = NotFoundException$1;
var FormatException_1$8 = FormatException$1;
var ChecksumException_1$3 = ChecksumException$1;
var Code128Reader = function(_super) {
  __extends$n(Code128Reader2, _super);
  function Code128Reader2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Code128Reader2.findStartPattern = function(row) {
    var width = row.getSize();
    var rowOffset = row.getNextSet(0);
    var counterPosition = 0;
    var counters = [0, 0, 0, 0, 0, 0];
    var patternStart = rowOffset;
    var isWhite = false;
    var patternLength = 6;
    for (var i = rowOffset; i < width; i++) {
      if (row.get(i) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (counterPosition === patternLength - 1) {
          var bestVariance = Code128Reader2.MAX_AVG_VARIANCE;
          var bestMatch = -1;
          for (var startCode = Code128Reader2.CODE_START_A; startCode <= Code128Reader2.CODE_START_C; startCode++) {
            var variance = OneDReader_1$6.default.patternMatchVariance(counters, Code128Reader2.CODE_PATTERNS[startCode], Code128Reader2.MAX_INDIVIDUAL_VARIANCE);
            if (variance < bestVariance) {
              bestVariance = variance;
              bestMatch = startCode;
            }
          }
          if (bestMatch >= 0 && row.isRange(Math.max(0, patternStart - (i - patternStart) / 2), patternStart, false)) {
            return [patternStart, i, bestMatch];
          }
          patternStart += counters[0] + counters[1];
          counters.splice(0, 2);
          counters[counterPosition - 1] = 0;
          counters[counterPosition] = 0;
          counterPosition--;
        } else {
          counterPosition++;
        }
        counters[counterPosition] = 1;
        isWhite = !isWhite;
      }
    }
    throw new NotFoundException_1$j.default();
  };
  Code128Reader2.decodeCode = function(row, counters, rowOffset) {
    OneDReader_1$6.default.recordPattern(row, rowOffset, counters);
    var bestVariance = Code128Reader2.MAX_AVG_VARIANCE;
    var bestMatch = -1;
    for (var d = 0; d < Code128Reader2.CODE_PATTERNS.length; d++) {
      var pattern = Code128Reader2.CODE_PATTERNS[d];
      var variance = this.patternMatchVariance(counters, pattern, Code128Reader2.MAX_INDIVIDUAL_VARIANCE);
      if (variance < bestVariance) {
        bestVariance = variance;
        bestMatch = d;
      }
    }
    if (bestMatch >= 0) {
      return bestMatch;
    } else {
      throw new NotFoundException_1$j.default();
    }
  };
  Code128Reader2.prototype.decodeRow = function(rowNumber, row, hints) {
    var convertFNC1 = hints && hints.get(DecodeHintType_1$b.default.ASSUME_GS1) === true;
    var startPatternInfo = Code128Reader2.findStartPattern(row);
    var startCode = startPatternInfo[2];
    var currentRawCodesIndex = 0;
    var rawCodes = new Uint8Array(20);
    rawCodes[currentRawCodesIndex++] = startCode;
    var codeSet;
    switch (startCode) {
      case Code128Reader2.CODE_START_A:
        codeSet = Code128Reader2.CODE_CODE_A;
        break;
      case Code128Reader2.CODE_START_B:
        codeSet = Code128Reader2.CODE_CODE_B;
        break;
      case Code128Reader2.CODE_START_C:
        codeSet = Code128Reader2.CODE_CODE_C;
        break;
      default:
        throw new FormatException_1$8.default();
    }
    var done = false;
    var isNextShifted = false;
    var result = "";
    var lastStart = startPatternInfo[0];
    var nextStart = startPatternInfo[1];
    var counters = [0, 0, 0, 0, 0, 0];
    var lastCode = 0;
    var code = 0;
    var checksumTotal = startCode;
    var multiplier = 0;
    var lastCharacterWasPrintable = true;
    var upperMode = false;
    var shiftUpperMode = false;
    while (!done) {
      var unshift = isNextShifted;
      isNextShifted = false;
      lastCode = code;
      code = Code128Reader2.decodeCode(row, counters, nextStart);
      rawCodes[currentRawCodesIndex++] = code;
      if (code !== Code128Reader2.CODE_STOP) {
        lastCharacterWasPrintable = true;
      }
      if (code !== Code128Reader2.CODE_STOP) {
        multiplier++;
        checksumTotal += multiplier * code;
      }
      lastStart = nextStart;
      nextStart += counters.reduce(function(previous, current) {
        return previous + current;
      }, 0);
      switch (code) {
        case Code128Reader2.CODE_START_A:
        case Code128Reader2.CODE_START_B:
        case Code128Reader2.CODE_START_C:
          throw new FormatException_1$8.default();
      }
      switch (codeSet) {
        case Code128Reader2.CODE_CODE_A:
          if (code < 64) {
            if (shiftUpperMode === upperMode) {
              result += String.fromCharCode(" ".charCodeAt(0) + code);
            } else {
              result += String.fromCharCode(" ".charCodeAt(0) + code + 128);
            }
            shiftUpperMode = false;
          } else if (code < 96) {
            if (shiftUpperMode === upperMode) {
              result += String.fromCharCode(code - 64);
            } else {
              result += String.fromCharCode(code + 64);
            }
            shiftUpperMode = false;
          } else {
            if (code !== Code128Reader2.CODE_STOP) {
              lastCharacterWasPrintable = false;
            }
            switch (code) {
              case Code128Reader2.CODE_FNC_1:
                if (convertFNC1) {
                  if (result.length === 0) {
                    result += "]C1";
                  } else {
                    result += String.fromCharCode(29);
                  }
                }
                break;
              case Code128Reader2.CODE_FNC_2:
              case Code128Reader2.CODE_FNC_3:
                break;
              case Code128Reader2.CODE_FNC_4_A:
                if (!upperMode && shiftUpperMode) {
                  upperMode = true;
                  shiftUpperMode = false;
                } else if (upperMode && shiftUpperMode) {
                  upperMode = false;
                  shiftUpperMode = false;
                } else {
                  shiftUpperMode = true;
                }
                break;
              case Code128Reader2.CODE_SHIFT:
                isNextShifted = true;
                codeSet = Code128Reader2.CODE_CODE_B;
                break;
              case Code128Reader2.CODE_CODE_B:
                codeSet = Code128Reader2.CODE_CODE_B;
                break;
              case Code128Reader2.CODE_CODE_C:
                codeSet = Code128Reader2.CODE_CODE_C;
                break;
              case Code128Reader2.CODE_STOP:
                done = true;
                break;
            }
          }
          break;
        case Code128Reader2.CODE_CODE_B:
          if (code < 96) {
            if (shiftUpperMode === upperMode) {
              result += String.fromCharCode(" ".charCodeAt(0) + code);
            } else {
              result += String.fromCharCode(" ".charCodeAt(0) + code + 128);
            }
            shiftUpperMode = false;
          } else {
            if (code !== Code128Reader2.CODE_STOP) {
              lastCharacterWasPrintable = false;
            }
            switch (code) {
              case Code128Reader2.CODE_FNC_1:
                if (convertFNC1) {
                  if (result.length === 0) {
                    result += "]C1";
                  } else {
                    result += String.fromCharCode(29);
                  }
                }
                break;
              case Code128Reader2.CODE_FNC_2:
              case Code128Reader2.CODE_FNC_3:
                break;
              case Code128Reader2.CODE_FNC_4_B:
                if (!upperMode && shiftUpperMode) {
                  upperMode = true;
                  shiftUpperMode = false;
                } else if (upperMode && shiftUpperMode) {
                  upperMode = false;
                  shiftUpperMode = false;
                } else {
                  shiftUpperMode = true;
                }
                break;
              case Code128Reader2.CODE_SHIFT:
                isNextShifted = true;
                codeSet = Code128Reader2.CODE_CODE_A;
                break;
              case Code128Reader2.CODE_CODE_A:
                codeSet = Code128Reader2.CODE_CODE_A;
                break;
              case Code128Reader2.CODE_CODE_C:
                codeSet = Code128Reader2.CODE_CODE_C;
                break;
              case Code128Reader2.CODE_STOP:
                done = true;
                break;
            }
          }
          break;
        case Code128Reader2.CODE_CODE_C:
          if (code < 100) {
            if (code < 10) {
              result += "0";
            }
            result += code;
          } else {
            if (code !== Code128Reader2.CODE_STOP) {
              lastCharacterWasPrintable = false;
            }
            switch (code) {
              case Code128Reader2.CODE_FNC_1:
                if (convertFNC1) {
                  if (result.length === 0) {
                    result += "]C1";
                  } else {
                    result += String.fromCharCode(29);
                  }
                }
                break;
              case Code128Reader2.CODE_CODE_A:
                codeSet = Code128Reader2.CODE_CODE_A;
                break;
              case Code128Reader2.CODE_CODE_B:
                codeSet = Code128Reader2.CODE_CODE_B;
                break;
              case Code128Reader2.CODE_STOP:
                done = true;
                break;
            }
          }
          break;
      }
      if (unshift) {
        codeSet = codeSet === Code128Reader2.CODE_CODE_A ? Code128Reader2.CODE_CODE_B : Code128Reader2.CODE_CODE_A;
      }
    }
    var lastPatternSize = nextStart - lastStart;
    nextStart = row.getNextUnset(nextStart);
    if (!row.isRange(nextStart, Math.min(row.getSize(), nextStart + (nextStart - lastStart) / 2), false)) {
      throw new NotFoundException_1$j.default();
    }
    checksumTotal -= multiplier * lastCode;
    if (checksumTotal % 103 !== lastCode) {
      throw new ChecksumException_1$3.default();
    }
    var resultLength = result.length;
    if (resultLength === 0) {
      throw new NotFoundException_1$j.default();
    }
    if (resultLength > 0 && lastCharacterWasPrintable) {
      if (codeSet === Code128Reader2.CODE_CODE_C) {
        result = result.substring(0, resultLength - 2);
      } else {
        result = result.substring(0, resultLength - 1);
      }
    }
    var left = (startPatternInfo[1] + startPatternInfo[0]) / 2;
    var right = lastStart + lastPatternSize / 2;
    var rawCodesSize = rawCodes.length;
    var rawBytes = new Uint8Array(rawCodesSize);
    for (var i = 0; i < rawCodesSize; i++) {
      rawBytes[i] = rawCodes[i];
    }
    var points = [new ResultPoint_1$c.default(left, rowNumber), new ResultPoint_1$c.default(right, rowNumber)];
    return new Result_1$7.default(result, rawBytes, 0, points, BarcodeFormat_1$e.default.CODE_128, new Date().getTime());
  };
  Code128Reader2.CODE_PATTERNS = [
    [2, 1, 2, 2, 2, 2],
    [2, 2, 2, 1, 2, 2],
    [2, 2, 2, 2, 2, 1],
    [1, 2, 1, 2, 2, 3],
    [1, 2, 1, 3, 2, 2],
    [1, 3, 1, 2, 2, 2],
    [1, 2, 2, 2, 1, 3],
    [1, 2, 2, 3, 1, 2],
    [1, 3, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 3],
    [2, 2, 1, 3, 1, 2],
    [2, 3, 1, 2, 1, 2],
    [1, 1, 2, 2, 3, 2],
    [1, 2, 2, 1, 3, 2],
    [1, 2, 2, 2, 3, 1],
    [1, 1, 3, 2, 2, 2],
    [1, 2, 3, 1, 2, 2],
    [1, 2, 3, 2, 2, 1],
    [2, 2, 3, 2, 1, 1],
    [2, 2, 1, 1, 3, 2],
    [2, 2, 1, 2, 3, 1],
    [2, 1, 3, 2, 1, 2],
    [2, 2, 3, 1, 1, 2],
    [3, 1, 2, 1, 3, 1],
    [3, 1, 1, 2, 2, 2],
    [3, 2, 1, 1, 2, 2],
    [3, 2, 1, 2, 2, 1],
    [3, 1, 2, 2, 1, 2],
    [3, 2, 2, 1, 1, 2],
    [3, 2, 2, 2, 1, 1],
    [2, 1, 2, 1, 2, 3],
    [2, 1, 2, 3, 2, 1],
    [2, 3, 2, 1, 2, 1],
    [1, 1, 1, 3, 2, 3],
    [1, 3, 1, 1, 2, 3],
    [1, 3, 1, 3, 2, 1],
    [1, 1, 2, 3, 1, 3],
    [1, 3, 2, 1, 1, 3],
    [1, 3, 2, 3, 1, 1],
    [2, 1, 1, 3, 1, 3],
    [2, 3, 1, 1, 1, 3],
    [2, 3, 1, 3, 1, 1],
    [1, 1, 2, 1, 3, 3],
    [1, 1, 2, 3, 3, 1],
    [1, 3, 2, 1, 3, 1],
    [1, 1, 3, 1, 2, 3],
    [1, 1, 3, 3, 2, 1],
    [1, 3, 3, 1, 2, 1],
    [3, 1, 3, 1, 2, 1],
    [2, 1, 1, 3, 3, 1],
    [2, 3, 1, 1, 3, 1],
    [2, 1, 3, 1, 1, 3],
    [2, 1, 3, 3, 1, 1],
    [2, 1, 3, 1, 3, 1],
    [3, 1, 1, 1, 2, 3],
    [3, 1, 1, 3, 2, 1],
    [3, 3, 1, 1, 2, 1],
    [3, 1, 2, 1, 1, 3],
    [3, 1, 2, 3, 1, 1],
    [3, 3, 2, 1, 1, 1],
    [3, 1, 4, 1, 1, 1],
    [2, 2, 1, 4, 1, 1],
    [4, 3, 1, 1, 1, 1],
    [1, 1, 1, 2, 2, 4],
    [1, 1, 1, 4, 2, 2],
    [1, 2, 1, 1, 2, 4],
    [1, 2, 1, 4, 2, 1],
    [1, 4, 1, 1, 2, 2],
    [1, 4, 1, 2, 2, 1],
    [1, 1, 2, 2, 1, 4],
    [1, 1, 2, 4, 1, 2],
    [1, 2, 2, 1, 1, 4],
    [1, 2, 2, 4, 1, 1],
    [1, 4, 2, 1, 1, 2],
    [1, 4, 2, 2, 1, 1],
    [2, 4, 1, 2, 1, 1],
    [2, 2, 1, 1, 1, 4],
    [4, 1, 3, 1, 1, 1],
    [2, 4, 1, 1, 1, 2],
    [1, 3, 4, 1, 1, 1],
    [1, 1, 1, 2, 4, 2],
    [1, 2, 1, 1, 4, 2],
    [1, 2, 1, 2, 4, 1],
    [1, 1, 4, 2, 1, 2],
    [1, 2, 4, 1, 1, 2],
    [1, 2, 4, 2, 1, 1],
    [4, 1, 1, 2, 1, 2],
    [4, 2, 1, 1, 1, 2],
    [4, 2, 1, 2, 1, 1],
    [2, 1, 2, 1, 4, 1],
    [2, 1, 4, 1, 2, 1],
    [4, 1, 2, 1, 2, 1],
    [1, 1, 1, 1, 4, 3],
    [1, 1, 1, 3, 4, 1],
    [1, 3, 1, 1, 4, 1],
    [1, 1, 4, 1, 1, 3],
    [1, 1, 4, 3, 1, 1],
    [4, 1, 1, 1, 1, 3],
    [4, 1, 1, 3, 1, 1],
    [1, 1, 3, 1, 4, 1],
    [1, 1, 4, 1, 3, 1],
    [3, 1, 1, 1, 4, 1],
    [4, 1, 1, 1, 3, 1],
    [2, 1, 1, 4, 1, 2],
    [2, 1, 1, 2, 1, 4],
    [2, 1, 1, 2, 3, 2],
    [2, 3, 3, 1, 1, 1, 2]
  ];
  Code128Reader2.MAX_AVG_VARIANCE = 0.25;
  Code128Reader2.MAX_INDIVIDUAL_VARIANCE = 0.7;
  Code128Reader2.CODE_SHIFT = 98;
  Code128Reader2.CODE_CODE_C = 99;
  Code128Reader2.CODE_CODE_B = 100;
  Code128Reader2.CODE_CODE_A = 101;
  Code128Reader2.CODE_FNC_1 = 102;
  Code128Reader2.CODE_FNC_2 = 97;
  Code128Reader2.CODE_FNC_3 = 96;
  Code128Reader2.CODE_FNC_4_A = 101;
  Code128Reader2.CODE_FNC_4_B = 100;
  Code128Reader2.CODE_START_A = 103;
  Code128Reader2.CODE_START_B = 104;
  Code128Reader2.CODE_START_C = 105;
  Code128Reader2.CODE_STOP = 106;
  return Code128Reader2;
}(OneDReader_1$6.default);
Code128Reader$1.default = Code128Reader;
var RSS14Reader$1 = {};
var AbstractRSSReader$1 = {};
var __extends$m = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(AbstractRSSReader$1, "__esModule", { value: true });
var OneDReader_1$5 = OneDReader$1;
var NotFoundException_1$i = NotFoundException$1;
var MathUtils_1$4 = MathUtils$1;
var AbstractRSSReader = function(_super) {
  __extends$m(AbstractRSSReader2, _super);
  function AbstractRSSReader2() {
    var _this = _super.call(this) || this;
    _this.decodeFinderCounters = new Array(4);
    _this.dataCharacterCounters = new Array(8);
    _this.oddRoundingErrors = new Array(4);
    _this.evenRoundingErrors = new Array(4);
    _this.oddCounts = new Array(_this.dataCharacterCounters.length / 2);
    _this.evenCounts = new Array(_this.dataCharacterCounters.length / 2);
    return _this;
  }
  AbstractRSSReader2.prototype.getDecodeFinderCounters = function() {
    return this.decodeFinderCounters;
  };
  AbstractRSSReader2.prototype.getDataCharacterCounters = function() {
    return this.dataCharacterCounters;
  };
  AbstractRSSReader2.prototype.getOddRoundingErrors = function() {
    return this.oddRoundingErrors;
  };
  AbstractRSSReader2.prototype.getEvenRoundingErrors = function() {
    return this.evenRoundingErrors;
  };
  AbstractRSSReader2.prototype.getOddCounts = function() {
    return this.oddCounts;
  };
  AbstractRSSReader2.prototype.getEvenCounts = function() {
    return this.evenCounts;
  };
  AbstractRSSReader2.prototype.parseFinderValue = function(counters, finderPatterns) {
    for (var value = 0; value < finderPatterns.length; value++) {
      if (OneDReader_1$5.default.patternMatchVariance(counters, finderPatterns[value], AbstractRSSReader2.MAX_INDIVIDUAL_VARIANCE) < AbstractRSSReader2.MAX_AVG_VARIANCE) {
        return value;
      }
    }
    throw new NotFoundException_1$i.default();
  };
  AbstractRSSReader2.count = function(array) {
    return MathUtils_1$4.default.sum(new Int32Array(array));
  };
  AbstractRSSReader2.increment = function(array, errors) {
    var index = 0;
    var biggestError = errors[0];
    for (var i = 1; i < array.length; i++) {
      if (errors[i] > biggestError) {
        biggestError = errors[i];
        index = i;
      }
    }
    array[index]++;
  };
  AbstractRSSReader2.decrement = function(array, errors) {
    var index = 0;
    var biggestError = errors[0];
    for (var i = 1; i < array.length; i++) {
      if (errors[i] < biggestError) {
        biggestError = errors[i];
        index = i;
      }
    }
    array[index]--;
  };
  AbstractRSSReader2.isFinderPattern = function(counters) {
    var firstTwoSum = counters[0] + counters[1];
    var sum = firstTwoSum + counters[2] + counters[3];
    var ratio = firstTwoSum / sum;
    if (ratio >= AbstractRSSReader2.MIN_FINDER_PATTERN_RATIO && ratio <= AbstractRSSReader2.MAX_FINDER_PATTERN_RATIO) {
      var minCounter = Number.MAX_SAFE_INTEGER;
      var maxCounter = Number.MIN_SAFE_INTEGER;
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        if (counter > maxCounter) {
          maxCounter = counter;
        }
        if (counter < minCounter) {
          minCounter = counter;
        }
      }
      return maxCounter < 10 * minCounter;
    }
    return false;
  };
  AbstractRSSReader2.MAX_AVG_VARIANCE = 0.2;
  AbstractRSSReader2.MAX_INDIVIDUAL_VARIANCE = 0.45;
  AbstractRSSReader2.MIN_FINDER_PATTERN_RATIO = 9.5 / 12;
  AbstractRSSReader2.MAX_FINDER_PATTERN_RATIO = 12.5 / 14;
  return AbstractRSSReader2;
}(OneDReader_1$5.default);
AbstractRSSReader$1.default = AbstractRSSReader;
var Pair$1 = {};
var DataCharacter$1 = {};
Object.defineProperty(DataCharacter$1, "__esModule", { value: true });
var DataCharacter = function() {
  function DataCharacter2(value, checksumPortion) {
    this.value = value;
    this.checksumPortion = checksumPortion;
  }
  DataCharacter2.prototype.getValue = function() {
    return this.value;
  };
  DataCharacter2.prototype.getChecksumPortion = function() {
    return this.checksumPortion;
  };
  DataCharacter2.prototype.toString = function() {
    return this.value + "(" + this.checksumPortion + ")";
  };
  DataCharacter2.prototype.equals = function(o) {
    if (!(o instanceof DataCharacter2)) {
      return false;
    }
    var that = o;
    return this.value === that.value && this.checksumPortion === that.checksumPortion;
  };
  DataCharacter2.prototype.hashCode = function() {
    return this.value ^ this.checksumPortion;
  };
  return DataCharacter2;
}();
DataCharacter$1.default = DataCharacter;
var __extends$l = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(Pair$1, "__esModule", { value: true });
var DataCharacter_1$1 = DataCharacter$1;
var Pair = function(_super) {
  __extends$l(Pair2, _super);
  function Pair2(value, checksumPortion, finderPattern) {
    var _this = _super.call(this, value, checksumPortion) || this;
    _this.count = 0;
    _this.finderPattern = finderPattern;
    return _this;
  }
  Pair2.prototype.getFinderPattern = function() {
    return this.finderPattern;
  };
  Pair2.prototype.getCount = function() {
    return this.count;
  };
  Pair2.prototype.incrementCount = function() {
    this.count++;
  };
  return Pair2;
}(DataCharacter_1$1.default);
Pair$1.default = Pair;
var FinderPattern$3 = {};
Object.defineProperty(FinderPattern$3, "__esModule", { value: true });
var ResultPoint_1$b = ResultPoint$1;
var FinderPattern$2 = function() {
  function FinderPattern2(value, startEnd, start, end, rowNumber) {
    this.value = value;
    this.startEnd = startEnd;
    this.value = value;
    this.startEnd = startEnd;
    this.resultPoints = new Array();
    this.resultPoints.push(new ResultPoint_1$b.default(start, rowNumber));
    this.resultPoints.push(new ResultPoint_1$b.default(end, rowNumber));
  }
  FinderPattern2.prototype.getValue = function() {
    return this.value;
  };
  FinderPattern2.prototype.getStartEnd = function() {
    return this.startEnd;
  };
  FinderPattern2.prototype.getResultPoints = function() {
    return this.resultPoints;
  };
  FinderPattern2.prototype.equals = function(o) {
    if (!(o instanceof FinderPattern2)) {
      return false;
    }
    var that = o;
    return this.value === that.value;
  };
  FinderPattern2.prototype.hashCode = function() {
    return this.value;
  };
  return FinderPattern2;
}();
FinderPattern$3.default = FinderPattern$2;
var RSSUtils$1 = {};
Object.defineProperty(RSSUtils$1, "__esModule", { value: true });
var RSSUtils = function() {
  function RSSUtils2() {
  }
  RSSUtils2.prototype.RSSUtils = function() {
  };
  RSSUtils2.getRSSvalue = function(widths, maxWidth, noNarrow) {
    var n = 0;
    for (var _i = 0, widths_1 = widths; _i < widths_1.length; _i++) {
      var width = widths_1[_i];
      n += width;
    }
    var val = 0;
    var narrowMask = 0;
    var elements = widths.length;
    for (var bar = 0; bar < elements - 1; bar++) {
      var elmWidth = void 0;
      for (elmWidth = 1, narrowMask |= 1 << bar; elmWidth < widths[bar]; elmWidth++, narrowMask &= ~(1 << bar)) {
        var subVal = RSSUtils2.combins(n - elmWidth - 1, elements - bar - 2);
        if (noNarrow && narrowMask === 0 && n - elmWidth - (elements - bar - 1) >= elements - bar - 1) {
          subVal -= RSSUtils2.combins(n - elmWidth - (elements - bar), elements - bar - 2);
        }
        if (elements - bar - 1 > 1) {
          var lessVal = 0;
          for (var mxwElement = n - elmWidth - (elements - bar - 2); mxwElement > maxWidth; mxwElement--) {
            lessVal += RSSUtils2.combins(n - elmWidth - mxwElement - 1, elements - bar - 3);
          }
          subVal -= lessVal * (elements - 1 - bar);
        } else if (n - elmWidth > maxWidth) {
          subVal--;
        }
        val += subVal;
      }
      n -= elmWidth;
    }
    return val;
  };
  RSSUtils2.combins = function(n, r) {
    var maxDenom;
    var minDenom;
    if (n - r > r) {
      minDenom = r;
      maxDenom = n - r;
    } else {
      minDenom = n - r;
      maxDenom = r;
    }
    var val = 1;
    var j = 1;
    for (var i = n; i > maxDenom; i--) {
      val *= i;
      if (j <= minDenom) {
        val /= j;
        j++;
      }
    }
    while (j <= minDenom) {
      val /= j;
      j++;
    }
    return val;
  };
  return RSSUtils2;
}();
RSSUtils$1.default = RSSUtils;
var __extends$k = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(RSS14Reader$1, "__esModule", { value: true });
var AbstractRSSReader_1 = AbstractRSSReader$1;
var Pair_1 = Pair$1;
var Result_1$6 = Result$1;
var DecodeHintType_1$a = DecodeHintType$1;
var NotFoundException_1$h = NotFoundException$1;
var StringBuilder_1$5 = StringBuilder$1;
var BarcodeFormat_1$d = BarcodeFormat$1;
var ResultPoint_1$a = ResultPoint$1;
var FinderPattern_1$1 = FinderPattern$3;
var DataCharacter_1 = DataCharacter$1;
var MathUtils_1$3 = MathUtils$1;
var RSSUtils_1 = RSSUtils$1;
var System_1$6 = System$1;
var OneDReader_1$4 = OneDReader$1;
var RSS14Reader = function(_super) {
  __extends$k(RSS14Reader2, _super);
  function RSS14Reader2() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.possibleLeftPairs = [];
    _this.possibleRightPairs = [];
    return _this;
  }
  RSS14Reader2.prototype.decodeRow = function(rowNumber, row, hints) {
    var leftPair = this.decodePair(row, false, rowNumber, hints);
    RSS14Reader2.addOrTally(this.possibleLeftPairs, leftPair);
    row.reverse();
    var rightPair = this.decodePair(row, true, rowNumber, hints);
    RSS14Reader2.addOrTally(this.possibleRightPairs, rightPair);
    row.reverse();
    for (var _i = 0, _a = this.possibleLeftPairs; _i < _a.length; _i++) {
      var left = _a[_i];
      if (left.getCount() > 1) {
        for (var _b = 0, _c = this.possibleRightPairs; _b < _c.length; _b++) {
          var right = _c[_b];
          if (right.getCount() > 1 && RSS14Reader2.checkChecksum(left, right)) {
            return RSS14Reader2.constructResult(left, right);
          }
        }
      }
    }
    throw new NotFoundException_1$h.default();
  };
  RSS14Reader2.addOrTally = function(possiblePairs, pair) {
    if (pair == null) {
      return;
    }
    var found = false;
    for (var _i = 0, possiblePairs_1 = possiblePairs; _i < possiblePairs_1.length; _i++) {
      var other = possiblePairs_1[_i];
      if (other.getValue() === pair.getValue()) {
        other.incrementCount();
        found = true;
        break;
      }
    }
    if (!found) {
      possiblePairs.push(pair);
    }
  };
  RSS14Reader2.prototype.reset = function() {
    this.possibleLeftPairs.length = 0;
    this.possibleRightPairs.length = 0;
  };
  RSS14Reader2.constructResult = function(leftPair, rightPair) {
    var symbolValue = 4537077 * leftPair.getValue() + rightPair.getValue();
    var text = new String(symbolValue).toString();
    var buffer = new StringBuilder_1$5.default();
    for (var i = 13 - text.length; i > 0; i--) {
      buffer.append("0");
    }
    buffer.append(text);
    var checkDigit = 0;
    for (var i = 0; i < 13; i++) {
      var digit = buffer.charAt(i).charCodeAt(0) - "0".charCodeAt(0);
      checkDigit += (i & 1) === 0 ? 3 * digit : digit;
    }
    checkDigit = 10 - checkDigit % 10;
    if (checkDigit === 10) {
      checkDigit = 0;
    }
    buffer.append(checkDigit.toString());
    var leftPoints = leftPair.getFinderPattern().getResultPoints();
    var rightPoints = rightPair.getFinderPattern().getResultPoints();
    return new Result_1$6.default(buffer.toString(), null, 0, [leftPoints[0], leftPoints[1], rightPoints[0], rightPoints[1]], BarcodeFormat_1$d.default.RSS_14, new Date().getTime());
  };
  RSS14Reader2.checkChecksum = function(leftPair, rightPair) {
    var checkValue = (leftPair.getChecksumPortion() + 16 * rightPair.getChecksumPortion()) % 79;
    var targetCheckValue = 9 * leftPair.getFinderPattern().getValue() + rightPair.getFinderPattern().getValue();
    if (targetCheckValue > 72) {
      targetCheckValue--;
    }
    if (targetCheckValue > 8) {
      targetCheckValue--;
    }
    return checkValue === targetCheckValue;
  };
  RSS14Reader2.prototype.decodePair = function(row, right, rowNumber, hints) {
    try {
      var startEnd = this.findFinderPattern(row, right);
      var pattern = this.parseFoundFinderPattern(row, rowNumber, right, startEnd);
      var resultPointCallback = hints == null ? null : hints.get(DecodeHintType_1$a.default.NEED_RESULT_POINT_CALLBACK);
      if (resultPointCallback != null) {
        var center = (startEnd[0] + startEnd[1]) / 2;
        if (right) {
          center = row.getSize() - 1 - center;
        }
        resultPointCallback.foundPossibleResultPoint(new ResultPoint_1$a.default(center, rowNumber));
      }
      var outside = this.decodeDataCharacter(row, pattern, true);
      var inside = this.decodeDataCharacter(row, pattern, false);
      return new Pair_1.default(1597 * outside.getValue() + inside.getValue(), outside.getChecksumPortion() + 4 * inside.getChecksumPortion(), pattern);
    } catch (err) {
      return null;
    }
  };
  RSS14Reader2.prototype.decodeDataCharacter = function(row, pattern, outsideChar) {
    var counters = this.getDataCharacterCounters();
    for (var x = 0; x < counters.length; x++) {
      counters[x] = 0;
    }
    if (outsideChar) {
      OneDReader_1$4.default.recordPatternInReverse(row, pattern.getStartEnd()[0], counters);
    } else {
      OneDReader_1$4.default.recordPattern(row, pattern.getStartEnd()[1] + 1, counters);
      for (var i = 0, j = counters.length - 1; i < j; i++, j--) {
        var temp = counters[i];
        counters[i] = counters[j];
        counters[j] = temp;
      }
    }
    var numModules = outsideChar ? 16 : 15;
    var elementWidth = MathUtils_1$3.default.sum(new Int32Array(counters)) / numModules;
    var oddCounts = this.getOddCounts();
    var evenCounts = this.getEvenCounts();
    var oddRoundingErrors = this.getOddRoundingErrors();
    var evenRoundingErrors = this.getEvenRoundingErrors();
    for (var i = 0; i < counters.length; i++) {
      var value = counters[i] / elementWidth;
      var count = Math.floor(value + 0.5);
      if (count < 1) {
        count = 1;
      } else if (count > 8) {
        count = 8;
      }
      var offset = Math.floor(i / 2);
      if ((i & 1) === 0) {
        oddCounts[offset] = count;
        oddRoundingErrors[offset] = value - count;
      } else {
        evenCounts[offset] = count;
        evenRoundingErrors[offset] = value - count;
      }
    }
    this.adjustOddEvenCounts(outsideChar, numModules);
    var oddSum = 0;
    var oddChecksumPortion = 0;
    for (var i = oddCounts.length - 1; i >= 0; i--) {
      oddChecksumPortion *= 9;
      oddChecksumPortion += oddCounts[i];
      oddSum += oddCounts[i];
    }
    var evenChecksumPortion = 0;
    var evenSum = 0;
    for (var i = evenCounts.length - 1; i >= 0; i--) {
      evenChecksumPortion *= 9;
      evenChecksumPortion += evenCounts[i];
      evenSum += evenCounts[i];
    }
    var checksumPortion = oddChecksumPortion + 3 * evenChecksumPortion;
    if (outsideChar) {
      if ((oddSum & 1) !== 0 || oddSum > 12 || oddSum < 4) {
        throw new NotFoundException_1$h.default();
      }
      var group = (12 - oddSum) / 2;
      var oddWidest = RSS14Reader2.OUTSIDE_ODD_WIDEST[group];
      var evenWidest = 9 - oddWidest;
      var vOdd = RSSUtils_1.default.getRSSvalue(oddCounts, oddWidest, false);
      var vEven = RSSUtils_1.default.getRSSvalue(evenCounts, evenWidest, true);
      var tEven = RSS14Reader2.OUTSIDE_EVEN_TOTAL_SUBSET[group];
      var gSum = RSS14Reader2.OUTSIDE_GSUM[group];
      return new DataCharacter_1.default(vOdd * tEven + vEven + gSum, checksumPortion);
    } else {
      if ((evenSum & 1) !== 0 || evenSum > 10 || evenSum < 4) {
        throw new NotFoundException_1$h.default();
      }
      var group = (10 - evenSum) / 2;
      var oddWidest = RSS14Reader2.INSIDE_ODD_WIDEST[group];
      var evenWidest = 9 - oddWidest;
      var vOdd = RSSUtils_1.default.getRSSvalue(oddCounts, oddWidest, true);
      var vEven = RSSUtils_1.default.getRSSvalue(evenCounts, evenWidest, false);
      var tOdd = RSS14Reader2.INSIDE_ODD_TOTAL_SUBSET[group];
      var gSum = RSS14Reader2.INSIDE_GSUM[group];
      return new DataCharacter_1.default(vEven * tOdd + vOdd + gSum, checksumPortion);
    }
  };
  RSS14Reader2.prototype.findFinderPattern = function(row, rightFinderPattern) {
    var counters = this.getDecodeFinderCounters();
    counters[0] = 0;
    counters[1] = 0;
    counters[2] = 0;
    counters[3] = 0;
    var width = row.getSize();
    var isWhite = false;
    var rowOffset = 0;
    while (rowOffset < width) {
      isWhite = !row.get(rowOffset);
      if (rightFinderPattern === isWhite) {
        break;
      }
      rowOffset++;
    }
    var counterPosition = 0;
    var patternStart = rowOffset;
    for (var x = rowOffset; x < width; x++) {
      if (row.get(x) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (counterPosition === 3) {
          if (AbstractRSSReader_1.default.isFinderPattern(counters)) {
            return [patternStart, x];
          }
          patternStart += counters[0] + counters[1];
          counters[0] = counters[2];
          counters[1] = counters[3];
          counters[2] = 0;
          counters[3] = 0;
          counterPosition--;
        } else {
          counterPosition++;
        }
        counters[counterPosition] = 1;
        isWhite = !isWhite;
      }
    }
    throw new NotFoundException_1$h.default();
  };
  RSS14Reader2.prototype.parseFoundFinderPattern = function(row, rowNumber, right, startEnd) {
    var firstIsBlack = row.get(startEnd[0]);
    var firstElementStart = startEnd[0] - 1;
    while (firstElementStart >= 0 && firstIsBlack !== row.get(firstElementStart)) {
      firstElementStart--;
    }
    firstElementStart++;
    var firstCounter = startEnd[0] - firstElementStart;
    var counters = this.getDecodeFinderCounters();
    var copy = new Array(counters.length);
    System_1$6.default.arraycopy(counters, 0, copy, 1, counters.length - 1);
    copy[0] = firstCounter;
    var value = this.parseFinderValue(copy, RSS14Reader2.FINDER_PATTERNS);
    var start = firstElementStart;
    var end = startEnd[1];
    if (right) {
      start = row.getSize() - 1 - start;
      end = row.getSize() - 1 - end;
    }
    return new FinderPattern_1$1.default(value, [firstElementStart, startEnd[1]], start, end, rowNumber);
  };
  RSS14Reader2.prototype.adjustOddEvenCounts = function(outsideChar, numModules) {
    var oddSum = MathUtils_1$3.default.sum(new Int32Array(this.getOddCounts()));
    var evenSum = MathUtils_1$3.default.sum(new Int32Array(this.getEvenCounts()));
    var incrementOdd = false;
    var decrementOdd = false;
    var incrementEven = false;
    var decrementEven = false;
    if (outsideChar) {
      if (oddSum > 12) {
        decrementOdd = true;
      } else if (oddSum < 4) {
        incrementOdd = true;
      }
      if (evenSum > 12) {
        decrementEven = true;
      } else if (evenSum < 4) {
        incrementEven = true;
      }
    } else {
      if (oddSum > 11) {
        decrementOdd = true;
      } else if (oddSum < 5) {
        incrementOdd = true;
      }
      if (evenSum > 10) {
        decrementEven = true;
      } else if (evenSum < 4) {
        incrementEven = true;
      }
    }
    var mismatch = oddSum + evenSum - numModules;
    var oddParityBad = (oddSum & 1) === (outsideChar ? 1 : 0);
    var evenParityBad = (evenSum & 1) === 1;
    if (mismatch === 1) {
      if (oddParityBad) {
        if (evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
        decrementOdd = true;
      } else {
        if (!evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
        decrementEven = true;
      }
    } else if (mismatch === -1) {
      if (oddParityBad) {
        if (evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
        incrementOdd = true;
      } else {
        if (!evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
        incrementEven = true;
      }
    } else if (mismatch === 0) {
      if (oddParityBad) {
        if (!evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
        if (oddSum < evenSum) {
          incrementOdd = true;
          decrementEven = true;
        } else {
          decrementOdd = true;
          incrementEven = true;
        }
      } else {
        if (evenParityBad) {
          throw new NotFoundException_1$h.default();
        }
      }
    } else {
      throw new NotFoundException_1$h.default();
    }
    if (incrementOdd) {
      if (decrementOdd) {
        throw new NotFoundException_1$h.default();
      }
      AbstractRSSReader_1.default.increment(this.getOddCounts(), this.getOddRoundingErrors());
    }
    if (decrementOdd) {
      AbstractRSSReader_1.default.decrement(this.getOddCounts(), this.getOddRoundingErrors());
    }
    if (incrementEven) {
      if (decrementEven) {
        throw new NotFoundException_1$h.default();
      }
      AbstractRSSReader_1.default.increment(this.getEvenCounts(), this.getOddRoundingErrors());
    }
    if (decrementEven) {
      AbstractRSSReader_1.default.decrement(this.getEvenCounts(), this.getEvenRoundingErrors());
    }
  };
  RSS14Reader2.OUTSIDE_EVEN_TOTAL_SUBSET = [1, 10, 34, 70, 126];
  RSS14Reader2.INSIDE_ODD_TOTAL_SUBSET = [4, 20, 48, 81];
  RSS14Reader2.OUTSIDE_GSUM = [0, 161, 961, 2015, 2715];
  RSS14Reader2.INSIDE_GSUM = [0, 336, 1036, 1516];
  RSS14Reader2.OUTSIDE_ODD_WIDEST = [8, 6, 4, 3, 1];
  RSS14Reader2.INSIDE_ODD_WIDEST = [2, 4, 6, 8];
  RSS14Reader2.FINDER_PATTERNS = [
    [3, 8, 2, 1],
    [3, 5, 5, 1],
    [3, 3, 7, 1],
    [3, 1, 9, 1],
    [2, 7, 4, 1],
    [2, 5, 6, 1],
    [2, 3, 8, 1],
    [1, 5, 7, 1],
    [1, 3, 9, 1]
  ];
  return RSS14Reader2;
}(AbstractRSSReader_1.default);
RSS14Reader$1.default = RSS14Reader;
var ITFReader$1 = {};
var __extends$j = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ITFReader$1, "__esModule", { value: true });
var BarcodeFormat_1$c = BarcodeFormat$1;
var DecodeHintType_1$9 = DecodeHintType$1;
var Result_1$5 = Result$1;
var ResultPoint_1$9 = ResultPoint$1;
var OneDReader_1$3 = OneDReader$1;
var StringBuilder_1$4 = StringBuilder$1;
var System_1$5 = System$1;
var FormatException_1$7 = FormatException$1;
var NotFoundException_1$g = NotFoundException$1;
var ITFReader = function(_super) {
  __extends$j(ITFReader2, _super);
  function ITFReader2() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.narrowLineWidth = -1;
    return _this;
  }
  ITFReader2.prototype.decodeRow = function(rowNumber, row, hints) {
    var startRange = this.decodeStart(row);
    var endRange = this.decodeEnd(row);
    var result = new StringBuilder_1$4.default();
    ITFReader2.decodeMiddle(row, startRange[1], endRange[0], result);
    var resultString = result.toString();
    var allowedLengths = null;
    if (hints != null) {
      allowedLengths = hints.get(DecodeHintType_1$9.default.ALLOWED_LENGTHS);
    }
    if (allowedLengths == null) {
      allowedLengths = ITFReader2.DEFAULT_ALLOWED_LENGTHS;
    }
    var length = resultString.length;
    var lengthOK = false;
    var maxAllowedLength = 0;
    for (var _i = 0, allowedLengths_1 = allowedLengths; _i < allowedLengths_1.length; _i++) {
      var value = allowedLengths_1[_i];
      if (length === value) {
        lengthOK = true;
        break;
      }
      if (value > maxAllowedLength) {
        maxAllowedLength = value;
      }
    }
    if (!lengthOK && length > maxAllowedLength) {
      lengthOK = true;
    }
    if (!lengthOK) {
      throw new FormatException_1$7.default();
    }
    var points = [new ResultPoint_1$9.default(startRange[1], rowNumber), new ResultPoint_1$9.default(endRange[0], rowNumber)];
    var resultReturn = new Result_1$5.default(resultString, null, 0, points, BarcodeFormat_1$c.default.ITF, new Date().getTime());
    return resultReturn;
  };
  ITFReader2.decodeMiddle = function(row, payloadStart, payloadEnd, resultString) {
    var counterDigitPair = new Array(10);
    var counterBlack = new Array(5);
    var counterWhite = new Array(5);
    counterDigitPair.fill(0);
    counterBlack.fill(0);
    counterWhite.fill(0);
    while (payloadStart < payloadEnd) {
      OneDReader_1$3.default.recordPattern(row, payloadStart, counterDigitPair);
      for (var k = 0; k < 5; k++) {
        var twoK = 2 * k;
        counterBlack[k] = counterDigitPair[twoK];
        counterWhite[k] = counterDigitPair[twoK + 1];
      }
      var bestMatch = ITFReader2.decodeDigit(counterBlack);
      resultString.append(bestMatch.toString());
      bestMatch = this.decodeDigit(counterWhite);
      resultString.append(bestMatch.toString());
      counterDigitPair.forEach(function(counterDigit) {
        payloadStart += counterDigit;
      });
    }
  };
  ITFReader2.prototype.decodeStart = function(row) {
    var endStart = ITFReader2.skipWhiteSpace(row);
    var startPattern = ITFReader2.findGuardPattern(row, endStart, ITFReader2.START_PATTERN);
    this.narrowLineWidth = (startPattern[1] - startPattern[0]) / 4;
    this.validateQuietZone(row, startPattern[0]);
    return startPattern;
  };
  ITFReader2.prototype.validateQuietZone = function(row, startPattern) {
    var quietCount = this.narrowLineWidth * 10;
    quietCount = quietCount < startPattern ? quietCount : startPattern;
    for (var i = startPattern - 1; quietCount > 0 && i >= 0; i--) {
      if (row.get(i)) {
        break;
      }
      quietCount--;
    }
    if (quietCount !== 0) {
      throw new NotFoundException_1$g.default();
    }
  };
  ITFReader2.skipWhiteSpace = function(row) {
    var width = row.getSize();
    var endStart = row.getNextSet(0);
    if (endStart === width) {
      throw new NotFoundException_1$g.default();
    }
    return endStart;
  };
  ITFReader2.prototype.decodeEnd = function(row) {
    row.reverse();
    try {
      var endStart = ITFReader2.skipWhiteSpace(row);
      var endPattern = void 0;
      try {
        endPattern = ITFReader2.findGuardPattern(row, endStart, ITFReader2.END_PATTERN_REVERSED[0]);
      } catch (NotFoundException2) {
        endPattern = ITFReader2.findGuardPattern(row, endStart, ITFReader2.END_PATTERN_REVERSED[1]);
      }
      this.validateQuietZone(row, endPattern[0]);
      var temp = endPattern[0];
      endPattern[0] = row.getSize() - endPattern[1];
      endPattern[1] = row.getSize() - temp;
      return endPattern;
    } finally {
      row.reverse();
    }
  };
  ITFReader2.findGuardPattern = function(row, rowOffset, pattern) {
    var patternLength = pattern.length;
    var counters = new Array(patternLength);
    var width = row.getSize();
    var isWhite = false;
    var counterPosition = 0;
    var patternStart = rowOffset;
    counters.fill(0);
    for (var x = rowOffset; x < width; x++) {
      if (row.get(x) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (counterPosition === patternLength - 1) {
          if (OneDReader_1$3.default.patternMatchVariance(counters, pattern, ITFReader2.MAX_INDIVIDUAL_VARIANCE) < ITFReader2.MAX_AVG_VARIANCE) {
            return [patternStart, x];
          }
          patternStart += counters[0] + counters[1];
          System_1$5.default.arraycopy(counters, 2, counters, 0, counterPosition - 1);
          counters[counterPosition - 1] = 0;
          counters[counterPosition] = 0;
          counterPosition--;
        } else {
          counterPosition++;
        }
        counters[counterPosition] = 1;
        isWhite = !isWhite;
      }
    }
    throw new NotFoundException_1$g.default();
  };
  ITFReader2.decodeDigit = function(counters) {
    var bestVariance = ITFReader2.MAX_AVG_VARIANCE;
    var bestMatch = -1;
    var max = ITFReader2.PATTERNS.length;
    for (var i = 0; i < max; i++) {
      var pattern = ITFReader2.PATTERNS[i];
      var variance = OneDReader_1$3.default.patternMatchVariance(counters, pattern, ITFReader2.MAX_INDIVIDUAL_VARIANCE);
      if (variance < bestVariance) {
        bestVariance = variance;
        bestMatch = i;
      } else if (variance === bestVariance) {
        bestMatch = -1;
      }
    }
    if (bestMatch >= 0) {
      return bestMatch % 10;
    } else {
      throw new NotFoundException_1$g.default();
    }
  };
  ITFReader2.W = 3;
  ITFReader2.w = 2;
  ITFReader2.N = 1;
  ITFReader2.PATTERNS = [
    [1, 1, 2, 2, 1],
    [2, 1, 1, 1, 2],
    [1, 2, 1, 1, 2],
    [2, 2, 1, 1, 1],
    [1, 1, 2, 1, 2],
    [2, 1, 2, 1, 1],
    [1, 2, 2, 1, 1],
    [1, 1, 1, 2, 2],
    [2, 1, 1, 2, 1],
    [1, 2, 1, 2, 1],
    [1, 1, 3, 3, 1],
    [3, 1, 1, 1, 3],
    [1, 3, 1, 1, 3],
    [3, 3, 1, 1, 1],
    [1, 1, 3, 1, 3],
    [3, 1, 3, 1, 1],
    [1, 3, 3, 1, 1],
    [1, 1, 1, 3, 3],
    [3, 1, 1, 3, 1],
    [1, 3, 1, 3, 1]
  ];
  ITFReader2.MAX_AVG_VARIANCE = 0.38;
  ITFReader2.MAX_INDIVIDUAL_VARIANCE = 0.5;
  ITFReader2.DEFAULT_ALLOWED_LENGTHS = [6, 8, 10, 12, 14];
  ITFReader2.START_PATTERN = [1, 1, 1, 1];
  ITFReader2.END_PATTERN_REVERSED = [
    [1, 1, 2],
    [1, 1, 3]
  ];
  return ITFReader2;
}(OneDReader_1$3.default);
ITFReader$1.default = ITFReader;
var MultiFormatUPCEANReader$1 = {};
var EAN13Reader$1 = {};
var UPCEANReader$1 = {};
var UPCEANExtensionSupport$1 = {};
var UPCEANExtension5Support$1 = {};
Object.defineProperty(UPCEANExtension5Support$1, "__esModule", { value: true });
var BarcodeFormat_1$b = BarcodeFormat$1;
var UPCEANReader_1$4 = UPCEANReader$1;
var Result_1$4 = Result$1;
var ResultPoint_1$8 = ResultPoint$1;
var ResultMetadataType_1$4 = ResultMetadataType$1;
var NotFoundException_1$f = NotFoundException$1;
var UPCEANExtension5Support = function() {
  function UPCEANExtension5Support2() {
    this.CHECK_DIGIT_ENCODINGS = [24, 20, 18, 17, 12, 6, 3, 10, 9, 5];
    this.decodeMiddleCounters = [0, 0, 0, 0];
    this.decodeRowStringBuffer = "";
  }
  UPCEANExtension5Support2.prototype.decodeRow = function(rowNumber, row, extensionStartRange) {
    var result = this.decodeRowStringBuffer;
    var end = this.decodeMiddle(row, extensionStartRange, result);
    var resultString = result.toString();
    var extensionData = UPCEANExtension5Support2.parseExtensionString(resultString);
    var resultPoints = [
      new ResultPoint_1$8.default((extensionStartRange[0] + extensionStartRange[1]) / 2, rowNumber),
      new ResultPoint_1$8.default(end, rowNumber)
    ];
    var extensionResult = new Result_1$4.default(resultString, null, 0, resultPoints, BarcodeFormat_1$b.default.UPC_EAN_EXTENSION, new Date().getTime());
    if (extensionData != null) {
      extensionResult.putAllMetadata(extensionData);
    }
    return extensionResult;
  };
  UPCEANExtension5Support2.prototype.decodeMiddle = function(row, startRange, resultString) {
    var counters = this.decodeMiddleCounters;
    counters[0] = 0;
    counters[1] = 0;
    counters[2] = 0;
    counters[3] = 0;
    var end = row.getSize();
    var rowOffset = startRange[1];
    var lgPatternFound = 0;
    for (var x = 0; x < 5 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1$4.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1$4.default.L_AND_G_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch % 10);
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        rowOffset += counter;
      }
      if (bestMatch >= 10) {
        lgPatternFound |= 1 << 4 - x;
      }
      if (x !== 4) {
        rowOffset = row.getNextSet(rowOffset);
        rowOffset = row.getNextUnset(rowOffset);
      }
    }
    if (resultString.length !== 5) {
      throw new NotFoundException_1$f.default();
    }
    var checkDigit = this.determineCheckDigit(lgPatternFound);
    if (UPCEANExtension5Support2.extensionChecksum(resultString.toString()) !== checkDigit) {
      throw new NotFoundException_1$f.default();
    }
    return rowOffset;
  };
  UPCEANExtension5Support2.extensionChecksum = function(s) {
    var length = s.length;
    var sum = 0;
    for (var i = length - 2; i >= 0; i -= 2) {
      sum += s.charAt(i).charCodeAt(0) - "0".charCodeAt(0);
    }
    sum *= 3;
    for (var i = length - 1; i >= 0; i -= 2) {
      sum += s.charAt(i).charCodeAt(0) - "0".charCodeAt(0);
    }
    sum *= 3;
    return sum % 10;
  };
  UPCEANExtension5Support2.prototype.determineCheckDigit = function(lgPatternFound) {
    for (var d = 0; d < 10; d++) {
      if (lgPatternFound === this.CHECK_DIGIT_ENCODINGS[d]) {
        return d;
      }
    }
    throw new NotFoundException_1$f.default();
  };
  UPCEANExtension5Support2.parseExtensionString = function(raw) {
    if (raw.length !== 5) {
      return null;
    }
    var value = UPCEANExtension5Support2.parseExtension5String(raw);
    if (value == null) {
      return null;
    }
    return /* @__PURE__ */ new Map([[ResultMetadataType_1$4.default.SUGGESTED_PRICE, value]]);
  };
  UPCEANExtension5Support2.parseExtension5String = function(raw) {
    var currency;
    switch (raw.charAt(0)) {
      case "0":
        currency = "\xA3";
        break;
      case "5":
        currency = "$";
        break;
      case "9":
        switch (raw) {
          case "90000":
            return null;
          case "99991":
            return "0.00";
          case "99990":
            return "Used";
        }
        currency = "";
        break;
      default:
        currency = "";
        break;
    }
    var rawAmount = parseInt(raw.substring(1));
    var unitsString = (rawAmount / 100).toString();
    var hundredths = rawAmount % 100;
    var hundredthsString = hundredths < 10 ? "0" + hundredths : hundredths.toString();
    return currency + unitsString + "." + hundredthsString;
  };
  return UPCEANExtension5Support2;
}();
UPCEANExtension5Support$1.default = UPCEANExtension5Support;
var UPCEANExtension2Support$1 = {};
Object.defineProperty(UPCEANExtension2Support$1, "__esModule", { value: true });
var BarcodeFormat_1$a = BarcodeFormat$1;
var UPCEANReader_1$3 = UPCEANReader$1;
var Result_1$3 = Result$1;
var ResultPoint_1$7 = ResultPoint$1;
var ResultMetadataType_1$3 = ResultMetadataType$1;
var NotFoundException_1$e = NotFoundException$1;
var UPCEANExtension2Support = function() {
  function UPCEANExtension2Support2() {
    this.decodeMiddleCounters = [0, 0, 0, 0];
    this.decodeRowStringBuffer = "";
  }
  UPCEANExtension2Support2.prototype.decodeRow = function(rowNumber, row, extensionStartRange) {
    var result = this.decodeRowStringBuffer;
    var end = this.decodeMiddle(row, extensionStartRange, result);
    var resultString = result.toString();
    var extensionData = UPCEANExtension2Support2.parseExtensionString(resultString);
    var resultPoints = [
      new ResultPoint_1$7.default((extensionStartRange[0] + extensionStartRange[1]) / 2, rowNumber),
      new ResultPoint_1$7.default(end, rowNumber)
    ];
    var extensionResult = new Result_1$3.default(resultString, null, 0, resultPoints, BarcodeFormat_1$a.default.UPC_EAN_EXTENSION, new Date().getTime());
    if (extensionData != null) {
      extensionResult.putAllMetadata(extensionData);
    }
    return extensionResult;
  };
  UPCEANExtension2Support2.prototype.decodeMiddle = function(row, startRange, resultString) {
    var counters = this.decodeMiddleCounters;
    counters[0] = 0;
    counters[1] = 0;
    counters[2] = 0;
    counters[3] = 0;
    var end = row.getSize();
    var rowOffset = startRange[1];
    var checkParity = 0;
    for (var x = 0; x < 2 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1$3.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1$3.default.L_AND_G_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch % 10);
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        rowOffset += counter;
      }
      if (bestMatch >= 10) {
        checkParity |= 1 << 1 - x;
      }
      if (x !== 1) {
        rowOffset = row.getNextSet(rowOffset);
        rowOffset = row.getNextUnset(rowOffset);
      }
    }
    if (resultString.length !== 2) {
      throw new NotFoundException_1$e.default();
    }
    if (parseInt(resultString.toString()) % 4 !== checkParity) {
      throw new NotFoundException_1$e.default();
    }
    return rowOffset;
  };
  UPCEANExtension2Support2.parseExtensionString = function(raw) {
    if (raw.length !== 2) {
      return null;
    }
    return /* @__PURE__ */ new Map([[ResultMetadataType_1$3.default.ISSUE_NUMBER, parseInt(raw)]]);
  };
  return UPCEANExtension2Support2;
}();
UPCEANExtension2Support$1.default = UPCEANExtension2Support;
Object.defineProperty(UPCEANExtensionSupport$1, "__esModule", { value: true });
var UPCEANReader_1$2 = UPCEANReader$1;
var UPCEANExtension5Support_1 = UPCEANExtension5Support$1;
var UPCEANExtension2Support_1 = UPCEANExtension2Support$1;
var UPCEANExtensionSupport = function() {
  function UPCEANExtensionSupport2() {
  }
  UPCEANExtensionSupport2.decodeRow = function(rowNumber, row, rowOffset) {
    var extensionStartRange = UPCEANReader_1$2.default.findGuardPattern(row, rowOffset, false, this.EXTENSION_START_PATTERN, new Array(this.EXTENSION_START_PATTERN.length).fill(0));
    try {
      var fiveSupport = new UPCEANExtension5Support_1.default();
      return fiveSupport.decodeRow(rowNumber, row, extensionStartRange);
    } catch (err) {
      var twoSupport = new UPCEANExtension2Support_1.default();
      return twoSupport.decodeRow(rowNumber, row, extensionStartRange);
    }
  };
  UPCEANExtensionSupport2.EXTENSION_START_PATTERN = [1, 1, 2];
  return UPCEANExtensionSupport2;
}();
UPCEANExtensionSupport$1.default = UPCEANExtensionSupport;
var __extends$i = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(UPCEANReader$1, "__esModule", { value: true });
var BarcodeFormat_1$9 = BarcodeFormat$1;
var DecodeHintType_1$8 = DecodeHintType$1;
var Result_1$2 = Result$1;
var ResultMetadataType_1$2 = ResultMetadataType$1;
var ResultPoint_1$6 = ResultPoint$1;
var OneDReader_1$2 = OneDReader$1;
var UPCEANExtensionSupport_1 = UPCEANExtensionSupport$1;
var NotFoundException_1$d = NotFoundException$1;
var FormatException_1$6 = FormatException$1;
var ChecksumException_1$2 = ChecksumException$1;
var UPCEANReader = function(_super) {
  __extends$i(UPCEANReader2, _super);
  function UPCEANReader2() {
    var _this = _super.call(this) || this;
    _this.decodeRowStringBuffer = "";
    _this.decodeRowStringBuffer = "";
    UPCEANReader2.L_AND_G_PATTERNS = UPCEANReader2.L_PATTERNS.map(function(arr) {
      return arr.slice();
    });
    for (var i = 10; i < 20; i++) {
      var widths = UPCEANReader2.L_PATTERNS[i - 10];
      var reversedWidths = new Array(widths.length);
      for (var j = 0; j < widths.length; j++) {
        reversedWidths[j] = widths[widths.length - j - 1];
      }
      UPCEANReader2.L_AND_G_PATTERNS[i] = reversedWidths;
    }
    return _this;
  }
  UPCEANReader2.findStartGuardPattern = function(row) {
    var foundStart = false;
    var startRange = null;
    var nextStart = 0;
    var counters = [0, 0, 0];
    while (!foundStart) {
      counters = [0, 0, 0];
      startRange = UPCEANReader2.findGuardPattern(row, nextStart, false, this.START_END_PATTERN, counters);
      var start = startRange[0];
      nextStart = startRange[1];
      var quietStart = start - (nextStart - start);
      if (quietStart >= 0) {
        foundStart = row.isRange(quietStart, start, false);
      }
    }
    return startRange;
  };
  UPCEANReader2.prototype.decodeRow = function(rowNumber, row, hints) {
    var startGuardRange = UPCEANReader2.findStartGuardPattern(row);
    var resultPointCallback = hints == null ? null : hints.get(DecodeHintType_1$8.default.NEED_RESULT_POINT_CALLBACK);
    if (resultPointCallback != null) {
      var resultPoint_1 = new ResultPoint_1$6.default((startGuardRange[0] + startGuardRange[1]) / 2, rowNumber);
      resultPointCallback.foundPossibleResultPoint(resultPoint_1);
    }
    var budello = this.decodeMiddle(row, startGuardRange, this.decodeRowStringBuffer);
    var endStart = budello.rowOffset;
    var result = budello.resultString;
    if (resultPointCallback != null) {
      var resultPoint_2 = new ResultPoint_1$6.default(endStart, rowNumber);
      resultPointCallback.foundPossibleResultPoint(resultPoint_2);
    }
    var endRange = UPCEANReader2.decodeEnd(row, endStart);
    if (resultPointCallback != null) {
      var resultPoint_3 = new ResultPoint_1$6.default((endRange[0] + endRange[1]) / 2, rowNumber);
      resultPointCallback.foundPossibleResultPoint(resultPoint_3);
    }
    var end = endRange[1];
    var quietEnd = end + (end - endRange[0]);
    if (quietEnd >= row.getSize() || !row.isRange(end, quietEnd, false)) {
      throw new NotFoundException_1$d.default();
    }
    var resultString = result.toString();
    if (resultString.length < 8) {
      throw new FormatException_1$6.default();
    }
    if (!UPCEANReader2.checkChecksum(resultString)) {
      throw new ChecksumException_1$2.default();
    }
    var left = (startGuardRange[1] + startGuardRange[0]) / 2;
    var right = (endRange[1] + endRange[0]) / 2;
    var format = this.getBarcodeFormat();
    var resultPoint = [new ResultPoint_1$6.default(left, rowNumber), new ResultPoint_1$6.default(right, rowNumber)];
    var decodeResult = new Result_1$2.default(resultString, null, 0, resultPoint, format, new Date().getTime());
    var extensionLength = 0;
    try {
      var extensionResult = UPCEANExtensionSupport_1.default.decodeRow(rowNumber, row, endRange[1]);
      decodeResult.putMetadata(ResultMetadataType_1$2.default.UPC_EAN_EXTENSION, extensionResult.getText());
      decodeResult.putAllMetadata(extensionResult.getResultMetadata());
      decodeResult.addResultPoints(extensionResult.getResultPoints());
      extensionLength = extensionResult.getText().length;
    } catch (err) {
    }
    var allowedExtensions = hints == null ? null : hints.get(DecodeHintType_1$8.default.ALLOWED_EAN_EXTENSIONS);
    if (allowedExtensions != null) {
      var valid = false;
      for (var length_1 in allowedExtensions) {
        if (extensionLength.toString() === length_1) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        throw new NotFoundException_1$d.default();
      }
    }
    if (format === BarcodeFormat_1$9.default.EAN_13 || format === BarcodeFormat_1$9.default.UPC_A)
      ;
    return decodeResult;
  };
  UPCEANReader2.checkChecksum = function(s) {
    return UPCEANReader2.checkStandardUPCEANChecksum(s);
  };
  UPCEANReader2.checkStandardUPCEANChecksum = function(s) {
    var length = s.length;
    if (length === 0)
      return false;
    var check = parseInt(s.charAt(length - 1), 10);
    return UPCEANReader2.getStandardUPCEANChecksum(s.substring(0, length - 1)) === check;
  };
  UPCEANReader2.getStandardUPCEANChecksum = function(s) {
    var length = s.length;
    var sum = 0;
    for (var i = length - 1; i >= 0; i -= 2) {
      var digit = s.charAt(i).charCodeAt(0) - "0".charCodeAt(0);
      if (digit < 0 || digit > 9) {
        throw new FormatException_1$6.default();
      }
      sum += digit;
    }
    sum *= 3;
    for (var i = length - 2; i >= 0; i -= 2) {
      var digit = s.charAt(i).charCodeAt(0) - "0".charCodeAt(0);
      if (digit < 0 || digit > 9) {
        throw new FormatException_1$6.default();
      }
      sum += digit;
    }
    return (1e3 - sum) % 10;
  };
  UPCEANReader2.decodeEnd = function(row, endStart) {
    return UPCEANReader2.findGuardPattern(row, endStart, false, UPCEANReader2.START_END_PATTERN, new Array(UPCEANReader2.START_END_PATTERN.length).fill(0));
  };
  UPCEANReader2.findGuardPattern = function(row, rowOffset, whiteFirst, pattern, counters) {
    var width = row.getSize();
    rowOffset = whiteFirst ? row.getNextUnset(rowOffset) : row.getNextSet(rowOffset);
    var counterPosition = 0;
    var patternStart = rowOffset;
    var patternLength = pattern.length;
    var isWhite = whiteFirst;
    for (var x = rowOffset; x < width; x++) {
      if (row.get(x) !== isWhite) {
        counters[counterPosition]++;
      } else {
        if (counterPosition === patternLength - 1) {
          if (OneDReader_1$2.default.patternMatchVariance(counters, pattern, UPCEANReader2.MAX_INDIVIDUAL_VARIANCE) < UPCEANReader2.MAX_AVG_VARIANCE) {
            return [patternStart, x];
          }
          patternStart += counters[0] + counters[1];
          var slice = counters.slice(2, counters.length);
          for (var i = 0; i < counterPosition - 1; i++) {
            counters[i] = slice[i];
          }
          counters[counterPosition - 1] = 0;
          counters[counterPosition] = 0;
          counterPosition--;
        } else {
          counterPosition++;
        }
        counters[counterPosition] = 1;
        isWhite = !isWhite;
      }
    }
    throw new NotFoundException_1$d.default();
  };
  UPCEANReader2.decodeDigit = function(row, counters, rowOffset, patterns) {
    this.recordPattern(row, rowOffset, counters);
    var bestVariance = this.MAX_AVG_VARIANCE;
    var bestMatch = -1;
    var max = patterns.length;
    for (var i = 0; i < max; i++) {
      var pattern = patterns[i];
      var variance = OneDReader_1$2.default.patternMatchVariance(counters, pattern, UPCEANReader2.MAX_INDIVIDUAL_VARIANCE);
      if (variance < bestVariance) {
        bestVariance = variance;
        bestMatch = i;
      }
    }
    if (bestMatch >= 0) {
      return bestMatch;
    } else {
      throw new NotFoundException_1$d.default();
    }
  };
  UPCEANReader2.MAX_AVG_VARIANCE = 0.48;
  UPCEANReader2.MAX_INDIVIDUAL_VARIANCE = 0.7;
  UPCEANReader2.START_END_PATTERN = [1, 1, 1];
  UPCEANReader2.MIDDLE_PATTERN = [1, 1, 1, 1, 1];
  UPCEANReader2.END_PATTERN = [1, 1, 1, 1, 1, 1];
  UPCEANReader2.L_PATTERNS = [
    [3, 2, 1, 1],
    [2, 2, 2, 1],
    [2, 1, 2, 2],
    [1, 4, 1, 1],
    [1, 1, 3, 2],
    [1, 2, 3, 1],
    [1, 1, 1, 4],
    [1, 3, 1, 2],
    [1, 2, 1, 3],
    [3, 1, 1, 2]
  ];
  return UPCEANReader2;
}(OneDReader_1$2.default);
UPCEANReader$1.default = UPCEANReader;
var __extends$h = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(EAN13Reader$1, "__esModule", { value: true });
var BarcodeFormat_1$8 = BarcodeFormat$1;
var UPCEANReader_1$1 = UPCEANReader$1;
var NotFoundException_1$c = NotFoundException$1;
var EAN13Reader = function(_super) {
  __extends$h(EAN13Reader2, _super);
  function EAN13Reader2() {
    var _this = _super.call(this) || this;
    _this.decodeMiddleCounters = [0, 0, 0, 0];
    return _this;
  }
  EAN13Reader2.prototype.decodeMiddle = function(row, startRange, resultString) {
    var counters = this.decodeMiddleCounters;
    counters[0] = 0;
    counters[1] = 0;
    counters[2] = 0;
    counters[3] = 0;
    var end = row.getSize();
    var rowOffset = startRange[1];
    var lgPatternFound = 0;
    for (var x = 0; x < 6 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1$1.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1$1.default.L_AND_G_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch % 10);
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        rowOffset += counter;
      }
      if (bestMatch >= 10) {
        lgPatternFound |= 1 << 5 - x;
      }
    }
    resultString = EAN13Reader2.determineFirstDigit(resultString, lgPatternFound);
    var middleRange = UPCEANReader_1$1.default.findGuardPattern(row, rowOffset, true, UPCEANReader_1$1.default.MIDDLE_PATTERN, new Array(UPCEANReader_1$1.default.MIDDLE_PATTERN.length).fill(0));
    rowOffset = middleRange[1];
    for (var x = 0; x < 6 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1$1.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1$1.default.L_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch);
      for (var _a = 0, counters_2 = counters; _a < counters_2.length; _a++) {
        var counter = counters_2[_a];
        rowOffset += counter;
      }
    }
    return { rowOffset, resultString };
  };
  EAN13Reader2.prototype.getBarcodeFormat = function() {
    return BarcodeFormat_1$8.default.EAN_13;
  };
  EAN13Reader2.determineFirstDigit = function(resultString, lgPatternFound) {
    for (var d = 0; d < 10; d++) {
      if (lgPatternFound === this.FIRST_DIGIT_ENCODINGS[d]) {
        resultString = String.fromCharCode("0".charCodeAt(0) + d) + resultString;
        return resultString;
      }
    }
    throw new NotFoundException_1$c.default();
  };
  EAN13Reader2.FIRST_DIGIT_ENCODINGS = [0, 11, 13, 14, 19, 25, 28, 21, 22, 26];
  return EAN13Reader2;
}(UPCEANReader_1$1.default);
EAN13Reader$1.default = EAN13Reader;
var EAN8Reader$1 = {};
var __extends$g = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(EAN8Reader$1, "__esModule", { value: true });
var BarcodeFormat_1$7 = BarcodeFormat$1;
var UPCEANReader_1 = UPCEANReader$1;
var EAN8Reader = function(_super) {
  __extends$g(EAN8Reader2, _super);
  function EAN8Reader2() {
    var _this = _super.call(this) || this;
    _this.decodeMiddleCounters = [0, 0, 0, 0];
    return _this;
  }
  EAN8Reader2.prototype.decodeMiddle = function(row, startRange, resultString) {
    var counters = this.decodeMiddleCounters;
    counters[0] = 0;
    counters[1] = 0;
    counters[2] = 0;
    counters[3] = 0;
    var end = row.getSize();
    var rowOffset = startRange[1];
    for (var x = 0; x < 4 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1.default.L_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch);
      for (var _i = 0, counters_1 = counters; _i < counters_1.length; _i++) {
        var counter = counters_1[_i];
        rowOffset += counter;
      }
    }
    var middleRange = UPCEANReader_1.default.findGuardPattern(row, rowOffset, true, UPCEANReader_1.default.MIDDLE_PATTERN, new Array(UPCEANReader_1.default.MIDDLE_PATTERN.length).fill(0));
    rowOffset = middleRange[1];
    for (var x = 0; x < 4 && rowOffset < end; x++) {
      var bestMatch = UPCEANReader_1.default.decodeDigit(row, counters, rowOffset, UPCEANReader_1.default.L_PATTERNS);
      resultString += String.fromCharCode("0".charCodeAt(0) + bestMatch);
      for (var _a = 0, counters_2 = counters; _a < counters_2.length; _a++) {
        var counter = counters_2[_a];
        rowOffset += counter;
      }
    }
    return { rowOffset, resultString };
  };
  EAN8Reader2.prototype.getBarcodeFormat = function() {
    return BarcodeFormat_1$7.default.EAN_8;
  };
  return EAN8Reader2;
}(UPCEANReader_1.default);
EAN8Reader$1.default = EAN8Reader;
var __extends$f = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(MultiFormatUPCEANReader$1, "__esModule", { value: true });
var BarcodeFormat_1$6 = BarcodeFormat$1;
var DecodeHintType_1$7 = DecodeHintType$1;
var OneDReader_1$1 = OneDReader$1;
var EAN13Reader_1 = EAN13Reader$1;
var EAN8Reader_1 = EAN8Reader$1;
var NotFoundException_1$b = NotFoundException$1;
var MultiFormatUPCEANReader = function(_super) {
  __extends$f(MultiFormatUPCEANReader2, _super);
  function MultiFormatUPCEANReader2(hints) {
    var _this = _super.call(this) || this;
    var possibleFormats = hints == null ? null : hints.get(DecodeHintType_1$7.default.POSSIBLE_FORMATS);
    var readers = [];
    if (possibleFormats != null) {
      if (possibleFormats.indexOf(BarcodeFormat_1$6.default.EAN_13) > -1) {
        readers.push(new EAN13Reader_1.default());
      }
      if (possibleFormats.indexOf(BarcodeFormat_1$6.default.EAN_8) > -1) {
        readers.push(new EAN8Reader_1.default());
      }
    }
    if (readers.length === 0) {
      readers.push(new EAN13Reader_1.default());
      readers.push(new EAN8Reader_1.default());
    }
    _this.readers = readers;
    return _this;
  }
  MultiFormatUPCEANReader2.prototype.decodeRow = function(rowNumber, row, hints) {
    for (var _i = 0, _a = this.readers; _i < _a.length; _i++) {
      var reader = _a[_i];
      try {
        return reader.decodeRow(rowNumber, row, hints);
      } catch (err) {
      }
    }
    throw new NotFoundException_1$b.default();
  };
  MultiFormatUPCEANReader2.prototype.reset = function() {
    for (var _i = 0, _a = this.readers; _i < _a.length; _i++) {
      var reader = _a[_i];
      reader.reset();
    }
  };
  return MultiFormatUPCEANReader2;
}(OneDReader_1$1.default);
MultiFormatUPCEANReader$1.default = MultiFormatUPCEANReader;
var __extends$e = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(MultiFormatOneDReader$1, "__esModule", { value: true });
var BarcodeFormat_1$5 = BarcodeFormat$1;
var Code39Reader_1 = Code39Reader$1;
var Code128Reader_1 = Code128Reader$1;
var RSS14Reader_1 = RSS14Reader$1;
var DecodeHintType_1$6 = DecodeHintType$1;
var ITFReader_1 = ITFReader$1;
var MultiFormatUPCEANReader_1 = MultiFormatUPCEANReader$1;
var NotFoundException_1$a = NotFoundException$1;
var OneDReader_1 = OneDReader$1;
var MultiFormatOneDReader = function(_super) {
  __extends$e(MultiFormatOneDReader2, _super);
  function MultiFormatOneDReader2(hints) {
    var _this = _super.call(this) || this;
    _this.readers = [];
    var possibleFormats = !hints ? null : hints.get(DecodeHintType_1$6.default.POSSIBLE_FORMATS);
    var useCode39CheckDigit = hints && hints.get(DecodeHintType_1$6.default.ASSUME_CODE_39_CHECK_DIGIT) !== void 0;
    if (possibleFormats) {
      if (possibleFormats.includes(BarcodeFormat_1$5.default.EAN_13) || possibleFormats.includes(BarcodeFormat_1$5.default.EAN_8)) {
        _this.readers.push(new MultiFormatUPCEANReader_1.default(hints));
      }
      if (possibleFormats.includes(BarcodeFormat_1$5.default.CODE_39)) {
        _this.readers.push(new Code39Reader_1.default(useCode39CheckDigit));
      }
      if (possibleFormats.includes(BarcodeFormat_1$5.default.CODE_128)) {
        _this.readers.push(new Code128Reader_1.default());
      }
      if (possibleFormats.includes(BarcodeFormat_1$5.default.ITF)) {
        _this.readers.push(new ITFReader_1.default());
      }
      if (possibleFormats.includes(BarcodeFormat_1$5.default.RSS_14)) {
        _this.readers.push(new RSS14Reader_1.default());
      }
    }
    if (_this.readers.length === 0) {
      _this.readers.push(new Code39Reader_1.default());
      _this.readers.push(new MultiFormatUPCEANReader_1.default(hints));
      _this.readers.push(new Code128Reader_1.default());
      _this.readers.push(new ITFReader_1.default());
      _this.readers.push(new RSS14Reader_1.default());
    }
    return _this;
  }
  MultiFormatOneDReader2.prototype.decodeRow = function(rowNumber, row, hints) {
    for (var i = 0; i < this.readers.length; i++) {
      try {
        return this.readers[i].decodeRow(rowNumber, row, hints);
      } catch (re) {
      }
    }
    throw new NotFoundException_1$a.default();
  };
  MultiFormatOneDReader2.prototype.reset = function() {
    this.readers.forEach(function(reader) {
      return reader.reset();
    });
  };
  return MultiFormatOneDReader2;
}(OneDReader_1.default);
MultiFormatOneDReader$1.default = MultiFormatOneDReader;
var __extends$d = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(BrowserBarcodeReader$1, "__esModule", { value: true });
var BrowserCodeReader_1$3 = BrowserCodeReader$1;
var MultiFormatOneDReader_1$1 = MultiFormatOneDReader$1;
var BrowserBarcodeReader = function(_super) {
  __extends$d(BrowserBarcodeReader2, _super);
  function BrowserBarcodeReader2(timeBetweenScansMillis, hints) {
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    return _super.call(this, new MultiFormatOneDReader_1$1.default(hints), timeBetweenScansMillis, hints) || this;
  }
  return BrowserBarcodeReader2;
}(BrowserCodeReader_1$3.BrowserCodeReader);
BrowserBarcodeReader$1.BrowserBarcodeReader = BrowserBarcodeReader;
var BrowserDatamatrixCodeReader$1 = {};
var DataMatrixReader$1 = {};
var Decoder$3 = {};
var ReedSolomonDecoder$1 = {};
var GenericGF$1 = {};
var GenericGFPoly$1 = {};
Object.defineProperty(GenericGFPoly$1, "__esModule", { value: true });
var GenericGF_1$4 = GenericGF$1;
var System_1$4 = System$1;
var IllegalArgumentException_1$e = IllegalArgumentException$1;
var GenericGFPoly = function() {
  function GenericGFPoly2(field, coefficients) {
    if (coefficients.length === 0) {
      throw new IllegalArgumentException_1$e.default();
    }
    this.field = field;
    var coefficientsLength = coefficients.length;
    if (coefficientsLength > 1 && coefficients[0] === 0) {
      var firstNonZero = 1;
      while (firstNonZero < coefficientsLength && coefficients[firstNonZero] === 0) {
        firstNonZero++;
      }
      if (firstNonZero === coefficientsLength) {
        this.coefficients = Int32Array.from([0]);
      } else {
        this.coefficients = new Int32Array(coefficientsLength - firstNonZero);
        System_1$4.default.arraycopy(coefficients, firstNonZero, this.coefficients, 0, this.coefficients.length);
      }
    } else {
      this.coefficients = coefficients;
    }
  }
  GenericGFPoly2.prototype.getCoefficients = function() {
    return this.coefficients;
  };
  GenericGFPoly2.prototype.getDegree = function() {
    return this.coefficients.length - 1;
  };
  GenericGFPoly2.prototype.isZero = function() {
    return this.coefficients[0] === 0;
  };
  GenericGFPoly2.prototype.getCoefficient = function(degree) {
    return this.coefficients[this.coefficients.length - 1 - degree];
  };
  GenericGFPoly2.prototype.evaluateAt = function(a) {
    if (a === 0) {
      return this.getCoefficient(0);
    }
    var coefficients = this.coefficients;
    var result;
    if (a === 1) {
      result = 0;
      for (var i = 0, length_1 = coefficients.length; i !== length_1; i++) {
        var coefficient = coefficients[i];
        result = GenericGF_1$4.default.addOrSubtract(result, coefficient);
      }
      return result;
    }
    result = coefficients[0];
    var size = coefficients.length;
    var field = this.field;
    for (var i = 1; i < size; i++) {
      result = GenericGF_1$4.default.addOrSubtract(field.multiply(a, result), coefficients[i]);
    }
    return result;
  };
  GenericGFPoly2.prototype.addOrSubtract = function(other) {
    if (!this.field.equals(other.field)) {
      throw new IllegalArgumentException_1$e.default("GenericGFPolys do not have same GenericGF field");
    }
    if (this.isZero()) {
      return other;
    }
    if (other.isZero()) {
      return this;
    }
    var smallerCoefficients = this.coefficients;
    var largerCoefficients = other.coefficients;
    if (smallerCoefficients.length > largerCoefficients.length) {
      var temp = smallerCoefficients;
      smallerCoefficients = largerCoefficients;
      largerCoefficients = temp;
    }
    var sumDiff = new Int32Array(largerCoefficients.length);
    var lengthDiff = largerCoefficients.length - smallerCoefficients.length;
    System_1$4.default.arraycopy(largerCoefficients, 0, sumDiff, 0, lengthDiff);
    for (var i = lengthDiff; i < largerCoefficients.length; i++) {
      sumDiff[i] = GenericGF_1$4.default.addOrSubtract(smallerCoefficients[i - lengthDiff], largerCoefficients[i]);
    }
    return new GenericGFPoly2(this.field, sumDiff);
  };
  GenericGFPoly2.prototype.multiply = function(other) {
    if (!this.field.equals(other.field)) {
      throw new IllegalArgumentException_1$e.default("GenericGFPolys do not have same GenericGF field");
    }
    if (this.isZero() || other.isZero()) {
      return this.field.getZero();
    }
    var aCoefficients = this.coefficients;
    var aLength = aCoefficients.length;
    var bCoefficients = other.coefficients;
    var bLength = bCoefficients.length;
    var product = new Int32Array(aLength + bLength - 1);
    var field = this.field;
    for (var i = 0; i < aLength; i++) {
      var aCoeff = aCoefficients[i];
      for (var j = 0; j < bLength; j++) {
        product[i + j] = GenericGF_1$4.default.addOrSubtract(product[i + j], field.multiply(aCoeff, bCoefficients[j]));
      }
    }
    return new GenericGFPoly2(field, product);
  };
  GenericGFPoly2.prototype.multiplyScalar = function(scalar) {
    if (scalar === 0) {
      return this.field.getZero();
    }
    if (scalar === 1) {
      return this;
    }
    var size = this.coefficients.length;
    var field = this.field;
    var product = new Int32Array(size);
    var coefficients = this.coefficients;
    for (var i = 0; i < size; i++) {
      product[i] = field.multiply(coefficients[i], scalar);
    }
    return new GenericGFPoly2(field, product);
  };
  GenericGFPoly2.prototype.multiplyByMonomial = function(degree, coefficient) {
    if (degree < 0) {
      throw new IllegalArgumentException_1$e.default();
    }
    if (coefficient === 0) {
      return this.field.getZero();
    }
    var coefficients = this.coefficients;
    var size = coefficients.length;
    var product = new Int32Array(size + degree);
    var field = this.field;
    for (var i = 0; i < size; i++) {
      product[i] = field.multiply(coefficients[i], coefficient);
    }
    return new GenericGFPoly2(field, product);
  };
  GenericGFPoly2.prototype.divide = function(other) {
    if (!this.field.equals(other.field)) {
      throw new IllegalArgumentException_1$e.default("GenericGFPolys do not have same GenericGF field");
    }
    if (other.isZero()) {
      throw new IllegalArgumentException_1$e.default("Divide by 0");
    }
    var field = this.field;
    var quotient = field.getZero();
    var remainder = this;
    var denominatorLeadingTerm = other.getCoefficient(other.getDegree());
    var inverseDenominatorLeadingTerm = field.inverse(denominatorLeadingTerm);
    while (remainder.getDegree() >= other.getDegree() && !remainder.isZero()) {
      var degreeDifference = remainder.getDegree() - other.getDegree();
      var scale = field.multiply(remainder.getCoefficient(remainder.getDegree()), inverseDenominatorLeadingTerm);
      var term = other.multiplyByMonomial(degreeDifference, scale);
      var iterationQuotient = field.buildMonomial(degreeDifference, scale);
      quotient = quotient.addOrSubtract(iterationQuotient);
      remainder = remainder.addOrSubtract(term);
    }
    return [quotient, remainder];
  };
  GenericGFPoly2.prototype.toString = function() {
    var result = "";
    for (var degree = this.getDegree(); degree >= 0; degree--) {
      var coefficient = this.getCoefficient(degree);
      if (coefficient !== 0) {
        if (coefficient < 0) {
          result += " - ";
          coefficient = -coefficient;
        } else {
          if (result.length > 0) {
            result += " + ";
          }
        }
        if (degree === 0 || coefficient !== 1) {
          var alphaPower = this.field.log(coefficient);
          if (alphaPower === 0) {
            result += "1";
          } else if (alphaPower === 1) {
            result += "a";
          } else {
            result += "a^";
            result += alphaPower;
          }
        }
        if (degree !== 0) {
          if (degree === 1) {
            result += "x";
          } else {
            result += "x^";
            result += degree;
          }
        }
      }
    }
    return result;
  };
  return GenericGFPoly2;
}();
GenericGFPoly$1.default = GenericGFPoly;
var ArithmeticException$1 = {};
var __extends$c = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ArithmeticException$1, "__esModule", { value: true });
var Exception_1$4 = Exception$1;
var ArithmeticException = function(_super) {
  __extends$c(ArithmeticException2, _super);
  function ArithmeticException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ArithmeticException2;
}(Exception_1$4.default);
ArithmeticException$1.default = ArithmeticException;
Object.defineProperty(GenericGF$1, "__esModule", { value: true });
var GenericGFPoly_1$2 = GenericGFPoly$1;
var Integer_1$2 = Integer$1;
var IllegalArgumentException_1$d = IllegalArgumentException$1;
var ArithmeticException_1 = ArithmeticException$1;
var GenericGF = function() {
  function GenericGF2(primitive, size, generatorBase) {
    this.primitive = primitive;
    this.size = size;
    this.generatorBase = generatorBase;
    var expTable = new Int32Array(size);
    var x = 1;
    for (var i = 0; i < size; i++) {
      expTable[i] = x;
      x *= 2;
      if (x >= size) {
        x ^= primitive;
        x &= size - 1;
      }
    }
    this.expTable = expTable;
    var logTable = new Int32Array(size);
    for (var i = 0; i < size - 1; i++) {
      logTable[expTable[i]] = i;
    }
    this.logTable = logTable;
    this.zero = new GenericGFPoly_1$2.default(this, Int32Array.from([0]));
    this.one = new GenericGFPoly_1$2.default(this, Int32Array.from([1]));
  }
  GenericGF2.prototype.getZero = function() {
    return this.zero;
  };
  GenericGF2.prototype.getOne = function() {
    return this.one;
  };
  GenericGF2.prototype.buildMonomial = function(degree, coefficient) {
    if (degree < 0) {
      throw new IllegalArgumentException_1$d.default();
    }
    if (coefficient === 0) {
      return this.zero;
    }
    var coefficients = new Int32Array(degree + 1);
    coefficients[0] = coefficient;
    return new GenericGFPoly_1$2.default(this, coefficients);
  };
  GenericGF2.addOrSubtract = function(a, b) {
    return a ^ b;
  };
  GenericGF2.prototype.exp = function(a) {
    return this.expTable[a];
  };
  GenericGF2.prototype.log = function(a) {
    if (a === 0) {
      throw new IllegalArgumentException_1$d.default();
    }
    return this.logTable[a];
  };
  GenericGF2.prototype.inverse = function(a) {
    if (a === 0) {
      throw new ArithmeticException_1.default();
    }
    return this.expTable[this.size - this.logTable[a] - 1];
  };
  GenericGF2.prototype.multiply = function(a, b) {
    if (a === 0 || b === 0) {
      return 0;
    }
    return this.expTable[(this.logTable[a] + this.logTable[b]) % (this.size - 1)];
  };
  GenericGF2.prototype.getSize = function() {
    return this.size;
  };
  GenericGF2.prototype.getGeneratorBase = function() {
    return this.generatorBase;
  };
  GenericGF2.prototype.toString = function() {
    return "GF(0x" + Integer_1$2.default.toHexString(this.primitive) + "," + this.size + ")";
  };
  GenericGF2.prototype.equals = function(o) {
    return o === this;
  };
  GenericGF2.AZTEC_DATA_12 = new GenericGF2(4201, 4096, 1);
  GenericGF2.AZTEC_DATA_10 = new GenericGF2(1033, 1024, 1);
  GenericGF2.AZTEC_DATA_6 = new GenericGF2(67, 64, 1);
  GenericGF2.AZTEC_PARAM = new GenericGF2(19, 16, 1);
  GenericGF2.QR_CODE_FIELD_256 = new GenericGF2(285, 256, 0);
  GenericGF2.DATA_MATRIX_FIELD_256 = new GenericGF2(301, 256, 1);
  GenericGF2.AZTEC_DATA_8 = GenericGF2.DATA_MATRIX_FIELD_256;
  GenericGF2.MAXICODE_FIELD_64 = GenericGF2.AZTEC_DATA_6;
  return GenericGF2;
}();
GenericGF$1.default = GenericGF;
var ReedSolomonException$1 = {};
var __extends$b = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ReedSolomonException$1, "__esModule", { value: true });
var Exception_1$3 = Exception$1;
var ReedSolomonException = function(_super) {
  __extends$b(ReedSolomonException2, _super);
  function ReedSolomonException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ReedSolomonException2;
}(Exception_1$3.default);
ReedSolomonException$1.default = ReedSolomonException;
var IllegalStateException$1 = {};
var __extends$a = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(IllegalStateException$1, "__esModule", { value: true });
var Exception_1$2 = Exception$1;
var IllegalStateException = function(_super) {
  __extends$a(IllegalStateException2, _super);
  function IllegalStateException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return IllegalStateException2;
}(Exception_1$2.default);
IllegalStateException$1.default = IllegalStateException;
Object.defineProperty(ReedSolomonDecoder$1, "__esModule", { value: true });
var GenericGF_1$3 = GenericGF$1;
var GenericGFPoly_1$1 = GenericGFPoly$1;
var ReedSolomonException_1 = ReedSolomonException$1;
var IllegalStateException_1$3 = IllegalStateException$1;
var ReedSolomonDecoder = function() {
  function ReedSolomonDecoder2(field) {
    this.field = field;
  }
  ReedSolomonDecoder2.prototype.decode = function(received, twoS) {
    var field = this.field;
    var poly = new GenericGFPoly_1$1.default(field, received);
    var syndromeCoefficients = new Int32Array(twoS);
    var noError = true;
    for (var i = 0; i < twoS; i++) {
      var evalResult = poly.evaluateAt(field.exp(i + field.getGeneratorBase()));
      syndromeCoefficients[syndromeCoefficients.length - 1 - i] = evalResult;
      if (evalResult !== 0) {
        noError = false;
      }
    }
    if (noError) {
      return;
    }
    var syndrome = new GenericGFPoly_1$1.default(field, syndromeCoefficients);
    var sigmaOmega = this.runEuclideanAlgorithm(field.buildMonomial(twoS, 1), syndrome, twoS);
    var sigma = sigmaOmega[0];
    var omega = sigmaOmega[1];
    var errorLocations = this.findErrorLocations(sigma);
    var errorMagnitudes = this.findErrorMagnitudes(omega, errorLocations);
    for (var i = 0; i < errorLocations.length; i++) {
      var position = received.length - 1 - field.log(errorLocations[i]);
      if (position < 0) {
        throw new ReedSolomonException_1.default("Bad error location");
      }
      received[position] = GenericGF_1$3.default.addOrSubtract(received[position], errorMagnitudes[i]);
    }
  };
  ReedSolomonDecoder2.prototype.runEuclideanAlgorithm = function(a, b, R) {
    if (a.getDegree() < b.getDegree()) {
      var temp = a;
      a = b;
      b = temp;
    }
    var field = this.field;
    var rLast = a;
    var r = b;
    var tLast = field.getZero();
    var t = field.getOne();
    while (r.getDegree() >= (R / 2 | 0)) {
      var rLastLast = rLast;
      var tLastLast = tLast;
      rLast = r;
      tLast = t;
      if (rLast.isZero()) {
        throw new ReedSolomonException_1.default("r_{i-1} was zero");
      }
      r = rLastLast;
      var q = field.getZero();
      var denominatorLeadingTerm = rLast.getCoefficient(rLast.getDegree());
      var dltInverse = field.inverse(denominatorLeadingTerm);
      while (r.getDegree() >= rLast.getDegree() && !r.isZero()) {
        var degreeDiff = r.getDegree() - rLast.getDegree();
        var scale = field.multiply(r.getCoefficient(r.getDegree()), dltInverse);
        q = q.addOrSubtract(field.buildMonomial(degreeDiff, scale));
        r = r.addOrSubtract(rLast.multiplyByMonomial(degreeDiff, scale));
      }
      t = q.multiply(tLast).addOrSubtract(tLastLast);
      if (r.getDegree() >= rLast.getDegree()) {
        throw new IllegalStateException_1$3.default("Division algorithm failed to reduce polynomial?");
      }
    }
    var sigmaTildeAtZero = t.getCoefficient(0);
    if (sigmaTildeAtZero === 0) {
      throw new ReedSolomonException_1.default("sigmaTilde(0) was zero");
    }
    var inverse = field.inverse(sigmaTildeAtZero);
    var sigma = t.multiplyScalar(inverse);
    var omega = r.multiplyScalar(inverse);
    return [sigma, omega];
  };
  ReedSolomonDecoder2.prototype.findErrorLocations = function(errorLocator) {
    var numErrors = errorLocator.getDegree();
    if (numErrors === 1) {
      return Int32Array.from([errorLocator.getCoefficient(1)]);
    }
    var result = new Int32Array(numErrors);
    var e = 0;
    var field = this.field;
    for (var i = 1; i < field.getSize() && e < numErrors; i++) {
      if (errorLocator.evaluateAt(i) === 0) {
        result[e] = field.inverse(i);
        e++;
      }
    }
    if (e !== numErrors) {
      throw new ReedSolomonException_1.default("Error locator degree does not match number of roots");
    }
    return result;
  };
  ReedSolomonDecoder2.prototype.findErrorMagnitudes = function(errorEvaluator, errorLocations) {
    var s = errorLocations.length;
    var result = new Int32Array(s);
    var field = this.field;
    for (var i = 0; i < s; i++) {
      var xiInverse = field.inverse(errorLocations[i]);
      var denominator = 1;
      for (var j = 0; j < s; j++) {
        if (i !== j) {
          var term = field.multiply(errorLocations[j], xiInverse);
          var termPlus1 = (term & 1) === 0 ? term | 1 : term & ~1;
          denominator = field.multiply(denominator, termPlus1);
        }
      }
      result[i] = field.multiply(errorEvaluator.evaluateAt(xiInverse), field.inverse(denominator));
      if (field.getGeneratorBase() !== 0) {
        result[i] = field.multiply(result[i], xiInverse);
      }
    }
    return result;
  };
  return ReedSolomonDecoder2;
}();
ReedSolomonDecoder$1.default = ReedSolomonDecoder;
var BitMatrixParser$3 = {};
var Version$3 = {};
Object.defineProperty(Version$3, "__esModule", { value: true });
var FormatException_1$5 = FormatException$1;
var ECBlocks$2 = function() {
  function ECBlocks2(ecCodewords, ecBlocks1, ecBlocks2) {
    this.ecCodewords = ecCodewords;
    this.ecBlocks = [ecBlocks1];
    ecBlocks2 && this.ecBlocks.push(ecBlocks2);
  }
  ECBlocks2.prototype.getECCodewords = function() {
    return this.ecCodewords;
  };
  ECBlocks2.prototype.getECBlocks = function() {
    return this.ecBlocks;
  };
  return ECBlocks2;
}();
Version$3.ECBlocks = ECBlocks$2;
var ECB$2 = function() {
  function ECB2(count, dataCodewords) {
    this.count = count;
    this.dataCodewords = dataCodewords;
  }
  ECB2.prototype.getCount = function() {
    return this.count;
  };
  ECB2.prototype.getDataCodewords = function() {
    return this.dataCodewords;
  };
  return ECB2;
}();
Version$3.ECB = ECB$2;
var Version$2 = function() {
  function Version2(versionNumber, symbolSizeRows, symbolSizeColumns, dataRegionSizeRows, dataRegionSizeColumns, ecBlocks) {
    this.versionNumber = versionNumber;
    this.symbolSizeRows = symbolSizeRows;
    this.symbolSizeColumns = symbolSizeColumns;
    this.dataRegionSizeRows = dataRegionSizeRows;
    this.dataRegionSizeColumns = dataRegionSizeColumns;
    this.ecBlocks = ecBlocks;
    var total = 0;
    var ecCodewords = ecBlocks.getECCodewords();
    var ecbArray = ecBlocks.getECBlocks();
    for (var _i = 0, ecbArray_1 = ecbArray; _i < ecbArray_1.length; _i++) {
      var ecBlock = ecbArray_1[_i];
      total += ecBlock.getCount() * (ecBlock.getDataCodewords() + ecCodewords);
    }
    this.totalCodewords = total;
  }
  Version2.prototype.getVersionNumber = function() {
    return this.versionNumber;
  };
  Version2.prototype.getSymbolSizeRows = function() {
    return this.symbolSizeRows;
  };
  Version2.prototype.getSymbolSizeColumns = function() {
    return this.symbolSizeColumns;
  };
  Version2.prototype.getDataRegionSizeRows = function() {
    return this.dataRegionSizeRows;
  };
  Version2.prototype.getDataRegionSizeColumns = function() {
    return this.dataRegionSizeColumns;
  };
  Version2.prototype.getTotalCodewords = function() {
    return this.totalCodewords;
  };
  Version2.prototype.getECBlocks = function() {
    return this.ecBlocks;
  };
  Version2.getVersionForDimensions = function(numRows, numColumns) {
    if ((numRows & 1) !== 0 || (numColumns & 1) !== 0) {
      throw new FormatException_1$5.default();
    }
    for (var _i = 0, _a = Version2.VERSIONS; _i < _a.length; _i++) {
      var version = _a[_i];
      if (version.symbolSizeRows === numRows && version.symbolSizeColumns === numColumns) {
        return version;
      }
    }
    throw new FormatException_1$5.default();
  };
  Version2.prototype.toString = function() {
    return "" + this.versionNumber;
  };
  Version2.buildVersions = function() {
    return [
      new Version2(1, 10, 10, 8, 8, new ECBlocks$2(5, new ECB$2(1, 3))),
      new Version2(2, 12, 12, 10, 10, new ECBlocks$2(7, new ECB$2(1, 5))),
      new Version2(3, 14, 14, 12, 12, new ECBlocks$2(10, new ECB$2(1, 8))),
      new Version2(4, 16, 16, 14, 14, new ECBlocks$2(12, new ECB$2(1, 12))),
      new Version2(5, 18, 18, 16, 16, new ECBlocks$2(14, new ECB$2(1, 18))),
      new Version2(6, 20, 20, 18, 18, new ECBlocks$2(18, new ECB$2(1, 22))),
      new Version2(7, 22, 22, 20, 20, new ECBlocks$2(20, new ECB$2(1, 30))),
      new Version2(8, 24, 24, 22, 22, new ECBlocks$2(24, new ECB$2(1, 36))),
      new Version2(9, 26, 26, 24, 24, new ECBlocks$2(28, new ECB$2(1, 44))),
      new Version2(10, 32, 32, 14, 14, new ECBlocks$2(36, new ECB$2(1, 62))),
      new Version2(11, 36, 36, 16, 16, new ECBlocks$2(42, new ECB$2(1, 86))),
      new Version2(12, 40, 40, 18, 18, new ECBlocks$2(48, new ECB$2(1, 114))),
      new Version2(13, 44, 44, 20, 20, new ECBlocks$2(56, new ECB$2(1, 144))),
      new Version2(14, 48, 48, 22, 22, new ECBlocks$2(68, new ECB$2(1, 174))),
      new Version2(15, 52, 52, 24, 24, new ECBlocks$2(42, new ECB$2(2, 102))),
      new Version2(16, 64, 64, 14, 14, new ECBlocks$2(56, new ECB$2(2, 140))),
      new Version2(17, 72, 72, 16, 16, new ECBlocks$2(36, new ECB$2(4, 92))),
      new Version2(18, 80, 80, 18, 18, new ECBlocks$2(48, new ECB$2(4, 114))),
      new Version2(19, 88, 88, 20, 20, new ECBlocks$2(56, new ECB$2(4, 144))),
      new Version2(20, 96, 96, 22, 22, new ECBlocks$2(68, new ECB$2(4, 174))),
      new Version2(21, 104, 104, 24, 24, new ECBlocks$2(56, new ECB$2(6, 136))),
      new Version2(22, 120, 120, 18, 18, new ECBlocks$2(68, new ECB$2(6, 175))),
      new Version2(23, 132, 132, 20, 20, new ECBlocks$2(62, new ECB$2(8, 163))),
      new Version2(24, 144, 144, 22, 22, new ECBlocks$2(62, new ECB$2(8, 156), new ECB$2(2, 155))),
      new Version2(25, 8, 18, 6, 16, new ECBlocks$2(7, new ECB$2(1, 5))),
      new Version2(26, 8, 32, 6, 14, new ECBlocks$2(11, new ECB$2(1, 10))),
      new Version2(27, 12, 26, 10, 24, new ECBlocks$2(14, new ECB$2(1, 16))),
      new Version2(28, 12, 36, 10, 16, new ECBlocks$2(18, new ECB$2(1, 22))),
      new Version2(29, 16, 36, 14, 16, new ECBlocks$2(24, new ECB$2(1, 32))),
      new Version2(30, 16, 48, 14, 22, new ECBlocks$2(28, new ECB$2(1, 49)))
    ];
  };
  Version2.VERSIONS = Version2.buildVersions();
  return Version2;
}();
Version$3.default = Version$2;
Object.defineProperty(BitMatrixParser$3, "__esModule", { value: true });
var BitMatrix_1$6 = BitMatrix$1;
var Version_1$3 = Version$3;
var FormatException_1$4 = FormatException$1;
var IllegalArgumentException_1$c = IllegalArgumentException$1;
var BitMatrixParser$2 = function() {
  function BitMatrixParser2(bitMatrix) {
    var dimension = bitMatrix.getHeight();
    if (dimension < 8 || dimension > 144 || (dimension & 1) !== 0) {
      throw new FormatException_1$4.default();
    }
    this.version = BitMatrixParser2.readVersion(bitMatrix);
    this.mappingBitMatrix = this.extractDataRegion(bitMatrix);
    this.readMappingMatrix = new BitMatrix_1$6.default(this.mappingBitMatrix.getWidth(), this.mappingBitMatrix.getHeight());
  }
  BitMatrixParser2.prototype.getVersion = function() {
    return this.version;
  };
  BitMatrixParser2.readVersion = function(bitMatrix) {
    var numRows = bitMatrix.getHeight();
    var numColumns = bitMatrix.getWidth();
    return Version_1$3.default.getVersionForDimensions(numRows, numColumns);
  };
  BitMatrixParser2.prototype.readCodewords = function() {
    var result = new Int8Array(this.version.getTotalCodewords());
    var resultOffset = 0;
    var row = 4;
    var column = 0;
    var numRows = this.mappingBitMatrix.getHeight();
    var numColumns = this.mappingBitMatrix.getWidth();
    var corner1Read = false;
    var corner2Read = false;
    var corner3Read = false;
    var corner4Read = false;
    do {
      if (row === numRows && column === 0 && !corner1Read) {
        result[resultOffset++] = this.readCorner1(numRows, numColumns) & 255;
        row -= 2;
        column += 2;
        corner1Read = true;
      } else if (row === numRows - 2 && column === 0 && (numColumns & 3) !== 0 && !corner2Read) {
        result[resultOffset++] = this.readCorner2(numRows, numColumns) & 255;
        row -= 2;
        column += 2;
        corner2Read = true;
      } else if (row === numRows + 4 && column === 2 && (numColumns & 7) === 0 && !corner3Read) {
        result[resultOffset++] = this.readCorner3(numRows, numColumns) & 255;
        row -= 2;
        column += 2;
        corner3Read = true;
      } else if (row === numRows - 2 && column === 0 && (numColumns & 7) === 4 && !corner4Read) {
        result[resultOffset++] = this.readCorner4(numRows, numColumns) & 255;
        row -= 2;
        column += 2;
        corner4Read = true;
      } else {
        do {
          if (row < numRows && column >= 0 && !this.readMappingMatrix.get(column, row)) {
            result[resultOffset++] = this.readUtah(row, column, numRows, numColumns) & 255;
          }
          row -= 2;
          column += 2;
        } while (row >= 0 && column < numColumns);
        row += 1;
        column += 3;
        do {
          if (row >= 0 && column < numColumns && !this.readMappingMatrix.get(column, row)) {
            result[resultOffset++] = this.readUtah(row, column, numRows, numColumns) & 255;
          }
          row += 2;
          column -= 2;
        } while (row < numRows && column >= 0);
        row += 3;
        column += 1;
      }
    } while (row < numRows || column < numColumns);
    if (resultOffset !== this.version.getTotalCodewords()) {
      throw new FormatException_1$4.default();
    }
    return result;
  };
  BitMatrixParser2.prototype.readModule = function(row, column, numRows, numColumns) {
    if (row < 0) {
      row += numRows;
      column += 4 - (numRows + 4 & 7);
    }
    if (column < 0) {
      column += numColumns;
      row += 4 - (numColumns + 4 & 7);
    }
    this.readMappingMatrix.set(column, row);
    return this.mappingBitMatrix.get(column, row);
  };
  BitMatrixParser2.prototype.readUtah = function(row, column, numRows, numColumns) {
    var currentByte = 0;
    if (this.readModule(row - 2, column - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row - 2, column - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row - 1, column - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row - 1, column - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row - 1, column, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row, column - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row, column - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(row, column, numRows, numColumns)) {
      currentByte |= 1;
    }
    return currentByte;
  };
  BitMatrixParser2.prototype.readCorner1 = function(numRows, numColumns) {
    var currentByte = 0;
    if (this.readModule(numRows - 1, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 1, 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 1, 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(2, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(3, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    return currentByte;
  };
  BitMatrixParser2.prototype.readCorner2 = function(numRows, numColumns) {
    var currentByte = 0;
    if (this.readModule(numRows - 3, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 2, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 1, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 4, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 3, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    return currentByte;
  };
  BitMatrixParser2.prototype.readCorner3 = function(numRows, numColumns) {
    var currentByte = 0;
    if (this.readModule(numRows - 1, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 1, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 3, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 3, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    return currentByte;
  };
  BitMatrixParser2.prototype.readCorner4 = function(numRows, numColumns) {
    var currentByte = 0;
    if (this.readModule(numRows - 3, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 2, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(numRows - 1, 0, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 2, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(0, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(1, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(2, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    currentByte <<= 1;
    if (this.readModule(3, numColumns - 1, numRows, numColumns)) {
      currentByte |= 1;
    }
    return currentByte;
  };
  BitMatrixParser2.prototype.extractDataRegion = function(bitMatrix) {
    var symbolSizeRows = this.version.getSymbolSizeRows();
    var symbolSizeColumns = this.version.getSymbolSizeColumns();
    if (bitMatrix.getHeight() !== symbolSizeRows) {
      throw new IllegalArgumentException_1$c.default("Dimension of bitMatrix must match the version size");
    }
    var dataRegionSizeRows = this.version.getDataRegionSizeRows();
    var dataRegionSizeColumns = this.version.getDataRegionSizeColumns();
    var numDataRegionsRow = symbolSizeRows / dataRegionSizeRows | 0;
    var numDataRegionsColumn = symbolSizeColumns / dataRegionSizeColumns | 0;
    var sizeDataRegionRow = numDataRegionsRow * dataRegionSizeRows;
    var sizeDataRegionColumn = numDataRegionsColumn * dataRegionSizeColumns;
    var bitMatrixWithoutAlignment = new BitMatrix_1$6.default(sizeDataRegionColumn, sizeDataRegionRow);
    for (var dataRegionRow = 0; dataRegionRow < numDataRegionsRow; ++dataRegionRow) {
      var dataRegionRowOffset = dataRegionRow * dataRegionSizeRows;
      for (var dataRegionColumn = 0; dataRegionColumn < numDataRegionsColumn; ++dataRegionColumn) {
        var dataRegionColumnOffset = dataRegionColumn * dataRegionSizeColumns;
        for (var i = 0; i < dataRegionSizeRows; ++i) {
          var readRowOffset = dataRegionRow * (dataRegionSizeRows + 2) + 1 + i;
          var writeRowOffset = dataRegionRowOffset + i;
          for (var j = 0; j < dataRegionSizeColumns; ++j) {
            var readColumnOffset = dataRegionColumn * (dataRegionSizeColumns + 2) + 1 + j;
            if (bitMatrix.get(readColumnOffset, readRowOffset)) {
              var writeColumnOffset = dataRegionColumnOffset + j;
              bitMatrixWithoutAlignment.set(writeColumnOffset, writeRowOffset);
            }
          }
        }
      }
    }
    return bitMatrixWithoutAlignment;
  };
  return BitMatrixParser2;
}();
BitMatrixParser$3.default = BitMatrixParser$2;
var DataBlock$3 = {};
Object.defineProperty(DataBlock$3, "__esModule", { value: true });
var IllegalArgumentException_1$b = IllegalArgumentException$1;
var DataBlock$2 = function() {
  function DataBlock2(numDataCodewords, codewords) {
    this.numDataCodewords = numDataCodewords;
    this.codewords = codewords;
  }
  DataBlock2.getDataBlocks = function(rawCodewords, version) {
    var ecBlocks = version.getECBlocks();
    var totalBlocks = 0;
    var ecBlockArray = ecBlocks.getECBlocks();
    for (var _i = 0, ecBlockArray_1 = ecBlockArray; _i < ecBlockArray_1.length; _i++) {
      var ecBlock = ecBlockArray_1[_i];
      totalBlocks += ecBlock.getCount();
    }
    var result = new Array(totalBlocks);
    var numResultBlocks = 0;
    for (var _a = 0, ecBlockArray_2 = ecBlockArray; _a < ecBlockArray_2.length; _a++) {
      var ecBlock = ecBlockArray_2[_a];
      for (var i = 0; i < ecBlock.getCount(); i++) {
        var numDataCodewords = ecBlock.getDataCodewords();
        var numBlockCodewords = ecBlocks.getECCodewords() + numDataCodewords;
        result[numResultBlocks++] = new DataBlock2(numDataCodewords, new Uint8Array(numBlockCodewords));
      }
    }
    var longerBlocksTotalCodewords = result[0].codewords.length;
    var longerBlocksNumDataCodewords = longerBlocksTotalCodewords - ecBlocks.getECCodewords();
    var shorterBlocksNumDataCodewords = longerBlocksNumDataCodewords - 1;
    var rawCodewordsOffset = 0;
    for (var i = 0; i < shorterBlocksNumDataCodewords; i++) {
      for (var j = 0; j < numResultBlocks; j++) {
        result[j].codewords[i] = rawCodewords[rawCodewordsOffset++];
      }
    }
    var specialVersion = version.getVersionNumber() === 24;
    var numLongerBlocks = specialVersion ? 8 : numResultBlocks;
    for (var j = 0; j < numLongerBlocks; j++) {
      result[j].codewords[longerBlocksNumDataCodewords - 1] = rawCodewords[rawCodewordsOffset++];
    }
    var max = result[0].codewords.length;
    for (var i = longerBlocksNumDataCodewords; i < max; i++) {
      for (var j = 0; j < numResultBlocks; j++) {
        var jOffset = specialVersion ? (j + 8) % numResultBlocks : j;
        var iOffset = specialVersion && jOffset > 7 ? i - 1 : i;
        result[jOffset].codewords[iOffset] = rawCodewords[rawCodewordsOffset++];
      }
    }
    if (rawCodewordsOffset !== rawCodewords.length) {
      throw new IllegalArgumentException_1$b.default();
    }
    return result;
  };
  DataBlock2.prototype.getNumDataCodewords = function() {
    return this.numDataCodewords;
  };
  DataBlock2.prototype.getCodewords = function() {
    return this.codewords;
  };
  return DataBlock2;
}();
DataBlock$3.default = DataBlock$2;
var DecodedBitStreamParser$3 = {};
var DecoderResult$1 = {};
Object.defineProperty(DecoderResult$1, "__esModule", { value: true });
var DecoderResult = function() {
  function DecoderResult2(rawBytes, text, byteSegments, ecLevel, structuredAppendSequenceNumber, structuredAppendParity) {
    if (structuredAppendSequenceNumber === void 0) {
      structuredAppendSequenceNumber = -1;
    }
    if (structuredAppendParity === void 0) {
      structuredAppendParity = -1;
    }
    this.rawBytes = rawBytes;
    this.text = text;
    this.byteSegments = byteSegments;
    this.ecLevel = ecLevel;
    this.structuredAppendSequenceNumber = structuredAppendSequenceNumber;
    this.structuredAppendParity = structuredAppendParity;
    this.numBits = rawBytes === void 0 || rawBytes === null ? 0 : 8 * rawBytes.length;
  }
  DecoderResult2.prototype.getRawBytes = function() {
    return this.rawBytes;
  };
  DecoderResult2.prototype.getNumBits = function() {
    return this.numBits;
  };
  DecoderResult2.prototype.setNumBits = function(numBits) {
    this.numBits = numBits;
  };
  DecoderResult2.prototype.getText = function() {
    return this.text;
  };
  DecoderResult2.prototype.getByteSegments = function() {
    return this.byteSegments;
  };
  DecoderResult2.prototype.getECLevel = function() {
    return this.ecLevel;
  };
  DecoderResult2.prototype.getErrorsCorrected = function() {
    return this.errorsCorrected;
  };
  DecoderResult2.prototype.setErrorsCorrected = function(errorsCorrected) {
    this.errorsCorrected = errorsCorrected;
  };
  DecoderResult2.prototype.getErasures = function() {
    return this.erasures;
  };
  DecoderResult2.prototype.setErasures = function(erasures) {
    this.erasures = erasures;
  };
  DecoderResult2.prototype.getOther = function() {
    return this.other;
  };
  DecoderResult2.prototype.setOther = function(other) {
    this.other = other;
  };
  DecoderResult2.prototype.hasStructuredAppend = function() {
    return this.structuredAppendParity >= 0 && this.structuredAppendSequenceNumber >= 0;
  };
  DecoderResult2.prototype.getStructuredAppendParity = function() {
    return this.structuredAppendParity;
  };
  DecoderResult2.prototype.getStructuredAppendSequenceNumber = function() {
    return this.structuredAppendSequenceNumber;
  };
  return DecoderResult2;
}();
DecoderResult$1.default = DecoderResult;
var BitSource$1 = {};
Object.defineProperty(BitSource$1, "__esModule", { value: true });
var IllegalArgumentException_1$a = IllegalArgumentException$1;
var BitSource = function() {
  function BitSource2(bytes) {
    this.bytes = bytes;
    this.byteOffset = 0;
    this.bitOffset = 0;
  }
  BitSource2.prototype.getBitOffset = function() {
    return this.bitOffset;
  };
  BitSource2.prototype.getByteOffset = function() {
    return this.byteOffset;
  };
  BitSource2.prototype.readBits = function(numBits) {
    if (numBits < 1 || numBits > 32 || numBits > this.available()) {
      throw new IllegalArgumentException_1$a.default("" + numBits);
    }
    var result = 0;
    var bitOffset = this.bitOffset;
    var byteOffset = this.byteOffset;
    var bytes = this.bytes;
    if (bitOffset > 0) {
      var bitsLeft = 8 - bitOffset;
      var toRead = numBits < bitsLeft ? numBits : bitsLeft;
      var bitsToNotRead = bitsLeft - toRead;
      var mask = 255 >> 8 - toRead << bitsToNotRead;
      result = (bytes[byteOffset] & mask) >> bitsToNotRead;
      numBits -= toRead;
      bitOffset += toRead;
      if (bitOffset === 8) {
        bitOffset = 0;
        byteOffset++;
      }
    }
    if (numBits > 0) {
      while (numBits >= 8) {
        result = result << 8 | bytes[byteOffset] & 255;
        byteOffset++;
        numBits -= 8;
      }
      if (numBits > 0) {
        var bitsToNotRead = 8 - numBits;
        var mask = 255 >> bitsToNotRead << bitsToNotRead;
        result = result << numBits | (bytes[byteOffset] & mask) >> bitsToNotRead;
        bitOffset += numBits;
      }
    }
    this.bitOffset = bitOffset;
    this.byteOffset = byteOffset;
    return result;
  };
  BitSource2.prototype.available = function() {
    return 8 * (this.bytes.length - this.byteOffset) - this.bitOffset;
  };
  return BitSource2;
}();
BitSource$1.default = BitSource;
var StringEncoding$1 = {};
var CharacterSetECI = {};
(function(exports) {
  Object.defineProperty(exports, "__esModule", { value: true });
  var FormatException_12 = FormatException$1;
  var CharacterSetValueIdentifiers;
  (function(CharacterSetValueIdentifiers2) {
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Cp437"] = 0] = "Cp437";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_1"] = 1] = "ISO8859_1";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_2"] = 2] = "ISO8859_2";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_3"] = 3] = "ISO8859_3";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_4"] = 4] = "ISO8859_4";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_5"] = 5] = "ISO8859_5";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_6"] = 6] = "ISO8859_6";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_7"] = 7] = "ISO8859_7";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_8"] = 8] = "ISO8859_8";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_9"] = 9] = "ISO8859_9";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_10"] = 10] = "ISO8859_10";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_11"] = 11] = "ISO8859_11";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_13"] = 12] = "ISO8859_13";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_14"] = 13] = "ISO8859_14";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_15"] = 14] = "ISO8859_15";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ISO8859_16"] = 15] = "ISO8859_16";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["SJIS"] = 16] = "SJIS";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Cp1250"] = 17] = "Cp1250";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Cp1251"] = 18] = "Cp1251";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Cp1252"] = 19] = "Cp1252";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Cp1256"] = 20] = "Cp1256";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["UnicodeBigUnmarked"] = 21] = "UnicodeBigUnmarked";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["UTF8"] = 22] = "UTF8";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["ASCII"] = 23] = "ASCII";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["Big5"] = 24] = "Big5";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["GB18030"] = 25] = "GB18030";
    CharacterSetValueIdentifiers2[CharacterSetValueIdentifiers2["EUC_KR"] = 26] = "EUC_KR";
  })(CharacterSetValueIdentifiers = exports.CharacterSetValueIdentifiers || (exports.CharacterSetValueIdentifiers = {}));
  var CharacterSetECI2 = function() {
    function CharacterSetECI3(valueIdentifier, valuesParam, name) {
      var otherEncodingNames = [];
      for (var _i = 3; _i < arguments.length; _i++) {
        otherEncodingNames[_i - 3] = arguments[_i];
      }
      this.valueIdentifier = valueIdentifier;
      this.name = name;
      if (typeof valuesParam === "number") {
        this.values = Int32Array.from([valuesParam]);
      } else {
        this.values = valuesParam;
      }
      this.otherEncodingNames = otherEncodingNames;
      CharacterSetECI3.VALUE_IDENTIFIER_TO_ECI.set(valueIdentifier, this);
      CharacterSetECI3.NAME_TO_ECI.set(name, this);
      var values = this.values;
      for (var i = 0, length_1 = values.length; i !== length_1; i++) {
        var v = values[i];
        CharacterSetECI3.VALUES_TO_ECI.set(v, this);
      }
      for (var _a = 0, otherEncodingNames_1 = otherEncodingNames; _a < otherEncodingNames_1.length; _a++) {
        var otherName = otherEncodingNames_1[_a];
        CharacterSetECI3.NAME_TO_ECI.set(otherName, this);
      }
    }
    CharacterSetECI3.prototype.getValueIdentifier = function() {
      return this.valueIdentifier;
    };
    CharacterSetECI3.prototype.getName = function() {
      return this.name;
    };
    CharacterSetECI3.prototype.getValue = function() {
      return this.values[0];
    };
    CharacterSetECI3.getCharacterSetECIByValue = function(value) {
      if (value < 0 || value >= 900) {
        throw new FormatException_12.default("incorect value");
      }
      var characterSet = CharacterSetECI3.VALUES_TO_ECI.get(value);
      if (void 0 === characterSet) {
        throw new FormatException_12.default("incorect value");
      }
      return characterSet;
    };
    CharacterSetECI3.getCharacterSetECIByName = function(name) {
      var characterSet = CharacterSetECI3.NAME_TO_ECI.get(name);
      if (void 0 === characterSet) {
        throw new FormatException_12.default("incorect value");
      }
      return characterSet;
    };
    CharacterSetECI3.prototype.equals = function(o) {
      if (!(o instanceof CharacterSetECI3)) {
        return false;
      }
      var other = o;
      return this.getName() === other.getName();
    };
    CharacterSetECI3.VALUE_IDENTIFIER_TO_ECI = /* @__PURE__ */ new Map();
    CharacterSetECI3.VALUES_TO_ECI = /* @__PURE__ */ new Map();
    CharacterSetECI3.NAME_TO_ECI = /* @__PURE__ */ new Map();
    CharacterSetECI3.Cp437 = new CharacterSetECI3(CharacterSetValueIdentifiers.Cp437, Int32Array.from([0, 2]), "Cp437");
    CharacterSetECI3.ISO8859_1 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_1, Int32Array.from([1, 3]), "ISO-8859-1", "ISO88591", "ISO8859_1");
    CharacterSetECI3.ISO8859_2 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_2, 4, "ISO-8859-2", "ISO88592", "ISO8859_2");
    CharacterSetECI3.ISO8859_3 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_3, 5, "ISO-8859-3", "ISO88593", "ISO8859_3");
    CharacterSetECI3.ISO8859_4 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_4, 6, "ISO-8859-4", "ISO88594", "ISO8859_4");
    CharacterSetECI3.ISO8859_5 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_5, 7, "ISO-8859-5", "ISO88595", "ISO8859_5");
    CharacterSetECI3.ISO8859_6 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_6, 8, "ISO-8859-6", "ISO88596", "ISO8859_6");
    CharacterSetECI3.ISO8859_7 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_7, 9, "ISO-8859-7", "ISO88597", "ISO8859_7");
    CharacterSetECI3.ISO8859_8 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_8, 10, "ISO-8859-8", "ISO88598", "ISO8859_8");
    CharacterSetECI3.ISO8859_9 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_9, 11, "ISO-8859-9", "ISO88599", "ISO8859_9");
    CharacterSetECI3.ISO8859_10 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_10, 12, "ISO-8859-10", "ISO885910", "ISO8859_10");
    CharacterSetECI3.ISO8859_11 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_11, 13, "ISO-8859-11", "ISO885911", "ISO8859_11");
    CharacterSetECI3.ISO8859_13 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_13, 15, "ISO-8859-13", "ISO885913", "ISO8859_13");
    CharacterSetECI3.ISO8859_14 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_14, 16, "ISO-8859-14", "ISO885914", "ISO8859_14");
    CharacterSetECI3.ISO8859_15 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_15, 17, "ISO-8859-15", "ISO885915", "ISO8859_15");
    CharacterSetECI3.ISO8859_16 = new CharacterSetECI3(CharacterSetValueIdentifiers.ISO8859_16, 18, "ISO-8859-16", "ISO885916", "ISO8859_16");
    CharacterSetECI3.SJIS = new CharacterSetECI3(CharacterSetValueIdentifiers.SJIS, 20, "SJIS", "Shift_JIS");
    CharacterSetECI3.Cp1250 = new CharacterSetECI3(CharacterSetValueIdentifiers.Cp1250, 21, "Cp1250", "windows-1250");
    CharacterSetECI3.Cp1251 = new CharacterSetECI3(CharacterSetValueIdentifiers.Cp1251, 22, "Cp1251", "windows-1251");
    CharacterSetECI3.Cp1252 = new CharacterSetECI3(CharacterSetValueIdentifiers.Cp1252, 23, "Cp1252", "windows-1252");
    CharacterSetECI3.Cp1256 = new CharacterSetECI3(CharacterSetValueIdentifiers.Cp1256, 24, "Cp1256", "windows-1256");
    CharacterSetECI3.UnicodeBigUnmarked = new CharacterSetECI3(CharacterSetValueIdentifiers.UnicodeBigUnmarked, 25, "UnicodeBigUnmarked", "UTF-16BE", "UnicodeBig");
    CharacterSetECI3.UTF8 = new CharacterSetECI3(CharacterSetValueIdentifiers.UTF8, 26, "UTF8", "UTF-8");
    CharacterSetECI3.ASCII = new CharacterSetECI3(CharacterSetValueIdentifiers.ASCII, Int32Array.from([27, 170]), "ASCII", "US-ASCII");
    CharacterSetECI3.Big5 = new CharacterSetECI3(CharacterSetValueIdentifiers.Big5, 28, "Big5");
    CharacterSetECI3.GB18030 = new CharacterSetECI3(CharacterSetValueIdentifiers.GB18030, 29, "GB18030", "GB2312", "EUC_CN", "GBK");
    CharacterSetECI3.EUC_KR = new CharacterSetECI3(CharacterSetValueIdentifiers.EUC_KR, 30, "EUC_KR", "EUC-KR");
    return CharacterSetECI3;
  }();
  exports.default = CharacterSetECI2;
})(CharacterSetECI);
Object.defineProperty(StringEncoding$1, "__esModule", { value: true });
var UnsupportedOperationException_1 = UnsupportedOperationException$1;
var CharacterSetECI_1$3 = CharacterSetECI;
var StringEncoding = function() {
  function StringEncoding2() {
  }
  StringEncoding2.decode = function(bytes, encoding) {
    var encodingName = this.encodingName(encoding);
    if (typeof TextDecoder === "undefined") {
      return this.decodeFallback(bytes, encodingName);
    }
    return new TextDecoder(encodingName).decode(bytes);
  };
  StringEncoding2.encode = function(s, encoding) {
    if (!StringEncoding2.isBrowser()) {
      var EncoderConstructor = TextEncoder;
      return new EncoderConstructor(this.encodingName(encoding), { NONSTANDARD_allowLegacyEncoding: true }).encode(s);
    }
    if (typeof TextEncoder === "undefined") {
      return this.encodeFallback(s);
    }
    return new TextEncoder().encode(s);
  };
  StringEncoding2.isBrowser = function() {
    return typeof window !== "undefined" && {}.toString.call(window) === "[object Window]";
  };
  StringEncoding2.encodingName = function(encoding) {
    return typeof encoding === "string" ? encoding : encoding.getName();
  };
  StringEncoding2.encodingCharacterSet = function(encoding) {
    return CharacterSetECI_1$3.default.getCharacterSetECIByName(this.encodingName(encoding));
  };
  StringEncoding2.decodeFallback = function(bytes, encoding) {
    var characterSet = this.encodingCharacterSet(encoding);
    if (characterSet.equals(CharacterSetECI_1$3.default.UTF8) || characterSet.equals(CharacterSetECI_1$3.default.ISO8859_1) || characterSet.equals(CharacterSetECI_1$3.default.ASCII)) {
      var s = "";
      for (var i = 0, length_1 = bytes.length; i < length_1; i++) {
        var h = bytes[i].toString(16);
        if (h.length < 2) {
          h = "0" + h;
        }
        s += "%" + h;
      }
      return decodeURIComponent(s);
    }
    if (characterSet.equals(CharacterSetECI_1$3.default.UnicodeBigUnmarked)) {
      return String.fromCharCode.apply(null, new Uint16Array(bytes.buffer));
    }
    throw new UnsupportedOperationException_1.default("Encoding " + this.encodingName(encoding) + " not supported by fallback.");
  };
  StringEncoding2.encodeFallback = function(s) {
    var encodedURIstring = btoa(unescape(encodeURIComponent(s)));
    var charList = encodedURIstring.split("");
    var uintArray = [];
    for (var i = 0; i < charList.length; i++) {
      uintArray.push(charList[i].charCodeAt(0));
    }
    return new Uint8Array(uintArray);
  };
  return StringEncoding2;
}();
StringEncoding$1.default = StringEncoding;
Object.defineProperty(DecodedBitStreamParser$3, "__esModule", { value: true });
var DecoderResult_1$1 = DecoderResult$1;
var BitSource_1$1 = BitSource$1;
var StringBuilder_1$3 = StringBuilder$1;
var StringEncoding_1$2 = StringEncoding$1;
var __1 = esm5;
var FormatException_1$3 = FormatException$1;
var IllegalStateException_1$2 = IllegalStateException$1;
var Mode$1;
(function(Mode2) {
  Mode2[Mode2["PAD_ENCODE"] = 0] = "PAD_ENCODE";
  Mode2[Mode2["ASCII_ENCODE"] = 1] = "ASCII_ENCODE";
  Mode2[Mode2["C40_ENCODE"] = 2] = "C40_ENCODE";
  Mode2[Mode2["TEXT_ENCODE"] = 3] = "TEXT_ENCODE";
  Mode2[Mode2["ANSIX12_ENCODE"] = 4] = "ANSIX12_ENCODE";
  Mode2[Mode2["EDIFACT_ENCODE"] = 5] = "EDIFACT_ENCODE";
  Mode2[Mode2["BASE256_ENCODE"] = 6] = "BASE256_ENCODE";
})(Mode$1 || (Mode$1 = {}));
var DecodedBitStreamParser$2 = function() {
  function DecodedBitStreamParser2() {
  }
  DecodedBitStreamParser2.decode = function(bytes) {
    var bits = new BitSource_1$1.default(bytes);
    var result = new StringBuilder_1$3.default();
    var resultTrailer = new StringBuilder_1$3.default();
    var byteSegments = new Array();
    var mode = Mode$1.ASCII_ENCODE;
    do {
      if (mode === Mode$1.ASCII_ENCODE) {
        mode = this.decodeAsciiSegment(bits, result, resultTrailer);
      } else {
        switch (mode) {
          case Mode$1.C40_ENCODE:
            this.decodeC40Segment(bits, result);
            break;
          case Mode$1.TEXT_ENCODE:
            this.decodeTextSegment(bits, result);
            break;
          case Mode$1.ANSIX12_ENCODE:
            this.decodeAnsiX12Segment(bits, result);
            break;
          case Mode$1.EDIFACT_ENCODE:
            this.decodeEdifactSegment(bits, result);
            break;
          case Mode$1.BASE256_ENCODE:
            this.decodeBase256Segment(bits, result, byteSegments);
            break;
          default:
            throw new FormatException_1$3.default();
        }
        mode = Mode$1.ASCII_ENCODE;
      }
    } while (mode !== Mode$1.PAD_ENCODE && bits.available() > 0);
    if (resultTrailer.length() > 0) {
      result.append(resultTrailer.toString());
    }
    return new DecoderResult_1$1.default(bytes, result.toString(), byteSegments.length === 0 ? null : byteSegments, null);
  };
  DecodedBitStreamParser2.decodeAsciiSegment = function(bits, result, resultTrailer) {
    var upperShift = false;
    do {
      var oneByte = bits.readBits(8);
      if (oneByte === 0) {
        throw new FormatException_1$3.default();
      } else if (oneByte <= 128) {
        if (upperShift) {
          oneByte += 128;
        }
        result.append(String.fromCharCode(oneByte - 1));
        return Mode$1.ASCII_ENCODE;
      } else if (oneByte === 129) {
        return Mode$1.PAD_ENCODE;
      } else if (oneByte <= 229) {
        var value = oneByte - 130;
        if (value < 10) {
          result.append("0");
        }
        result.append("" + value);
      } else {
        switch (oneByte) {
          case 230:
            return Mode$1.C40_ENCODE;
          case 231:
            return Mode$1.BASE256_ENCODE;
          case 232:
            result.append(String.fromCharCode(29));
            break;
          case 233:
          case 234:
            break;
          case 235:
            upperShift = true;
            break;
          case 236:
            result.append("[)>05");
            resultTrailer.insert(0, "");
            break;
          case 237:
            result.append("[)>06");
            resultTrailer.insert(0, "");
            break;
          case 238:
            return Mode$1.ANSIX12_ENCODE;
          case 239:
            return Mode$1.TEXT_ENCODE;
          case 240:
            return Mode$1.EDIFACT_ENCODE;
          case 241:
            break;
          default:
            if (oneByte !== 254 || bits.available() !== 0) {
              throw new FormatException_1$3.default();
            }
            break;
        }
      }
    } while (bits.available() > 0);
    return Mode$1.ASCII_ENCODE;
  };
  DecodedBitStreamParser2.decodeC40Segment = function(bits, result) {
    var upperShift = false;
    var cValues = [];
    var shift = 0;
    do {
      if (bits.available() === 8) {
        return;
      }
      var firstByte = bits.readBits(8);
      if (firstByte === 254) {
        return;
      }
      this.parseTwoBytes(firstByte, bits.readBits(8), cValues);
      for (var i = 0; i < 3; i++) {
        var cValue = cValues[i];
        switch (shift) {
          case 0:
            if (cValue < 3) {
              shift = cValue + 1;
            } else if (cValue < this.C40_BASIC_SET_CHARS.length) {
              var c40char = this.C40_BASIC_SET_CHARS[cValue];
              if (upperShift) {
                result.append(String.fromCharCode(c40char.charCodeAt(0) + 128));
                upperShift = false;
              } else {
                result.append(c40char);
              }
            } else {
              throw new FormatException_1$3.default();
            }
            break;
          case 1:
            if (upperShift) {
              result.append(String.fromCharCode(cValue + 128));
              upperShift = false;
            } else {
              result.append(String.fromCharCode(cValue));
            }
            shift = 0;
            break;
          case 2:
            if (cValue < this.C40_SHIFT2_SET_CHARS.length) {
              var c40char = this.C40_SHIFT2_SET_CHARS[cValue];
              if (upperShift) {
                result.append(String.fromCharCode(c40char.charCodeAt(0) + 128));
                upperShift = false;
              } else {
                result.append(c40char);
              }
            } else {
              switch (cValue) {
                case 27:
                  result.append(String.fromCharCode(29));
                  break;
                case 30:
                  upperShift = true;
                  break;
                default:
                  throw new FormatException_1$3.default();
              }
            }
            shift = 0;
            break;
          case 3:
            if (upperShift) {
              result.append(String.fromCharCode(cValue + 224));
              upperShift = false;
            } else {
              result.append(String.fromCharCode(cValue + 96));
            }
            shift = 0;
            break;
          default:
            throw new FormatException_1$3.default();
        }
      }
    } while (bits.available() > 0);
  };
  DecodedBitStreamParser2.decodeTextSegment = function(bits, result) {
    var upperShift = false;
    var cValues = [];
    var shift = 0;
    do {
      if (bits.available() === 8) {
        return;
      }
      var firstByte = bits.readBits(8);
      if (firstByte === 254) {
        return;
      }
      this.parseTwoBytes(firstByte, bits.readBits(8), cValues);
      for (var i = 0; i < 3; i++) {
        var cValue = cValues[i];
        switch (shift) {
          case 0:
            if (cValue < 3) {
              shift = cValue + 1;
            } else if (cValue < this.TEXT_BASIC_SET_CHARS.length) {
              var textChar = this.TEXT_BASIC_SET_CHARS[cValue];
              if (upperShift) {
                result.append(String.fromCharCode(textChar.charCodeAt(0) + 128));
                upperShift = false;
              } else {
                result.append(textChar);
              }
            } else {
              throw new FormatException_1$3.default();
            }
            break;
          case 1:
            if (upperShift) {
              result.append(String.fromCharCode(cValue + 128));
              upperShift = false;
            } else {
              result.append(String.fromCharCode(cValue));
            }
            shift = 0;
            break;
          case 2:
            if (cValue < this.TEXT_SHIFT2_SET_CHARS.length) {
              var textChar = this.TEXT_SHIFT2_SET_CHARS[cValue];
              if (upperShift) {
                result.append(String.fromCharCode(textChar.charCodeAt(0) + 128));
                upperShift = false;
              } else {
                result.append(textChar);
              }
            } else {
              switch (cValue) {
                case 27:
                  result.append(String.fromCharCode(29));
                  break;
                case 30:
                  upperShift = true;
                  break;
                default:
                  throw new FormatException_1$3.default();
              }
            }
            shift = 0;
            break;
          case 3:
            if (cValue < this.TEXT_SHIFT3_SET_CHARS.length) {
              var textChar = this.TEXT_SHIFT3_SET_CHARS[cValue];
              if (upperShift) {
                result.append(String.fromCharCode(textChar.charCodeAt(0) + 128));
                upperShift = false;
              } else {
                result.append(textChar);
              }
              shift = 0;
            } else {
              throw new FormatException_1$3.default();
            }
            break;
          default:
            throw new FormatException_1$3.default();
        }
      }
    } while (bits.available() > 0);
  };
  DecodedBitStreamParser2.decodeAnsiX12Segment = function(bits, result) {
    var cValues = [];
    do {
      if (bits.available() === 8) {
        return;
      }
      var firstByte = bits.readBits(8);
      if (firstByte === 254) {
        return;
      }
      this.parseTwoBytes(firstByte, bits.readBits(8), cValues);
      for (var i = 0; i < 3; i++) {
        var cValue = cValues[i];
        switch (cValue) {
          case 0:
            result.append("\r");
            break;
          case 1:
            result.append("*");
            break;
          case 2:
            result.append(">");
            break;
          case 3:
            result.append(" ");
            break;
          default:
            if (cValue < 14) {
              result.append(String.fromCharCode(cValue + 44));
            } else if (cValue < 40) {
              result.append(String.fromCharCode(cValue + 51));
            } else {
              throw new FormatException_1$3.default();
            }
            break;
        }
      }
    } while (bits.available() > 0);
  };
  DecodedBitStreamParser2.parseTwoBytes = function(firstByte, secondByte, result) {
    var fullBitValue = (firstByte << 8) + secondByte - 1;
    var temp = Math.floor(fullBitValue / 1600);
    result[0] = temp;
    fullBitValue -= temp * 1600;
    temp = Math.floor(fullBitValue / 40);
    result[1] = temp;
    result[2] = fullBitValue - temp * 40;
  };
  DecodedBitStreamParser2.decodeEdifactSegment = function(bits, result) {
    do {
      if (bits.available() <= 16) {
        return;
      }
      for (var i = 0; i < 4; i++) {
        var edifactValue = bits.readBits(6);
        if (edifactValue === 31) {
          var bitsLeft = 8 - bits.getBitOffset();
          if (bitsLeft !== 8) {
            bits.readBits(bitsLeft);
          }
          return;
        }
        if ((edifactValue & 32) === 0) {
          edifactValue |= 64;
        }
        result.append(String.fromCharCode(edifactValue));
      }
    } while (bits.available() > 0);
  };
  DecodedBitStreamParser2.decodeBase256Segment = function(bits, result, byteSegments) {
    var codewordPosition = 1 + bits.getByteOffset();
    var d1 = this.unrandomize255State(bits.readBits(8), codewordPosition++);
    var count;
    if (d1 === 0) {
      count = bits.available() / 8 | 0;
    } else if (d1 < 250) {
      count = d1;
    } else {
      count = 250 * (d1 - 249) + this.unrandomize255State(bits.readBits(8), codewordPosition++);
    }
    if (count < 0) {
      throw new FormatException_1$3.default();
    }
    var bytes = new Uint8Array(count);
    for (var i = 0; i < count; i++) {
      if (bits.available() < 8) {
        throw new FormatException_1$3.default();
      }
      bytes[i] = this.unrandomize255State(bits.readBits(8), codewordPosition++);
    }
    byteSegments.push(bytes);
    try {
      result.append(StringEncoding_1$2.default.decode(bytes, __1.StringUtils.ISO88591));
    } catch (uee) {
      throw new IllegalStateException_1$2.default("Platform does not support required encoding: " + uee.message);
    }
  };
  DecodedBitStreamParser2.unrandomize255State = function(randomizedBase256Codeword, base256CodewordPosition) {
    var pseudoRandomNumber = 149 * base256CodewordPosition % 255 + 1;
    var tempVariable = randomizedBase256Codeword - pseudoRandomNumber;
    return tempVariable >= 0 ? tempVariable : tempVariable + 256;
  };
  DecodedBitStreamParser2.C40_BASIC_SET_CHARS = [
    "*",
    "*",
    "*",
    " ",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  DecodedBitStreamParser2.C40_SHIFT2_SET_CHARS = [
    "!",
    '"',
    "#",
    "$",
    "%",
    "&",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    "-",
    ".",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "@",
    "[",
    "\\",
    "]",
    "^",
    "_"
  ];
  DecodedBitStreamParser2.TEXT_BASIC_SET_CHARS = [
    "*",
    "*",
    "*",
    " ",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z"
  ];
  DecodedBitStreamParser2.TEXT_SHIFT2_SET_CHARS = DecodedBitStreamParser2.C40_SHIFT2_SET_CHARS;
  DecodedBitStreamParser2.TEXT_SHIFT3_SET_CHARS = [
    "`",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "{",
    "|",
    "}",
    "~",
    String.fromCharCode(127)
  ];
  return DecodedBitStreamParser2;
}();
DecodedBitStreamParser$3.default = DecodedBitStreamParser$2;
Object.defineProperty(Decoder$3, "__esModule", { value: true });
var ReedSolomonDecoder_1$1 = ReedSolomonDecoder$1;
var GenericGF_1$2 = GenericGF$1;
var BitMatrixParser_1$1 = BitMatrixParser$3;
var DataBlock_1$1 = DataBlock$3;
var DecodedBitStreamParser_1$1 = DecodedBitStreamParser$3;
var ChecksumException_1$1 = ChecksumException$1;
var Decoder$2 = function() {
  function Decoder2() {
    this.rsDecoder = new ReedSolomonDecoder_1$1.default(GenericGF_1$2.default.DATA_MATRIX_FIELD_256);
  }
  Decoder2.prototype.decode = function(bits) {
    var parser = new BitMatrixParser_1$1.default(bits);
    var version = parser.getVersion();
    var codewords = parser.readCodewords();
    var dataBlocks = DataBlock_1$1.default.getDataBlocks(codewords, version);
    var totalBytes = 0;
    for (var _i = 0, dataBlocks_1 = dataBlocks; _i < dataBlocks_1.length; _i++) {
      var db = dataBlocks_1[_i];
      totalBytes += db.getNumDataCodewords();
    }
    var resultBytes = new Uint8Array(totalBytes);
    var dataBlocksCount = dataBlocks.length;
    for (var j = 0; j < dataBlocksCount; j++) {
      var dataBlock = dataBlocks[j];
      var codewordBytes = dataBlock.getCodewords();
      var numDataCodewords = dataBlock.getNumDataCodewords();
      this.correctErrors(codewordBytes, numDataCodewords);
      for (var i = 0; i < numDataCodewords; i++) {
        resultBytes[i * dataBlocksCount + j] = codewordBytes[i];
      }
    }
    return DecodedBitStreamParser_1$1.default.decode(resultBytes);
  };
  Decoder2.prototype.correctErrors = function(codewordBytes, numDataCodewords) {
    codewordBytes.length;
    var codewordsInts = new Int32Array(codewordBytes);
    try {
      this.rsDecoder.decode(codewordsInts, codewordBytes.length - numDataCodewords);
    } catch (ignored) {
      throw new ChecksumException_1$1.default();
    }
    for (var i = 0; i < numDataCodewords; i++) {
      codewordBytes[i] = codewordsInts[i];
    }
  };
  return Decoder2;
}();
Decoder$3.default = Decoder$2;
var Detector$3 = {};
var DetectorResult$1 = {};
Object.defineProperty(DetectorResult$1, "__esModule", { value: true });
var DetectorResult = function() {
  function DetectorResult2(bits, points) {
    this.bits = bits;
    this.points = points;
  }
  DetectorResult2.prototype.getBits = function() {
    return this.bits;
  };
  DetectorResult2.prototype.getPoints = function() {
    return this.points;
  };
  return DetectorResult2;
}();
DetectorResult$1.default = DetectorResult;
var GridSamplerInstance$1 = {};
var DefaultGridSampler$1 = {};
var GridSampler$1 = {};
Object.defineProperty(GridSampler$1, "__esModule", { value: true });
var NotFoundException_1$9 = NotFoundException$1;
var GridSampler = function() {
  function GridSampler2() {
  }
  GridSampler2.checkAndNudgePoints = function(image, points) {
    var width = image.getWidth();
    var height = image.getHeight();
    var nudged = true;
    for (var offset = 0; offset < points.length && nudged; offset += 2) {
      var x = Math.floor(points[offset]);
      var y = Math.floor(points[offset + 1]);
      if (x < -1 || x > width || y < -1 || y > height) {
        throw new NotFoundException_1$9.default();
      }
      nudged = false;
      if (x === -1) {
        points[offset] = 0;
        nudged = true;
      } else if (x === width) {
        points[offset] = width - 1;
        nudged = true;
      }
      if (y === -1) {
        points[offset + 1] = 0;
        nudged = true;
      } else if (y === height) {
        points[offset + 1] = height - 1;
        nudged = true;
      }
    }
    nudged = true;
    for (var offset = points.length - 2; offset >= 0 && nudged; offset -= 2) {
      var x = Math.floor(points[offset]);
      var y = Math.floor(points[offset + 1]);
      if (x < -1 || x > width || y < -1 || y > height) {
        throw new NotFoundException_1$9.default();
      }
      nudged = false;
      if (x === -1) {
        points[offset] = 0;
        nudged = true;
      } else if (x === width) {
        points[offset] = width - 1;
        nudged = true;
      }
      if (y === -1) {
        points[offset + 1] = 0;
        nudged = true;
      } else if (y === height) {
        points[offset + 1] = height - 1;
        nudged = true;
      }
    }
  };
  return GridSampler2;
}();
GridSampler$1.default = GridSampler;
var PerspectiveTransform$1 = {};
Object.defineProperty(PerspectiveTransform$1, "__esModule", { value: true });
var PerspectiveTransform = function() {
  function PerspectiveTransform2(a11, a21, a31, a12, a22, a32, a13, a23, a33) {
    this.a11 = a11;
    this.a21 = a21;
    this.a31 = a31;
    this.a12 = a12;
    this.a22 = a22;
    this.a32 = a32;
    this.a13 = a13;
    this.a23 = a23;
    this.a33 = a33;
  }
  PerspectiveTransform2.quadrilateralToQuadrilateral = function(x0, y0, x1, y1, x2, y2, x3, y3, x0p, y0p, x1p, y1p, x2p, y2p, x3p, y3p) {
    var qToS = PerspectiveTransform2.quadrilateralToSquare(x0, y0, x1, y1, x2, y2, x3, y3);
    var sToQ = PerspectiveTransform2.squareToQuadrilateral(x0p, y0p, x1p, y1p, x2p, y2p, x3p, y3p);
    return sToQ.times(qToS);
  };
  PerspectiveTransform2.prototype.transformPoints = function(points) {
    var max = points.length;
    var a11 = this.a11;
    var a12 = this.a12;
    var a13 = this.a13;
    var a21 = this.a21;
    var a22 = this.a22;
    var a23 = this.a23;
    var a31 = this.a31;
    var a32 = this.a32;
    var a33 = this.a33;
    for (var i = 0; i < max; i += 2) {
      var x = points[i];
      var y = points[i + 1];
      var denominator = a13 * x + a23 * y + a33;
      points[i] = (a11 * x + a21 * y + a31) / denominator;
      points[i + 1] = (a12 * x + a22 * y + a32) / denominator;
    }
  };
  PerspectiveTransform2.prototype.transformPointsWithValues = function(xValues, yValues) {
    var a11 = this.a11;
    var a12 = this.a12;
    var a13 = this.a13;
    var a21 = this.a21;
    var a22 = this.a22;
    var a23 = this.a23;
    var a31 = this.a31;
    var a32 = this.a32;
    var a33 = this.a33;
    var n = xValues.length;
    for (var i = 0; i < n; i++) {
      var x = xValues[i];
      var y = yValues[i];
      var denominator = a13 * x + a23 * y + a33;
      xValues[i] = (a11 * x + a21 * y + a31) / denominator;
      yValues[i] = (a12 * x + a22 * y + a32) / denominator;
    }
  };
  PerspectiveTransform2.squareToQuadrilateral = function(x0, y0, x1, y1, x2, y2, x3, y3) {
    var dx3 = x0 - x1 + x2 - x3;
    var dy3 = y0 - y1 + y2 - y3;
    if (dx3 === 0 && dy3 === 0) {
      return new PerspectiveTransform2(x1 - x0, x2 - x1, x0, y1 - y0, y2 - y1, y0, 0, 0, 1);
    } else {
      var dx1 = x1 - x2;
      var dx2 = x3 - x2;
      var dy1 = y1 - y2;
      var dy2 = y3 - y2;
      var denominator = dx1 * dy2 - dx2 * dy1;
      var a13 = (dx3 * dy2 - dx2 * dy3) / denominator;
      var a23 = (dx1 * dy3 - dx3 * dy1) / denominator;
      return new PerspectiveTransform2(x1 - x0 + a13 * x1, x3 - x0 + a23 * x3, x0, y1 - y0 + a13 * y1, y3 - y0 + a23 * y3, y0, a13, a23, 1);
    }
  };
  PerspectiveTransform2.quadrilateralToSquare = function(x0, y0, x1, y1, x2, y2, x3, y3) {
    return PerspectiveTransform2.squareToQuadrilateral(x0, y0, x1, y1, x2, y2, x3, y3).buildAdjoint();
  };
  PerspectiveTransform2.prototype.buildAdjoint = function() {
    return new PerspectiveTransform2(this.a22 * this.a33 - this.a23 * this.a32, this.a23 * this.a31 - this.a21 * this.a33, this.a21 * this.a32 - this.a22 * this.a31, this.a13 * this.a32 - this.a12 * this.a33, this.a11 * this.a33 - this.a13 * this.a31, this.a12 * this.a31 - this.a11 * this.a32, this.a12 * this.a23 - this.a13 * this.a22, this.a13 * this.a21 - this.a11 * this.a23, this.a11 * this.a22 - this.a12 * this.a21);
  };
  PerspectiveTransform2.prototype.times = function(other) {
    return new PerspectiveTransform2(this.a11 * other.a11 + this.a21 * other.a12 + this.a31 * other.a13, this.a11 * other.a21 + this.a21 * other.a22 + this.a31 * other.a23, this.a11 * other.a31 + this.a21 * other.a32 + this.a31 * other.a33, this.a12 * other.a11 + this.a22 * other.a12 + this.a32 * other.a13, this.a12 * other.a21 + this.a22 * other.a22 + this.a32 * other.a23, this.a12 * other.a31 + this.a22 * other.a32 + this.a32 * other.a33, this.a13 * other.a11 + this.a23 * other.a12 + this.a33 * other.a13, this.a13 * other.a21 + this.a23 * other.a22 + this.a33 * other.a23, this.a13 * other.a31 + this.a23 * other.a32 + this.a33 * other.a33);
  };
  return PerspectiveTransform2;
}();
PerspectiveTransform$1.default = PerspectiveTransform;
var __extends$9 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(DefaultGridSampler$1, "__esModule", { value: true });
var GridSampler_1 = GridSampler$1;
var BitMatrix_1$5 = BitMatrix$1;
var PerspectiveTransform_1$1 = PerspectiveTransform$1;
var NotFoundException_1$8 = NotFoundException$1;
var DefaultGridSampler = function(_super) {
  __extends$9(DefaultGridSampler2, _super);
  function DefaultGridSampler2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  DefaultGridSampler2.prototype.sampleGrid = function(image, dimensionX, dimensionY, p1ToX, p1ToY, p2ToX, p2ToY, p3ToX, p3ToY, p4ToX, p4ToY, p1FromX, p1FromY, p2FromX, p2FromY, p3FromX, p3FromY, p4FromX, p4FromY) {
    var transform = PerspectiveTransform_1$1.default.quadrilateralToQuadrilateral(p1ToX, p1ToY, p2ToX, p2ToY, p3ToX, p3ToY, p4ToX, p4ToY, p1FromX, p1FromY, p2FromX, p2FromY, p3FromX, p3FromY, p4FromX, p4FromY);
    return this.sampleGridWithTransform(image, dimensionX, dimensionY, transform);
  };
  DefaultGridSampler2.prototype.sampleGridWithTransform = function(image, dimensionX, dimensionY, transform) {
    if (dimensionX <= 0 || dimensionY <= 0) {
      throw new NotFoundException_1$8.default();
    }
    var bits = new BitMatrix_1$5.default(dimensionX, dimensionY);
    var points = new Float32Array(2 * dimensionX);
    for (var y = 0; y < dimensionY; y++) {
      var max = points.length;
      var iValue = y + 0.5;
      for (var x = 0; x < max; x += 2) {
        points[x] = x / 2 + 0.5;
        points[x + 1] = iValue;
      }
      transform.transformPoints(points);
      GridSampler_1.default.checkAndNudgePoints(image, points);
      try {
        for (var x = 0; x < max; x += 2) {
          if (image.get(Math.floor(points[x]), Math.floor(points[x + 1]))) {
            bits.set(x / 2, y);
          }
        }
      } catch (aioobe) {
        throw new NotFoundException_1$8.default();
      }
    }
    return bits;
  };
  return DefaultGridSampler2;
}(GridSampler_1.default);
DefaultGridSampler$1.default = DefaultGridSampler;
Object.defineProperty(GridSamplerInstance$1, "__esModule", { value: true });
var DefaultGridSampler_1 = DefaultGridSampler$1;
var GridSamplerInstance = function() {
  function GridSamplerInstance2() {
  }
  GridSamplerInstance2.setGridSampler = function(newGridSampler) {
    GridSamplerInstance2.gridSampler = newGridSampler;
  };
  GridSamplerInstance2.getInstance = function() {
    return GridSamplerInstance2.gridSampler;
  };
  GridSamplerInstance2.gridSampler = new DefaultGridSampler_1.default();
  return GridSamplerInstance2;
}();
GridSamplerInstance$1.default = GridSamplerInstance;
var WhiteRectangleDetector$1 = {};
Object.defineProperty(WhiteRectangleDetector$1, "__esModule", { value: true });
var ResultPoint_1$5 = ResultPoint$1;
var MathUtils_1$2 = MathUtils$1;
var NotFoundException_1$7 = NotFoundException$1;
var WhiteRectangleDetector = function() {
  function WhiteRectangleDetector2(image, initSize, x, y) {
    this.image = image;
    this.height = image.getHeight();
    this.width = image.getWidth();
    if (void 0 === initSize || null === initSize) {
      initSize = WhiteRectangleDetector2.INIT_SIZE;
    }
    if (void 0 === x || null === x) {
      x = image.getWidth() / 2 | 0;
    }
    if (void 0 === y || null === y) {
      y = image.getHeight() / 2 | 0;
    }
    var halfsize = initSize / 2 | 0;
    this.leftInit = x - halfsize;
    this.rightInit = x + halfsize;
    this.upInit = y - halfsize;
    this.downInit = y + halfsize;
    if (this.upInit < 0 || this.leftInit < 0 || this.downInit >= this.height || this.rightInit >= this.width) {
      throw new NotFoundException_1$7.default();
    }
  }
  WhiteRectangleDetector2.prototype.detect = function() {
    var left = this.leftInit;
    var right = this.rightInit;
    var up = this.upInit;
    var down = this.downInit;
    var sizeExceeded = false;
    var aBlackPointFoundOnBorder = true;
    var atLeastOneBlackPointFoundOnBorder = false;
    var atLeastOneBlackPointFoundOnRight = false;
    var atLeastOneBlackPointFoundOnBottom = false;
    var atLeastOneBlackPointFoundOnLeft = false;
    var atLeastOneBlackPointFoundOnTop = false;
    var width = this.width;
    var height = this.height;
    while (aBlackPointFoundOnBorder) {
      aBlackPointFoundOnBorder = false;
      var rightBorderNotWhite = true;
      while ((rightBorderNotWhite || !atLeastOneBlackPointFoundOnRight) && right < width) {
        rightBorderNotWhite = this.containsBlackPoint(up, down, right, false);
        if (rightBorderNotWhite) {
          right++;
          aBlackPointFoundOnBorder = true;
          atLeastOneBlackPointFoundOnRight = true;
        } else if (!atLeastOneBlackPointFoundOnRight) {
          right++;
        }
      }
      if (right >= width) {
        sizeExceeded = true;
        break;
      }
      var bottomBorderNotWhite = true;
      while ((bottomBorderNotWhite || !atLeastOneBlackPointFoundOnBottom) && down < height) {
        bottomBorderNotWhite = this.containsBlackPoint(left, right, down, true);
        if (bottomBorderNotWhite) {
          down++;
          aBlackPointFoundOnBorder = true;
          atLeastOneBlackPointFoundOnBottom = true;
        } else if (!atLeastOneBlackPointFoundOnBottom) {
          down++;
        }
      }
      if (down >= height) {
        sizeExceeded = true;
        break;
      }
      var leftBorderNotWhite = true;
      while ((leftBorderNotWhite || !atLeastOneBlackPointFoundOnLeft) && left >= 0) {
        leftBorderNotWhite = this.containsBlackPoint(up, down, left, false);
        if (leftBorderNotWhite) {
          left--;
          aBlackPointFoundOnBorder = true;
          atLeastOneBlackPointFoundOnLeft = true;
        } else if (!atLeastOneBlackPointFoundOnLeft) {
          left--;
        }
      }
      if (left < 0) {
        sizeExceeded = true;
        break;
      }
      var topBorderNotWhite = true;
      while ((topBorderNotWhite || !atLeastOneBlackPointFoundOnTop) && up >= 0) {
        topBorderNotWhite = this.containsBlackPoint(left, right, up, true);
        if (topBorderNotWhite) {
          up--;
          aBlackPointFoundOnBorder = true;
          atLeastOneBlackPointFoundOnTop = true;
        } else if (!atLeastOneBlackPointFoundOnTop) {
          up--;
        }
      }
      if (up < 0) {
        sizeExceeded = true;
        break;
      }
      if (aBlackPointFoundOnBorder) {
        atLeastOneBlackPointFoundOnBorder = true;
      }
    }
    if (!sizeExceeded && atLeastOneBlackPointFoundOnBorder) {
      var maxSize = right - left;
      var z = null;
      for (var i = 1; z === null && i < maxSize; i++) {
        z = this.getBlackPointOnSegment(left, down - i, left + i, down);
      }
      if (z == null) {
        throw new NotFoundException_1$7.default();
      }
      var t = null;
      for (var i = 1; t === null && i < maxSize; i++) {
        t = this.getBlackPointOnSegment(left, up + i, left + i, up);
      }
      if (t == null) {
        throw new NotFoundException_1$7.default();
      }
      var x = null;
      for (var i = 1; x === null && i < maxSize; i++) {
        x = this.getBlackPointOnSegment(right, up + i, right - i, up);
      }
      if (x == null) {
        throw new NotFoundException_1$7.default();
      }
      var y = null;
      for (var i = 1; y === null && i < maxSize; i++) {
        y = this.getBlackPointOnSegment(right, down - i, right - i, down);
      }
      if (y == null) {
        throw new NotFoundException_1$7.default();
      }
      return this.centerEdges(y, z, x, t);
    } else {
      throw new NotFoundException_1$7.default();
    }
  };
  WhiteRectangleDetector2.prototype.getBlackPointOnSegment = function(aX, aY, bX, bY) {
    var dist = MathUtils_1$2.default.round(MathUtils_1$2.default.distance(aX, aY, bX, bY));
    var xStep = (bX - aX) / dist;
    var yStep = (bY - aY) / dist;
    var image = this.image;
    for (var i = 0; i < dist; i++) {
      var x = MathUtils_1$2.default.round(aX + i * xStep);
      var y = MathUtils_1$2.default.round(aY + i * yStep);
      if (image.get(x, y)) {
        return new ResultPoint_1$5.default(x, y);
      }
    }
    return null;
  };
  WhiteRectangleDetector2.prototype.centerEdges = function(y, z, x, t) {
    var yi = y.getX();
    var yj = y.getY();
    var zi = z.getX();
    var zj = z.getY();
    var xi = x.getX();
    var xj = x.getY();
    var ti = t.getX();
    var tj = t.getY();
    var CORR = WhiteRectangleDetector2.CORR;
    if (yi < this.width / 2) {
      return [
        new ResultPoint_1$5.default(ti - CORR, tj + CORR),
        new ResultPoint_1$5.default(zi + CORR, zj + CORR),
        new ResultPoint_1$5.default(xi - CORR, xj - CORR),
        new ResultPoint_1$5.default(yi + CORR, yj - CORR)
      ];
    } else {
      return [
        new ResultPoint_1$5.default(ti + CORR, tj + CORR),
        new ResultPoint_1$5.default(zi + CORR, zj - CORR),
        new ResultPoint_1$5.default(xi - CORR, xj + CORR),
        new ResultPoint_1$5.default(yi - CORR, yj - CORR)
      ];
    }
  };
  WhiteRectangleDetector2.prototype.containsBlackPoint = function(a, b, fixed, horizontal) {
    var image = this.image;
    if (horizontal) {
      for (var x = a; x <= b; x++) {
        if (image.get(x, fixed)) {
          return true;
        }
      }
    } else {
      for (var y = a; y <= b; y++) {
        if (image.get(fixed, y)) {
          return true;
        }
      }
    }
    return false;
  };
  WhiteRectangleDetector2.INIT_SIZE = 10;
  WhiteRectangleDetector2.CORR = 1;
  return WhiteRectangleDetector2;
}();
WhiteRectangleDetector$1.default = WhiteRectangleDetector;
Object.defineProperty(Detector$3, "__esModule", { value: true });
var ResultPoint_1$4 = ResultPoint$1;
var DetectorResult_1$1 = DetectorResult$1;
var GridSamplerInstance_1$1 = GridSamplerInstance$1;
var MathUtils_1$1 = MathUtils$1;
var WhiteRectangleDetector_1 = WhiteRectangleDetector$1;
var NotFoundException_1$6 = NotFoundException$1;
var Detector$2 = function() {
  function Detector2(image) {
    this.image = image;
    this.rectangleDetector = new WhiteRectangleDetector_1.default(image);
  }
  Detector2.prototype.detect = function() {
    var cornerPoints = this.rectangleDetector.detect();
    var pointA = cornerPoints[0];
    var pointB = cornerPoints[1];
    var pointC = cornerPoints[2];
    var pointD = cornerPoints[3];
    var transitions = [];
    transitions.push(this.transitionsBetween(pointA, pointB));
    transitions.push(this.transitionsBetween(pointA, pointC));
    transitions.push(this.transitionsBetween(pointB, pointD));
    transitions.push(this.transitionsBetween(pointC, pointD));
    transitions.sort(ResultPointsAndTransitions.resultPointsAndTransitionsComparator);
    var lSideOne = transitions[0];
    var lSideTwo = transitions[1];
    var pointCount = /* @__PURE__ */ new Map();
    Detector2.increment(pointCount, lSideOne.getFrom());
    Detector2.increment(pointCount, lSideOne.getTo());
    Detector2.increment(pointCount, lSideTwo.getFrom());
    Detector2.increment(pointCount, lSideTwo.getTo());
    var maybeTopLeft = null;
    var bottomLeft = null;
    var maybeBottomRight = null;
    for (var _i = 0, _a = Array.from(pointCount.entries()); _i < _a.length; _i++) {
      var _b = _a[_i], point = _b[0], value = _b[1];
      if (value === 2) {
        bottomLeft = point;
      } else {
        if (maybeTopLeft == null) {
          maybeTopLeft = point;
        } else {
          maybeBottomRight = point;
        }
      }
    }
    if (maybeTopLeft == null || bottomLeft == null || maybeBottomRight == null) {
      throw new NotFoundException_1$6.default();
    }
    var corners = [maybeTopLeft, bottomLeft, maybeBottomRight];
    ResultPoint_1$4.default.orderBestPatterns(corners);
    var bottomRight = corners[0];
    bottomLeft = corners[1];
    var topLeft = corners[2];
    var topRight;
    if (!pointCount.has(pointA)) {
      topRight = pointA;
    } else if (!pointCount.has(pointB)) {
      topRight = pointB;
    } else if (!pointCount.has(pointC)) {
      topRight = pointC;
    } else {
      topRight = pointD;
    }
    var dimensionTop = this.transitionsBetween(topLeft, topRight).getTransitions();
    var dimensionRight = this.transitionsBetween(bottomRight, topRight).getTransitions();
    if ((dimensionTop & 1) === 1) {
      dimensionTop++;
    }
    dimensionTop += 2;
    if ((dimensionRight & 1) === 1) {
      dimensionRight++;
    }
    dimensionRight += 2;
    var bits;
    var correctedTopRight;
    if (4 * dimensionTop >= 7 * dimensionRight || 4 * dimensionRight >= 7 * dimensionTop) {
      correctedTopRight = this.correctTopRightRectangular(bottomLeft, bottomRight, topLeft, topRight, dimensionTop, dimensionRight);
      if (correctedTopRight == null) {
        correctedTopRight = topRight;
      }
      dimensionTop = this.transitionsBetween(topLeft, correctedTopRight).getTransitions();
      dimensionRight = this.transitionsBetween(bottomRight, correctedTopRight).getTransitions();
      if ((dimensionTop & 1) === 1) {
        dimensionTop++;
      }
      if ((dimensionRight & 1) === 1) {
        dimensionRight++;
      }
      bits = Detector2.sampleGrid(this.image, topLeft, bottomLeft, bottomRight, correctedTopRight, dimensionTop, dimensionRight);
    } else {
      var dimension = Math.min(dimensionRight, dimensionTop);
      correctedTopRight = this.correctTopRight(bottomLeft, bottomRight, topLeft, topRight, dimension);
      if (correctedTopRight == null) {
        correctedTopRight = topRight;
      }
      var dimensionCorrected = Math.max(this.transitionsBetween(topLeft, correctedTopRight).getTransitions(), this.transitionsBetween(bottomRight, correctedTopRight).getTransitions());
      dimensionCorrected++;
      if ((dimensionCorrected & 1) === 1) {
        dimensionCorrected++;
      }
      bits = Detector2.sampleGrid(this.image, topLeft, bottomLeft, bottomRight, correctedTopRight, dimensionCorrected, dimensionCorrected);
    }
    return new DetectorResult_1$1.default(bits, [topLeft, bottomLeft, bottomRight, correctedTopRight]);
  };
  Detector2.prototype.correctTopRightRectangular = function(bottomLeft, bottomRight, topLeft, topRight, dimensionTop, dimensionRight) {
    var corr = Detector2.distance(bottomLeft, bottomRight) / dimensionTop;
    var norm = Detector2.distance(topLeft, topRight);
    var cos = (topRight.getX() - topLeft.getX()) / norm;
    var sin = (topRight.getY() - topLeft.getY()) / norm;
    var c1 = new ResultPoint_1$4.default(topRight.getX() + corr * cos, topRight.getY() + corr * sin);
    corr = Detector2.distance(bottomLeft, topLeft) / dimensionRight;
    norm = Detector2.distance(bottomRight, topRight);
    cos = (topRight.getX() - bottomRight.getX()) / norm;
    sin = (topRight.getY() - bottomRight.getY()) / norm;
    var c2 = new ResultPoint_1$4.default(topRight.getX() + corr * cos, topRight.getY() + corr * sin);
    if (!this.isValid(c1)) {
      if (this.isValid(c2)) {
        return c2;
      }
      return null;
    }
    if (!this.isValid(c2)) {
      return c1;
    }
    var l1 = Math.abs(dimensionTop - this.transitionsBetween(topLeft, c1).getTransitions()) + Math.abs(dimensionRight - this.transitionsBetween(bottomRight, c1).getTransitions());
    var l2 = Math.abs(dimensionTop - this.transitionsBetween(topLeft, c2).getTransitions()) + Math.abs(dimensionRight - this.transitionsBetween(bottomRight, c2).getTransitions());
    if (l1 <= l2) {
      return c1;
    }
    return c2;
  };
  Detector2.prototype.correctTopRight = function(bottomLeft, bottomRight, topLeft, topRight, dimension) {
    var corr = Detector2.distance(bottomLeft, bottomRight) / dimension;
    var norm = Detector2.distance(topLeft, topRight);
    var cos = (topRight.getX() - topLeft.getX()) / norm;
    var sin = (topRight.getY() - topLeft.getY()) / norm;
    var c1 = new ResultPoint_1$4.default(topRight.getX() + corr * cos, topRight.getY() + corr * sin);
    corr = Detector2.distance(bottomLeft, topLeft) / dimension;
    norm = Detector2.distance(bottomRight, topRight);
    cos = (topRight.getX() - bottomRight.getX()) / norm;
    sin = (topRight.getY() - bottomRight.getY()) / norm;
    var c2 = new ResultPoint_1$4.default(topRight.getX() + corr * cos, topRight.getY() + corr * sin);
    if (!this.isValid(c1)) {
      if (this.isValid(c2)) {
        return c2;
      }
      return null;
    }
    if (!this.isValid(c2)) {
      return c1;
    }
    var l1 = Math.abs(this.transitionsBetween(topLeft, c1).getTransitions() - this.transitionsBetween(bottomRight, c1).getTransitions());
    var l2 = Math.abs(this.transitionsBetween(topLeft, c2).getTransitions() - this.transitionsBetween(bottomRight, c2).getTransitions());
    return l1 <= l2 ? c1 : c2;
  };
  Detector2.prototype.isValid = function(p) {
    return p.getX() >= 0 && p.getX() < this.image.getWidth() && p.getY() > 0 && p.getY() < this.image.getHeight();
  };
  Detector2.distance = function(a, b) {
    return MathUtils_1$1.default.round(ResultPoint_1$4.default.distance(a, b));
  };
  Detector2.increment = function(table, key) {
    var value = table.get(key);
    table.set(key, value == null ? 1 : value + 1);
  };
  Detector2.sampleGrid = function(image, topLeft, bottomLeft, bottomRight, topRight, dimensionX, dimensionY) {
    var sampler = GridSamplerInstance_1$1.default.getInstance();
    return sampler.sampleGrid(image, dimensionX, dimensionY, 0.5, 0.5, dimensionX - 0.5, 0.5, dimensionX - 0.5, dimensionY - 0.5, 0.5, dimensionY - 0.5, topLeft.getX(), topLeft.getY(), topRight.getX(), topRight.getY(), bottomRight.getX(), bottomRight.getY(), bottomLeft.getX(), bottomLeft.getY());
  };
  Detector2.prototype.transitionsBetween = function(from, to) {
    var fromX = from.getX() | 0;
    var fromY = from.getY() | 0;
    var toX = to.getX() | 0;
    var toY = to.getY() | 0;
    var steep = Math.abs(toY - fromY) > Math.abs(toX - fromX);
    if (steep) {
      var temp = fromX;
      fromX = fromY;
      fromY = temp;
      temp = toX;
      toX = toY;
      toY = temp;
    }
    var dx = Math.abs(toX - fromX);
    var dy = Math.abs(toY - fromY);
    var error = -dx / 2;
    var ystep = fromY < toY ? 1 : -1;
    var xstep = fromX < toX ? 1 : -1;
    var transitions = 0;
    var inBlack = this.image.get(steep ? fromY : fromX, steep ? fromX : fromY);
    for (var x = fromX, y = fromY; x !== toX; x += xstep) {
      var isBlack = this.image.get(steep ? y : x, steep ? x : y);
      if (isBlack !== inBlack) {
        transitions++;
        inBlack = isBlack;
      }
      error += dy;
      if (error > 0) {
        if (y === toY) {
          break;
        }
        y += ystep;
        error -= dx;
      }
    }
    return new ResultPointsAndTransitions(from, to, transitions);
  };
  return Detector2;
}();
Detector$3.default = Detector$2;
var ResultPointsAndTransitions = function() {
  function ResultPointsAndTransitions2(from, to, transitions) {
    this.from = from;
    this.to = to;
    this.transitions = transitions;
  }
  ResultPointsAndTransitions2.prototype.getFrom = function() {
    return this.from;
  };
  ResultPointsAndTransitions2.prototype.getTo = function() {
    return this.to;
  };
  ResultPointsAndTransitions2.prototype.getTransitions = function() {
    return this.transitions;
  };
  ResultPointsAndTransitions2.prototype.toString = function() {
    return this.from + "/" + this.to + "/" + this.transitions;
  };
  ResultPointsAndTransitions2.resultPointsAndTransitionsComparator = function(o1, o2) {
    return o1.getTransitions() - o2.getTransitions();
  };
  return ResultPointsAndTransitions2;
}();
Object.defineProperty(DataMatrixReader$1, "__esModule", { value: true });
var Decoder_1$1 = Decoder$3;
var Result_1$1 = Result$1;
var BarcodeFormat_1$4 = BarcodeFormat$1;
var DecodeHintType_1$5 = DecodeHintType$1;
var ResultMetadataType_1$1 = ResultMetadataType$1;
var BitMatrix_1$4 = BitMatrix$1;
var Detector_1$1 = Detector$3;
var System_1$3 = System$1;
var NotFoundException_1$5 = NotFoundException$1;
var DataMatrixReader = function() {
  function DataMatrixReader2() {
    this.decoder = new Decoder_1$1.default();
  }
  DataMatrixReader2.prototype.decode = function(image, hints) {
    if (hints === void 0) {
      hints = null;
    }
    var decoderResult;
    var points;
    if (hints != null && hints.has(DecodeHintType_1$5.default.PURE_BARCODE)) {
      var bits = DataMatrixReader2.extractPureBits(image.getBlackMatrix());
      decoderResult = this.decoder.decode(bits);
      points = DataMatrixReader2.NO_POINTS;
    } else {
      var detectorResult = new Detector_1$1.default(image.getBlackMatrix()).detect();
      decoderResult = this.decoder.decode(detectorResult.getBits());
      points = detectorResult.getPoints();
    }
    var rawBytes = decoderResult.getRawBytes();
    var result = new Result_1$1.default(decoderResult.getText(), rawBytes, 8 * rawBytes.length, points, BarcodeFormat_1$4.default.DATA_MATRIX, System_1$3.default.currentTimeMillis());
    var byteSegments = decoderResult.getByteSegments();
    if (byteSegments != null) {
      result.putMetadata(ResultMetadataType_1$1.default.BYTE_SEGMENTS, byteSegments);
    }
    var ecLevel = decoderResult.getECLevel();
    if (ecLevel != null) {
      result.putMetadata(ResultMetadataType_1$1.default.ERROR_CORRECTION_LEVEL, ecLevel);
    }
    return result;
  };
  DataMatrixReader2.prototype.reset = function() {
  };
  DataMatrixReader2.extractPureBits = function(image) {
    var leftTopBlack = image.getTopLeftOnBit();
    var rightBottomBlack = image.getBottomRightOnBit();
    if (leftTopBlack == null || rightBottomBlack == null) {
      throw new NotFoundException_1$5.default();
    }
    var moduleSize = this.moduleSize(leftTopBlack, image);
    var top = leftTopBlack[1];
    var bottom = rightBottomBlack[1];
    var left = leftTopBlack[0];
    var right = rightBottomBlack[0];
    var matrixWidth = (right - left + 1) / moduleSize;
    var matrixHeight = (bottom - top + 1) / moduleSize;
    if (matrixWidth <= 0 || matrixHeight <= 0) {
      throw new NotFoundException_1$5.default();
    }
    var nudge = moduleSize / 2;
    top += nudge;
    left += nudge;
    var bits = new BitMatrix_1$4.default(matrixWidth, matrixHeight);
    for (var y = 0; y < matrixHeight; y++) {
      var iOffset = top + y * moduleSize;
      for (var x = 0; x < matrixWidth; x++) {
        if (image.get(left + x * moduleSize, iOffset)) {
          bits.set(x, y);
        }
      }
    }
    return bits;
  };
  DataMatrixReader2.moduleSize = function(leftTopBlack, image) {
    var width = image.getWidth();
    var x = leftTopBlack[0];
    var y = leftTopBlack[1];
    while (x < width && image.get(x, y)) {
      x++;
    }
    if (x === width) {
      throw new NotFoundException_1$5.default();
    }
    var moduleSize = x - leftTopBlack[0];
    if (moduleSize === 0) {
      throw new NotFoundException_1$5.default();
    }
    return moduleSize;
  };
  DataMatrixReader2.NO_POINTS = [];
  return DataMatrixReader2;
}();
DataMatrixReader$1.default = DataMatrixReader;
var __extends$8 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(BrowserDatamatrixCodeReader$1, "__esModule", { value: true });
var BrowserCodeReader_1$2 = BrowserCodeReader$1;
var DataMatrixReader_1$1 = DataMatrixReader$1;
var BrowserDatamatrixCodeReader = function(_super) {
  __extends$8(BrowserDatamatrixCodeReader2, _super);
  function BrowserDatamatrixCodeReader2(timeBetweenScansMillis) {
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    return _super.call(this, new DataMatrixReader_1$1.default(), timeBetweenScansMillis) || this;
  }
  return BrowserDatamatrixCodeReader2;
}(BrowserCodeReader_1$2.BrowserCodeReader);
BrowserDatamatrixCodeReader$1.BrowserDatamatrixCodeReader = BrowserDatamatrixCodeReader;
var BrowserMultiFormatReader$1 = {};
var MultiFormatReader$1 = {};
var QRCodeReader$1 = {};
var Decoder$1 = {};
var BitMatrixParser$1 = {};
var Version$1 = {};
var FormatInformation$1 = {};
var ErrorCorrectionLevel = {};
(function(exports) {
  Object.defineProperty(exports, "__esModule", { value: true });
  var ArgumentException_12 = ArgumentException$1;
  var IllegalArgumentException_12 = IllegalArgumentException$1;
  var ErrorCorrectionLevelValues;
  (function(ErrorCorrectionLevelValues2) {
    ErrorCorrectionLevelValues2[ErrorCorrectionLevelValues2["L"] = 0] = "L";
    ErrorCorrectionLevelValues2[ErrorCorrectionLevelValues2["M"] = 1] = "M";
    ErrorCorrectionLevelValues2[ErrorCorrectionLevelValues2["Q"] = 2] = "Q";
    ErrorCorrectionLevelValues2[ErrorCorrectionLevelValues2["H"] = 3] = "H";
  })(ErrorCorrectionLevelValues = exports.ErrorCorrectionLevelValues || (exports.ErrorCorrectionLevelValues = {}));
  var ErrorCorrectionLevel2 = function() {
    function ErrorCorrectionLevel3(value, stringValue, bits) {
      this.value = value;
      this.stringValue = stringValue;
      this.bits = bits;
      ErrorCorrectionLevel3.FOR_BITS.set(bits, this);
      ErrorCorrectionLevel3.FOR_VALUE.set(value, this);
    }
    ErrorCorrectionLevel3.prototype.getValue = function() {
      return this.value;
    };
    ErrorCorrectionLevel3.prototype.getBits = function() {
      return this.bits;
    };
    ErrorCorrectionLevel3.fromString = function(s) {
      switch (s) {
        case "L":
          return ErrorCorrectionLevel3.L;
        case "M":
          return ErrorCorrectionLevel3.M;
        case "Q":
          return ErrorCorrectionLevel3.Q;
        case "H":
          return ErrorCorrectionLevel3.H;
        default:
          throw new ArgumentException_12.default(s + "not available");
      }
    };
    ErrorCorrectionLevel3.prototype.toString = function() {
      return this.stringValue;
    };
    ErrorCorrectionLevel3.prototype.equals = function(o) {
      if (!(o instanceof ErrorCorrectionLevel3)) {
        return false;
      }
      var other = o;
      return this.value === other.value;
    };
    ErrorCorrectionLevel3.forBits = function(bits) {
      if (bits < 0 || bits >= ErrorCorrectionLevel3.FOR_BITS.size) {
        throw new IllegalArgumentException_12.default();
      }
      return ErrorCorrectionLevel3.FOR_BITS.get(bits);
    };
    ErrorCorrectionLevel3.FOR_BITS = /* @__PURE__ */ new Map();
    ErrorCorrectionLevel3.FOR_VALUE = /* @__PURE__ */ new Map();
    ErrorCorrectionLevel3.L = new ErrorCorrectionLevel3(ErrorCorrectionLevelValues.L, "L", 1);
    ErrorCorrectionLevel3.M = new ErrorCorrectionLevel3(ErrorCorrectionLevelValues.M, "M", 0);
    ErrorCorrectionLevel3.Q = new ErrorCorrectionLevel3(ErrorCorrectionLevelValues.Q, "Q", 3);
    ErrorCorrectionLevel3.H = new ErrorCorrectionLevel3(ErrorCorrectionLevelValues.H, "H", 2);
    return ErrorCorrectionLevel3;
  }();
  exports.default = ErrorCorrectionLevel2;
})(ErrorCorrectionLevel);
Object.defineProperty(FormatInformation$1, "__esModule", { value: true });
var ErrorCorrectionLevel_1$2 = ErrorCorrectionLevel;
var Integer_1$1 = Integer$1;
var FormatInformation = function() {
  function FormatInformation2(formatInfo) {
    this.errorCorrectionLevel = ErrorCorrectionLevel_1$2.default.forBits(formatInfo >> 3 & 3);
    this.dataMask = formatInfo & 7;
  }
  FormatInformation2.numBitsDiffering = function(a, b) {
    return Integer_1$1.default.bitCount(a ^ b);
  };
  FormatInformation2.decodeFormatInformation = function(maskedFormatInfo1, maskedFormatInfo2) {
    var formatInfo = FormatInformation2.doDecodeFormatInformation(maskedFormatInfo1, maskedFormatInfo2);
    if (formatInfo !== null) {
      return formatInfo;
    }
    return FormatInformation2.doDecodeFormatInformation(maskedFormatInfo1 ^ FormatInformation2.FORMAT_INFO_MASK_QR, maskedFormatInfo2 ^ FormatInformation2.FORMAT_INFO_MASK_QR);
  };
  FormatInformation2.doDecodeFormatInformation = function(maskedFormatInfo1, maskedFormatInfo2) {
    var bestDifference = Number.MAX_SAFE_INTEGER;
    var bestFormatInfo = 0;
    for (var _i = 0, _a = FormatInformation2.FORMAT_INFO_DECODE_LOOKUP; _i < _a.length; _i++) {
      var decodeInfo = _a[_i];
      var targetInfo = decodeInfo[0];
      if (targetInfo === maskedFormatInfo1 || targetInfo === maskedFormatInfo2) {
        return new FormatInformation2(decodeInfo[1]);
      }
      var bitsDifference = FormatInformation2.numBitsDiffering(maskedFormatInfo1, targetInfo);
      if (bitsDifference < bestDifference) {
        bestFormatInfo = decodeInfo[1];
        bestDifference = bitsDifference;
      }
      if (maskedFormatInfo1 !== maskedFormatInfo2) {
        bitsDifference = FormatInformation2.numBitsDiffering(maskedFormatInfo2, targetInfo);
        if (bitsDifference < bestDifference) {
          bestFormatInfo = decodeInfo[1];
          bestDifference = bitsDifference;
        }
      }
    }
    if (bestDifference <= 3) {
      return new FormatInformation2(bestFormatInfo);
    }
    return null;
  };
  FormatInformation2.prototype.getErrorCorrectionLevel = function() {
    return this.errorCorrectionLevel;
  };
  FormatInformation2.prototype.getDataMask = function() {
    return this.dataMask;
  };
  FormatInformation2.prototype.hashCode = function() {
    return this.errorCorrectionLevel.getBits() << 3 | this.dataMask;
  };
  FormatInformation2.prototype.equals = function(o) {
    if (!(o instanceof FormatInformation2)) {
      return false;
    }
    var other = o;
    return this.errorCorrectionLevel === other.errorCorrectionLevel && this.dataMask === other.dataMask;
  };
  FormatInformation2.FORMAT_INFO_MASK_QR = 21522;
  FormatInformation2.FORMAT_INFO_DECODE_LOOKUP = [
    Int32Array.from([21522, 0]),
    Int32Array.from([20773, 1]),
    Int32Array.from([24188, 2]),
    Int32Array.from([23371, 3]),
    Int32Array.from([17913, 4]),
    Int32Array.from([16590, 5]),
    Int32Array.from([20375, 6]),
    Int32Array.from([19104, 7]),
    Int32Array.from([30660, 8]),
    Int32Array.from([29427, 9]),
    Int32Array.from([32170, 10]),
    Int32Array.from([30877, 11]),
    Int32Array.from([26159, 12]),
    Int32Array.from([25368, 13]),
    Int32Array.from([27713, 14]),
    Int32Array.from([26998, 15]),
    Int32Array.from([5769, 16]),
    Int32Array.from([5054, 17]),
    Int32Array.from([7399, 18]),
    Int32Array.from([6608, 19]),
    Int32Array.from([1890, 20]),
    Int32Array.from([597, 21]),
    Int32Array.from([3340, 22]),
    Int32Array.from([2107, 23]),
    Int32Array.from([13663, 24]),
    Int32Array.from([12392, 25]),
    Int32Array.from([16177, 26]),
    Int32Array.from([14854, 27]),
    Int32Array.from([9396, 28]),
    Int32Array.from([8579, 29]),
    Int32Array.from([11994, 30]),
    Int32Array.from([11245, 31])
  ];
  return FormatInformation2;
}();
FormatInformation$1.default = FormatInformation;
var ECBlocks$1 = {};
Object.defineProperty(ECBlocks$1, "__esModule", { value: true });
var ECBlocks = function() {
  function ECBlocks2(ecCodewordsPerBlock) {
    var ecBlocks = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      ecBlocks[_i - 1] = arguments[_i];
    }
    this.ecCodewordsPerBlock = ecCodewordsPerBlock;
    this.ecBlocks = ecBlocks;
  }
  ECBlocks2.prototype.getECCodewordsPerBlock = function() {
    return this.ecCodewordsPerBlock;
  };
  ECBlocks2.prototype.getNumBlocks = function() {
    var total = 0;
    var ecBlocks = this.ecBlocks;
    for (var _i = 0, ecBlocks_1 = ecBlocks; _i < ecBlocks_1.length; _i++) {
      var ecBlock = ecBlocks_1[_i];
      total += ecBlock.getCount();
    }
    return total;
  };
  ECBlocks2.prototype.getTotalECCodewords = function() {
    return this.ecCodewordsPerBlock * this.getNumBlocks();
  };
  ECBlocks2.prototype.getECBlocks = function() {
    return this.ecBlocks;
  };
  return ECBlocks2;
}();
ECBlocks$1.default = ECBlocks;
var ECB$1 = {};
Object.defineProperty(ECB$1, "__esModule", { value: true });
var ECB = function() {
  function ECB2(count, dataCodewords) {
    this.count = count;
    this.dataCodewords = dataCodewords;
  }
  ECB2.prototype.getCount = function() {
    return this.count;
  };
  ECB2.prototype.getDataCodewords = function() {
    return this.dataCodewords;
  };
  return ECB2;
}();
ECB$1.default = ECB;
Object.defineProperty(Version$1, "__esModule", { value: true });
var BitMatrix_1$3 = BitMatrix$1;
var FormatInformation_1$1 = FormatInformation$1;
var ECBlocks_1 = ECBlocks$1;
var ECB_1 = ECB$1;
var FormatException_1$2 = FormatException$1;
var IllegalArgumentException_1$9 = IllegalArgumentException$1;
var Version = function() {
  function Version2(versionNumber, alignmentPatternCenters) {
    var ecBlocks = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      ecBlocks[_i - 2] = arguments[_i];
    }
    this.versionNumber = versionNumber;
    this.alignmentPatternCenters = alignmentPatternCenters;
    this.ecBlocks = ecBlocks;
    var total = 0;
    var ecCodewords = ecBlocks[0].getECCodewordsPerBlock();
    var ecbArray = ecBlocks[0].getECBlocks();
    for (var _a = 0, ecbArray_1 = ecbArray; _a < ecbArray_1.length; _a++) {
      var ecBlock = ecbArray_1[_a];
      total += ecBlock.getCount() * (ecBlock.getDataCodewords() + ecCodewords);
    }
    this.totalCodewords = total;
  }
  Version2.prototype.getVersionNumber = function() {
    return this.versionNumber;
  };
  Version2.prototype.getAlignmentPatternCenters = function() {
    return this.alignmentPatternCenters;
  };
  Version2.prototype.getTotalCodewords = function() {
    return this.totalCodewords;
  };
  Version2.prototype.getDimensionForVersion = function() {
    return 17 + 4 * this.versionNumber;
  };
  Version2.prototype.getECBlocksForLevel = function(ecLevel) {
    return this.ecBlocks[ecLevel.getValue()];
  };
  Version2.getProvisionalVersionForDimension = function(dimension) {
    if (dimension % 4 !== 1) {
      throw new FormatException_1$2.default();
    }
    try {
      return this.getVersionForNumber((dimension - 17) / 4);
    } catch (ignored) {
      throw new FormatException_1$2.default();
    }
  };
  Version2.getVersionForNumber = function(versionNumber) {
    if (versionNumber < 1 || versionNumber > 40) {
      throw new IllegalArgumentException_1$9.default();
    }
    return Version2.VERSIONS[versionNumber - 1];
  };
  Version2.decodeVersionInformation = function(versionBits) {
    var bestDifference = Number.MAX_SAFE_INTEGER;
    var bestVersion = 0;
    for (var i = 0; i < Version2.VERSION_DECODE_INFO.length; i++) {
      var targetVersion = Version2.VERSION_DECODE_INFO[i];
      if (targetVersion === versionBits) {
        return Version2.getVersionForNumber(i + 7);
      }
      var bitsDifference = FormatInformation_1$1.default.numBitsDiffering(versionBits, targetVersion);
      if (bitsDifference < bestDifference) {
        bestVersion = i + 7;
        bestDifference = bitsDifference;
      }
    }
    if (bestDifference <= 3) {
      return Version2.getVersionForNumber(bestVersion);
    }
    return null;
  };
  Version2.prototype.buildFunctionPattern = function() {
    var dimension = this.getDimensionForVersion();
    var bitMatrix = new BitMatrix_1$3.default(dimension);
    bitMatrix.setRegion(0, 0, 9, 9);
    bitMatrix.setRegion(dimension - 8, 0, 8, 9);
    bitMatrix.setRegion(0, dimension - 8, 9, 8);
    var max = this.alignmentPatternCenters.length;
    for (var x = 0; x < max; x++) {
      var i = this.alignmentPatternCenters[x] - 2;
      for (var y = 0; y < max; y++) {
        if (x === 0 && (y === 0 || y === max - 1) || x === max - 1 && y === 0) {
          continue;
        }
        bitMatrix.setRegion(this.alignmentPatternCenters[y] - 2, i, 5, 5);
      }
    }
    bitMatrix.setRegion(6, 9, 1, dimension - 17);
    bitMatrix.setRegion(9, 6, dimension - 17, 1);
    if (this.versionNumber > 6) {
      bitMatrix.setRegion(dimension - 11, 0, 3, 6);
      bitMatrix.setRegion(0, dimension - 11, 6, 3);
    }
    return bitMatrix;
  };
  Version2.prototype.toString = function() {
    return "" + this.versionNumber;
  };
  Version2.VERSION_DECODE_INFO = Int32Array.from([
    31892,
    34236,
    39577,
    42195,
    48118,
    51042,
    55367,
    58893,
    63784,
    68472,
    70749,
    76311,
    79154,
    84390,
    87683,
    92361,
    96236,
    102084,
    102881,
    110507,
    110734,
    117786,
    119615,
    126325,
    127568,
    133589,
    136944,
    141498,
    145311,
    150283,
    152622,
    158308,
    161089,
    167017
  ]);
  Version2.VERSIONS = [
    new Version2(1, new Int32Array(0), new ECBlocks_1.default(7, new ECB_1.default(1, 19)), new ECBlocks_1.default(10, new ECB_1.default(1, 16)), new ECBlocks_1.default(13, new ECB_1.default(1, 13)), new ECBlocks_1.default(17, new ECB_1.default(1, 9))),
    new Version2(2, Int32Array.from([6, 18]), new ECBlocks_1.default(10, new ECB_1.default(1, 34)), new ECBlocks_1.default(16, new ECB_1.default(1, 28)), new ECBlocks_1.default(22, new ECB_1.default(1, 22)), new ECBlocks_1.default(28, new ECB_1.default(1, 16))),
    new Version2(3, Int32Array.from([6, 22]), new ECBlocks_1.default(15, new ECB_1.default(1, 55)), new ECBlocks_1.default(26, new ECB_1.default(1, 44)), new ECBlocks_1.default(18, new ECB_1.default(2, 17)), new ECBlocks_1.default(22, new ECB_1.default(2, 13))),
    new Version2(4, Int32Array.from([6, 26]), new ECBlocks_1.default(20, new ECB_1.default(1, 80)), new ECBlocks_1.default(18, new ECB_1.default(2, 32)), new ECBlocks_1.default(26, new ECB_1.default(2, 24)), new ECBlocks_1.default(16, new ECB_1.default(4, 9))),
    new Version2(5, Int32Array.from([6, 30]), new ECBlocks_1.default(26, new ECB_1.default(1, 108)), new ECBlocks_1.default(24, new ECB_1.default(2, 43)), new ECBlocks_1.default(18, new ECB_1.default(2, 15), new ECB_1.default(2, 16)), new ECBlocks_1.default(22, new ECB_1.default(2, 11), new ECB_1.default(2, 12))),
    new Version2(6, Int32Array.from([6, 34]), new ECBlocks_1.default(18, new ECB_1.default(2, 68)), new ECBlocks_1.default(16, new ECB_1.default(4, 27)), new ECBlocks_1.default(24, new ECB_1.default(4, 19)), new ECBlocks_1.default(28, new ECB_1.default(4, 15))),
    new Version2(7, Int32Array.from([6, 22, 38]), new ECBlocks_1.default(20, new ECB_1.default(2, 78)), new ECBlocks_1.default(18, new ECB_1.default(4, 31)), new ECBlocks_1.default(18, new ECB_1.default(2, 14), new ECB_1.default(4, 15)), new ECBlocks_1.default(26, new ECB_1.default(4, 13), new ECB_1.default(1, 14))),
    new Version2(8, Int32Array.from([6, 24, 42]), new ECBlocks_1.default(24, new ECB_1.default(2, 97)), new ECBlocks_1.default(22, new ECB_1.default(2, 38), new ECB_1.default(2, 39)), new ECBlocks_1.default(22, new ECB_1.default(4, 18), new ECB_1.default(2, 19)), new ECBlocks_1.default(26, new ECB_1.default(4, 14), new ECB_1.default(2, 15))),
    new Version2(9, Int32Array.from([6, 26, 46]), new ECBlocks_1.default(30, new ECB_1.default(2, 116)), new ECBlocks_1.default(22, new ECB_1.default(3, 36), new ECB_1.default(2, 37)), new ECBlocks_1.default(20, new ECB_1.default(4, 16), new ECB_1.default(4, 17)), new ECBlocks_1.default(24, new ECB_1.default(4, 12), new ECB_1.default(4, 13))),
    new Version2(10, Int32Array.from([6, 28, 50]), new ECBlocks_1.default(18, new ECB_1.default(2, 68), new ECB_1.default(2, 69)), new ECBlocks_1.default(26, new ECB_1.default(4, 43), new ECB_1.default(1, 44)), new ECBlocks_1.default(24, new ECB_1.default(6, 19), new ECB_1.default(2, 20)), new ECBlocks_1.default(28, new ECB_1.default(6, 15), new ECB_1.default(2, 16))),
    new Version2(11, Int32Array.from([6, 30, 54]), new ECBlocks_1.default(20, new ECB_1.default(4, 81)), new ECBlocks_1.default(30, new ECB_1.default(1, 50), new ECB_1.default(4, 51)), new ECBlocks_1.default(28, new ECB_1.default(4, 22), new ECB_1.default(4, 23)), new ECBlocks_1.default(24, new ECB_1.default(3, 12), new ECB_1.default(8, 13))),
    new Version2(12, Int32Array.from([6, 32, 58]), new ECBlocks_1.default(24, new ECB_1.default(2, 92), new ECB_1.default(2, 93)), new ECBlocks_1.default(22, new ECB_1.default(6, 36), new ECB_1.default(2, 37)), new ECBlocks_1.default(26, new ECB_1.default(4, 20), new ECB_1.default(6, 21)), new ECBlocks_1.default(28, new ECB_1.default(7, 14), new ECB_1.default(4, 15))),
    new Version2(13, Int32Array.from([6, 34, 62]), new ECBlocks_1.default(26, new ECB_1.default(4, 107)), new ECBlocks_1.default(22, new ECB_1.default(8, 37), new ECB_1.default(1, 38)), new ECBlocks_1.default(24, new ECB_1.default(8, 20), new ECB_1.default(4, 21)), new ECBlocks_1.default(22, new ECB_1.default(12, 11), new ECB_1.default(4, 12))),
    new Version2(14, Int32Array.from([6, 26, 46, 66]), new ECBlocks_1.default(30, new ECB_1.default(3, 115), new ECB_1.default(1, 116)), new ECBlocks_1.default(24, new ECB_1.default(4, 40), new ECB_1.default(5, 41)), new ECBlocks_1.default(20, new ECB_1.default(11, 16), new ECB_1.default(5, 17)), new ECBlocks_1.default(24, new ECB_1.default(11, 12), new ECB_1.default(5, 13))),
    new Version2(15, Int32Array.from([6, 26, 48, 70]), new ECBlocks_1.default(22, new ECB_1.default(5, 87), new ECB_1.default(1, 88)), new ECBlocks_1.default(24, new ECB_1.default(5, 41), new ECB_1.default(5, 42)), new ECBlocks_1.default(30, new ECB_1.default(5, 24), new ECB_1.default(7, 25)), new ECBlocks_1.default(24, new ECB_1.default(11, 12), new ECB_1.default(7, 13))),
    new Version2(16, Int32Array.from([6, 26, 50, 74]), new ECBlocks_1.default(24, new ECB_1.default(5, 98), new ECB_1.default(1, 99)), new ECBlocks_1.default(28, new ECB_1.default(7, 45), new ECB_1.default(3, 46)), new ECBlocks_1.default(24, new ECB_1.default(15, 19), new ECB_1.default(2, 20)), new ECBlocks_1.default(30, new ECB_1.default(3, 15), new ECB_1.default(13, 16))),
    new Version2(17, Int32Array.from([6, 30, 54, 78]), new ECBlocks_1.default(28, new ECB_1.default(1, 107), new ECB_1.default(5, 108)), new ECBlocks_1.default(28, new ECB_1.default(10, 46), new ECB_1.default(1, 47)), new ECBlocks_1.default(28, new ECB_1.default(1, 22), new ECB_1.default(15, 23)), new ECBlocks_1.default(28, new ECB_1.default(2, 14), new ECB_1.default(17, 15))),
    new Version2(18, Int32Array.from([6, 30, 56, 82]), new ECBlocks_1.default(30, new ECB_1.default(5, 120), new ECB_1.default(1, 121)), new ECBlocks_1.default(26, new ECB_1.default(9, 43), new ECB_1.default(4, 44)), new ECBlocks_1.default(28, new ECB_1.default(17, 22), new ECB_1.default(1, 23)), new ECBlocks_1.default(28, new ECB_1.default(2, 14), new ECB_1.default(19, 15))),
    new Version2(19, Int32Array.from([6, 30, 58, 86]), new ECBlocks_1.default(28, new ECB_1.default(3, 113), new ECB_1.default(4, 114)), new ECBlocks_1.default(26, new ECB_1.default(3, 44), new ECB_1.default(11, 45)), new ECBlocks_1.default(26, new ECB_1.default(17, 21), new ECB_1.default(4, 22)), new ECBlocks_1.default(26, new ECB_1.default(9, 13), new ECB_1.default(16, 14))),
    new Version2(20, Int32Array.from([6, 34, 62, 90]), new ECBlocks_1.default(28, new ECB_1.default(3, 107), new ECB_1.default(5, 108)), new ECBlocks_1.default(26, new ECB_1.default(3, 41), new ECB_1.default(13, 42)), new ECBlocks_1.default(30, new ECB_1.default(15, 24), new ECB_1.default(5, 25)), new ECBlocks_1.default(28, new ECB_1.default(15, 15), new ECB_1.default(10, 16))),
    new Version2(21, Int32Array.from([6, 28, 50, 72, 94]), new ECBlocks_1.default(28, new ECB_1.default(4, 116), new ECB_1.default(4, 117)), new ECBlocks_1.default(26, new ECB_1.default(17, 42)), new ECBlocks_1.default(28, new ECB_1.default(17, 22), new ECB_1.default(6, 23)), new ECBlocks_1.default(30, new ECB_1.default(19, 16), new ECB_1.default(6, 17))),
    new Version2(22, Int32Array.from([6, 26, 50, 74, 98]), new ECBlocks_1.default(28, new ECB_1.default(2, 111), new ECB_1.default(7, 112)), new ECBlocks_1.default(28, new ECB_1.default(17, 46)), new ECBlocks_1.default(30, new ECB_1.default(7, 24), new ECB_1.default(16, 25)), new ECBlocks_1.default(24, new ECB_1.default(34, 13))),
    new Version2(23, Int32Array.from([6, 30, 54, 78, 102]), new ECBlocks_1.default(30, new ECB_1.default(4, 121), new ECB_1.default(5, 122)), new ECBlocks_1.default(28, new ECB_1.default(4, 47), new ECB_1.default(14, 48)), new ECBlocks_1.default(30, new ECB_1.default(11, 24), new ECB_1.default(14, 25)), new ECBlocks_1.default(30, new ECB_1.default(16, 15), new ECB_1.default(14, 16))),
    new Version2(24, Int32Array.from([6, 28, 54, 80, 106]), new ECBlocks_1.default(30, new ECB_1.default(6, 117), new ECB_1.default(4, 118)), new ECBlocks_1.default(28, new ECB_1.default(6, 45), new ECB_1.default(14, 46)), new ECBlocks_1.default(30, new ECB_1.default(11, 24), new ECB_1.default(16, 25)), new ECBlocks_1.default(30, new ECB_1.default(30, 16), new ECB_1.default(2, 17))),
    new Version2(25, Int32Array.from([6, 32, 58, 84, 110]), new ECBlocks_1.default(26, new ECB_1.default(8, 106), new ECB_1.default(4, 107)), new ECBlocks_1.default(28, new ECB_1.default(8, 47), new ECB_1.default(13, 48)), new ECBlocks_1.default(30, new ECB_1.default(7, 24), new ECB_1.default(22, 25)), new ECBlocks_1.default(30, new ECB_1.default(22, 15), new ECB_1.default(13, 16))),
    new Version2(26, Int32Array.from([6, 30, 58, 86, 114]), new ECBlocks_1.default(28, new ECB_1.default(10, 114), new ECB_1.default(2, 115)), new ECBlocks_1.default(28, new ECB_1.default(19, 46), new ECB_1.default(4, 47)), new ECBlocks_1.default(28, new ECB_1.default(28, 22), new ECB_1.default(6, 23)), new ECBlocks_1.default(30, new ECB_1.default(33, 16), new ECB_1.default(4, 17))),
    new Version2(27, Int32Array.from([6, 34, 62, 90, 118]), new ECBlocks_1.default(30, new ECB_1.default(8, 122), new ECB_1.default(4, 123)), new ECBlocks_1.default(28, new ECB_1.default(22, 45), new ECB_1.default(3, 46)), new ECBlocks_1.default(30, new ECB_1.default(8, 23), new ECB_1.default(26, 24)), new ECBlocks_1.default(30, new ECB_1.default(12, 15), new ECB_1.default(28, 16))),
    new Version2(28, Int32Array.from([6, 26, 50, 74, 98, 122]), new ECBlocks_1.default(30, new ECB_1.default(3, 117), new ECB_1.default(10, 118)), new ECBlocks_1.default(28, new ECB_1.default(3, 45), new ECB_1.default(23, 46)), new ECBlocks_1.default(30, new ECB_1.default(4, 24), new ECB_1.default(31, 25)), new ECBlocks_1.default(30, new ECB_1.default(11, 15), new ECB_1.default(31, 16))),
    new Version2(29, Int32Array.from([6, 30, 54, 78, 102, 126]), new ECBlocks_1.default(30, new ECB_1.default(7, 116), new ECB_1.default(7, 117)), new ECBlocks_1.default(28, new ECB_1.default(21, 45), new ECB_1.default(7, 46)), new ECBlocks_1.default(30, new ECB_1.default(1, 23), new ECB_1.default(37, 24)), new ECBlocks_1.default(30, new ECB_1.default(19, 15), new ECB_1.default(26, 16))),
    new Version2(30, Int32Array.from([6, 26, 52, 78, 104, 130]), new ECBlocks_1.default(30, new ECB_1.default(5, 115), new ECB_1.default(10, 116)), new ECBlocks_1.default(28, new ECB_1.default(19, 47), new ECB_1.default(10, 48)), new ECBlocks_1.default(30, new ECB_1.default(15, 24), new ECB_1.default(25, 25)), new ECBlocks_1.default(30, new ECB_1.default(23, 15), new ECB_1.default(25, 16))),
    new Version2(31, Int32Array.from([6, 30, 56, 82, 108, 134]), new ECBlocks_1.default(30, new ECB_1.default(13, 115), new ECB_1.default(3, 116)), new ECBlocks_1.default(28, new ECB_1.default(2, 46), new ECB_1.default(29, 47)), new ECBlocks_1.default(30, new ECB_1.default(42, 24), new ECB_1.default(1, 25)), new ECBlocks_1.default(30, new ECB_1.default(23, 15), new ECB_1.default(28, 16))),
    new Version2(32, Int32Array.from([6, 34, 60, 86, 112, 138]), new ECBlocks_1.default(30, new ECB_1.default(17, 115)), new ECBlocks_1.default(28, new ECB_1.default(10, 46), new ECB_1.default(23, 47)), new ECBlocks_1.default(30, new ECB_1.default(10, 24), new ECB_1.default(35, 25)), new ECBlocks_1.default(30, new ECB_1.default(19, 15), new ECB_1.default(35, 16))),
    new Version2(33, Int32Array.from([6, 30, 58, 86, 114, 142]), new ECBlocks_1.default(30, new ECB_1.default(17, 115), new ECB_1.default(1, 116)), new ECBlocks_1.default(28, new ECB_1.default(14, 46), new ECB_1.default(21, 47)), new ECBlocks_1.default(30, new ECB_1.default(29, 24), new ECB_1.default(19, 25)), new ECBlocks_1.default(30, new ECB_1.default(11, 15), new ECB_1.default(46, 16))),
    new Version2(34, Int32Array.from([6, 34, 62, 90, 118, 146]), new ECBlocks_1.default(30, new ECB_1.default(13, 115), new ECB_1.default(6, 116)), new ECBlocks_1.default(28, new ECB_1.default(14, 46), new ECB_1.default(23, 47)), new ECBlocks_1.default(30, new ECB_1.default(44, 24), new ECB_1.default(7, 25)), new ECBlocks_1.default(30, new ECB_1.default(59, 16), new ECB_1.default(1, 17))),
    new Version2(35, Int32Array.from([6, 30, 54, 78, 102, 126, 150]), new ECBlocks_1.default(30, new ECB_1.default(12, 121), new ECB_1.default(7, 122)), new ECBlocks_1.default(28, new ECB_1.default(12, 47), new ECB_1.default(26, 48)), new ECBlocks_1.default(30, new ECB_1.default(39, 24), new ECB_1.default(14, 25)), new ECBlocks_1.default(30, new ECB_1.default(22, 15), new ECB_1.default(41, 16))),
    new Version2(36, Int32Array.from([6, 24, 50, 76, 102, 128, 154]), new ECBlocks_1.default(30, new ECB_1.default(6, 121), new ECB_1.default(14, 122)), new ECBlocks_1.default(28, new ECB_1.default(6, 47), new ECB_1.default(34, 48)), new ECBlocks_1.default(30, new ECB_1.default(46, 24), new ECB_1.default(10, 25)), new ECBlocks_1.default(30, new ECB_1.default(2, 15), new ECB_1.default(64, 16))),
    new Version2(37, Int32Array.from([6, 28, 54, 80, 106, 132, 158]), new ECBlocks_1.default(30, new ECB_1.default(17, 122), new ECB_1.default(4, 123)), new ECBlocks_1.default(28, new ECB_1.default(29, 46), new ECB_1.default(14, 47)), new ECBlocks_1.default(30, new ECB_1.default(49, 24), new ECB_1.default(10, 25)), new ECBlocks_1.default(30, new ECB_1.default(24, 15), new ECB_1.default(46, 16))),
    new Version2(38, Int32Array.from([6, 32, 58, 84, 110, 136, 162]), new ECBlocks_1.default(30, new ECB_1.default(4, 122), new ECB_1.default(18, 123)), new ECBlocks_1.default(28, new ECB_1.default(13, 46), new ECB_1.default(32, 47)), new ECBlocks_1.default(30, new ECB_1.default(48, 24), new ECB_1.default(14, 25)), new ECBlocks_1.default(30, new ECB_1.default(42, 15), new ECB_1.default(32, 16))),
    new Version2(39, Int32Array.from([6, 26, 54, 82, 110, 138, 166]), new ECBlocks_1.default(30, new ECB_1.default(20, 117), new ECB_1.default(4, 118)), new ECBlocks_1.default(28, new ECB_1.default(40, 47), new ECB_1.default(7, 48)), new ECBlocks_1.default(30, new ECB_1.default(43, 24), new ECB_1.default(22, 25)), new ECBlocks_1.default(30, new ECB_1.default(10, 15), new ECB_1.default(67, 16))),
    new Version2(40, Int32Array.from([6, 30, 58, 86, 114, 142, 170]), new ECBlocks_1.default(30, new ECB_1.default(19, 118), new ECB_1.default(6, 119)), new ECBlocks_1.default(28, new ECB_1.default(18, 47), new ECB_1.default(31, 48)), new ECBlocks_1.default(30, new ECB_1.default(34, 24), new ECB_1.default(34, 25)), new ECBlocks_1.default(30, new ECB_1.default(20, 15), new ECB_1.default(61, 16)))
  ];
  return Version2;
}();
Version$1.default = Version;
var DataMask = {};
(function(exports) {
  Object.defineProperty(exports, "__esModule", { value: true });
  var DataMaskValues;
  (function(DataMaskValues2) {
    DataMaskValues2[DataMaskValues2["DATA_MASK_000"] = 0] = "DATA_MASK_000";
    DataMaskValues2[DataMaskValues2["DATA_MASK_001"] = 1] = "DATA_MASK_001";
    DataMaskValues2[DataMaskValues2["DATA_MASK_010"] = 2] = "DATA_MASK_010";
    DataMaskValues2[DataMaskValues2["DATA_MASK_011"] = 3] = "DATA_MASK_011";
    DataMaskValues2[DataMaskValues2["DATA_MASK_100"] = 4] = "DATA_MASK_100";
    DataMaskValues2[DataMaskValues2["DATA_MASK_101"] = 5] = "DATA_MASK_101";
    DataMaskValues2[DataMaskValues2["DATA_MASK_110"] = 6] = "DATA_MASK_110";
    DataMaskValues2[DataMaskValues2["DATA_MASK_111"] = 7] = "DATA_MASK_111";
  })(DataMaskValues = exports.DataMaskValues || (exports.DataMaskValues = {}));
  var DataMask2 = function() {
    function DataMask3(value, isMasked) {
      this.value = value;
      this.isMasked = isMasked;
    }
    DataMask3.prototype.unmaskBitMatrix = function(bits, dimension) {
      for (var i = 0; i < dimension; i++) {
        for (var j = 0; j < dimension; j++) {
          if (this.isMasked(i, j)) {
            bits.flip(j, i);
          }
        }
      }
    };
    DataMask3.values = /* @__PURE__ */ new Map([
      [DataMaskValues.DATA_MASK_000, new DataMask3(DataMaskValues.DATA_MASK_000, function(i, j) {
        return (i + j & 1) === 0;
      })],
      [DataMaskValues.DATA_MASK_001, new DataMask3(DataMaskValues.DATA_MASK_001, function(i, j) {
        return (i & 1) === 0;
      })],
      [DataMaskValues.DATA_MASK_010, new DataMask3(DataMaskValues.DATA_MASK_010, function(i, j) {
        return j % 3 === 0;
      })],
      [DataMaskValues.DATA_MASK_011, new DataMask3(DataMaskValues.DATA_MASK_011, function(i, j) {
        return (i + j) % 3 === 0;
      })],
      [DataMaskValues.DATA_MASK_100, new DataMask3(DataMaskValues.DATA_MASK_100, function(i, j) {
        return (Math.floor(i / 2) + Math.floor(j / 3) & 1) === 0;
      })],
      [DataMaskValues.DATA_MASK_101, new DataMask3(DataMaskValues.DATA_MASK_101, function(i, j) {
        return i * j % 6 === 0;
      })],
      [DataMaskValues.DATA_MASK_110, new DataMask3(DataMaskValues.DATA_MASK_110, function(i, j) {
        return i * j % 6 < 3;
      })],
      [DataMaskValues.DATA_MASK_111, new DataMask3(DataMaskValues.DATA_MASK_111, function(i, j) {
        return (i + j + i * j % 3 & 1) === 0;
      })]
    ]);
    return DataMask3;
  }();
  exports.default = DataMask2;
})(DataMask);
Object.defineProperty(BitMatrixParser$1, "__esModule", { value: true });
var Version_1$2 = Version$1;
var FormatInformation_1 = FormatInformation$1;
var DataMask_1 = DataMask;
var FormatException_1$1 = FormatException$1;
var BitMatrixParser = function() {
  function BitMatrixParser2(bitMatrix) {
    var dimension = bitMatrix.getHeight();
    if (dimension < 21 || (dimension & 3) !== 1) {
      throw new FormatException_1$1.default();
    }
    this.bitMatrix = bitMatrix;
  }
  BitMatrixParser2.prototype.readFormatInformation = function() {
    if (this.parsedFormatInfo !== null && this.parsedFormatInfo !== void 0) {
      return this.parsedFormatInfo;
    }
    var formatInfoBits1 = 0;
    for (var i = 0; i < 6; i++) {
      formatInfoBits1 = this.copyBit(i, 8, formatInfoBits1);
    }
    formatInfoBits1 = this.copyBit(7, 8, formatInfoBits1);
    formatInfoBits1 = this.copyBit(8, 8, formatInfoBits1);
    formatInfoBits1 = this.copyBit(8, 7, formatInfoBits1);
    for (var j = 5; j >= 0; j--) {
      formatInfoBits1 = this.copyBit(8, j, formatInfoBits1);
    }
    var dimension = this.bitMatrix.getHeight();
    var formatInfoBits2 = 0;
    var jMin = dimension - 7;
    for (var j = dimension - 1; j >= jMin; j--) {
      formatInfoBits2 = this.copyBit(8, j, formatInfoBits2);
    }
    for (var i = dimension - 8; i < dimension; i++) {
      formatInfoBits2 = this.copyBit(i, 8, formatInfoBits2);
    }
    this.parsedFormatInfo = FormatInformation_1.default.decodeFormatInformation(formatInfoBits1, formatInfoBits2);
    if (this.parsedFormatInfo !== null) {
      return this.parsedFormatInfo;
    }
    throw new FormatException_1$1.default();
  };
  BitMatrixParser2.prototype.readVersion = function() {
    if (this.parsedVersion !== null && this.parsedVersion !== void 0) {
      return this.parsedVersion;
    }
    var dimension = this.bitMatrix.getHeight();
    var provisionalVersion = Math.floor((dimension - 17) / 4);
    if (provisionalVersion <= 6) {
      return Version_1$2.default.getVersionForNumber(provisionalVersion);
    }
    var versionBits = 0;
    var ijMin = dimension - 11;
    for (var j = 5; j >= 0; j--) {
      for (var i = dimension - 9; i >= ijMin; i--) {
        versionBits = this.copyBit(i, j, versionBits);
      }
    }
    var theParsedVersion = Version_1$2.default.decodeVersionInformation(versionBits);
    if (theParsedVersion !== null && theParsedVersion.getDimensionForVersion() === dimension) {
      this.parsedVersion = theParsedVersion;
      return theParsedVersion;
    }
    versionBits = 0;
    for (var i = 5; i >= 0; i--) {
      for (var j = dimension - 9; j >= ijMin; j--) {
        versionBits = this.copyBit(i, j, versionBits);
      }
    }
    theParsedVersion = Version_1$2.default.decodeVersionInformation(versionBits);
    if (theParsedVersion !== null && theParsedVersion.getDimensionForVersion() === dimension) {
      this.parsedVersion = theParsedVersion;
      return theParsedVersion;
    }
    throw new FormatException_1$1.default();
  };
  BitMatrixParser2.prototype.copyBit = function(i, j, versionBits) {
    var bit = this.isMirror ? this.bitMatrix.get(j, i) : this.bitMatrix.get(i, j);
    return bit ? versionBits << 1 | 1 : versionBits << 1;
  };
  BitMatrixParser2.prototype.readCodewords = function() {
    var formatInfo = this.readFormatInformation();
    var version = this.readVersion();
    var dataMask = DataMask_1.default.values.get(formatInfo.getDataMask());
    var dimension = this.bitMatrix.getHeight();
    dataMask.unmaskBitMatrix(this.bitMatrix, dimension);
    var functionPattern = version.buildFunctionPattern();
    var readingUp = true;
    var result = new Uint8Array(version.getTotalCodewords());
    var resultOffset = 0;
    var currentByte = 0;
    var bitsRead = 0;
    for (var j = dimension - 1; j > 0; j -= 2) {
      if (j === 6) {
        j--;
      }
      for (var count = 0; count < dimension; count++) {
        var i = readingUp ? dimension - 1 - count : count;
        for (var col = 0; col < 2; col++) {
          if (!functionPattern.get(j - col, i)) {
            bitsRead++;
            currentByte <<= 1;
            if (this.bitMatrix.get(j - col, i)) {
              currentByte |= 1;
            }
            if (bitsRead === 8) {
              result[resultOffset++] = currentByte;
              bitsRead = 0;
              currentByte = 0;
            }
          }
        }
      }
      readingUp = !readingUp;
    }
    if (resultOffset !== version.getTotalCodewords()) {
      throw new FormatException_1$1.default();
    }
    return result;
  };
  BitMatrixParser2.prototype.remask = function() {
    if (this.parsedFormatInfo === null) {
      return;
    }
    var dataMask = DataMask_1.default.values[this.parsedFormatInfo.getDataMask()];
    var dimension = this.bitMatrix.getHeight();
    dataMask.unmaskBitMatrix(this.bitMatrix, dimension);
  };
  BitMatrixParser2.prototype.setMirror = function(isMirror) {
    this.parsedVersion = null;
    this.parsedFormatInfo = null;
    this.isMirror = isMirror;
  };
  BitMatrixParser2.prototype.mirror = function() {
    var bitMatrix = this.bitMatrix;
    for (var x = 0, width = bitMatrix.getWidth(); x < width; x++) {
      for (var y = x + 1, height = bitMatrix.getHeight(); y < height; y++) {
        if (bitMatrix.get(x, y) !== bitMatrix.get(y, x)) {
          bitMatrix.flip(y, x);
          bitMatrix.flip(x, y);
        }
      }
    }
  };
  return BitMatrixParser2;
}();
BitMatrixParser$1.default = BitMatrixParser;
var QRCodeDecoderMetaData$1 = {};
Object.defineProperty(QRCodeDecoderMetaData$1, "__esModule", { value: true });
var QRCodeDecoderMetaData = function() {
  function QRCodeDecoderMetaData2(mirrored) {
    this.mirrored = mirrored;
  }
  QRCodeDecoderMetaData2.prototype.isMirrored = function() {
    return this.mirrored;
  };
  QRCodeDecoderMetaData2.prototype.applyMirroredCorrection = function(points) {
    if (!this.mirrored || points === null || points.length < 3) {
      return;
    }
    var bottomLeft = points[0];
    points[0] = points[2];
    points[2] = bottomLeft;
  };
  return QRCodeDecoderMetaData2;
}();
QRCodeDecoderMetaData$1.default = QRCodeDecoderMetaData;
var DataBlock$1 = {};
Object.defineProperty(DataBlock$1, "__esModule", { value: true });
var IllegalArgumentException_1$8 = IllegalArgumentException$1;
var DataBlock = function() {
  function DataBlock2(numDataCodewords, codewords) {
    this.numDataCodewords = numDataCodewords;
    this.codewords = codewords;
  }
  DataBlock2.getDataBlocks = function(rawCodewords, version, ecLevel) {
    if (rawCodewords.length !== version.getTotalCodewords()) {
      throw new IllegalArgumentException_1$8.default();
    }
    var ecBlocks = version.getECBlocksForLevel(ecLevel);
    var totalBlocks = 0;
    var ecBlockArray = ecBlocks.getECBlocks();
    for (var _i = 0, ecBlockArray_1 = ecBlockArray; _i < ecBlockArray_1.length; _i++) {
      var ecBlock = ecBlockArray_1[_i];
      totalBlocks += ecBlock.getCount();
    }
    var result = new Array(totalBlocks);
    var numResultBlocks = 0;
    for (var _a = 0, ecBlockArray_2 = ecBlockArray; _a < ecBlockArray_2.length; _a++) {
      var ecBlock = ecBlockArray_2[_a];
      for (var i = 0; i < ecBlock.getCount(); i++) {
        var numDataCodewords = ecBlock.getDataCodewords();
        var numBlockCodewords = ecBlocks.getECCodewordsPerBlock() + numDataCodewords;
        result[numResultBlocks++] = new DataBlock2(numDataCodewords, new Uint8Array(numBlockCodewords));
      }
    }
    var shorterBlocksTotalCodewords = result[0].codewords.length;
    var longerBlocksStartAt = result.length - 1;
    while (longerBlocksStartAt >= 0) {
      var numCodewords = result[longerBlocksStartAt].codewords.length;
      if (numCodewords === shorterBlocksTotalCodewords) {
        break;
      }
      longerBlocksStartAt--;
    }
    longerBlocksStartAt++;
    var shorterBlocksNumDataCodewords = shorterBlocksTotalCodewords - ecBlocks.getECCodewordsPerBlock();
    var rawCodewordsOffset = 0;
    for (var i = 0; i < shorterBlocksNumDataCodewords; i++) {
      for (var j = 0; j < numResultBlocks; j++) {
        result[j].codewords[i] = rawCodewords[rawCodewordsOffset++];
      }
    }
    for (var j = longerBlocksStartAt; j < numResultBlocks; j++) {
      result[j].codewords[shorterBlocksNumDataCodewords] = rawCodewords[rawCodewordsOffset++];
    }
    var max = result[0].codewords.length;
    for (var i = shorterBlocksNumDataCodewords; i < max; i++) {
      for (var j = 0; j < numResultBlocks; j++) {
        var iOffset = j < longerBlocksStartAt ? i : i + 1;
        result[j].codewords[iOffset] = rawCodewords[rawCodewordsOffset++];
      }
    }
    return result;
  };
  DataBlock2.prototype.getNumDataCodewords = function() {
    return this.numDataCodewords;
  };
  DataBlock2.prototype.getCodewords = function() {
    return this.codewords;
  };
  return DataBlock2;
}();
DataBlock$1.default = DataBlock;
var DecodedBitStreamParser$1 = {};
var StringUtils$1 = {};
Object.defineProperty(StringUtils$1, "__esModule", { value: true });
var DecodeHintType_1$4 = DecodeHintType$1;
var CharacterSetECI_1$2 = CharacterSetECI;
var StringUtils = function() {
  function StringUtils2() {
  }
  StringUtils2.prototype.StringUtils = function() {
  };
  StringUtils2.guessEncoding = function(bytes, hints) {
    if (hints !== null && hints !== void 0 && void 0 !== hints.get(DecodeHintType_1$4.default.CHARACTER_SET)) {
      return hints.get(DecodeHintType_1$4.default.CHARACTER_SET).toString();
    }
    var length = bytes.length;
    var canBeISO88591 = true;
    var canBeShiftJIS = true;
    var canBeUTF8 = true;
    var utf8BytesLeft = 0;
    var utf2BytesChars = 0;
    var utf3BytesChars = 0;
    var utf4BytesChars = 0;
    var sjisBytesLeft = 0;
    var sjisKatakanaChars = 0;
    var sjisCurKatakanaWordLength = 0;
    var sjisCurDoubleBytesWordLength = 0;
    var sjisMaxKatakanaWordLength = 0;
    var sjisMaxDoubleBytesWordLength = 0;
    var isoHighOther = 0;
    var utf8bom = bytes.length > 3 && bytes[0] === 239 && bytes[1] === 187 && bytes[2] === 191;
    for (var i = 0; i < length && (canBeISO88591 || canBeShiftJIS || canBeUTF8); i++) {
      var value = bytes[i] & 255;
      if (canBeUTF8) {
        if (utf8BytesLeft > 0) {
          if ((value & 128) === 0) {
            canBeUTF8 = false;
          } else {
            utf8BytesLeft--;
          }
        } else if ((value & 128) !== 0) {
          if ((value & 64) === 0) {
            canBeUTF8 = false;
          } else {
            utf8BytesLeft++;
            if ((value & 32) === 0) {
              utf2BytesChars++;
            } else {
              utf8BytesLeft++;
              if ((value & 16) === 0) {
                utf3BytesChars++;
              } else {
                utf8BytesLeft++;
                if ((value & 8) === 0) {
                  utf4BytesChars++;
                } else {
                  canBeUTF8 = false;
                }
              }
            }
          }
        }
      }
      if (canBeISO88591) {
        if (value > 127 && value < 160) {
          canBeISO88591 = false;
        } else if (value > 159) {
          if (value < 192 || value === 215 || value === 247) {
            isoHighOther++;
          }
        }
      }
      if (canBeShiftJIS) {
        if (sjisBytesLeft > 0) {
          if (value < 64 || value === 127 || value > 252) {
            canBeShiftJIS = false;
          } else {
            sjisBytesLeft--;
          }
        } else if (value === 128 || value === 160 || value > 239) {
          canBeShiftJIS = false;
        } else if (value > 160 && value < 224) {
          sjisKatakanaChars++;
          sjisCurDoubleBytesWordLength = 0;
          sjisCurKatakanaWordLength++;
          if (sjisCurKatakanaWordLength > sjisMaxKatakanaWordLength) {
            sjisMaxKatakanaWordLength = sjisCurKatakanaWordLength;
          }
        } else if (value > 127) {
          sjisBytesLeft++;
          sjisCurKatakanaWordLength = 0;
          sjisCurDoubleBytesWordLength++;
          if (sjisCurDoubleBytesWordLength > sjisMaxDoubleBytesWordLength) {
            sjisMaxDoubleBytesWordLength = sjisCurDoubleBytesWordLength;
          }
        } else {
          sjisCurKatakanaWordLength = 0;
          sjisCurDoubleBytesWordLength = 0;
        }
      }
    }
    if (canBeUTF8 && utf8BytesLeft > 0) {
      canBeUTF8 = false;
    }
    if (canBeShiftJIS && sjisBytesLeft > 0) {
      canBeShiftJIS = false;
    }
    if (canBeUTF8 && (utf8bom || utf2BytesChars + utf3BytesChars + utf4BytesChars > 0)) {
      return StringUtils2.UTF8;
    }
    if (canBeShiftJIS && (StringUtils2.ASSUME_SHIFT_JIS || sjisMaxKatakanaWordLength >= 3 || sjisMaxDoubleBytesWordLength >= 3)) {
      return StringUtils2.SHIFT_JIS;
    }
    if (canBeISO88591 && canBeShiftJIS) {
      return sjisMaxKatakanaWordLength === 2 && sjisKatakanaChars === 2 || isoHighOther * 10 >= length ? StringUtils2.SHIFT_JIS : StringUtils2.ISO88591;
    }
    if (canBeISO88591) {
      return StringUtils2.ISO88591;
    }
    if (canBeShiftJIS) {
      return StringUtils2.SHIFT_JIS;
    }
    if (canBeUTF8) {
      return StringUtils2.UTF8;
    }
    return StringUtils2.PLATFORM_DEFAULT_ENCODING;
  };
  StringUtils2.SHIFT_JIS = CharacterSetECI_1$2.default.SJIS.getName();
  StringUtils2.GB2312 = "GB2312";
  StringUtils2.ISO88591 = CharacterSetECI_1$2.default.ISO8859_1.getName();
  StringUtils2.EUC_JP = "EUC_JP";
  StringUtils2.UTF8 = CharacterSetECI_1$2.default.UTF8.getName();
  StringUtils2.PLATFORM_DEFAULT_ENCODING = StringUtils2.UTF8;
  StringUtils2.ASSUME_SHIFT_JIS = false;
  return StringUtils2;
}();
StringUtils$1.default = StringUtils;
var Mode = {};
(function(exports) {
  Object.defineProperty(exports, "__esModule", { value: true });
  var IllegalArgumentException_12 = IllegalArgumentException$1;
  var ModeValues;
  (function(ModeValues2) {
    ModeValues2[ModeValues2["TERMINATOR"] = 0] = "TERMINATOR";
    ModeValues2[ModeValues2["NUMERIC"] = 1] = "NUMERIC";
    ModeValues2[ModeValues2["ALPHANUMERIC"] = 2] = "ALPHANUMERIC";
    ModeValues2[ModeValues2["STRUCTURED_APPEND"] = 3] = "STRUCTURED_APPEND";
    ModeValues2[ModeValues2["BYTE"] = 4] = "BYTE";
    ModeValues2[ModeValues2["ECI"] = 5] = "ECI";
    ModeValues2[ModeValues2["KANJI"] = 6] = "KANJI";
    ModeValues2[ModeValues2["FNC1_FIRST_POSITION"] = 7] = "FNC1_FIRST_POSITION";
    ModeValues2[ModeValues2["FNC1_SECOND_POSITION"] = 8] = "FNC1_SECOND_POSITION";
    ModeValues2[ModeValues2["HANZI"] = 9] = "HANZI";
  })(ModeValues = exports.ModeValues || (exports.ModeValues = {}));
  var Mode2 = function() {
    function Mode3(value, stringValue, characterCountBitsForVersions, bits) {
      this.value = value;
      this.stringValue = stringValue;
      this.characterCountBitsForVersions = characterCountBitsForVersions;
      this.bits = bits;
      Mode3.FOR_BITS.set(bits, this);
      Mode3.FOR_VALUE.set(value, this);
    }
    Mode3.forBits = function(bits) {
      var mode = Mode3.FOR_BITS.get(bits);
      if (void 0 === mode) {
        throw new IllegalArgumentException_12.default();
      }
      return mode;
    };
    Mode3.prototype.getCharacterCountBits = function(version) {
      var versionNumber = version.getVersionNumber();
      var offset;
      if (versionNumber <= 9) {
        offset = 0;
      } else if (versionNumber <= 26) {
        offset = 1;
      } else {
        offset = 2;
      }
      return this.characterCountBitsForVersions[offset];
    };
    Mode3.prototype.getValue = function() {
      return this.value;
    };
    Mode3.prototype.getBits = function() {
      return this.bits;
    };
    Mode3.prototype.equals = function(o) {
      if (!(o instanceof Mode3)) {
        return false;
      }
      var other = o;
      return this.value === other.value;
    };
    Mode3.prototype.toString = function() {
      return this.stringValue;
    };
    Mode3.FOR_BITS = /* @__PURE__ */ new Map();
    Mode3.FOR_VALUE = /* @__PURE__ */ new Map();
    Mode3.TERMINATOR = new Mode3(ModeValues.TERMINATOR, "TERMINATOR", Int32Array.from([0, 0, 0]), 0);
    Mode3.NUMERIC = new Mode3(ModeValues.NUMERIC, "NUMERIC", Int32Array.from([10, 12, 14]), 1);
    Mode3.ALPHANUMERIC = new Mode3(ModeValues.ALPHANUMERIC, "ALPHANUMERIC", Int32Array.from([9, 11, 13]), 2);
    Mode3.STRUCTURED_APPEND = new Mode3(ModeValues.STRUCTURED_APPEND, "STRUCTURED_APPEND", Int32Array.from([0, 0, 0]), 3);
    Mode3.BYTE = new Mode3(ModeValues.BYTE, "BYTE", Int32Array.from([8, 16, 16]), 4);
    Mode3.ECI = new Mode3(ModeValues.ECI, "ECI", Int32Array.from([0, 0, 0]), 7);
    Mode3.KANJI = new Mode3(ModeValues.KANJI, "KANJI", Int32Array.from([8, 10, 12]), 8);
    Mode3.FNC1_FIRST_POSITION = new Mode3(ModeValues.FNC1_FIRST_POSITION, "FNC1_FIRST_POSITION", Int32Array.from([0, 0, 0]), 5);
    Mode3.FNC1_SECOND_POSITION = new Mode3(ModeValues.FNC1_SECOND_POSITION, "FNC1_SECOND_POSITION", Int32Array.from([0, 0, 0]), 9);
    Mode3.HANZI = new Mode3(ModeValues.HANZI, "HANZI", Int32Array.from([8, 10, 12]), 13);
    return Mode3;
  }();
  exports.default = Mode2;
})(Mode);
Object.defineProperty(DecodedBitStreamParser$1, "__esModule", { value: true });
var BitSource_1 = BitSource$1;
var CharacterSetECI_1$1 = CharacterSetECI;
var DecoderResult_1 = DecoderResult$1;
var StringUtils_1 = StringUtils$1;
var Mode_1$1 = Mode;
var StringBuilder_1$2 = StringBuilder$1;
var StringEncoding_1$1 = StringEncoding$1;
var FormatException_1 = FormatException$1;
var DecodedBitStreamParser = function() {
  function DecodedBitStreamParser2() {
  }
  DecodedBitStreamParser2.decode = function(bytes, version, ecLevel, hints) {
    var bits = new BitSource_1.default(bytes);
    var result = new StringBuilder_1$2.default();
    var byteSegments = new Array();
    var symbolSequence = -1;
    var parityData = -1;
    try {
      var currentCharacterSetECI = null;
      var fc1InEffect = false;
      var mode = void 0;
      do {
        if (bits.available() < 4) {
          mode = Mode_1$1.default.TERMINATOR;
        } else {
          var modeBits = bits.readBits(4);
          mode = Mode_1$1.default.forBits(modeBits);
        }
        switch (mode) {
          case Mode_1$1.default.TERMINATOR:
            break;
          case Mode_1$1.default.FNC1_FIRST_POSITION:
          case Mode_1$1.default.FNC1_SECOND_POSITION:
            fc1InEffect = true;
            break;
          case Mode_1$1.default.STRUCTURED_APPEND:
            if (bits.available() < 16) {
              throw new FormatException_1.default();
            }
            symbolSequence = bits.readBits(8);
            parityData = bits.readBits(8);
            break;
          case Mode_1$1.default.ECI:
            var value = DecodedBitStreamParser2.parseECIValue(bits);
            currentCharacterSetECI = CharacterSetECI_1$1.default.getCharacterSetECIByValue(value);
            if (currentCharacterSetECI === null) {
              throw new FormatException_1.default();
            }
            break;
          case Mode_1$1.default.HANZI:
            var subset = bits.readBits(4);
            var countHanzi = bits.readBits(mode.getCharacterCountBits(version));
            if (subset === DecodedBitStreamParser2.GB2312_SUBSET) {
              DecodedBitStreamParser2.decodeHanziSegment(bits, result, countHanzi);
            }
            break;
          default:
            var count = bits.readBits(mode.getCharacterCountBits(version));
            switch (mode) {
              case Mode_1$1.default.NUMERIC:
                DecodedBitStreamParser2.decodeNumericSegment(bits, result, count);
                break;
              case Mode_1$1.default.ALPHANUMERIC:
                DecodedBitStreamParser2.decodeAlphanumericSegment(bits, result, count, fc1InEffect);
                break;
              case Mode_1$1.default.BYTE:
                DecodedBitStreamParser2.decodeByteSegment(bits, result, count, currentCharacterSetECI, byteSegments, hints);
                break;
              case Mode_1$1.default.KANJI:
                DecodedBitStreamParser2.decodeKanjiSegment(bits, result, count);
                break;
              default:
                throw new FormatException_1.default();
            }
            break;
        }
      } while (mode !== Mode_1$1.default.TERMINATOR);
    } catch (iae) {
      throw new FormatException_1.default();
    }
    return new DecoderResult_1.default(bytes, result.toString(), byteSegments.length === 0 ? null : byteSegments, ecLevel === null ? null : ecLevel.toString(), symbolSequence, parityData);
  };
  DecodedBitStreamParser2.decodeHanziSegment = function(bits, result, count) {
    if (count * 13 > bits.available()) {
      throw new FormatException_1.default();
    }
    var buffer = new Uint8Array(2 * count);
    var offset = 0;
    while (count > 0) {
      var twoBytes = bits.readBits(13);
      var assembledTwoBytes = twoBytes / 96 << 8 & 4294967295 | twoBytes % 96;
      if (assembledTwoBytes < 959) {
        assembledTwoBytes += 41377;
      } else {
        assembledTwoBytes += 42657;
      }
      buffer[offset] = assembledTwoBytes >> 8 & 255;
      buffer[offset + 1] = assembledTwoBytes & 255;
      offset += 2;
      count--;
    }
    try {
      result.append(StringEncoding_1$1.default.decode(buffer, StringUtils_1.default.GB2312));
    } catch (ignored) {
      throw new FormatException_1.default(ignored);
    }
  };
  DecodedBitStreamParser2.decodeKanjiSegment = function(bits, result, count) {
    if (count * 13 > bits.available()) {
      throw new FormatException_1.default();
    }
    var buffer = new Uint8Array(2 * count);
    var offset = 0;
    while (count > 0) {
      var twoBytes = bits.readBits(13);
      var assembledTwoBytes = twoBytes / 192 << 8 & 4294967295 | twoBytes % 192;
      if (assembledTwoBytes < 7936) {
        assembledTwoBytes += 33088;
      } else {
        assembledTwoBytes += 49472;
      }
      buffer[offset] = assembledTwoBytes >> 8;
      buffer[offset + 1] = assembledTwoBytes;
      offset += 2;
      count--;
    }
    try {
      result.append(StringEncoding_1$1.default.decode(buffer, StringUtils_1.default.SHIFT_JIS));
    } catch (ignored) {
      throw new FormatException_1.default(ignored);
    }
  };
  DecodedBitStreamParser2.decodeByteSegment = function(bits, result, count, currentCharacterSetECI, byteSegments, hints) {
    if (8 * count > bits.available()) {
      throw new FormatException_1.default();
    }
    var readBytes = new Uint8Array(count);
    for (var i = 0; i < count; i++) {
      readBytes[i] = bits.readBits(8);
    }
    var encoding;
    if (currentCharacterSetECI === null) {
      encoding = StringUtils_1.default.guessEncoding(readBytes, hints);
    } else {
      encoding = currentCharacterSetECI.getName();
    }
    try {
      result.append(StringEncoding_1$1.default.decode(readBytes, encoding));
    } catch (ignored) {
      throw new FormatException_1.default(ignored);
    }
    byteSegments.push(readBytes);
  };
  DecodedBitStreamParser2.toAlphaNumericChar = function(value) {
    if (value >= DecodedBitStreamParser2.ALPHANUMERIC_CHARS.length) {
      throw new FormatException_1.default();
    }
    return DecodedBitStreamParser2.ALPHANUMERIC_CHARS[value];
  };
  DecodedBitStreamParser2.decodeAlphanumericSegment = function(bits, result, count, fc1InEffect) {
    var start = result.length();
    while (count > 1) {
      if (bits.available() < 11) {
        throw new FormatException_1.default();
      }
      var nextTwoCharsBits = bits.readBits(11);
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(Math.floor(nextTwoCharsBits / 45)));
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(nextTwoCharsBits % 45));
      count -= 2;
    }
    if (count === 1) {
      if (bits.available() < 6) {
        throw new FormatException_1.default();
      }
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(bits.readBits(6)));
    }
    if (fc1InEffect) {
      for (var i = start; i < result.length(); i++) {
        if (result.charAt(i) === "%") {
          if (i < result.length() - 1 && result.charAt(i + 1) === "%") {
            result.deleteCharAt(i + 1);
          } else {
            result.setCharAt(i, String.fromCharCode(29));
          }
        }
      }
    }
  };
  DecodedBitStreamParser2.decodeNumericSegment = function(bits, result, count) {
    while (count >= 3) {
      if (bits.available() < 10) {
        throw new FormatException_1.default();
      }
      var threeDigitsBits = bits.readBits(10);
      if (threeDigitsBits >= 1e3) {
        throw new FormatException_1.default();
      }
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(Math.floor(threeDigitsBits / 100)));
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(Math.floor(threeDigitsBits / 10) % 10));
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(threeDigitsBits % 10));
      count -= 3;
    }
    if (count === 2) {
      if (bits.available() < 7) {
        throw new FormatException_1.default();
      }
      var twoDigitsBits = bits.readBits(7);
      if (twoDigitsBits >= 100) {
        throw new FormatException_1.default();
      }
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(Math.floor(twoDigitsBits / 10)));
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(twoDigitsBits % 10));
    } else if (count === 1) {
      if (bits.available() < 4) {
        throw new FormatException_1.default();
      }
      var digitBits = bits.readBits(4);
      if (digitBits >= 10) {
        throw new FormatException_1.default();
      }
      result.append(DecodedBitStreamParser2.toAlphaNumericChar(digitBits));
    }
  };
  DecodedBitStreamParser2.parseECIValue = function(bits) {
    var firstByte = bits.readBits(8);
    if ((firstByte & 128) === 0) {
      return firstByte & 127;
    }
    if ((firstByte & 192) === 128) {
      var secondByte = bits.readBits(8);
      return (firstByte & 63) << 8 & 4294967295 | secondByte;
    }
    if ((firstByte & 224) === 192) {
      var secondThirdBytes = bits.readBits(16);
      return (firstByte & 31) << 16 & 4294967295 | secondThirdBytes;
    }
    throw new FormatException_1.default();
  };
  DecodedBitStreamParser2.ALPHANUMERIC_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ $%*+-./:";
  DecodedBitStreamParser2.GB2312_SUBSET = 1;
  return DecodedBitStreamParser2;
}();
DecodedBitStreamParser$1.default = DecodedBitStreamParser;
Object.defineProperty(Decoder$1, "__esModule", { value: true });
var BitMatrix_1$2 = BitMatrix$1;
var GenericGF_1$1 = GenericGF$1;
var ReedSolomonDecoder_1 = ReedSolomonDecoder$1;
var BitMatrixParser_1 = BitMatrixParser$1;
var QRCodeDecoderMetaData_1$1 = QRCodeDecoderMetaData$1;
var DataBlock_1 = DataBlock$1;
var DecodedBitStreamParser_1 = DecodedBitStreamParser$1;
var ChecksumException_1 = ChecksumException$1;
var Decoder = function() {
  function Decoder2() {
    this.rsDecoder = new ReedSolomonDecoder_1.default(GenericGF_1$1.default.QR_CODE_FIELD_256);
  }
  Decoder2.prototype.decodeBooleanArray = function(image, hints) {
    return this.decodeBitMatrix(BitMatrix_1$2.default.parseFromBooleanArray(image), hints);
  };
  Decoder2.prototype.decodeBitMatrix = function(bits, hints) {
    var parser = new BitMatrixParser_1.default(bits);
    var ex = null;
    try {
      return this.decodeBitMatrixParser(parser, hints);
    } catch (e) {
      ex = e;
    }
    try {
      parser.remask();
      parser.setMirror(true);
      parser.readVersion();
      parser.readFormatInformation();
      parser.mirror();
      var result = this.decodeBitMatrixParser(parser, hints);
      result.setOther(new QRCodeDecoderMetaData_1$1.default(true));
      return result;
    } catch (e) {
      if (ex !== null) {
        throw ex;
      }
      throw e;
    }
  };
  Decoder2.prototype.decodeBitMatrixParser = function(parser, hints) {
    var version = parser.readVersion();
    var ecLevel = parser.readFormatInformation().getErrorCorrectionLevel();
    var codewords = parser.readCodewords();
    var dataBlocks = DataBlock_1.default.getDataBlocks(codewords, version, ecLevel);
    var totalBytes = 0;
    for (var _i = 0, dataBlocks_1 = dataBlocks; _i < dataBlocks_1.length; _i++) {
      var dataBlock = dataBlocks_1[_i];
      totalBytes += dataBlock.getNumDataCodewords();
    }
    var resultBytes = new Uint8Array(totalBytes);
    var resultOffset = 0;
    for (var _a = 0, dataBlocks_2 = dataBlocks; _a < dataBlocks_2.length; _a++) {
      var dataBlock = dataBlocks_2[_a];
      var codewordBytes = dataBlock.getCodewords();
      var numDataCodewords = dataBlock.getNumDataCodewords();
      this.correctErrors(codewordBytes, numDataCodewords);
      for (var i = 0; i < numDataCodewords; i++) {
        resultBytes[resultOffset++] = codewordBytes[i];
      }
    }
    return DecodedBitStreamParser_1.default.decode(resultBytes, version, ecLevel, hints);
  };
  Decoder2.prototype.correctErrors = function(codewordBytes, numDataCodewords) {
    codewordBytes.length;
    var codewordsInts = new Int32Array(codewordBytes);
    try {
      this.rsDecoder.decode(codewordsInts, codewordBytes.length - numDataCodewords);
    } catch (ignored) {
      throw new ChecksumException_1.default();
    }
    for (var i = 0; i < numDataCodewords; i++) {
      codewordBytes[i] = codewordsInts[i];
    }
  };
  return Decoder2;
}();
Decoder$1.default = Decoder;
var Detector$1 = {};
var FinderPatternFinder$1 = {};
var FinderPattern$1 = {};
var __extends$7 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(FinderPattern$1, "__esModule", { value: true });
var ResultPoint_1$3 = ResultPoint$1;
var FinderPattern = function(_super) {
  __extends$7(FinderPattern2, _super);
  function FinderPattern2(posX, posY, estimatedModuleSize, count) {
    var _this = _super.call(this, posX, posY) || this;
    _this.estimatedModuleSize = estimatedModuleSize;
    _this.count = count;
    if (void 0 === count) {
      _this.count = 1;
    }
    return _this;
  }
  FinderPattern2.prototype.getEstimatedModuleSize = function() {
    return this.estimatedModuleSize;
  };
  FinderPattern2.prototype.getCount = function() {
    return this.count;
  };
  FinderPattern2.prototype.aboutEquals = function(moduleSize, i, j) {
    if (Math.abs(i - this.getY()) <= moduleSize && Math.abs(j - this.getX()) <= moduleSize) {
      var moduleSizeDiff = Math.abs(moduleSize - this.estimatedModuleSize);
      return moduleSizeDiff <= 1 || moduleSizeDiff <= this.estimatedModuleSize;
    }
    return false;
  };
  FinderPattern2.prototype.combineEstimate = function(i, j, newModuleSize) {
    var combinedCount = this.count + 1;
    var combinedX = (this.count * this.getX() + j) / combinedCount;
    var combinedY = (this.count * this.getY() + i) / combinedCount;
    var combinedModuleSize = (this.count * this.estimatedModuleSize + newModuleSize) / combinedCount;
    return new FinderPattern2(combinedX, combinedY, combinedModuleSize, combinedCount);
  };
  return FinderPattern2;
}(ResultPoint_1$3.default);
FinderPattern$1.default = FinderPattern;
var FinderPatternInfo$1 = {};
Object.defineProperty(FinderPatternInfo$1, "__esModule", { value: true });
var FinderPatternInfo = function() {
  function FinderPatternInfo2(patternCenters) {
    this.bottomLeft = patternCenters[0];
    this.topLeft = patternCenters[1];
    this.topRight = patternCenters[2];
  }
  FinderPatternInfo2.prototype.getBottomLeft = function() {
    return this.bottomLeft;
  };
  FinderPatternInfo2.prototype.getTopLeft = function() {
    return this.topLeft;
  };
  FinderPatternInfo2.prototype.getTopRight = function() {
    return this.topRight;
  };
  return FinderPatternInfo2;
}();
FinderPatternInfo$1.default = FinderPatternInfo;
Object.defineProperty(FinderPatternFinder$1, "__esModule", { value: true });
var DecodeHintType_1$3 = DecodeHintType$1;
var ResultPoint_1$2 = ResultPoint$1;
var FinderPattern_1 = FinderPattern$1;
var FinderPatternInfo_1 = FinderPatternInfo$1;
var NotFoundException_1$4 = NotFoundException$1;
var FinderPatternFinder = function() {
  function FinderPatternFinder2(image, resultPointCallback) {
    this.image = image;
    this.resultPointCallback = resultPointCallback;
    this.possibleCenters = [];
    this.crossCheckStateCount = new Int32Array(5);
    this.resultPointCallback = resultPointCallback;
  }
  FinderPatternFinder2.prototype.getImage = function() {
    return this.image;
  };
  FinderPatternFinder2.prototype.getPossibleCenters = function() {
    return this.possibleCenters;
  };
  FinderPatternFinder2.prototype.find = function(hints) {
    var tryHarder = hints !== null && hints !== void 0 && void 0 !== hints.get(DecodeHintType_1$3.default.TRY_HARDER);
    var pureBarcode = hints !== null && hints !== void 0 && void 0 !== hints.get(DecodeHintType_1$3.default.PURE_BARCODE);
    var image = this.image;
    var maxI = image.getHeight();
    var maxJ = image.getWidth();
    var iSkip = Math.floor(3 * maxI / (4 * FinderPatternFinder2.MAX_MODULES));
    if (iSkip < FinderPatternFinder2.MIN_SKIP || tryHarder) {
      iSkip = FinderPatternFinder2.MIN_SKIP;
    }
    var done = false;
    var stateCount = new Int32Array(5);
    for (var i = iSkip - 1; i < maxI && !done; i += iSkip) {
      stateCount[0] = 0;
      stateCount[1] = 0;
      stateCount[2] = 0;
      stateCount[3] = 0;
      stateCount[4] = 0;
      var currentState = 0;
      for (var j = 0; j < maxJ; j++) {
        if (image.get(j, i)) {
          if ((currentState & 1) === 1) {
            currentState++;
          }
          stateCount[currentState]++;
        } else {
          if ((currentState & 1) === 0) {
            if (currentState === 4) {
              if (FinderPatternFinder2.foundPatternCross(stateCount)) {
                var confirmed = this.handlePossibleCenter(stateCount, i, j, pureBarcode);
                if (confirmed === true) {
                  iSkip = 2;
                  if (this.hasSkipped === true) {
                    done = this.haveMultiplyConfirmedCenters();
                  } else {
                    var rowSkip = this.findRowSkip();
                    if (rowSkip > stateCount[2]) {
                      i += rowSkip - stateCount[2] - iSkip;
                      j = maxJ - 1;
                    }
                  }
                } else {
                  stateCount[0] = stateCount[2];
                  stateCount[1] = stateCount[3];
                  stateCount[2] = stateCount[4];
                  stateCount[3] = 1;
                  stateCount[4] = 0;
                  currentState = 3;
                  continue;
                }
                currentState = 0;
                stateCount[0] = 0;
                stateCount[1] = 0;
                stateCount[2] = 0;
                stateCount[3] = 0;
                stateCount[4] = 0;
              } else {
                stateCount[0] = stateCount[2];
                stateCount[1] = stateCount[3];
                stateCount[2] = stateCount[4];
                stateCount[3] = 1;
                stateCount[4] = 0;
                currentState = 3;
              }
            } else {
              stateCount[++currentState]++;
            }
          } else {
            stateCount[currentState]++;
          }
        }
      }
      if (FinderPatternFinder2.foundPatternCross(stateCount)) {
        var confirmed = this.handlePossibleCenter(stateCount, i, maxJ, pureBarcode);
        if (confirmed === true) {
          iSkip = stateCount[0];
          if (this.hasSkipped) {
            done = this.haveMultiplyConfirmedCenters();
          }
        }
      }
    }
    var patternInfo = this.selectBestPatterns();
    ResultPoint_1$2.default.orderBestPatterns(patternInfo);
    return new FinderPatternInfo_1.default(patternInfo);
  };
  FinderPatternFinder2.centerFromEnd = function(stateCount, end) {
    return end - stateCount[4] - stateCount[3] - stateCount[2] / 2;
  };
  FinderPatternFinder2.foundPatternCross = function(stateCount) {
    var totalModuleSize = 0;
    for (var i = 0; i < 5; i++) {
      var count = stateCount[i];
      if (count === 0) {
        return false;
      }
      totalModuleSize += count;
    }
    if (totalModuleSize < 7) {
      return false;
    }
    var moduleSize = totalModuleSize / 7;
    var maxVariance = moduleSize / 2;
    return Math.abs(moduleSize - stateCount[0]) < maxVariance && Math.abs(moduleSize - stateCount[1]) < maxVariance && Math.abs(3 * moduleSize - stateCount[2]) < 3 * maxVariance && Math.abs(moduleSize - stateCount[3]) < maxVariance && Math.abs(moduleSize - stateCount[4]) < maxVariance;
  };
  FinderPatternFinder2.prototype.getCrossCheckStateCount = function() {
    var crossCheckStateCount = this.crossCheckStateCount;
    crossCheckStateCount[0] = 0;
    crossCheckStateCount[1] = 0;
    crossCheckStateCount[2] = 0;
    crossCheckStateCount[3] = 0;
    crossCheckStateCount[4] = 0;
    return crossCheckStateCount;
  };
  FinderPatternFinder2.prototype.crossCheckDiagonal = function(startI, centerJ, maxCount, originalStateCountTotal) {
    var stateCount = this.getCrossCheckStateCount();
    var i = 0;
    var image = this.image;
    while (startI >= i && centerJ >= i && image.get(centerJ - i, startI - i)) {
      stateCount[2]++;
      i++;
    }
    if (startI < i || centerJ < i) {
      return false;
    }
    while (startI >= i && centerJ >= i && !image.get(centerJ - i, startI - i) && stateCount[1] <= maxCount) {
      stateCount[1]++;
      i++;
    }
    if (startI < i || centerJ < i || stateCount[1] > maxCount) {
      return false;
    }
    while (startI >= i && centerJ >= i && image.get(centerJ - i, startI - i) && stateCount[0] <= maxCount) {
      stateCount[0]++;
      i++;
    }
    if (stateCount[0] > maxCount) {
      return false;
    }
    var maxI = image.getHeight();
    var maxJ = image.getWidth();
    i = 1;
    while (startI + i < maxI && centerJ + i < maxJ && image.get(centerJ + i, startI + i)) {
      stateCount[2]++;
      i++;
    }
    if (startI + i >= maxI || centerJ + i >= maxJ) {
      return false;
    }
    while (startI + i < maxI && centerJ + i < maxJ && !image.get(centerJ + i, startI + i) && stateCount[3] < maxCount) {
      stateCount[3]++;
      i++;
    }
    if (startI + i >= maxI || centerJ + i >= maxJ || stateCount[3] >= maxCount) {
      return false;
    }
    while (startI + i < maxI && centerJ + i < maxJ && image.get(centerJ + i, startI + i) && stateCount[4] < maxCount) {
      stateCount[4]++;
      i++;
    }
    if (stateCount[4] >= maxCount) {
      return false;
    }
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2] + stateCount[3] + stateCount[4];
    return Math.abs(stateCountTotal - originalStateCountTotal) < 2 * originalStateCountTotal && FinderPatternFinder2.foundPatternCross(stateCount);
  };
  FinderPatternFinder2.prototype.crossCheckVertical = function(startI, centerJ, maxCount, originalStateCountTotal) {
    var image = this.image;
    var maxI = image.getHeight();
    var stateCount = this.getCrossCheckStateCount();
    var i = startI;
    while (i >= 0 && image.get(centerJ, i)) {
      stateCount[2]++;
      i--;
    }
    if (i < 0) {
      return NaN;
    }
    while (i >= 0 && !image.get(centerJ, i) && stateCount[1] <= maxCount) {
      stateCount[1]++;
      i--;
    }
    if (i < 0 || stateCount[1] > maxCount) {
      return NaN;
    }
    while (i >= 0 && image.get(centerJ, i) && stateCount[0] <= maxCount) {
      stateCount[0]++;
      i--;
    }
    if (stateCount[0] > maxCount) {
      return NaN;
    }
    i = startI + 1;
    while (i < maxI && image.get(centerJ, i)) {
      stateCount[2]++;
      i++;
    }
    if (i === maxI) {
      return NaN;
    }
    while (i < maxI && !image.get(centerJ, i) && stateCount[3] < maxCount) {
      stateCount[3]++;
      i++;
    }
    if (i === maxI || stateCount[3] >= maxCount) {
      return NaN;
    }
    while (i < maxI && image.get(centerJ, i) && stateCount[4] < maxCount) {
      stateCount[4]++;
      i++;
    }
    if (stateCount[4] >= maxCount) {
      return NaN;
    }
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2] + stateCount[3] + stateCount[4];
    if (5 * Math.abs(stateCountTotal - originalStateCountTotal) >= 2 * originalStateCountTotal) {
      return NaN;
    }
    return FinderPatternFinder2.foundPatternCross(stateCount) ? FinderPatternFinder2.centerFromEnd(stateCount, i) : NaN;
  };
  FinderPatternFinder2.prototype.crossCheckHorizontal = function(startJ, centerI, maxCount, originalStateCountTotal) {
    var image = this.image;
    var maxJ = image.getWidth();
    var stateCount = this.getCrossCheckStateCount();
    var j = startJ;
    while (j >= 0 && image.get(j, centerI)) {
      stateCount[2]++;
      j--;
    }
    if (j < 0) {
      return NaN;
    }
    while (j >= 0 && !image.get(j, centerI) && stateCount[1] <= maxCount) {
      stateCount[1]++;
      j--;
    }
    if (j < 0 || stateCount[1] > maxCount) {
      return NaN;
    }
    while (j >= 0 && image.get(j, centerI) && stateCount[0] <= maxCount) {
      stateCount[0]++;
      j--;
    }
    if (stateCount[0] > maxCount) {
      return NaN;
    }
    j = startJ + 1;
    while (j < maxJ && image.get(j, centerI)) {
      stateCount[2]++;
      j++;
    }
    if (j === maxJ) {
      return NaN;
    }
    while (j < maxJ && !image.get(j, centerI) && stateCount[3] < maxCount) {
      stateCount[3]++;
      j++;
    }
    if (j === maxJ || stateCount[3] >= maxCount) {
      return NaN;
    }
    while (j < maxJ && image.get(j, centerI) && stateCount[4] < maxCount) {
      stateCount[4]++;
      j++;
    }
    if (stateCount[4] >= maxCount) {
      return NaN;
    }
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2] + stateCount[3] + stateCount[4];
    if (5 * Math.abs(stateCountTotal - originalStateCountTotal) >= originalStateCountTotal) {
      return NaN;
    }
    return FinderPatternFinder2.foundPatternCross(stateCount) ? FinderPatternFinder2.centerFromEnd(stateCount, j) : NaN;
  };
  FinderPatternFinder2.prototype.handlePossibleCenter = function(stateCount, i, j, pureBarcode) {
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2] + stateCount[3] + stateCount[4];
    var centerJ = FinderPatternFinder2.centerFromEnd(stateCount, j);
    var centerI = this.crossCheckVertical(i, Math.floor(centerJ), stateCount[2], stateCountTotal);
    if (!isNaN(centerI)) {
      centerJ = this.crossCheckHorizontal(Math.floor(centerJ), Math.floor(centerI), stateCount[2], stateCountTotal);
      if (!isNaN(centerJ) && (!pureBarcode || this.crossCheckDiagonal(Math.floor(centerI), Math.floor(centerJ), stateCount[2], stateCountTotal))) {
        var estimatedModuleSize = stateCountTotal / 7;
        var found = false;
        var possibleCenters = this.possibleCenters;
        for (var index = 0, length_1 = possibleCenters.length; index < length_1; index++) {
          var center = possibleCenters[index];
          if (center.aboutEquals(estimatedModuleSize, centerI, centerJ)) {
            possibleCenters[index] = center.combineEstimate(centerI, centerJ, estimatedModuleSize);
            found = true;
            break;
          }
        }
        if (!found) {
          var point = new FinderPattern_1.default(centerJ, centerI, estimatedModuleSize);
          possibleCenters.push(point);
          if (this.resultPointCallback !== null && this.resultPointCallback !== void 0) {
            this.resultPointCallback.foundPossibleResultPoint(point);
          }
        }
        return true;
      }
    }
    return false;
  };
  FinderPatternFinder2.prototype.findRowSkip = function() {
    var max = this.possibleCenters.length;
    if (max <= 1) {
      return 0;
    }
    var firstConfirmedCenter = null;
    for (var _i = 0, _a = this.possibleCenters; _i < _a.length; _i++) {
      var center = _a[_i];
      if (center.getCount() >= FinderPatternFinder2.CENTER_QUORUM) {
        if (firstConfirmedCenter == null) {
          firstConfirmedCenter = center;
        } else {
          this.hasSkipped = true;
          return Math.floor((Math.abs(firstConfirmedCenter.getX() - center.getX()) - Math.abs(firstConfirmedCenter.getY() - center.getY())) / 2);
        }
      }
    }
    return 0;
  };
  FinderPatternFinder2.prototype.haveMultiplyConfirmedCenters = function() {
    var confirmedCount = 0;
    var totalModuleSize = 0;
    var max = this.possibleCenters.length;
    for (var _i = 0, _a = this.possibleCenters; _i < _a.length; _i++) {
      var pattern = _a[_i];
      if (pattern.getCount() >= FinderPatternFinder2.CENTER_QUORUM) {
        confirmedCount++;
        totalModuleSize += pattern.getEstimatedModuleSize();
      }
    }
    if (confirmedCount < 3) {
      return false;
    }
    var average = totalModuleSize / max;
    var totalDeviation = 0;
    for (var _b = 0, _c = this.possibleCenters; _b < _c.length; _b++) {
      var pattern = _c[_b];
      totalDeviation += Math.abs(pattern.getEstimatedModuleSize() - average);
    }
    return totalDeviation <= 0.05 * totalModuleSize;
  };
  FinderPatternFinder2.prototype.selectBestPatterns = function() {
    var startSize = this.possibleCenters.length;
    if (startSize < 3) {
      throw new NotFoundException_1$4.default();
    }
    var possibleCenters = this.possibleCenters;
    var average;
    if (startSize > 3) {
      var totalModuleSize = 0;
      var square = 0;
      for (var _i = 0, _a = this.possibleCenters; _i < _a.length; _i++) {
        var center = _a[_i];
        var size = center.getEstimatedModuleSize();
        totalModuleSize += size;
        square += size * size;
      }
      average = totalModuleSize / startSize;
      var stdDev = Math.sqrt(square / startSize - average * average);
      possibleCenters.sort(function(center1, center2) {
        var dA = Math.abs(center2.getEstimatedModuleSize() - average);
        var dB = Math.abs(center1.getEstimatedModuleSize() - average);
        return dA < dB ? -1 : dA > dB ? 1 : 0;
      });
      var limit = Math.max(0.2 * average, stdDev);
      for (var i = 0; i < possibleCenters.length && possibleCenters.length > 3; i++) {
        var pattern = possibleCenters[i];
        if (Math.abs(pattern.getEstimatedModuleSize() - average) > limit) {
          possibleCenters.splice(i, 1);
          i--;
        }
      }
    }
    if (possibleCenters.length > 3) {
      var totalModuleSize = 0;
      for (var _b = 0, possibleCenters_1 = possibleCenters; _b < possibleCenters_1.length; _b++) {
        var possibleCenter = possibleCenters_1[_b];
        totalModuleSize += possibleCenter.getEstimatedModuleSize();
      }
      average = totalModuleSize / possibleCenters.length;
      possibleCenters.sort(function(center1, center2) {
        if (center2.getCount() === center1.getCount()) {
          var dA = Math.abs(center2.getEstimatedModuleSize() - average);
          var dB = Math.abs(center1.getEstimatedModuleSize() - average);
          return dA < dB ? 1 : dA > dB ? -1 : 0;
        } else {
          return center2.getCount() - center1.getCount();
        }
      });
      possibleCenters.splice(3);
    }
    return [
      possibleCenters[0],
      possibleCenters[1],
      possibleCenters[2]
    ];
  };
  FinderPatternFinder2.CENTER_QUORUM = 2;
  FinderPatternFinder2.MIN_SKIP = 3;
  FinderPatternFinder2.MAX_MODULES = 57;
  return FinderPatternFinder2;
}();
FinderPatternFinder$1.default = FinderPatternFinder;
var AlignmentPatternFinder$1 = {};
var AlignmentPattern$1 = {};
var __extends$6 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(AlignmentPattern$1, "__esModule", { value: true });
var ResultPoint_1$1 = ResultPoint$1;
var AlignmentPattern = function(_super) {
  __extends$6(AlignmentPattern2, _super);
  function AlignmentPattern2(posX, posY, estimatedModuleSize) {
    var _this = _super.call(this, posX, posY) || this;
    _this.estimatedModuleSize = estimatedModuleSize;
    return _this;
  }
  AlignmentPattern2.prototype.aboutEquals = function(moduleSize, i, j) {
    if (Math.abs(i - this.getY()) <= moduleSize && Math.abs(j - this.getX()) <= moduleSize) {
      var moduleSizeDiff = Math.abs(moduleSize - this.estimatedModuleSize);
      return moduleSizeDiff <= 1 || moduleSizeDiff <= this.estimatedModuleSize;
    }
    return false;
  };
  AlignmentPattern2.prototype.combineEstimate = function(i, j, newModuleSize) {
    var combinedX = (this.getX() + j) / 2;
    var combinedY = (this.getY() + i) / 2;
    var combinedModuleSize = (this.estimatedModuleSize + newModuleSize) / 2;
    return new AlignmentPattern2(combinedX, combinedY, combinedModuleSize);
  };
  return AlignmentPattern2;
}(ResultPoint_1$1.default);
AlignmentPattern$1.default = AlignmentPattern;
Object.defineProperty(AlignmentPatternFinder$1, "__esModule", { value: true });
var AlignmentPattern_1 = AlignmentPattern$1;
var NotFoundException_1$3 = NotFoundException$1;
var AlignmentPatternFinder = function() {
  function AlignmentPatternFinder2(image, startX, startY, width, height, moduleSize, resultPointCallback) {
    this.image = image;
    this.startX = startX;
    this.startY = startY;
    this.width = width;
    this.height = height;
    this.moduleSize = moduleSize;
    this.resultPointCallback = resultPointCallback;
    this.possibleCenters = [];
    this.crossCheckStateCount = new Int32Array(3);
  }
  AlignmentPatternFinder2.prototype.find = function() {
    var startX = this.startX;
    var height = this.height;
    var width = this.width;
    var maxJ = startX + width;
    var middleI = this.startY + height / 2;
    var stateCount = new Int32Array(3);
    var image = this.image;
    for (var iGen = 0; iGen < height; iGen++) {
      var i = middleI + ((iGen & 1) === 0 ? Math.floor((iGen + 1) / 2) : -Math.floor((iGen + 1) / 2));
      stateCount[0] = 0;
      stateCount[1] = 0;
      stateCount[2] = 0;
      var j = startX;
      while (j < maxJ && !image.get(j, i)) {
        j++;
      }
      var currentState = 0;
      while (j < maxJ) {
        if (image.get(j, i)) {
          if (currentState === 1) {
            stateCount[1]++;
          } else {
            if (currentState === 2) {
              if (this.foundPatternCross(stateCount)) {
                var confirmed = this.handlePossibleCenter(stateCount, i, j);
                if (confirmed !== null) {
                  return confirmed;
                }
              }
              stateCount[0] = stateCount[2];
              stateCount[1] = 1;
              stateCount[2] = 0;
              currentState = 1;
            } else {
              stateCount[++currentState]++;
            }
          }
        } else {
          if (currentState === 1) {
            currentState++;
          }
          stateCount[currentState]++;
        }
        j++;
      }
      if (this.foundPatternCross(stateCount)) {
        var confirmed = this.handlePossibleCenter(stateCount, i, maxJ);
        if (confirmed !== null) {
          return confirmed;
        }
      }
    }
    if (this.possibleCenters.length !== 0) {
      return this.possibleCenters[0];
    }
    throw new NotFoundException_1$3.default();
  };
  AlignmentPatternFinder2.centerFromEnd = function(stateCount, end) {
    return end - stateCount[2] - stateCount[1] / 2;
  };
  AlignmentPatternFinder2.prototype.foundPatternCross = function(stateCount) {
    var moduleSize = this.moduleSize;
    var maxVariance = moduleSize / 2;
    for (var i = 0; i < 3; i++) {
      if (Math.abs(moduleSize - stateCount[i]) >= maxVariance) {
        return false;
      }
    }
    return true;
  };
  AlignmentPatternFinder2.prototype.crossCheckVertical = function(startI, centerJ, maxCount, originalStateCountTotal) {
    var image = this.image;
    var maxI = image.getHeight();
    var stateCount = this.crossCheckStateCount;
    stateCount[0] = 0;
    stateCount[1] = 0;
    stateCount[2] = 0;
    var i = startI;
    while (i >= 0 && image.get(centerJ, i) && stateCount[1] <= maxCount) {
      stateCount[1]++;
      i--;
    }
    if (i < 0 || stateCount[1] > maxCount) {
      return NaN;
    }
    while (i >= 0 && !image.get(centerJ, i) && stateCount[0] <= maxCount) {
      stateCount[0]++;
      i--;
    }
    if (stateCount[0] > maxCount) {
      return NaN;
    }
    i = startI + 1;
    while (i < maxI && image.get(centerJ, i) && stateCount[1] <= maxCount) {
      stateCount[1]++;
      i++;
    }
    if (i === maxI || stateCount[1] > maxCount) {
      return NaN;
    }
    while (i < maxI && !image.get(centerJ, i) && stateCount[2] <= maxCount) {
      stateCount[2]++;
      i++;
    }
    if (stateCount[2] > maxCount) {
      return NaN;
    }
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2];
    if (5 * Math.abs(stateCountTotal - originalStateCountTotal) >= 2 * originalStateCountTotal) {
      return NaN;
    }
    return this.foundPatternCross(stateCount) ? AlignmentPatternFinder2.centerFromEnd(stateCount, i) : NaN;
  };
  AlignmentPatternFinder2.prototype.handlePossibleCenter = function(stateCount, i, j) {
    var stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2];
    var centerJ = AlignmentPatternFinder2.centerFromEnd(stateCount, j);
    var centerI = this.crossCheckVertical(i, centerJ, 2 * stateCount[1], stateCountTotal);
    if (!isNaN(centerI)) {
      var estimatedModuleSize = (stateCount[0] + stateCount[1] + stateCount[2]) / 3;
      for (var _i = 0, _a = this.possibleCenters; _i < _a.length; _i++) {
        var center = _a[_i];
        if (center.aboutEquals(estimatedModuleSize, centerI, centerJ)) {
          return center.combineEstimate(centerI, centerJ, estimatedModuleSize);
        }
      }
      var point = new AlignmentPattern_1.default(centerJ, centerI, estimatedModuleSize);
      this.possibleCenters.push(point);
      if (this.resultPointCallback !== null && this.resultPointCallback !== void 0) {
        this.resultPointCallback.foundPossibleResultPoint(point);
      }
    }
    return null;
  };
  return AlignmentPatternFinder2;
}();
AlignmentPatternFinder$1.default = AlignmentPatternFinder;
Object.defineProperty(Detector$1, "__esModule", { value: true });
var DecodeHintType_1$2 = DecodeHintType$1;
var ResultPoint_1 = ResultPoint$1;
var DetectorResult_1 = DetectorResult$1;
var GridSamplerInstance_1 = GridSamplerInstance$1;
var PerspectiveTransform_1 = PerspectiveTransform$1;
var MathUtils_1 = MathUtils$1;
var Version_1$1 = Version$1;
var FinderPatternFinder_1 = FinderPatternFinder$1;
var AlignmentPatternFinder_1 = AlignmentPatternFinder$1;
var NotFoundException_1$2 = NotFoundException$1;
var Detector = function() {
  function Detector2(image) {
    this.image = image;
  }
  Detector2.prototype.getImage = function() {
    return this.image;
  };
  Detector2.prototype.getResultPointCallback = function() {
    return this.resultPointCallback;
  };
  Detector2.prototype.detect = function(hints) {
    this.resultPointCallback = hints === null || hints === void 0 ? null : hints.get(DecodeHintType_1$2.default.NEED_RESULT_POINT_CALLBACK);
    var finder = new FinderPatternFinder_1.default(this.image, this.resultPointCallback);
    var info = finder.find(hints);
    return this.processFinderPatternInfo(info);
  };
  Detector2.prototype.processFinderPatternInfo = function(info) {
    var topLeft = info.getTopLeft();
    var topRight = info.getTopRight();
    var bottomLeft = info.getBottomLeft();
    var moduleSize = this.calculateModuleSize(topLeft, topRight, bottomLeft);
    if (moduleSize < 1) {
      throw new NotFoundException_1$2.default("No pattern found in proccess finder.");
    }
    var dimension = Detector2.computeDimension(topLeft, topRight, bottomLeft, moduleSize);
    var provisionalVersion = Version_1$1.default.getProvisionalVersionForDimension(dimension);
    var modulesBetweenFPCenters = provisionalVersion.getDimensionForVersion() - 7;
    var alignmentPattern = null;
    if (provisionalVersion.getAlignmentPatternCenters().length > 0) {
      var bottomRightX = topRight.getX() - topLeft.getX() + bottomLeft.getX();
      var bottomRightY = topRight.getY() - topLeft.getY() + bottomLeft.getY();
      var correctionToTopLeft = 1 - 3 / modulesBetweenFPCenters;
      var estAlignmentX = Math.floor(topLeft.getX() + correctionToTopLeft * (bottomRightX - topLeft.getX()));
      var estAlignmentY = Math.floor(topLeft.getY() + correctionToTopLeft * (bottomRightY - topLeft.getY()));
      for (var i = 4; i <= 16; i <<= 1) {
        try {
          alignmentPattern = this.findAlignmentInRegion(moduleSize, estAlignmentX, estAlignmentY, i);
          break;
        } catch (re) {
          if (!(re instanceof NotFoundException_1$2.default)) {
            throw re;
          }
        }
      }
    }
    var transform = Detector2.createTransform(topLeft, topRight, bottomLeft, alignmentPattern, dimension);
    var bits = Detector2.sampleGrid(this.image, transform, dimension);
    var points;
    if (alignmentPattern === null) {
      points = [bottomLeft, topLeft, topRight];
    } else {
      points = [bottomLeft, topLeft, topRight, alignmentPattern];
    }
    return new DetectorResult_1.default(bits, points);
  };
  Detector2.createTransform = function(topLeft, topRight, bottomLeft, alignmentPattern, dimension) {
    var dimMinusThree = dimension - 3.5;
    var bottomRightX;
    var bottomRightY;
    var sourceBottomRightX;
    var sourceBottomRightY;
    if (alignmentPattern !== null) {
      bottomRightX = alignmentPattern.getX();
      bottomRightY = alignmentPattern.getY();
      sourceBottomRightX = dimMinusThree - 3;
      sourceBottomRightY = sourceBottomRightX;
    } else {
      bottomRightX = topRight.getX() - topLeft.getX() + bottomLeft.getX();
      bottomRightY = topRight.getY() - topLeft.getY() + bottomLeft.getY();
      sourceBottomRightX = dimMinusThree;
      sourceBottomRightY = dimMinusThree;
    }
    return PerspectiveTransform_1.default.quadrilateralToQuadrilateral(3.5, 3.5, dimMinusThree, 3.5, sourceBottomRightX, sourceBottomRightY, 3.5, dimMinusThree, topLeft.getX(), topLeft.getY(), topRight.getX(), topRight.getY(), bottomRightX, bottomRightY, bottomLeft.getX(), bottomLeft.getY());
  };
  Detector2.sampleGrid = function(image, transform, dimension) {
    var sampler = GridSamplerInstance_1.default.getInstance();
    return sampler.sampleGridWithTransform(image, dimension, dimension, transform);
  };
  Detector2.computeDimension = function(topLeft, topRight, bottomLeft, moduleSize) {
    var tltrCentersDimension = MathUtils_1.default.round(ResultPoint_1.default.distance(topLeft, topRight) / moduleSize);
    var tlblCentersDimension = MathUtils_1.default.round(ResultPoint_1.default.distance(topLeft, bottomLeft) / moduleSize);
    var dimension = Math.floor((tltrCentersDimension + tlblCentersDimension) / 2) + 7;
    switch (dimension & 3) {
      case 0:
        dimension++;
        break;
      case 2:
        dimension--;
        break;
      case 3:
        throw new NotFoundException_1$2.default("Dimensions could be not found.");
    }
    return dimension;
  };
  Detector2.prototype.calculateModuleSize = function(topLeft, topRight, bottomLeft) {
    return (this.calculateModuleSizeOneWay(topLeft, topRight) + this.calculateModuleSizeOneWay(topLeft, bottomLeft)) / 2;
  };
  Detector2.prototype.calculateModuleSizeOneWay = function(pattern, otherPattern) {
    var moduleSizeEst1 = this.sizeOfBlackWhiteBlackRunBothWays(Math.floor(pattern.getX()), Math.floor(pattern.getY()), Math.floor(otherPattern.getX()), Math.floor(otherPattern.getY()));
    var moduleSizeEst2 = this.sizeOfBlackWhiteBlackRunBothWays(Math.floor(otherPattern.getX()), Math.floor(otherPattern.getY()), Math.floor(pattern.getX()), Math.floor(pattern.getY()));
    if (isNaN(moduleSizeEst1)) {
      return moduleSizeEst2 / 7;
    }
    if (isNaN(moduleSizeEst2)) {
      return moduleSizeEst1 / 7;
    }
    return (moduleSizeEst1 + moduleSizeEst2) / 14;
  };
  Detector2.prototype.sizeOfBlackWhiteBlackRunBothWays = function(fromX, fromY, toX, toY) {
    var result = this.sizeOfBlackWhiteBlackRun(fromX, fromY, toX, toY);
    var scale = 1;
    var otherToX = fromX - (toX - fromX);
    if (otherToX < 0) {
      scale = fromX / (fromX - otherToX);
      otherToX = 0;
    } else if (otherToX >= this.image.getWidth()) {
      scale = (this.image.getWidth() - 1 - fromX) / (otherToX - fromX);
      otherToX = this.image.getWidth() - 1;
    }
    var otherToY = Math.floor(fromY - (toY - fromY) * scale);
    scale = 1;
    if (otherToY < 0) {
      scale = fromY / (fromY - otherToY);
      otherToY = 0;
    } else if (otherToY >= this.image.getHeight()) {
      scale = (this.image.getHeight() - 1 - fromY) / (otherToY - fromY);
      otherToY = this.image.getHeight() - 1;
    }
    otherToX = Math.floor(fromX + (otherToX - fromX) * scale);
    result += this.sizeOfBlackWhiteBlackRun(fromX, fromY, otherToX, otherToY);
    return result - 1;
  };
  Detector2.prototype.sizeOfBlackWhiteBlackRun = function(fromX, fromY, toX, toY) {
    var steep = Math.abs(toY - fromY) > Math.abs(toX - fromX);
    if (steep) {
      var temp = fromX;
      fromX = fromY;
      fromY = temp;
      temp = toX;
      toX = toY;
      toY = temp;
    }
    var dx = Math.abs(toX - fromX);
    var dy = Math.abs(toY - fromY);
    var error = -dx / 2;
    var xstep = fromX < toX ? 1 : -1;
    var ystep = fromY < toY ? 1 : -1;
    var state = 0;
    var xLimit = toX + xstep;
    for (var x = fromX, y = fromY; x !== xLimit; x += xstep) {
      var realX = steep ? y : x;
      var realY = steep ? x : y;
      if (state === 1 === this.image.get(realX, realY)) {
        if (state === 2) {
          return MathUtils_1.default.distance(x, y, fromX, fromY);
        }
        state++;
      }
      error += dy;
      if (error > 0) {
        if (y === toY) {
          break;
        }
        y += ystep;
        error -= dx;
      }
    }
    if (state === 2) {
      return MathUtils_1.default.distance(toX + xstep, toY, fromX, fromY);
    }
    return NaN;
  };
  Detector2.prototype.findAlignmentInRegion = function(overallEstModuleSize, estAlignmentX, estAlignmentY, allowanceFactor) {
    var allowance = Math.floor(allowanceFactor * overallEstModuleSize);
    var alignmentAreaLeftX = Math.max(0, estAlignmentX - allowance);
    var alignmentAreaRightX = Math.min(this.image.getWidth() - 1, estAlignmentX + allowance);
    if (alignmentAreaRightX - alignmentAreaLeftX < overallEstModuleSize * 3) {
      throw new NotFoundException_1$2.default("Alignment top exceeds estimated module size.");
    }
    var alignmentAreaTopY = Math.max(0, estAlignmentY - allowance);
    var alignmentAreaBottomY = Math.min(this.image.getHeight() - 1, estAlignmentY + allowance);
    if (alignmentAreaBottomY - alignmentAreaTopY < overallEstModuleSize * 3) {
      throw new NotFoundException_1$2.default("Alignment bottom exceeds estimated module size.");
    }
    var alignmentFinder = new AlignmentPatternFinder_1.default(this.image, alignmentAreaLeftX, alignmentAreaTopY, alignmentAreaRightX - alignmentAreaLeftX, alignmentAreaBottomY - alignmentAreaTopY, overallEstModuleSize, this.resultPointCallback);
    return alignmentFinder.find();
  };
  return Detector2;
}();
Detector$1.default = Detector;
Object.defineProperty(QRCodeReader$1, "__esModule", { value: true });
var BarcodeFormat_1$3 = BarcodeFormat$1;
var DecodeHintType_1$1 = DecodeHintType$1;
var Result_1 = Result$1;
var ResultMetadataType_1 = ResultMetadataType$1;
var BitMatrix_1$1 = BitMatrix$1;
var Decoder_1 = Decoder$1;
var QRCodeDecoderMetaData_1 = QRCodeDecoderMetaData$1;
var Detector_1 = Detector$1;
var NotFoundException_1$1 = NotFoundException$1;
var QRCodeReader = function() {
  function QRCodeReader2() {
    this.decoder = new Decoder_1.default();
  }
  QRCodeReader2.prototype.getDecoder = function() {
    return this.decoder;
  };
  QRCodeReader2.prototype.decode = function(image, hints) {
    var decoderResult;
    var points;
    if (hints !== void 0 && hints !== null && void 0 !== hints.get(DecodeHintType_1$1.default.PURE_BARCODE)) {
      var bits = QRCodeReader2.extractPureBits(image.getBlackMatrix());
      decoderResult = this.decoder.decodeBitMatrix(bits, hints);
      points = QRCodeReader2.NO_POINTS;
    } else {
      var detectorResult = new Detector_1.default(image.getBlackMatrix()).detect(hints);
      decoderResult = this.decoder.decodeBitMatrix(detectorResult.getBits(), hints);
      points = detectorResult.getPoints();
    }
    if (decoderResult.getOther() instanceof QRCodeDecoderMetaData_1.default) {
      decoderResult.getOther().applyMirroredCorrection(points);
    }
    var result = new Result_1.default(decoderResult.getText(), decoderResult.getRawBytes(), void 0, points, BarcodeFormat_1$3.default.QR_CODE, void 0);
    var byteSegments = decoderResult.getByteSegments();
    if (byteSegments !== null) {
      result.putMetadata(ResultMetadataType_1.default.BYTE_SEGMENTS, byteSegments);
    }
    var ecLevel = decoderResult.getECLevel();
    if (ecLevel !== null) {
      result.putMetadata(ResultMetadataType_1.default.ERROR_CORRECTION_LEVEL, ecLevel);
    }
    if (decoderResult.hasStructuredAppend()) {
      result.putMetadata(ResultMetadataType_1.default.STRUCTURED_APPEND_SEQUENCE, decoderResult.getStructuredAppendSequenceNumber());
      result.putMetadata(ResultMetadataType_1.default.STRUCTURED_APPEND_PARITY, decoderResult.getStructuredAppendParity());
    }
    return result;
  };
  QRCodeReader2.prototype.reset = function() {
  };
  QRCodeReader2.extractPureBits = function(image) {
    var leftTopBlack = image.getTopLeftOnBit();
    var rightBottomBlack = image.getBottomRightOnBit();
    if (leftTopBlack === null || rightBottomBlack === null) {
      throw new NotFoundException_1$1.default();
    }
    var moduleSize = this.moduleSize(leftTopBlack, image);
    var top = leftTopBlack[1];
    var bottom = rightBottomBlack[1];
    var left = leftTopBlack[0];
    var right = rightBottomBlack[0];
    if (left >= right || top >= bottom) {
      throw new NotFoundException_1$1.default();
    }
    if (bottom - top !== right - left) {
      right = left + (bottom - top);
      if (right >= image.getWidth()) {
        throw new NotFoundException_1$1.default();
      }
    }
    var matrixWidth = Math.round((right - left + 1) / moduleSize);
    var matrixHeight = Math.round((bottom - top + 1) / moduleSize);
    if (matrixWidth <= 0 || matrixHeight <= 0) {
      throw new NotFoundException_1$1.default();
    }
    if (matrixHeight !== matrixWidth) {
      throw new NotFoundException_1$1.default();
    }
    var nudge = Math.floor(moduleSize / 2);
    top += nudge;
    left += nudge;
    var nudgedTooFarRight = left + Math.floor((matrixWidth - 1) * moduleSize) - right;
    if (nudgedTooFarRight > 0) {
      if (nudgedTooFarRight > nudge) {
        throw new NotFoundException_1$1.default();
      }
      left -= nudgedTooFarRight;
    }
    var nudgedTooFarDown = top + Math.floor((matrixHeight - 1) * moduleSize) - bottom;
    if (nudgedTooFarDown > 0) {
      if (nudgedTooFarDown > nudge) {
        throw new NotFoundException_1$1.default();
      }
      top -= nudgedTooFarDown;
    }
    var bits = new BitMatrix_1$1.default(matrixWidth, matrixHeight);
    for (var y = 0; y < matrixHeight; y++) {
      var iOffset = top + Math.floor(y * moduleSize);
      for (var x = 0; x < matrixWidth; x++) {
        if (image.get(left + Math.floor(x * moduleSize), iOffset)) {
          bits.set(x, y);
        }
      }
    }
    return bits;
  };
  QRCodeReader2.moduleSize = function(leftTopBlack, image) {
    var height = image.getHeight();
    var width = image.getWidth();
    var x = leftTopBlack[0];
    var y = leftTopBlack[1];
    var inBlack = true;
    var transitions = 0;
    while (x < width && y < height) {
      if (inBlack !== image.get(x, y)) {
        if (++transitions === 5) {
          break;
        }
        inBlack = !inBlack;
      }
      x++;
      y++;
    }
    if (x === width || y === height) {
      throw new NotFoundException_1$1.default();
    }
    return (x - leftTopBlack[0]) / 7;
  };
  QRCodeReader2.NO_POINTS = new Array();
  return QRCodeReader2;
}();
QRCodeReader$1.default = QRCodeReader;
var ReaderException$1 = {};
var __extends$5 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(ReaderException$1, "__esModule", { value: true });
var Exception_1$1 = Exception$1;
var ReaderException = function(_super) {
  __extends$5(ReaderException2, _super);
  function ReaderException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return ReaderException2;
}(Exception_1$1.default);
ReaderException$1.default = ReaderException;
Object.defineProperty(MultiFormatReader$1, "__esModule", { value: true });
var DecodeHintType_1 = DecodeHintType$1;
var BarcodeFormat_1$2 = BarcodeFormat$1;
var QRCodeReader_1$1 = QRCodeReader$1;
var MultiFormatOneDReader_1 = MultiFormatOneDReader$1;
var DataMatrixReader_1 = DataMatrixReader$1;
var NotFoundException_1 = NotFoundException$1;
var ReaderException_1 = ReaderException$1;
var MultiFormatReader = function() {
  function MultiFormatReader2() {
  }
  MultiFormatReader2.prototype.decode = function(image, hints) {
    this.setHints(hints);
    return this.decodeInternal(image);
  };
  MultiFormatReader2.prototype.decodeWithState = function(image) {
    if (this.readers === null || this.readers === void 0) {
      this.setHints(null);
    }
    return this.decodeInternal(image);
  };
  MultiFormatReader2.prototype.setHints = function(hints) {
    this.hints = hints;
    var tryHarder = hints !== null && hints !== void 0 && void 0 !== hints.get(DecodeHintType_1.default.TRY_HARDER);
    var formats = hints === null || hints === void 0 ? null : hints.get(DecodeHintType_1.default.POSSIBLE_FORMATS);
    var readers = new Array();
    if (formats !== null && formats !== void 0) {
      var addOneDReader = formats.some(function(f) {
        return f === BarcodeFormat_1$2.default.UPC_A || f === BarcodeFormat_1$2.default.UPC_E || f === BarcodeFormat_1$2.default.EAN_13 || f === BarcodeFormat_1$2.default.EAN_8 || f === BarcodeFormat_1$2.default.CODABAR || f === BarcodeFormat_1$2.default.CODE_39 || f === BarcodeFormat_1$2.default.CODE_93 || f === BarcodeFormat_1$2.default.CODE_128 || f === BarcodeFormat_1$2.default.ITF || f === BarcodeFormat_1$2.default.RSS_14 || f === BarcodeFormat_1$2.default.RSS_EXPANDED;
      });
      if (addOneDReader && !tryHarder) {
        readers.push(new MultiFormatOneDReader_1.default(hints));
      }
      if (formats.includes(BarcodeFormat_1$2.default.QR_CODE)) {
        readers.push(new QRCodeReader_1$1.default());
      }
      if (formats.includes(BarcodeFormat_1$2.default.DATA_MATRIX)) {
        readers.push(new DataMatrixReader_1.default());
      }
      if (addOneDReader && tryHarder) {
        readers.push(new MultiFormatOneDReader_1.default(hints));
      }
    }
    if (readers.length === 0) {
      if (!tryHarder) {
        readers.push(new MultiFormatOneDReader_1.default(hints));
      }
      readers.push(new QRCodeReader_1$1.default());
      readers.push(new DataMatrixReader_1.default());
      if (tryHarder) {
        readers.push(new MultiFormatOneDReader_1.default(hints));
      }
    }
    this.readers = readers;
  };
  MultiFormatReader2.prototype.reset = function() {
    if (this.readers !== null) {
      for (var _i = 0, _a = this.readers; _i < _a.length; _i++) {
        var reader = _a[_i];
        reader.reset();
      }
    }
  };
  MultiFormatReader2.prototype.decodeInternal = function(image) {
    if (this.readers === null) {
      throw new ReaderException_1.default("No readers where selected, nothing can be read.");
    }
    for (var _i = 0, _a = this.readers; _i < _a.length; _i++) {
      var reader = _a[_i];
      try {
        return reader.decode(image, this.hints);
      } catch (ex) {
        if (ex instanceof ReaderException_1.default) {
          continue;
        }
      }
    }
    throw new NotFoundException_1.default("No MultiFormat Readers were able to detect the code.");
  };
  return MultiFormatReader2;
}();
MultiFormatReader$1.default = MultiFormatReader;
var __extends$4 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(BrowserMultiFormatReader$1, "__esModule", { value: true });
var BrowserCodeReader_1$1 = BrowserCodeReader$1;
var MultiFormatReader_1 = MultiFormatReader$1;
var BrowserMultiFormatReader = function(_super) {
  __extends$4(BrowserMultiFormatReader2, _super);
  function BrowserMultiFormatReader2(hints, timeBetweenScansMillis) {
    if (hints === void 0) {
      hints = null;
    }
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    var _this = this;
    var reader = new MultiFormatReader_1.default();
    reader.setHints(hints);
    _this = _super.call(this, reader, timeBetweenScansMillis) || this;
    return _this;
  }
  BrowserMultiFormatReader2.prototype.decodeBitmap = function(binaryBitmap) {
    return this.reader.decodeWithState(binaryBitmap);
  };
  return BrowserMultiFormatReader2;
}(BrowserCodeReader_1$1.BrowserCodeReader);
BrowserMultiFormatReader$1.BrowserMultiFormatReader = BrowserMultiFormatReader;
var BrowserQRCodeReader$1 = {};
var __extends$3 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(BrowserQRCodeReader$1, "__esModule", { value: true });
var BrowserCodeReader_1 = BrowserCodeReader$1;
var QRCodeReader_1 = QRCodeReader$1;
var BrowserQRCodeReader = function(_super) {
  __extends$3(BrowserQRCodeReader2, _super);
  function BrowserQRCodeReader2(timeBetweenScansMillis) {
    if (timeBetweenScansMillis === void 0) {
      timeBetweenScansMillis = 500;
    }
    return _super.call(this, new QRCodeReader_1.default(), timeBetweenScansMillis) || this;
  }
  return BrowserQRCodeReader2;
}(BrowserCodeReader_1.BrowserCodeReader);
BrowserQRCodeReader$1.BrowserQRCodeReader = BrowserQRCodeReader;
var BrowserQRCodeSvgWriter$1 = {};
var EncodeHintType$1 = {};
Object.defineProperty(EncodeHintType$1, "__esModule", { value: true });
var EncodeHintType;
(function(EncodeHintType2) {
  EncodeHintType2[EncodeHintType2["ERROR_CORRECTION"] = 0] = "ERROR_CORRECTION";
  EncodeHintType2[EncodeHintType2["CHARACTER_SET"] = 1] = "CHARACTER_SET";
  EncodeHintType2[EncodeHintType2["DATA_MATRIX_SHAPE"] = 2] = "DATA_MATRIX_SHAPE";
  EncodeHintType2[EncodeHintType2["MIN_SIZE"] = 3] = "MIN_SIZE";
  EncodeHintType2[EncodeHintType2["MAX_SIZE"] = 4] = "MAX_SIZE";
  EncodeHintType2[EncodeHintType2["MARGIN"] = 5] = "MARGIN";
  EncodeHintType2[EncodeHintType2["PDF417_COMPACT"] = 6] = "PDF417_COMPACT";
  EncodeHintType2[EncodeHintType2["PDF417_COMPACTION"] = 7] = "PDF417_COMPACTION";
  EncodeHintType2[EncodeHintType2["PDF417_DIMENSIONS"] = 8] = "PDF417_DIMENSIONS";
  EncodeHintType2[EncodeHintType2["AZTEC_LAYERS"] = 9] = "AZTEC_LAYERS";
  EncodeHintType2[EncodeHintType2["QR_VERSION"] = 10] = "QR_VERSION";
})(EncodeHintType || (EncodeHintType = {}));
EncodeHintType$1.default = EncodeHintType;
var Encoder$1 = {};
var ReedSolomonEncoder$1 = {};
Object.defineProperty(ReedSolomonEncoder$1, "__esModule", { value: true });
var GenericGFPoly_1 = GenericGFPoly$1;
var System_1$2 = System$1;
var IllegalArgumentException_1$7 = IllegalArgumentException$1;
var ReedSolomonEncoder = function() {
  function ReedSolomonEncoder2(field) {
    this.field = field;
    this.cachedGenerators = [];
    this.cachedGenerators.push(new GenericGFPoly_1.default(field, Int32Array.from([1])));
  }
  ReedSolomonEncoder2.prototype.buildGenerator = function(degree) {
    var cachedGenerators = this.cachedGenerators;
    if (degree >= cachedGenerators.length) {
      var lastGenerator = cachedGenerators[cachedGenerators.length - 1];
      var field = this.field;
      for (var d = cachedGenerators.length; d <= degree; d++) {
        var nextGenerator = lastGenerator.multiply(new GenericGFPoly_1.default(field, Int32Array.from([1, field.exp(d - 1 + field.getGeneratorBase())])));
        cachedGenerators.push(nextGenerator);
        lastGenerator = nextGenerator;
      }
    }
    return cachedGenerators[degree];
  };
  ReedSolomonEncoder2.prototype.encode = function(toEncode, ecBytes) {
    if (ecBytes === 0) {
      throw new IllegalArgumentException_1$7.default("No error correction bytes");
    }
    var dataBytes = toEncode.length - ecBytes;
    if (dataBytes <= 0) {
      throw new IllegalArgumentException_1$7.default("No data bytes provided");
    }
    var generator = this.buildGenerator(ecBytes);
    var infoCoefficients = new Int32Array(dataBytes);
    System_1$2.default.arraycopy(toEncode, 0, infoCoefficients, 0, dataBytes);
    var info = new GenericGFPoly_1.default(this.field, infoCoefficients);
    info = info.multiplyByMonomial(ecBytes, 1);
    var remainder = info.divide(generator)[1];
    var coefficients = remainder.getCoefficients();
    var numZeroCoefficients = ecBytes - coefficients.length;
    for (var i = 0; i < numZeroCoefficients; i++) {
      toEncode[dataBytes + i] = 0;
    }
    System_1$2.default.arraycopy(coefficients, 0, toEncode, dataBytes + numZeroCoefficients, coefficients.length);
  };
  return ReedSolomonEncoder2;
}();
ReedSolomonEncoder$1.default = ReedSolomonEncoder;
var MaskUtil$1 = {};
Object.defineProperty(MaskUtil$1, "__esModule", { value: true });
var IllegalArgumentException_1$6 = IllegalArgumentException$1;
var MaskUtil = function() {
  function MaskUtil2() {
  }
  MaskUtil2.applyMaskPenaltyRule1 = function(matrix) {
    return MaskUtil2.applyMaskPenaltyRule1Internal(matrix, true) + MaskUtil2.applyMaskPenaltyRule1Internal(matrix, false);
  };
  MaskUtil2.applyMaskPenaltyRule2 = function(matrix) {
    var penalty = 0;
    var array = matrix.getArray();
    var width = matrix.getWidth();
    var height = matrix.getHeight();
    for (var y = 0; y < height - 1; y++) {
      var arrayY = array[y];
      for (var x = 0; x < width - 1; x++) {
        var value = arrayY[x];
        if (value === arrayY[x + 1] && value === array[y + 1][x] && value === array[y + 1][x + 1]) {
          penalty++;
        }
      }
    }
    return MaskUtil2.N2 * penalty;
  };
  MaskUtil2.applyMaskPenaltyRule3 = function(matrix) {
    var numPenalties = 0;
    var array = matrix.getArray();
    var width = matrix.getWidth();
    var height = matrix.getHeight();
    for (var y = 0; y < height; y++) {
      for (var x = 0; x < width; x++) {
        var arrayY = array[y];
        if (x + 6 < width && arrayY[x] === 1 && arrayY[x + 1] === 0 && arrayY[x + 2] === 1 && arrayY[x + 3] === 1 && arrayY[x + 4] === 1 && arrayY[x + 5] === 0 && arrayY[x + 6] === 1 && (MaskUtil2.isWhiteHorizontal(arrayY, x - 4, x) || MaskUtil2.isWhiteHorizontal(arrayY, x + 7, x + 11))) {
          numPenalties++;
        }
        if (y + 6 < height && array[y][x] === 1 && array[y + 1][x] === 0 && array[y + 2][x] === 1 && array[y + 3][x] === 1 && array[y + 4][x] === 1 && array[y + 5][x] === 0 && array[y + 6][x] === 1 && (MaskUtil2.isWhiteVertical(array, x, y - 4, y) || MaskUtil2.isWhiteVertical(array, x, y + 7, y + 11))) {
          numPenalties++;
        }
      }
    }
    return numPenalties * MaskUtil2.N3;
  };
  MaskUtil2.isWhiteHorizontal = function(rowArray, from, to) {
    from = Math.max(from, 0);
    to = Math.min(to, rowArray.length);
    for (var i = from; i < to; i++) {
      if (rowArray[i] === 1) {
        return false;
      }
    }
    return true;
  };
  MaskUtil2.isWhiteVertical = function(array, col, from, to) {
    from = Math.max(from, 0);
    to = Math.min(to, array.length);
    for (var i = from; i < to; i++) {
      if (array[i][col] === 1) {
        return false;
      }
    }
    return true;
  };
  MaskUtil2.applyMaskPenaltyRule4 = function(matrix) {
    var numDarkCells = 0;
    var array = matrix.getArray();
    var width = matrix.getWidth();
    var height = matrix.getHeight();
    for (var y = 0; y < height; y++) {
      var arrayY = array[y];
      for (var x = 0; x < width; x++) {
        if (arrayY[x] === 1) {
          numDarkCells++;
        }
      }
    }
    var numTotalCells = matrix.getHeight() * matrix.getWidth();
    var fivePercentVariances = Math.floor(Math.abs(numDarkCells * 2 - numTotalCells) * 10 / numTotalCells);
    return fivePercentVariances * MaskUtil2.N4;
  };
  MaskUtil2.getDataMaskBit = function(maskPattern, x, y) {
    var intermediate;
    var temp;
    switch (maskPattern) {
      case 0:
        intermediate = y + x & 1;
        break;
      case 1:
        intermediate = y & 1;
        break;
      case 2:
        intermediate = x % 3;
        break;
      case 3:
        intermediate = (y + x) % 3;
        break;
      case 4:
        intermediate = Math.floor(y / 2) + Math.floor(x / 3) & 1;
        break;
      case 5:
        temp = y * x;
        intermediate = (temp & 1) + temp % 3;
        break;
      case 6:
        temp = y * x;
        intermediate = (temp & 1) + temp % 3 & 1;
        break;
      case 7:
        temp = y * x;
        intermediate = temp % 3 + (y + x & 1) & 1;
        break;
      default:
        throw new IllegalArgumentException_1$6.default("Invalid mask pattern: " + maskPattern);
    }
    return intermediate === 0;
  };
  MaskUtil2.applyMaskPenaltyRule1Internal = function(matrix, isHorizontal) {
    var penalty = 0;
    var iLimit = isHorizontal ? matrix.getHeight() : matrix.getWidth();
    var jLimit = isHorizontal ? matrix.getWidth() : matrix.getHeight();
    var array = matrix.getArray();
    for (var i = 0; i < iLimit; i++) {
      var numSameBitCells = 0;
      var prevBit = -1;
      for (var j = 0; j < jLimit; j++) {
        var bit = isHorizontal ? array[i][j] : array[j][i];
        if (bit === prevBit) {
          numSameBitCells++;
        } else {
          if (numSameBitCells >= 5) {
            penalty += MaskUtil2.N1 + (numSameBitCells - 5);
          }
          numSameBitCells = 1;
          prevBit = bit;
        }
      }
      if (numSameBitCells >= 5) {
        penalty += MaskUtil2.N1 + (numSameBitCells - 5);
      }
    }
    return penalty;
  };
  MaskUtil2.N1 = 3;
  MaskUtil2.N2 = 3;
  MaskUtil2.N3 = 40;
  MaskUtil2.N4 = 10;
  return MaskUtil2;
}();
MaskUtil$1.default = MaskUtil;
var ByteMatrix$1 = {};
Object.defineProperty(ByteMatrix$1, "__esModule", { value: true });
var Arrays_1 = Arrays$1;
var StringBuilder_1$1 = StringBuilder$1;
var ByteMatrix = function() {
  function ByteMatrix2(width, height) {
    this.width = width;
    this.height = height;
    var bytes = new Array(height);
    for (var i = 0; i !== height; i++) {
      bytes[i] = new Uint8Array(width);
    }
    this.bytes = bytes;
  }
  ByteMatrix2.prototype.getHeight = function() {
    return this.height;
  };
  ByteMatrix2.prototype.getWidth = function() {
    return this.width;
  };
  ByteMatrix2.prototype.get = function(x, y) {
    return this.bytes[y][x];
  };
  ByteMatrix2.prototype.getArray = function() {
    return this.bytes;
  };
  ByteMatrix2.prototype.setNumber = function(x, y, value) {
    this.bytes[y][x] = value;
  };
  ByteMatrix2.prototype.setBoolean = function(x, y, value) {
    this.bytes[y][x] = value ? 1 : 0;
  };
  ByteMatrix2.prototype.clear = function(value) {
    for (var _i = 0, _a = this.bytes; _i < _a.length; _i++) {
      var aByte = _a[_i];
      Arrays_1.default.fillUint8Array(aByte, value);
    }
  };
  ByteMatrix2.prototype.equals = function(o) {
    if (!(o instanceof ByteMatrix2)) {
      return false;
    }
    var other = o;
    if (this.width !== other.width) {
      return false;
    }
    if (this.height !== other.height) {
      return false;
    }
    for (var y = 0, height = this.height; y < height; ++y) {
      var bytesY = this.bytes[y];
      var otherBytesY = other.bytes[y];
      for (var x = 0, width = this.width; x < width; ++x) {
        if (bytesY[x] !== otherBytesY[x]) {
          return false;
        }
      }
    }
    return true;
  };
  ByteMatrix2.prototype.toString = function() {
    var result = new StringBuilder_1$1.default();
    for (var y = 0, height = this.height; y < height; ++y) {
      var bytesY = this.bytes[y];
      for (var x = 0, width = this.width; x < width; ++x) {
        switch (bytesY[x]) {
          case 0:
            result.append(" 0");
            break;
          case 1:
            result.append(" 1");
            break;
          default:
            result.append("  ");
            break;
        }
      }
      result.append("\n");
    }
    return result.toString();
  };
  return ByteMatrix2;
}();
ByteMatrix$1.default = ByteMatrix;
var QRCode$1 = {};
Object.defineProperty(QRCode$1, "__esModule", { value: true });
var StringBuilder_1 = StringBuilder$1;
var QRCode = function() {
  function QRCode2() {
    this.maskPattern = -1;
  }
  QRCode2.prototype.getMode = function() {
    return this.mode;
  };
  QRCode2.prototype.getECLevel = function() {
    return this.ecLevel;
  };
  QRCode2.prototype.getVersion = function() {
    return this.version;
  };
  QRCode2.prototype.getMaskPattern = function() {
    return this.maskPattern;
  };
  QRCode2.prototype.getMatrix = function() {
    return this.matrix;
  };
  QRCode2.prototype.toString = function() {
    var result = new StringBuilder_1.default();
    result.append("<<\n");
    result.append(" mode: ");
    result.append(this.mode ? this.mode.toString() : "null");
    result.append("\n ecLevel: ");
    result.append(this.ecLevel ? this.ecLevel.toString() : "null");
    result.append("\n version: ");
    result.append(this.version ? this.version.toString() : "null");
    result.append("\n maskPattern: ");
    result.append(this.maskPattern.toString());
    if (this.matrix) {
      result.append("\n matrix:\n");
      result.append(this.matrix.toString());
    } else {
      result.append("\n matrix: null\n");
    }
    result.append(">>\n");
    return result.toString();
  };
  QRCode2.prototype.setMode = function(value) {
    this.mode = value;
  };
  QRCode2.prototype.setECLevel = function(value) {
    this.ecLevel = value;
  };
  QRCode2.prototype.setVersion = function(version) {
    this.version = version;
  };
  QRCode2.prototype.setMaskPattern = function(value) {
    this.maskPattern = value;
  };
  QRCode2.prototype.setMatrix = function(value) {
    this.matrix = value;
  };
  QRCode2.isValidMaskPattern = function(maskPattern) {
    return maskPattern >= 0 && maskPattern < QRCode2.NUM_MASK_PATTERNS;
  };
  QRCode2.NUM_MASK_PATTERNS = 8;
  return QRCode2;
}();
QRCode$1.default = QRCode;
var MatrixUtil$1 = {};
var WriterException$1 = {};
var __extends$2 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(WriterException$1, "__esModule", { value: true });
var Exception_1 = Exception$1;
var WriterException = function(_super) {
  __extends$2(WriterException2, _super);
  function WriterException2() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return WriterException2;
}(Exception_1.default);
WriterException$1.default = WriterException;
Object.defineProperty(MatrixUtil$1, "__esModule", { value: true });
var BitArray_1$1 = BitArray$1;
var Integer_1 = Integer$1;
var QRCode_1$1 = QRCode$1;
var MaskUtil_1$1 = MaskUtil$1;
var WriterException_1$1 = WriterException$1;
var IllegalArgumentException_1$5 = IllegalArgumentException$1;
var MatrixUtil = function() {
  function MatrixUtil2() {
  }
  MatrixUtil2.clearMatrix = function(matrix) {
    matrix.clear(255);
  };
  MatrixUtil2.buildMatrix = function(dataBits, ecLevel, version, maskPattern, matrix) {
    MatrixUtil2.clearMatrix(matrix);
    MatrixUtil2.embedBasicPatterns(version, matrix);
    MatrixUtil2.embedTypeInfo(ecLevel, maskPattern, matrix);
    MatrixUtil2.maybeEmbedVersionInfo(version, matrix);
    MatrixUtil2.embedDataBits(dataBits, maskPattern, matrix);
  };
  MatrixUtil2.embedBasicPatterns = function(version, matrix) {
    MatrixUtil2.embedPositionDetectionPatternsAndSeparators(matrix);
    MatrixUtil2.embedDarkDotAtLeftBottomCorner(matrix);
    MatrixUtil2.maybeEmbedPositionAdjustmentPatterns(version, matrix);
    MatrixUtil2.embedTimingPatterns(matrix);
  };
  MatrixUtil2.embedTypeInfo = function(ecLevel, maskPattern, matrix) {
    var typeInfoBits = new BitArray_1$1.default();
    MatrixUtil2.makeTypeInfoBits(ecLevel, maskPattern, typeInfoBits);
    for (var i = 0, size = typeInfoBits.getSize(); i < size; ++i) {
      var bit = typeInfoBits.get(typeInfoBits.getSize() - 1 - i);
      var coordinates = MatrixUtil2.TYPE_INFO_COORDINATES[i];
      var x1 = coordinates[0];
      var y1 = coordinates[1];
      matrix.setBoolean(x1, y1, bit);
      if (i < 8) {
        var x2 = matrix.getWidth() - i - 1;
        var y2 = 8;
        matrix.setBoolean(x2, y2, bit);
      } else {
        var x2 = 8;
        var y2 = matrix.getHeight() - 7 + (i - 8);
        matrix.setBoolean(x2, y2, bit);
      }
    }
  };
  MatrixUtil2.maybeEmbedVersionInfo = function(version, matrix) {
    if (version.getVersionNumber() < 7) {
      return;
    }
    var versionInfoBits = new BitArray_1$1.default();
    MatrixUtil2.makeVersionInfoBits(version, versionInfoBits);
    var bitIndex = 6 * 3 - 1;
    for (var i = 0; i < 6; ++i) {
      for (var j = 0; j < 3; ++j) {
        var bit = versionInfoBits.get(bitIndex);
        bitIndex--;
        matrix.setBoolean(i, matrix.getHeight() - 11 + j, bit);
        matrix.setBoolean(matrix.getHeight() - 11 + j, i, bit);
      }
    }
  };
  MatrixUtil2.embedDataBits = function(dataBits, maskPattern, matrix) {
    var bitIndex = 0;
    var direction = -1;
    var x = matrix.getWidth() - 1;
    var y = matrix.getHeight() - 1;
    while (x > 0) {
      if (x === 6) {
        x -= 1;
      }
      while (y >= 0 && y < matrix.getHeight()) {
        for (var i = 0; i < 2; ++i) {
          var xx = x - i;
          if (!MatrixUtil2.isEmpty(matrix.get(xx, y))) {
            continue;
          }
          var bit = void 0;
          if (bitIndex < dataBits.getSize()) {
            bit = dataBits.get(bitIndex);
            ++bitIndex;
          } else {
            bit = false;
          }
          if (maskPattern !== 255 && MaskUtil_1$1.default.getDataMaskBit(maskPattern, xx, y)) {
            bit = !bit;
          }
          matrix.setBoolean(xx, y, bit);
        }
        y += direction;
      }
      direction = -direction;
      y += direction;
      x -= 2;
    }
    if (bitIndex !== dataBits.getSize()) {
      throw new WriterException_1$1.default("Not all bits consumed: " + bitIndex + "/" + dataBits.getSize());
    }
  };
  MatrixUtil2.findMSBSet = function(value) {
    return 32 - Integer_1.default.numberOfLeadingZeros(value);
  };
  MatrixUtil2.calculateBCHCode = function(value, poly) {
    if (poly === 0) {
      throw new IllegalArgumentException_1$5.default("0 polynomial");
    }
    var msbSetInPoly = MatrixUtil2.findMSBSet(poly);
    value <<= msbSetInPoly - 1;
    while (MatrixUtil2.findMSBSet(value) >= msbSetInPoly) {
      value ^= poly << MatrixUtil2.findMSBSet(value) - msbSetInPoly;
    }
    return value;
  };
  MatrixUtil2.makeTypeInfoBits = function(ecLevel, maskPattern, bits) {
    if (!QRCode_1$1.default.isValidMaskPattern(maskPattern)) {
      throw new WriterException_1$1.default("Invalid mask pattern");
    }
    var typeInfo = ecLevel.getBits() << 3 | maskPattern;
    bits.appendBits(typeInfo, 5);
    var bchCode = MatrixUtil2.calculateBCHCode(typeInfo, MatrixUtil2.TYPE_INFO_POLY);
    bits.appendBits(bchCode, 10);
    var maskBits = new BitArray_1$1.default();
    maskBits.appendBits(MatrixUtil2.TYPE_INFO_MASK_PATTERN, 15);
    bits.xor(maskBits);
    if (bits.getSize() !== 15) {
      throw new WriterException_1$1.default("should not happen but we got: " + bits.getSize());
    }
  };
  MatrixUtil2.makeVersionInfoBits = function(version, bits) {
    bits.appendBits(version.getVersionNumber(), 6);
    var bchCode = MatrixUtil2.calculateBCHCode(version.getVersionNumber(), MatrixUtil2.VERSION_INFO_POLY);
    bits.appendBits(bchCode, 12);
    if (bits.getSize() !== 18) {
      throw new WriterException_1$1.default("should not happen but we got: " + bits.getSize());
    }
  };
  MatrixUtil2.isEmpty = function(value) {
    return value === 255;
  };
  MatrixUtil2.embedTimingPatterns = function(matrix) {
    for (var i = 8; i < matrix.getWidth() - 8; ++i) {
      var bit = (i + 1) % 2;
      if (MatrixUtil2.isEmpty(matrix.get(i, 6))) {
        matrix.setNumber(i, 6, bit);
      }
      if (MatrixUtil2.isEmpty(matrix.get(6, i))) {
        matrix.setNumber(6, i, bit);
      }
    }
  };
  MatrixUtil2.embedDarkDotAtLeftBottomCorner = function(matrix) {
    if (matrix.get(8, matrix.getHeight() - 8) === 0) {
      throw new WriterException_1$1.default();
    }
    matrix.setNumber(8, matrix.getHeight() - 8, 1);
  };
  MatrixUtil2.embedHorizontalSeparationPattern = function(xStart, yStart, matrix) {
    for (var x = 0; x < 8; ++x) {
      if (!MatrixUtil2.isEmpty(matrix.get(xStart + x, yStart))) {
        throw new WriterException_1$1.default();
      }
      matrix.setNumber(xStart + x, yStart, 0);
    }
  };
  MatrixUtil2.embedVerticalSeparationPattern = function(xStart, yStart, matrix) {
    for (var y = 0; y < 7; ++y) {
      if (!MatrixUtil2.isEmpty(matrix.get(xStart, yStart + y))) {
        throw new WriterException_1$1.default();
      }
      matrix.setNumber(xStart, yStart + y, 0);
    }
  };
  MatrixUtil2.embedPositionAdjustmentPattern = function(xStart, yStart, matrix) {
    for (var y = 0; y < 5; ++y) {
      var patternY = MatrixUtil2.POSITION_ADJUSTMENT_PATTERN[y];
      for (var x = 0; x < 5; ++x) {
        matrix.setNumber(xStart + x, yStart + y, patternY[x]);
      }
    }
  };
  MatrixUtil2.embedPositionDetectionPattern = function(xStart, yStart, matrix) {
    for (var y = 0; y < 7; ++y) {
      var patternY = MatrixUtil2.POSITION_DETECTION_PATTERN[y];
      for (var x = 0; x < 7; ++x) {
        matrix.setNumber(xStart + x, yStart + y, patternY[x]);
      }
    }
  };
  MatrixUtil2.embedPositionDetectionPatternsAndSeparators = function(matrix) {
    var pdpWidth = MatrixUtil2.POSITION_DETECTION_PATTERN[0].length;
    MatrixUtil2.embedPositionDetectionPattern(0, 0, matrix);
    MatrixUtil2.embedPositionDetectionPattern(matrix.getWidth() - pdpWidth, 0, matrix);
    MatrixUtil2.embedPositionDetectionPattern(0, matrix.getWidth() - pdpWidth, matrix);
    var hspWidth = 8;
    MatrixUtil2.embedHorizontalSeparationPattern(0, hspWidth - 1, matrix);
    MatrixUtil2.embedHorizontalSeparationPattern(matrix.getWidth() - hspWidth, hspWidth - 1, matrix);
    MatrixUtil2.embedHorizontalSeparationPattern(0, matrix.getWidth() - hspWidth, matrix);
    var vspSize = 7;
    MatrixUtil2.embedVerticalSeparationPattern(vspSize, 0, matrix);
    MatrixUtil2.embedVerticalSeparationPattern(matrix.getHeight() - vspSize - 1, 0, matrix);
    MatrixUtil2.embedVerticalSeparationPattern(vspSize, matrix.getHeight() - vspSize, matrix);
  };
  MatrixUtil2.maybeEmbedPositionAdjustmentPatterns = function(version, matrix) {
    if (version.getVersionNumber() < 2) {
      return;
    }
    var index = version.getVersionNumber() - 1;
    var coordinates = MatrixUtil2.POSITION_ADJUSTMENT_PATTERN_COORDINATE_TABLE[index];
    for (var i = 0, length_1 = coordinates.length; i !== length_1; i++) {
      var y = coordinates[i];
      if (y >= 0) {
        for (var j = 0; j !== length_1; j++) {
          var x = coordinates[j];
          if (x >= 0 && MatrixUtil2.isEmpty(matrix.get(x, y))) {
            MatrixUtil2.embedPositionAdjustmentPattern(x - 2, y - 2, matrix);
          }
        }
      }
    }
  };
  MatrixUtil2.POSITION_DETECTION_PATTERN = Array.from([
    Int32Array.from([1, 1, 1, 1, 1, 1, 1]),
    Int32Array.from([1, 0, 0, 0, 0, 0, 1]),
    Int32Array.from([1, 0, 1, 1, 1, 0, 1]),
    Int32Array.from([1, 0, 1, 1, 1, 0, 1]),
    Int32Array.from([1, 0, 1, 1, 1, 0, 1]),
    Int32Array.from([1, 0, 0, 0, 0, 0, 1]),
    Int32Array.from([1, 1, 1, 1, 1, 1, 1])
  ]);
  MatrixUtil2.POSITION_ADJUSTMENT_PATTERN = Array.from([
    Int32Array.from([1, 1, 1, 1, 1]),
    Int32Array.from([1, 0, 0, 0, 1]),
    Int32Array.from([1, 0, 1, 0, 1]),
    Int32Array.from([1, 0, 0, 0, 1]),
    Int32Array.from([1, 1, 1, 1, 1])
  ]);
  MatrixUtil2.POSITION_ADJUSTMENT_PATTERN_COORDINATE_TABLE = Array.from([
    Int32Array.from([-1, -1, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 18, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 22, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 26, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 30, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 34, -1, -1, -1, -1, -1]),
    Int32Array.from([6, 22, 38, -1, -1, -1, -1]),
    Int32Array.from([6, 24, 42, -1, -1, -1, -1]),
    Int32Array.from([6, 26, 46, -1, -1, -1, -1]),
    Int32Array.from([6, 28, 50, -1, -1, -1, -1]),
    Int32Array.from([6, 30, 54, -1, -1, -1, -1]),
    Int32Array.from([6, 32, 58, -1, -1, -1, -1]),
    Int32Array.from([6, 34, 62, -1, -1, -1, -1]),
    Int32Array.from([6, 26, 46, 66, -1, -1, -1]),
    Int32Array.from([6, 26, 48, 70, -1, -1, -1]),
    Int32Array.from([6, 26, 50, 74, -1, -1, -1]),
    Int32Array.from([6, 30, 54, 78, -1, -1, -1]),
    Int32Array.from([6, 30, 56, 82, -1, -1, -1]),
    Int32Array.from([6, 30, 58, 86, -1, -1, -1]),
    Int32Array.from([6, 34, 62, 90, -1, -1, -1]),
    Int32Array.from([6, 28, 50, 72, 94, -1, -1]),
    Int32Array.from([6, 26, 50, 74, 98, -1, -1]),
    Int32Array.from([6, 30, 54, 78, 102, -1, -1]),
    Int32Array.from([6, 28, 54, 80, 106, -1, -1]),
    Int32Array.from([6, 32, 58, 84, 110, -1, -1]),
    Int32Array.from([6, 30, 58, 86, 114, -1, -1]),
    Int32Array.from([6, 34, 62, 90, 118, -1, -1]),
    Int32Array.from([6, 26, 50, 74, 98, 122, -1]),
    Int32Array.from([6, 30, 54, 78, 102, 126, -1]),
    Int32Array.from([6, 26, 52, 78, 104, 130, -1]),
    Int32Array.from([6, 30, 56, 82, 108, 134, -1]),
    Int32Array.from([6, 34, 60, 86, 112, 138, -1]),
    Int32Array.from([6, 30, 58, 86, 114, 142, -1]),
    Int32Array.from([6, 34, 62, 90, 118, 146, -1]),
    Int32Array.from([6, 30, 54, 78, 102, 126, 150]),
    Int32Array.from([6, 24, 50, 76, 102, 128, 154]),
    Int32Array.from([6, 28, 54, 80, 106, 132, 158]),
    Int32Array.from([6, 32, 58, 84, 110, 136, 162]),
    Int32Array.from([6, 26, 54, 82, 110, 138, 166]),
    Int32Array.from([6, 30, 58, 86, 114, 142, 170])
  ]);
  MatrixUtil2.TYPE_INFO_COORDINATES = Array.from([
    Int32Array.from([8, 0]),
    Int32Array.from([8, 1]),
    Int32Array.from([8, 2]),
    Int32Array.from([8, 3]),
    Int32Array.from([8, 4]),
    Int32Array.from([8, 5]),
    Int32Array.from([8, 7]),
    Int32Array.from([8, 8]),
    Int32Array.from([7, 8]),
    Int32Array.from([5, 8]),
    Int32Array.from([4, 8]),
    Int32Array.from([3, 8]),
    Int32Array.from([2, 8]),
    Int32Array.from([1, 8]),
    Int32Array.from([0, 8])
  ]);
  MatrixUtil2.VERSION_INFO_POLY = 7973;
  MatrixUtil2.TYPE_INFO_POLY = 1335;
  MatrixUtil2.TYPE_INFO_MASK_PATTERN = 21522;
  return MatrixUtil2;
}();
MatrixUtil$1.default = MatrixUtil;
var BlockPair$1 = {};
Object.defineProperty(BlockPair$1, "__esModule", { value: true });
var BlockPair = function() {
  function BlockPair2(dataBytes, errorCorrectionBytes) {
    this.dataBytes = dataBytes;
    this.errorCorrectionBytes = errorCorrectionBytes;
  }
  BlockPair2.prototype.getDataBytes = function() {
    return this.dataBytes;
  };
  BlockPair2.prototype.getErrorCorrectionBytes = function() {
    return this.errorCorrectionBytes;
  };
  return BlockPair2;
}();
BlockPair$1.default = BlockPair;
Object.defineProperty(Encoder$1, "__esModule", { value: true });
var EncodeHintType_1$2 = EncodeHintType$1;
var BitArray_1 = BitArray$1;
var CharacterSetECI_1 = CharacterSetECI;
var GenericGF_1 = GenericGF$1;
var ReedSolomonEncoder_1 = ReedSolomonEncoder$1;
var Mode_1 = Mode;
var Version_1 = Version$1;
var MaskUtil_1 = MaskUtil$1;
var ByteMatrix_1 = ByteMatrix$1;
var QRCode_1 = QRCode$1;
var MatrixUtil_1 = MatrixUtil$1;
var StringEncoding_1 = StringEncoding$1;
var BlockPair_1 = BlockPair$1;
var WriterException_1 = WriterException$1;
var Encoder = function() {
  function Encoder2() {
  }
  Encoder2.calculateMaskPenalty = function(matrix) {
    return MaskUtil_1.default.applyMaskPenaltyRule1(matrix) + MaskUtil_1.default.applyMaskPenaltyRule2(matrix) + MaskUtil_1.default.applyMaskPenaltyRule3(matrix) + MaskUtil_1.default.applyMaskPenaltyRule4(matrix);
  };
  Encoder2.encode = function(content, ecLevel, hints) {
    if (hints === void 0) {
      hints = null;
    }
    var encoding = Encoder2.DEFAULT_BYTE_MODE_ENCODING;
    var hasEncodingHint = hints !== null && void 0 !== hints.get(EncodeHintType_1$2.default.CHARACTER_SET);
    if (hasEncodingHint) {
      encoding = hints.get(EncodeHintType_1$2.default.CHARACTER_SET).toString();
    }
    var mode = this.chooseMode(content, encoding);
    var headerBits = new BitArray_1.default();
    if (mode === Mode_1.default.BYTE && (hasEncodingHint || Encoder2.DEFAULT_BYTE_MODE_ENCODING !== encoding)) {
      var eci = CharacterSetECI_1.default.getCharacterSetECIByName(encoding);
      if (eci !== void 0) {
        this.appendECI(eci, headerBits);
      }
    }
    this.appendModeInfo(mode, headerBits);
    var dataBits = new BitArray_1.default();
    this.appendBytes(content, mode, dataBits, encoding);
    var version;
    if (hints !== null && void 0 !== hints.get(EncodeHintType_1$2.default.QR_VERSION)) {
      var versionNumber = Number.parseInt(hints.get(EncodeHintType_1$2.default.QR_VERSION).toString(), 10);
      version = Version_1.default.getVersionForNumber(versionNumber);
      var bitsNeeded = this.calculateBitsNeeded(mode, headerBits, dataBits, version);
      if (!this.willFit(bitsNeeded, version, ecLevel)) {
        throw new WriterException_1.default("Data too big for requested version");
      }
    } else {
      version = this.recommendVersion(ecLevel, mode, headerBits, dataBits);
    }
    var headerAndDataBits = new BitArray_1.default();
    headerAndDataBits.appendBitArray(headerBits);
    var numLetters = mode === Mode_1.default.BYTE ? dataBits.getSizeInBytes() : content.length;
    this.appendLengthInfo(numLetters, version, mode, headerAndDataBits);
    headerAndDataBits.appendBitArray(dataBits);
    var ecBlocks = version.getECBlocksForLevel(ecLevel);
    var numDataBytes = version.getTotalCodewords() - ecBlocks.getTotalECCodewords();
    this.terminateBits(numDataBytes, headerAndDataBits);
    var finalBits = this.interleaveWithECBytes(headerAndDataBits, version.getTotalCodewords(), numDataBytes, ecBlocks.getNumBlocks());
    var qrCode = new QRCode_1.default();
    qrCode.setECLevel(ecLevel);
    qrCode.setMode(mode);
    qrCode.setVersion(version);
    var dimension = version.getDimensionForVersion();
    var matrix = new ByteMatrix_1.default(dimension, dimension);
    var maskPattern = this.chooseMaskPattern(finalBits, ecLevel, version, matrix);
    qrCode.setMaskPattern(maskPattern);
    MatrixUtil_1.default.buildMatrix(finalBits, ecLevel, version, maskPattern, matrix);
    qrCode.setMatrix(matrix);
    return qrCode;
  };
  Encoder2.recommendVersion = function(ecLevel, mode, headerBits, dataBits) {
    var provisionalBitsNeeded = this.calculateBitsNeeded(mode, headerBits, dataBits, Version_1.default.getVersionForNumber(1));
    var provisionalVersion = this.chooseVersion(provisionalBitsNeeded, ecLevel);
    var bitsNeeded = this.calculateBitsNeeded(mode, headerBits, dataBits, provisionalVersion);
    return this.chooseVersion(bitsNeeded, ecLevel);
  };
  Encoder2.calculateBitsNeeded = function(mode, headerBits, dataBits, version) {
    return headerBits.getSize() + mode.getCharacterCountBits(version) + dataBits.getSize();
  };
  Encoder2.getAlphanumericCode = function(code) {
    if (code < Encoder2.ALPHANUMERIC_TABLE.length) {
      return Encoder2.ALPHANUMERIC_TABLE[code];
    }
    return -1;
  };
  Encoder2.chooseMode = function(content, encoding) {
    if (encoding === void 0) {
      encoding = null;
    }
    if (CharacterSetECI_1.default.SJIS.getName() === encoding && this.isOnlyDoubleByteKanji(content)) {
      return Mode_1.default.KANJI;
    }
    var hasNumeric = false;
    var hasAlphanumeric = false;
    for (var i = 0, length_1 = content.length; i < length_1; ++i) {
      var c = content.charAt(i);
      if (Encoder2.isDigit(c)) {
        hasNumeric = true;
      } else if (this.getAlphanumericCode(c.charCodeAt(0)) !== -1) {
        hasAlphanumeric = true;
      } else {
        return Mode_1.default.BYTE;
      }
    }
    if (hasAlphanumeric) {
      return Mode_1.default.ALPHANUMERIC;
    }
    if (hasNumeric) {
      return Mode_1.default.NUMERIC;
    }
    return Mode_1.default.BYTE;
  };
  Encoder2.isOnlyDoubleByteKanji = function(content) {
    var bytes;
    try {
      bytes = StringEncoding_1.default.encode(content, CharacterSetECI_1.default.SJIS);
    } catch (ignored) {
      return false;
    }
    var length = bytes.length;
    if (length % 2 !== 0) {
      return false;
    }
    for (var i = 0; i < length; i += 2) {
      var byte1 = bytes[i] & 255;
      if ((byte1 < 129 || byte1 > 159) && (byte1 < 224 || byte1 > 235)) {
        return false;
      }
    }
    return true;
  };
  Encoder2.chooseMaskPattern = function(bits, ecLevel, version, matrix) {
    var minPenalty = Number.MAX_SAFE_INTEGER;
    var bestMaskPattern = -1;
    for (var maskPattern = 0; maskPattern < QRCode_1.default.NUM_MASK_PATTERNS; maskPattern++) {
      MatrixUtil_1.default.buildMatrix(bits, ecLevel, version, maskPattern, matrix);
      var penalty = this.calculateMaskPenalty(matrix);
      if (penalty < minPenalty) {
        minPenalty = penalty;
        bestMaskPattern = maskPattern;
      }
    }
    return bestMaskPattern;
  };
  Encoder2.chooseVersion = function(numInputBits, ecLevel) {
    for (var versionNum = 1; versionNum <= 40; versionNum++) {
      var version = Version_1.default.getVersionForNumber(versionNum);
      if (Encoder2.willFit(numInputBits, version, ecLevel)) {
        return version;
      }
    }
    throw new WriterException_1.default("Data too big");
  };
  Encoder2.willFit = function(numInputBits, version, ecLevel) {
    var numBytes = version.getTotalCodewords();
    var ecBlocks = version.getECBlocksForLevel(ecLevel);
    var numEcBytes = ecBlocks.getTotalECCodewords();
    var numDataBytes = numBytes - numEcBytes;
    var totalInputBytes = (numInputBits + 7) / 8;
    return numDataBytes >= totalInputBytes;
  };
  Encoder2.terminateBits = function(numDataBytes, bits) {
    var capacity = numDataBytes * 8;
    if (bits.getSize() > capacity) {
      throw new WriterException_1.default("data bits cannot fit in the QR Code" + bits.getSize() + " > " + capacity);
    }
    for (var i = 0; i < 4 && bits.getSize() < capacity; ++i) {
      bits.appendBit(false);
    }
    var numBitsInLastByte = bits.getSize() & 7;
    if (numBitsInLastByte > 0) {
      for (var i = numBitsInLastByte; i < 8; i++) {
        bits.appendBit(false);
      }
    }
    var numPaddingBytes = numDataBytes - bits.getSizeInBytes();
    for (var i = 0; i < numPaddingBytes; ++i) {
      bits.appendBits((i & 1) === 0 ? 236 : 17, 8);
    }
    if (bits.getSize() !== capacity) {
      throw new WriterException_1.default("Bits size does not equal capacity");
    }
  };
  Encoder2.getNumDataBytesAndNumECBytesForBlockID = function(numTotalBytes, numDataBytes, numRSBlocks, blockID, numDataBytesInBlock, numECBytesInBlock) {
    if (blockID >= numRSBlocks) {
      throw new WriterException_1.default("Block ID too large");
    }
    var numRsBlocksInGroup2 = numTotalBytes % numRSBlocks;
    var numRsBlocksInGroup1 = numRSBlocks - numRsBlocksInGroup2;
    var numTotalBytesInGroup1 = Math.floor(numTotalBytes / numRSBlocks);
    var numTotalBytesInGroup2 = numTotalBytesInGroup1 + 1;
    var numDataBytesInGroup1 = Math.floor(numDataBytes / numRSBlocks);
    var numDataBytesInGroup2 = numDataBytesInGroup1 + 1;
    var numEcBytesInGroup1 = numTotalBytesInGroup1 - numDataBytesInGroup1;
    var numEcBytesInGroup2 = numTotalBytesInGroup2 - numDataBytesInGroup2;
    if (numEcBytesInGroup1 !== numEcBytesInGroup2) {
      throw new WriterException_1.default("EC bytes mismatch");
    }
    if (numRSBlocks !== numRsBlocksInGroup1 + numRsBlocksInGroup2) {
      throw new WriterException_1.default("RS blocks mismatch");
    }
    if (numTotalBytes !== (numDataBytesInGroup1 + numEcBytesInGroup1) * numRsBlocksInGroup1 + (numDataBytesInGroup2 + numEcBytesInGroup2) * numRsBlocksInGroup2) {
      throw new WriterException_1.default("Total bytes mismatch");
    }
    if (blockID < numRsBlocksInGroup1) {
      numDataBytesInBlock[0] = numDataBytesInGroup1;
      numECBytesInBlock[0] = numEcBytesInGroup1;
    } else {
      numDataBytesInBlock[0] = numDataBytesInGroup2;
      numECBytesInBlock[0] = numEcBytesInGroup2;
    }
  };
  Encoder2.interleaveWithECBytes = function(bits, numTotalBytes, numDataBytes, numRSBlocks) {
    if (bits.getSizeInBytes() !== numDataBytes) {
      throw new WriterException_1.default("Number of bits and data bytes does not match");
    }
    var dataBytesOffset = 0;
    var maxNumDataBytes = 0;
    var maxNumEcBytes = 0;
    var blocks = new Array();
    for (var i = 0; i < numRSBlocks; ++i) {
      var numDataBytesInBlock = new Int32Array(1);
      var numEcBytesInBlock = new Int32Array(1);
      Encoder2.getNumDataBytesAndNumECBytesForBlockID(numTotalBytes, numDataBytes, numRSBlocks, i, numDataBytesInBlock, numEcBytesInBlock);
      var size = numDataBytesInBlock[0];
      var dataBytes = new Uint8Array(size);
      bits.toBytes(8 * dataBytesOffset, dataBytes, 0, size);
      var ecBytes = Encoder2.generateECBytes(dataBytes, numEcBytesInBlock[0]);
      blocks.push(new BlockPair_1.default(dataBytes, ecBytes));
      maxNumDataBytes = Math.max(maxNumDataBytes, size);
      maxNumEcBytes = Math.max(maxNumEcBytes, ecBytes.length);
      dataBytesOffset += numDataBytesInBlock[0];
    }
    if (numDataBytes !== dataBytesOffset) {
      throw new WriterException_1.default("Data bytes does not match offset");
    }
    var result = new BitArray_1.default();
    for (var i = 0; i < maxNumDataBytes; ++i) {
      for (var _i = 0, blocks_1 = blocks; _i < blocks_1.length; _i++) {
        var block = blocks_1[_i];
        var dataBytes = block.getDataBytes();
        if (i < dataBytes.length) {
          result.appendBits(dataBytes[i], 8);
        }
      }
    }
    for (var i = 0; i < maxNumEcBytes; ++i) {
      for (var _a = 0, blocks_2 = blocks; _a < blocks_2.length; _a++) {
        var block = blocks_2[_a];
        var ecBytes = block.getErrorCorrectionBytes();
        if (i < ecBytes.length) {
          result.appendBits(ecBytes[i], 8);
        }
      }
    }
    if (numTotalBytes !== result.getSizeInBytes()) {
      throw new WriterException_1.default("Interleaving error: " + numTotalBytes + " and " + result.getSizeInBytes() + " differ.");
    }
    return result;
  };
  Encoder2.generateECBytes = function(dataBytes, numEcBytesInBlock) {
    var numDataBytes = dataBytes.length;
    var toEncode = new Int32Array(numDataBytes + numEcBytesInBlock);
    for (var i = 0; i < numDataBytes; i++) {
      toEncode[i] = dataBytes[i] & 255;
    }
    new ReedSolomonEncoder_1.default(GenericGF_1.default.QR_CODE_FIELD_256).encode(toEncode, numEcBytesInBlock);
    var ecBytes = new Uint8Array(numEcBytesInBlock);
    for (var i = 0; i < numEcBytesInBlock; i++) {
      ecBytes[i] = toEncode[numDataBytes + i];
    }
    return ecBytes;
  };
  Encoder2.appendModeInfo = function(mode, bits) {
    bits.appendBits(mode.getBits(), 4);
  };
  Encoder2.appendLengthInfo = function(numLetters, version, mode, bits) {
    var numBits = mode.getCharacterCountBits(version);
    if (numLetters >= 1 << numBits) {
      throw new WriterException_1.default(numLetters + " is bigger than " + ((1 << numBits) - 1));
    }
    bits.appendBits(numLetters, numBits);
  };
  Encoder2.appendBytes = function(content, mode, bits, encoding) {
    switch (mode) {
      case Mode_1.default.NUMERIC:
        Encoder2.appendNumericBytes(content, bits);
        break;
      case Mode_1.default.ALPHANUMERIC:
        Encoder2.appendAlphanumericBytes(content, bits);
        break;
      case Mode_1.default.BYTE:
        Encoder2.append8BitBytes(content, bits, encoding);
        break;
      case Mode_1.default.KANJI:
        Encoder2.appendKanjiBytes(content, bits);
        break;
      default:
        throw new WriterException_1.default("Invalid mode: " + mode);
    }
  };
  Encoder2.getDigit = function(singleCharacter) {
    return singleCharacter.charCodeAt(0) - 48;
  };
  Encoder2.isDigit = function(singleCharacter) {
    var cn = Encoder2.getDigit(singleCharacter);
    return cn >= 0 && cn <= 9;
  };
  Encoder2.appendNumericBytes = function(content, bits) {
    var length = content.length;
    var i = 0;
    while (i < length) {
      var num1 = Encoder2.getDigit(content.charAt(i));
      if (i + 2 < length) {
        var num2 = Encoder2.getDigit(content.charAt(i + 1));
        var num3 = Encoder2.getDigit(content.charAt(i + 2));
        bits.appendBits(num1 * 100 + num2 * 10 + num3, 10);
        i += 3;
      } else if (i + 1 < length) {
        var num2 = Encoder2.getDigit(content.charAt(i + 1));
        bits.appendBits(num1 * 10 + num2, 7);
        i += 2;
      } else {
        bits.appendBits(num1, 4);
        i++;
      }
    }
  };
  Encoder2.appendAlphanumericBytes = function(content, bits) {
    var length = content.length;
    var i = 0;
    while (i < length) {
      var code1 = Encoder2.getAlphanumericCode(content.charCodeAt(i));
      if (code1 === -1) {
        throw new WriterException_1.default();
      }
      if (i + 1 < length) {
        var code2 = Encoder2.getAlphanumericCode(content.charCodeAt(i + 1));
        if (code2 === -1) {
          throw new WriterException_1.default();
        }
        bits.appendBits(code1 * 45 + code2, 11);
        i += 2;
      } else {
        bits.appendBits(code1, 6);
        i++;
      }
    }
  };
  Encoder2.append8BitBytes = function(content, bits, encoding) {
    var bytes;
    try {
      bytes = StringEncoding_1.default.encode(content, encoding);
    } catch (uee) {
      throw new WriterException_1.default(uee);
    }
    for (var i = 0, length_2 = bytes.length; i !== length_2; i++) {
      var b = bytes[i];
      bits.appendBits(b, 8);
    }
  };
  Encoder2.appendKanjiBytes = function(content, bits) {
    var bytes;
    try {
      bytes = StringEncoding_1.default.encode(content, CharacterSetECI_1.default.SJIS);
    } catch (uee) {
      throw new WriterException_1.default(uee);
    }
    var length = bytes.length;
    for (var i = 0; i < length; i += 2) {
      var byte1 = bytes[i] & 255;
      var byte2 = bytes[i + 1] & 255;
      var code = byte1 << 8 & 4294967295 | byte2;
      var subtracted = -1;
      if (code >= 33088 && code <= 40956) {
        subtracted = code - 33088;
      } else if (code >= 57408 && code <= 60351) {
        subtracted = code - 49472;
      }
      if (subtracted === -1) {
        throw new WriterException_1.default("Invalid byte sequence");
      }
      var encoded = (subtracted >> 8) * 192 + (subtracted & 255);
      bits.appendBits(encoded, 13);
    }
  };
  Encoder2.appendECI = function(eci, bits) {
    bits.appendBits(Mode_1.default.ECI.getBits(), 4);
    bits.appendBits(eci.getValue(), 8);
  };
  Encoder2.ALPHANUMERIC_TABLE = Int32Array.from([
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    36,
    -1,
    -1,
    -1,
    37,
    38,
    -1,
    -1,
    -1,
    -1,
    39,
    40,
    -1,
    41,
    42,
    43,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    44,
    -1,
    -1,
    -1,
    -1,
    -1,
    -1,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    -1,
    -1,
    -1,
    -1,
    -1
  ]);
  Encoder2.DEFAULT_BYTE_MODE_ENCODING = CharacterSetECI_1.default.UTF8.getName();
  return Encoder2;
}();
Encoder$1.default = Encoder;
Object.defineProperty(BrowserQRCodeSvgWriter$1, "__esModule", { value: true });
var EncodeHintType_1$1 = EncodeHintType$1;
var Encoder_1$1 = Encoder$1;
var ErrorCorrectionLevel_1$1 = ErrorCorrectionLevel;
var IllegalArgumentException_1$4 = IllegalArgumentException$1;
var IllegalStateException_1$1 = IllegalStateException$1;
var BrowserQRCodeSvgWriter = function() {
  function BrowserQRCodeSvgWriter2() {
  }
  BrowserQRCodeSvgWriter2.prototype.write = function(contents, width, height, hints) {
    if (hints === void 0) {
      hints = null;
    }
    if (contents.length === 0) {
      throw new IllegalArgumentException_1$4.default("Found empty contents");
    }
    if (width < 0 || height < 0) {
      throw new IllegalArgumentException_1$4.default("Requested dimensions are too small: " + width + "x" + height);
    }
    var errorCorrectionLevel = ErrorCorrectionLevel_1$1.default.L;
    var quietZone = BrowserQRCodeSvgWriter2.QUIET_ZONE_SIZE;
    if (hints !== null) {
      if (void 0 !== hints.get(EncodeHintType_1$1.default.ERROR_CORRECTION)) {
        errorCorrectionLevel = ErrorCorrectionLevel_1$1.default.fromString(hints.get(EncodeHintType_1$1.default.ERROR_CORRECTION).toString());
      }
      if (void 0 !== hints.get(EncodeHintType_1$1.default.MARGIN)) {
        quietZone = Number.parseInt(hints.get(EncodeHintType_1$1.default.MARGIN).toString(), 10);
      }
    }
    var code = Encoder_1$1.default.encode(contents, errorCorrectionLevel, hints);
    return this.renderResult(code, width, height, quietZone);
  };
  BrowserQRCodeSvgWriter2.prototype.writeToDom = function(containerElement, contents, width, height, hints) {
    if (hints === void 0) {
      hints = null;
    }
    if (typeof containerElement === "string") {
      containerElement = document.querySelector(containerElement);
    }
    var svgElement = this.write(contents, width, height, hints);
    if (containerElement)
      containerElement.appendChild(svgElement);
  };
  BrowserQRCodeSvgWriter2.prototype.renderResult = function(code, width, height, quietZone) {
    var input = code.getMatrix();
    if (input === null) {
      throw new IllegalStateException_1$1.default();
    }
    var inputWidth = input.getWidth();
    var inputHeight = input.getHeight();
    var qrWidth = inputWidth + quietZone * 2;
    var qrHeight = inputHeight + quietZone * 2;
    var outputWidth = Math.max(width, qrWidth);
    var outputHeight = Math.max(height, qrHeight);
    var multiple = Math.min(Math.floor(outputWidth / qrWidth), Math.floor(outputHeight / qrHeight));
    var leftPadding = Math.floor((outputWidth - inputWidth * multiple) / 2);
    var topPadding = Math.floor((outputHeight - inputHeight * multiple) / 2);
    var svgElement = this.createSVGElement(outputWidth, outputHeight);
    for (var inputY = 0, outputY = topPadding; inputY < inputHeight; inputY++, outputY += multiple) {
      for (var inputX = 0, outputX = leftPadding; inputX < inputWidth; inputX++, outputX += multiple) {
        if (input.get(inputX, inputY) === 1) {
          var svgRectElement = this.createSvgRectElement(outputX, outputY, multiple, multiple);
          svgElement.appendChild(svgRectElement);
        }
      }
    }
    return svgElement;
  };
  BrowserQRCodeSvgWriter2.prototype.createSVGElement = function(w, h) {
    var svgElement = document.createElementNS(BrowserQRCodeSvgWriter2.SVG_NS, "svg");
    svgElement.setAttributeNS(null, "height", w.toString());
    svgElement.setAttributeNS(null, "width", h.toString());
    return svgElement;
  };
  BrowserQRCodeSvgWriter2.prototype.createSvgRectElement = function(x, y, w, h) {
    var rect = document.createElementNS(BrowserQRCodeSvgWriter2.SVG_NS, "rect");
    rect.setAttributeNS(null, "x", x.toString());
    rect.setAttributeNS(null, "y", y.toString());
    rect.setAttributeNS(null, "height", w.toString());
    rect.setAttributeNS(null, "width", h.toString());
    rect.setAttributeNS(null, "fill", "#000000");
    return rect;
  };
  BrowserQRCodeSvgWriter2.QUIET_ZONE_SIZE = 4;
  BrowserQRCodeSvgWriter2.SVG_NS = "http://www.w3.org/2000/svg";
  return BrowserQRCodeSvgWriter2;
}();
BrowserQRCodeSvgWriter$1.BrowserQRCodeSvgWriter = BrowserQRCodeSvgWriter;
var MultiFormatWriter$1 = {};
var QRCodeWriter$1 = {};
Object.defineProperty(QRCodeWriter$1, "__esModule", { value: true });
var BarcodeFormat_1$1 = BarcodeFormat$1;
var EncodeHintType_1 = EncodeHintType$1;
var BitMatrix_1 = BitMatrix$1;
var ErrorCorrectionLevel_1 = ErrorCorrectionLevel;
var Encoder_1 = Encoder$1;
var IllegalArgumentException_1$3 = IllegalArgumentException$1;
var IllegalStateException_1 = IllegalStateException$1;
var QRCodeWriter = function() {
  function QRCodeWriter2() {
  }
  QRCodeWriter2.prototype.encode = function(contents, format, width, height, hints) {
    if (contents.length === 0) {
      throw new IllegalArgumentException_1$3.default("Found empty contents");
    }
    if (format !== BarcodeFormat_1$1.default.QR_CODE) {
      throw new IllegalArgumentException_1$3.default("Can only encode QR_CODE, but got " + format);
    }
    if (width < 0 || height < 0) {
      throw new IllegalArgumentException_1$3.default("Requested dimensions are too small: " + width + "x" + height);
    }
    var errorCorrectionLevel = ErrorCorrectionLevel_1.default.L;
    var quietZone = QRCodeWriter2.QUIET_ZONE_SIZE;
    if (hints !== null) {
      if (void 0 !== hints.get(EncodeHintType_1.default.ERROR_CORRECTION)) {
        errorCorrectionLevel = ErrorCorrectionLevel_1.default.fromString(hints.get(EncodeHintType_1.default.ERROR_CORRECTION).toString());
      }
      if (void 0 !== hints.get(EncodeHintType_1.default.MARGIN)) {
        quietZone = Number.parseInt(hints.get(EncodeHintType_1.default.MARGIN).toString(), 10);
      }
    }
    var code = Encoder_1.default.encode(contents, errorCorrectionLevel, hints);
    return QRCodeWriter2.renderResult(code, width, height, quietZone);
  };
  QRCodeWriter2.renderResult = function(code, width, height, quietZone) {
    var input = code.getMatrix();
    if (input === null) {
      throw new IllegalStateException_1.default();
    }
    var inputWidth = input.getWidth();
    var inputHeight = input.getHeight();
    var qrWidth = inputWidth + quietZone * 2;
    var qrHeight = inputHeight + quietZone * 2;
    var outputWidth = Math.max(width, qrWidth);
    var outputHeight = Math.max(height, qrHeight);
    var multiple = Math.min(Math.floor(outputWidth / qrWidth), Math.floor(outputHeight / qrHeight));
    var leftPadding = Math.floor((outputWidth - inputWidth * multiple) / 2);
    var topPadding = Math.floor((outputHeight - inputHeight * multiple) / 2);
    var output = new BitMatrix_1.default(outputWidth, outputHeight);
    for (var inputY = 0, outputY = topPadding; inputY < inputHeight; inputY++, outputY += multiple) {
      for (var inputX = 0, outputX = leftPadding; inputX < inputWidth; inputX++, outputX += multiple) {
        if (input.get(inputX, inputY) === 1) {
          output.setRegion(outputX, outputY, multiple, multiple);
        }
      }
    }
    return output;
  };
  QRCodeWriter2.QUIET_ZONE_SIZE = 4;
  return QRCodeWriter2;
}();
QRCodeWriter$1.default = QRCodeWriter;
Object.defineProperty(MultiFormatWriter$1, "__esModule", { value: true });
var QRCodeWriter_1 = QRCodeWriter$1;
var BarcodeFormat_1 = BarcodeFormat$1;
var IllegalArgumentException_1$2 = IllegalArgumentException$1;
var MultiFormatWriter = function() {
  function MultiFormatWriter2() {
  }
  MultiFormatWriter2.prototype.encode = function(contents, format, width, height, hints) {
    var writer;
    switch (format) {
      case BarcodeFormat_1.default.QR_CODE:
        writer = new QRCodeWriter_1.default();
        break;
      default:
        throw new IllegalArgumentException_1$2.default("No encoder available for format " + format);
    }
    return writer.encode(contents, format, width, height, hints);
  };
  return MultiFormatWriter2;
}();
MultiFormatWriter$1.default = MultiFormatWriter;
var PlanarYUVLuminanceSource$1 = {};
var __extends$1 = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(PlanarYUVLuminanceSource$1, "__esModule", { value: true });
var System_1$1 = System$1;
var LuminanceSource_1$1 = LuminanceSource$1;
var InvertedLuminanceSource_1$1 = InvertedLuminanceSource$1;
var IllegalArgumentException_1$1 = IllegalArgumentException$1;
var PlanarYUVLuminanceSource = function(_super) {
  __extends$1(PlanarYUVLuminanceSource2, _super);
  function PlanarYUVLuminanceSource2(yuvData, dataWidth, dataHeight, left, top, width, height, reverseHorizontal) {
    var _this = _super.call(this, width, height) || this;
    _this.yuvData = yuvData;
    _this.dataWidth = dataWidth;
    _this.dataHeight = dataHeight;
    _this.left = left;
    _this.top = top;
    if (left + width > dataWidth || top + height > dataHeight) {
      throw new IllegalArgumentException_1$1.default("Crop rectangle does not fit within image data.");
    }
    if (reverseHorizontal) {
      _this.reverseHorizontal(width, height);
    }
    return _this;
  }
  PlanarYUVLuminanceSource2.prototype.getRow = function(y, row) {
    if (y < 0 || y >= this.getHeight()) {
      throw new IllegalArgumentException_1$1.default("Requested row is outside the image: " + y);
    }
    var width = this.getWidth();
    if (row === null || row === void 0 || row.length < width) {
      row = new Uint8ClampedArray(width);
    }
    var offset = (y + this.top) * this.dataWidth + this.left;
    System_1$1.default.arraycopy(this.yuvData, offset, row, 0, width);
    return row;
  };
  PlanarYUVLuminanceSource2.prototype.getMatrix = function() {
    var width = this.getWidth();
    var height = this.getHeight();
    if (width === this.dataWidth && height === this.dataHeight) {
      return this.yuvData;
    }
    var area = width * height;
    var matrix = new Uint8ClampedArray(area);
    var inputOffset = this.top * this.dataWidth + this.left;
    if (width === this.dataWidth) {
      System_1$1.default.arraycopy(this.yuvData, inputOffset, matrix, 0, area);
      return matrix;
    }
    for (var y = 0; y < height; y++) {
      var outputOffset = y * width;
      System_1$1.default.arraycopy(this.yuvData, inputOffset, matrix, outputOffset, width);
      inputOffset += this.dataWidth;
    }
    return matrix;
  };
  PlanarYUVLuminanceSource2.prototype.isCropSupported = function() {
    return true;
  };
  PlanarYUVLuminanceSource2.prototype.crop = function(left, top, width, height) {
    return new PlanarYUVLuminanceSource2(this.yuvData, this.dataWidth, this.dataHeight, this.left + left, this.top + top, width, height, false);
  };
  PlanarYUVLuminanceSource2.prototype.renderThumbnail = function() {
    var width = this.getWidth() / PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR;
    var height = this.getHeight() / PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR;
    var pixels = new Int32Array(width * height);
    var yuv = this.yuvData;
    var inputOffset = this.top * this.dataWidth + this.left;
    for (var y = 0; y < height; y++) {
      var outputOffset = y * width;
      for (var x = 0; x < width; x++) {
        var grey = yuv[inputOffset + x * PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR] & 255;
        pixels[outputOffset + x] = 4278190080 | grey * 65793;
      }
      inputOffset += this.dataWidth * PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR;
    }
    return pixels;
  };
  PlanarYUVLuminanceSource2.prototype.getThumbnailWidth = function() {
    return this.getWidth() / PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR;
  };
  PlanarYUVLuminanceSource2.prototype.getThumbnailHeight = function() {
    return this.getHeight() / PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR;
  };
  PlanarYUVLuminanceSource2.prototype.reverseHorizontal = function(width, height) {
    var yuvData = this.yuvData;
    for (var y = 0, rowStart = this.top * this.dataWidth + this.left; y < height; y++, rowStart += this.dataWidth) {
      var middle = rowStart + width / 2;
      for (var x1 = rowStart, x2 = rowStart + width - 1; x1 < middle; x1++, x2--) {
        var temp = yuvData[x1];
        yuvData[x1] = yuvData[x2];
        yuvData[x2] = temp;
      }
    }
  };
  PlanarYUVLuminanceSource2.prototype.invert = function() {
    return new InvertedLuminanceSource_1$1.default(this);
  };
  PlanarYUVLuminanceSource2.THUMBNAIL_SCALE_FACTOR = 2;
  return PlanarYUVLuminanceSource2;
}(LuminanceSource_1$1.default);
PlanarYUVLuminanceSource$1.default = PlanarYUVLuminanceSource;
var RGBLuminanceSource$1 = {};
var __extends = commonjsGlobal && commonjsGlobal.__extends || function() {
  var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
      d2.__proto__ = b2;
    } || function(d2, b2) {
      for (var p in b2)
        if (b2.hasOwnProperty(p))
          d2[p] = b2[p];
    };
    return extendStatics(d, b);
  };
  return function(d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(RGBLuminanceSource$1, "__esModule", { value: true });
var InvertedLuminanceSource_1 = InvertedLuminanceSource$1;
var LuminanceSource_1 = LuminanceSource$1;
var System_1 = System$1;
var IllegalArgumentException_1 = IllegalArgumentException$1;
var RGBLuminanceSource = function(_super) {
  __extends(RGBLuminanceSource2, _super);
  function RGBLuminanceSource2(luminances, width, height, dataWidth, dataHeight, left, top) {
    var _this = _super.call(this, width, height) || this;
    _this.dataWidth = dataWidth;
    _this.dataHeight = dataHeight;
    _this.left = left;
    _this.top = top;
    if (luminances.BYTES_PER_ELEMENT === 4) {
      var size = width * height;
      var luminancesUint8Array = new Uint8ClampedArray(size);
      for (var offset = 0; offset < size; offset++) {
        var pixel = luminances[offset];
        var r = pixel >> 16 & 255;
        var g2 = pixel >> 7 & 510;
        var b = pixel & 255;
        luminancesUint8Array[offset] = (r + g2 + b) / 4 & 255;
      }
      _this.luminances = luminancesUint8Array;
    } else {
      _this.luminances = luminances;
    }
    if (void 0 === dataWidth) {
      _this.dataWidth = width;
    }
    if (void 0 === dataHeight) {
      _this.dataHeight = height;
    }
    if (void 0 === left) {
      _this.left = 0;
    }
    if (void 0 === top) {
      _this.top = 0;
    }
    if (_this.left + width > _this.dataWidth || _this.top + height > _this.dataHeight) {
      throw new IllegalArgumentException_1.default("Crop rectangle does not fit within image data.");
    }
    return _this;
  }
  RGBLuminanceSource2.prototype.getRow = function(y, row) {
    if (y < 0 || y >= this.getHeight()) {
      throw new IllegalArgumentException_1.default("Requested row is outside the image: " + y);
    }
    var width = this.getWidth();
    if (row === null || row === void 0 || row.length < width) {
      row = new Uint8ClampedArray(width);
    }
    var offset = (y + this.top) * this.dataWidth + this.left;
    System_1.default.arraycopy(this.luminances, offset, row, 0, width);
    return row;
  };
  RGBLuminanceSource2.prototype.getMatrix = function() {
    var width = this.getWidth();
    var height = this.getHeight();
    if (width === this.dataWidth && height === this.dataHeight) {
      return this.luminances;
    }
    var area = width * height;
    var matrix = new Uint8ClampedArray(area);
    var inputOffset = this.top * this.dataWidth + this.left;
    if (width === this.dataWidth) {
      System_1.default.arraycopy(this.luminances, inputOffset, matrix, 0, area);
      return matrix;
    }
    for (var y = 0; y < height; y++) {
      var outputOffset = y * width;
      System_1.default.arraycopy(this.luminances, inputOffset, matrix, outputOffset, width);
      inputOffset += this.dataWidth;
    }
    return matrix;
  };
  RGBLuminanceSource2.prototype.isCropSupported = function() {
    return true;
  };
  RGBLuminanceSource2.prototype.crop = function(left, top, width, height) {
    return new RGBLuminanceSource2(this.luminances, width, height, this.dataWidth, this.dataHeight, this.left + left, this.top + top);
  };
  RGBLuminanceSource2.prototype.invert = function() {
    return new InvertedLuminanceSource_1.default(this);
  };
  return RGBLuminanceSource2;
}(LuminanceSource_1.default);
RGBLuminanceSource$1.default = RGBLuminanceSource;
(function(exports) {
  function __export(m) {
    for (var p in m)
      if (!exports.hasOwnProperty(p))
        exports[p] = m[p];
  }
  Object.defineProperty(exports, "__esModule", { value: true });
  __export(BrowserBarcodeReader$1);
  __export(BrowserCodeReader$1);
  __export(BrowserDatamatrixCodeReader$1);
  __export(BrowserMultiFormatReader$1);
  __export(BrowserQRCodeReader$1);
  __export(BrowserQRCodeSvgWriter$1);
  __export(HTMLCanvasElementLuminanceSource$1);
  __export(VideoInputDevice$1);
  var ArgumentException_12 = ArgumentException$1;
  exports.ArgumentException = ArgumentException_12.default;
  var ArithmeticException_12 = ArithmeticException$1;
  exports.ArithmeticException = ArithmeticException_12.default;
  var ChecksumException_12 = ChecksumException$1;
  exports.ChecksumException = ChecksumException_12.default;
  var Exception_12 = Exception$1;
  exports.Exception = Exception_12.default;
  var FormatException_12 = FormatException$1;
  exports.FormatException = FormatException_12.default;
  var IllegalArgumentException_12 = IllegalArgumentException$1;
  exports.IllegalArgumentException = IllegalArgumentException_12.default;
  var IllegalStateException_12 = IllegalStateException$1;
  exports.IllegalStateException = IllegalStateException_12.default;
  var NotFoundException_12 = NotFoundException$1;
  exports.NotFoundException = NotFoundException_12.default;
  var ReaderException_12 = ReaderException$1;
  exports.ReaderException = ReaderException_12.default;
  var ReedSolomonException_12 = ReedSolomonException$1;
  exports.ReedSolomonException = ReedSolomonException_12.default;
  var UnsupportedOperationException_12 = UnsupportedOperationException$1;
  exports.UnsupportedOperationException = UnsupportedOperationException_12.default;
  var WriterException_12 = WriterException$1;
  exports.WriterException = WriterException_12.default;
  var BarcodeFormat_12 = BarcodeFormat$1;
  exports.BarcodeFormat = BarcodeFormat_12.default;
  var Binarizer_12 = Binarizer$1;
  exports.Binarizer = Binarizer_12.default;
  var BinaryBitmap_12 = BinaryBitmap$1;
  exports.BinaryBitmap = BinaryBitmap_12.default;
  var DecodeHintType_12 = DecodeHintType$1;
  exports.DecodeHintType = DecodeHintType_12.default;
  var InvertedLuminanceSource_12 = InvertedLuminanceSource$1;
  exports.InvertedLuminanceSource = InvertedLuminanceSource_12.default;
  var LuminanceSource_12 = LuminanceSource$1;
  exports.LuminanceSource = LuminanceSource_12.default;
  var MultiFormatReader_12 = MultiFormatReader$1;
  exports.MultiFormatReader = MultiFormatReader_12.default;
  var MultiFormatWriter_1 = MultiFormatWriter$1;
  exports.MultiFormatWriter = MultiFormatWriter_1.default;
  var PlanarYUVLuminanceSource_1 = PlanarYUVLuminanceSource$1;
  exports.PlanarYUVLuminanceSource = PlanarYUVLuminanceSource_1.default;
  var Result_12 = Result$1;
  exports.Result = Result_12.default;
  var ResultMetadataType_12 = ResultMetadataType$1;
  exports.ResultMetadataType = ResultMetadataType_12.default;
  var RGBLuminanceSource_1 = RGBLuminanceSource$1;
  exports.RGBLuminanceSource = RGBLuminanceSource_1.default;
  var BitArray_12 = BitArray$1;
  exports.BitArray = BitArray_12.default;
  var BitMatrix_12 = BitMatrix$1;
  exports.BitMatrix = BitMatrix_12.default;
  var BitSource_12 = BitSource$1;
  exports.BitSource = BitSource_12.default;
  var CharacterSetECI_12 = CharacterSetECI;
  exports.CharacterSetECI = CharacterSetECI_12.default;
  var DecoderResult_12 = DecoderResult$1;
  exports.DecoderResult = DecoderResult_12.default;
  var DefaultGridSampler_12 = DefaultGridSampler$1;
  exports.DefaultGridSampler = DefaultGridSampler_12.default;
  var DetectorResult_12 = DetectorResult$1;
  exports.DetectorResult = DetectorResult_12.default;
  var EncodeHintType_12 = EncodeHintType$1;
  exports.EncodeHintType = EncodeHintType_12.default;
  var GlobalHistogramBinarizer_12 = GlobalHistogramBinarizer$1;
  exports.GlobalHistogramBinarizer = GlobalHistogramBinarizer_12.default;
  var GridSampler_12 = GridSampler$1;
  exports.GridSampler = GridSampler_12.default;
  var GridSamplerInstance_12 = GridSamplerInstance$1;
  exports.GridSamplerInstance = GridSamplerInstance_12.default;
  var HybridBinarizer_12 = HybridBinarizer$1;
  exports.HybridBinarizer = HybridBinarizer_12.default;
  var PerspectiveTransform_12 = PerspectiveTransform$1;
  exports.PerspectiveTransform = PerspectiveTransform_12.default;
  var StringUtils_12 = StringUtils$1;
  exports.StringUtils = StringUtils_12.default;
  var MathUtils_12 = MathUtils$1;
  exports.MathUtils = MathUtils_12.default;
  var WhiteRectangleDetector_12 = WhiteRectangleDetector$1;
  exports.WhiteRectangleDetector = WhiteRectangleDetector_12.default;
  var GenericGF_12 = GenericGF$1;
  exports.GenericGF = GenericGF_12.default;
  var GenericGFPoly_12 = GenericGFPoly$1;
  exports.GenericGFPoly = GenericGFPoly_12.default;
  var ReedSolomonDecoder_12 = ReedSolomonDecoder$1;
  exports.ReedSolomonDecoder = ReedSolomonDecoder_12.default;
  var ReedSolomonEncoder_12 = ReedSolomonEncoder$1;
  exports.ReedSolomonEncoder = ReedSolomonEncoder_12.default;
  var DataMatrixReader_12 = DataMatrixReader$1;
  exports.DataMatrixReader = DataMatrixReader_12.default;
  var QRCodeReader_12 = QRCodeReader$1;
  exports.QRCodeReader = QRCodeReader_12.default;
  var QRCodeWriter_12 = QRCodeWriter$1;
  exports.QRCodeWriter = QRCodeWriter_12.default;
  var ErrorCorrectionLevel_12 = ErrorCorrectionLevel;
  exports.QRCodeDecoderErrorCorrectionLevel = ErrorCorrectionLevel_12.default;
  var Encoder_12 = Encoder$1;
  exports.QRCodeEncoder = Encoder_12.default;
  var QRCode_12 = QRCode$1;
  exports.QRCodeEncoderQRCode = QRCode_12.default;
  var OneDReader_12 = OneDReader$1;
  exports.OneDReader = OneDReader_12.default;
  var EAN13Reader_12 = EAN13Reader$1;
  exports.EAN13Reader = EAN13Reader_12.default;
  var Code128Reader_12 = Code128Reader$1;
  exports.Code128Reader = Code128Reader_12.default;
  var ITFReader_12 = ITFReader$1;
  exports.ITFReader = ITFReader_12.default;
  var Code39Reader_12 = Code39Reader$1;
  exports.Code39Reader = Code39Reader_12.default;
  var RSS14Reader_12 = RSS14Reader$1;
  exports.RSS14Reader = RSS14Reader_12.default;
  var MultiFormatOneDReader_12 = MultiFormatOneDReader$1;
  exports.MultiformatReader = MultiFormatOneDReader_12.default;
})(esm5);
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "scanner-container"
  }, [_c("video", {
    ref: "scanner",
    attrs: {
      "poster": "data:image/gif,AAAA"
    }
  }), _c("div", {
    staticClass: "overlay-element"
  }), _c("div", {
    staticClass: "laser"
  })]), _vm.capabilities && _vm.capabilities.zoom ? _c("div", {
    staticClass: "d-flex align-items-center justify-content-center p-4"
  }, [_c("a-icon", {
    attrs: {
      "type": "zoom-out"
    }
  }), _c("a-slider", {
    staticClass: "w-100 mx-3 my-0",
    style: {
      maxWidth: "300px"
    },
    attrs: {
      "value": _vm.zoomValue,
      "min": _vm.capabilities.zoom.min,
      "max": _vm.capabilities.zoom.max,
      "step": 0.1,
      "tooltip-visible": false
    },
    on: {
      "change": _vm.onZoom
    }
  }), _c("a-icon", {
    attrs: {
      "type": "zoom-in"
    }
  })], 1) : _vm._e(), _c("canvas", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "canvas",
    attrs: {
      "width": _vm.canvasWidth,
      "height": _vm.canvasHeight
    }
  })]);
};
var staticRenderFns$1 = [];
var StreamBarcodeReader_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "StreamBarcodeReader",
  props: {
    barcodeType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      apiUrl,
      isLoading: true,
      codeReader: void 0,
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices,
      controls: void 0,
      decodeResult: "",
      imageUrl: "",
      canvasWidth: 300,
      canvasHeight: 180,
      videoTrack: void 0,
      capabilities: void 0,
      zoomValue: 1
    };
  },
  mounted() {
    const hints = /* @__PURE__ */ new Map();
    const formats = [esm5.BarcodeFormat.CODE_128];
    hints.set(esm5.DecodeHintType.POSSIBLE_FORMATS, formats);
    this.codeReader = new esm5.BrowserMultiFormatReader(hints);
    this.start();
    this.$refs.scanner.oncanplay = (event) => {
      this.isLoading = false;
      this.$emit("loaded");
    };
  },
  beforeDestroy() {
    this.codeReader.reset();
  },
  methods: {
    start() {
      this.codeReader.decodeFromVideoDevice(void 0, this.$refs.scanner, (result, err) => {
        if (result) {
          this.$emit("scanned", result.text);
        }
      });
    },
    onZoom(value) {
      this.zoomValue = value;
      this.videoTrack[0].applyConstraints({
        advanced: [{ zoom: value }]
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0bb08db8", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var StreamBarcodeReader = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    staticClass: "barcode-scanner d-flex flex-column align-items-center",
    attrs: {
      "after-close": _vm.cancelScanning,
      "destroy-on-close": "",
      "closable": false,
      "body-style": {
        padding: "24px 0px"
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancelScanning
          }
        }, [_vm._v(" Cancel Scanning ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalVisible,
      callback: function($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("stream-barcode-reader", {
    staticClass: "pb-2",
    attrs: {
      "barcode-type": _vm.barcodeType
    },
    on: {
      "scanned": _vm.onScanned
    }
  })], 1);
};
var staticRenderFns = [];
var BarcodeScanner_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "BarcodeScanner",
  components: {
    StreamBarcodeReader
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    barcodeType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      modalVisible: this.visible
    };
  },
  watch: {
    visible(newVal) {
      this.modalVisible = newVal;
    }
  },
  methods: {
    cancelScanning() {
      this.$emit("update:visible", false);
    },
    onScanned(a) {
      console.log(a);
      this.$emit("change", a);
      this.cancelScanning();
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "57e9217e", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var BarcodeScanner = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { BarcodeScanner as default };
